import InsectTypes from '@/mixins/insectTypes';

export default {
  mixins: [InsectTypes],
  methods: {
    calcRouteCounts(insectCounts) {
      const result = { indoor: 0, outdoor: 0 };

      Object.keys(insectCounts).forEach((insectType) => {
        const routeType = this.INSECT_TYPES[insectType].route;
        result[routeType] += insectCounts[insectType];
      });

      return result;
    },
  },
};
