<template>
  <pie-chart :data="chartData" :options="chartOptions" />
</template>

<i18n lang="yaml">
ja:
  others: '他'
  top5: '捕獲上位5種'
en:
  others: 'Others'
  top5: 'Top 5 Species'
</i18n>

<script>
import palette from 'google-palette';

import InsectTypes from '@/mixins/insectTypes';

import PieChart from '@/components/atoms/PieChart';

export default {
  components: {
    PieChart,
  },
  mixins: [InsectTypes],
  props: {
    insectCounts: {
      type: Object,
      required: true,
    },
  },
  computed: {
    insects() {
      const insects = this._.map(this.insectCounts, (count, key) => ({
        ...this.INSECT_TYPES[key],
        count,
      }));
      return this.addColor(insects);
    },
    insectsOther() {
      return this.insects.filter(x => x.label === this.INSECT_TYPES.others.label)[0];
    },
    insectsWithoutOther() {
      return this.insects.filter(x => x.label !== this.INSECT_TYPES.others.label);
    },
    insectsSortedByCount() {
      return this.insectsWithoutOther.sort((a, b) => {
        if (a.count < b.count) return 1;
        if (a.count > b.count) return -1;
        if (a.symbol > b.symbol) return 1;
        if (a.symbol < b.symbol) return -1;
        return 0;
      });
    },
    generateChartData() {
      const labels = [];
      const counts = [];
      const colors = [];
      let othersCount = this.insectsOther ? this.insectsOther.count : 0;

      for (let i = 0; i < this.insectsSortedByCount.length; i += 1) {
        if (i < 5) {
          labels.push(this.insectsSortedByCount[i].label);
          counts.push(this.insectsSortedByCount[i].count);
          colors.push(this.insectsSortedByCount[i].color);
        } else {
          othersCount += this.insectsSortedByCount[i].count;
        }
      }
      if (othersCount !== 0) {
        labels.push(this.$t('others'));
        counts.push(othersCount);
        colors.push('#c0c0c0');
      }
      return { label: labels, count: counts, color: colors };
    },
    chartData() {
      return {
        labels: this.generateChartData.label,
        datasets: [
          {
            backgroundColor: this.generateChartData.color,
            data: this.generateChartData.count,
          },
        ],
      };
    },
    chartOptions() {
      return {
        plugins: {
          legend: {
            // BarChartとPieChartの動きを統一する為、クリックイベントを無効化
            onClick: function () {},
          },
          title: {
            display: true,
            font: { size: 18 },
            text: this.$t('top5'),
          },
        },
      };
    },
  },
  methods: {
    addColor(insects) {
      const colors = palette('mpn65', insects.length).map(hex => `#${hex}`);
      insects.sort((a, b) => (a.symbol < b.symbol ? 1 : -1));
      insects.forEach((v, i) => { v.color = colors[i]; return 0; });
      return insects;
    },
  },
};
</script>
