<script>
import Pagination from '@/mixins/pagination';

export default {
  name: 'data-list--list',
  mixins: [Pagination],
  render(h) {
    return h('div', { class: 'data-list data-list--list' }, this.pagedItems.map((item, index) => (
      h('div', this.getItemProps(item, { class: 'data-list__item' }, index), this.$slots.default.map(node => (
        h('div', { class: 'data-list__field' }, [
          h('div', { class: 'field__label pb-1' }, node.data.attrs.name),
          h('div', { class: 'field__value pb-1' }, node.data.scopedSlots.default({ index, item })),
        ])
      )))
    )));
  },
  props: {
    getItemProps: {
      type: Function,
      default: (item, props, index) => props, // eslint-disable-line no-unused-vars
    },
    items: Array,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'

.data-list--list
  .data-list
    &__field
      display: flex

    &__item
      padding: 2rem 0

      &:not(:last-child)
        border-bottom: #fff solid 1px

  .field
    &__label,
    &__value
      word-break: break-word

    &__label
      border-right: map-get($material-theme, 'fg-color') solid 1px
      box-sizing: content-box
      margin-right: 1rem
      min-width: 30%
      padding-right: 1rem
      width: 30%

    &__value
      flex-grow: 1
</style>
