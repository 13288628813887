<template>
  <div class="icon" :style="style">
    <div :style="adjust">
      <img
        :src="iconUrl"
        :alt="type"
        :class="iconClass"
        v-if="availableIcons.includes(type)"
      />
      <div class="icon__badge" v-if="availableBadges.includes(badge)">
        <img :src="badgeUrl" :alt="badge" :class="badgeClass" />
      </div>
    </div>
  </div>
</template>

<script>
import { SENSOR_TYPES } from '@/mixins/sensorTypes';

const bugIcon = require('../assets/sensor-icon/bug.png');
const hykecamIcon = require('../assets/sensor-icon/hykecam.png');
const ratIcon = require('../assets/sensor-icon/rat.png');
const userIcon = require('../assets/user-icon.png');

const ICONS = {
  [SENSOR_TYPES.INSECT_V2]: bugIcon,
  [SENSOR_TYPES.FLY_COUNT]: bugIcon,
  [SENSOR_TYPES.FLY_SCAN]: bugIcon,
  [SENSOR_TYPES.FLY]: bugIcon,
  [SENSOR_TYPES.HYKECAM]: hykecamIcon,
  [SENSOR_TYPES.RAT]: ratIcon,
  user: userIcon,
};

const BADGES = {};
BADGES.warning = require('../assets/sensor-icon/warning.png');

export default {
  name: 'icon',
  computed: {
    availableIcons() {
      return Object.keys(ICONS);
    },
    iconUrl() {
      return ICONS[this.type];
    },
    iconClass() {
      return `icon-${this.type}`;
    },
    availableBadges() {
      return Object.keys(BADGES);
    },
    badgeUrl() {
      return BADGES[this.badge];
    },
    badgeClass() {
      return `badge-${this.type}`;
    },
    style() {
      return {
        height: `${this.size}px`,
        maxHeight: `${this.size}px`,
        maxWidth: `${this.size}px`,
        padding: `${this.size * 0.5}px`,
        width: `${this.size}px`,
      };
    },
    adjust() {
      return {
        margin: `-${this.size * 0.5}px`,
        height: `${this.size}px`,
        width: `${this.size}px`,
      };
    },
  },
  props: {
    size: {
      type: Number,
      default: 48,
    },
    badge: {
      type: String,
    },
    type: {
      type: String,
    },
  },
};
</script>

<style scoped lang="sass">
.icon
  display: inline-block
  position: relative

  img
    width: 100%
    height: 100%

  &__badge
    position: absolute
    width: 40%
    height: 25%
    top: 0
    right: 0

    img
      display: block
      width: auto
      height: 100%
      margin-left: auto
</style>
