import Vue from 'vue';
import Map from 'collections/map';
import { SENSOR_TYPES } from '@/mixins/sensorTypes';
import i18n from '../i18n';
import * as types from '../constants/mutationTypes';

export const actions = {
  getUserInfo({ commit }) {
    return Vue.http.get('/api/user_info').then((response) => {
      commit(types.SET_CURRENT_USER, response.data);
      return response.data;
    });
  },
  updateUserInfo({ commit }, attributes) {
    return Vue.http.post('/api/user_info', attributes).then((response) => {
      commit(types.SET_CURRENT_USER, response.data);
      return response.data;
    });
  },
  clearUserInfo({ commit }) {
    commit(types.CLEAR_CURRENT_USER);
  },
  getUsedTags({ commit }, params) {
    return Vue.http.get('/api/user_info/used_tags', { params }).then(response => response.data);
  },
  createUser({ commit }, attributes) {
    return Vue.http.post('/api/users', attributes).then((response) => {
      commit(types.SET_USER, { email: response.data.email, item: response.data });
      return response;
    });
  },
  registerUser({ commit }, attributes) {
    return Vue.http.post('/api/register', attributes);
  },
  updateUser({ commit }, { email, attributes }) {
    return Vue.http.put(`/api/users/${email}`, attributes).then((response) => {
      commit(types.SET_USER, { email, item: response.data });
      return response;
    });
  },
  requestUser({ commit }, email) {
    return Vue.http.get(`/api/users/${email}`).then((response) => {
      commit(types.SET_USER, { email, item: response.data });
      return response;
    });
  },
  requestUsers({ commit }) {
    return Vue.http.get('/api/users').then((response) => {
      commit(types.SET_USERS, response.data);
      return response.data;
    });
  },
  clearUsers({ commit }) {
    commit(types.CLEAR_USERS);
  },
  syncBlockedState(_, email) {
    return Vue.http.post(`/api/users/${email}/sync`);
  },
  requestPasswordReset(_, email) {
    return Vue.http.post(`/api/users/${email}/request_password_reset`);
  },
  assumeIdentity({ commit }, user) {
    commit(types.ASSUME_IDENTITY, user);
  },
  resetIdentity({ commit }) {
    commit(types.ASSUME_IDENTITY, null);
  },
};

export const mutations = {
  SET_CURRENT_USER(state, userData) {
    state.current = userData;
    if (userData.locale) {
      i18n.locale = userData.locale;
    }
  },
  CLEAR_CURRENT_USER(state) {
    state.current = {};
  },
  SET_USER(state, { email, item }) {
    state.items = state.items.concat([
      [email, item],
    ]);
  },
  SET_USERS(state, items) {
    state.items = state.items.concat(
      items.map(item => [item.email, item]),
    );
  },
  CLEAR_USERS(state) {
    state.items = new Map();
  },
  ADD_ORGANIZATION_USER(state, { id, user }) {
    const item = state.items.get(user.email);
    state.items = state.items.concat([
      [user.email, { ...item, owner_id: id }],
    ]);
  },
  REMOVE_ORGANIZATION_USER(state, { email }) {
    const item = state.items.get(email);
    state.items = state.items.concat([
      [email, { ...item, owner_id: null }],
    ]);
  },
  ASSUME_IDENTITY(state, user) {
    state.assumedUser = user;
  },
};

export const getters = {
  getCurrentUser(state) {
    return state.current;
  },
  currentUserRetrieved(state) {
    return state.current && state.current.email;
  },
  getUser(state) {
    return email => state.items.get(email);
  },
  getUsers(state) {
    return state.items;
  },
  hasPermission(state) {
    return (permission) => {
      if (!getters.currentUserRetrieved(state)) {
        return false;
      }
      if (Vue._.values(SENSOR_TYPES).includes(permission)) {
        return state.current.allowed_sensor_types.includes(permission);
      }
      return state.current.permissions.includes(permission);
    };
  },
  assumedUser(state) {
    return state.assumedUser;
  },
};

export const state = () => ({
  current: {},
  items: new Map(),
  assumedUser: null,
});
