<template>
  <round-button
    icon="file_download"
    @click="exportCsv"
  >
    {{ $t('label') }}
  </round-button>
</template>

<i18n lang="yaml">
ja:
  label: 'CSV出力'
  error:
    selectData: 'CSV出力するデータを選択して下さい'

en:
  label: 'CSV Download'
  error:
    selectData: 'Select the data for which you want to download as CSV.'
</i18n>

<script>
import moment from 'moment';
import { arrayToCsvRow, exportCsv } from '@/libs/csv';
import InsectTypes from '@/mixins/insectTypes';
import LocalizedTerms from '@/mixins/localizedTerms';
import Notifications from '@/mixins/notifications';

import RoundButton from '@/components/atoms/RoundButton';

const DATE_FORMAT = 'YYYY/MM/DD';
const TIME_FORMAT = 'YYYY/MM/DD HH:mm:ss';

const MILLISECONDS_PER_DAY = 24 * 60 * 60 * 1000;

const calcDays = (event) => {
  const beginning = moment(event.installed_at).startOf('day');
  const end = moment(event.collected_at).endOf('day');
  return Math.ceil(end.diff(beginning) / MILLISECONDS_PER_DAY);
};

export default {
  name: 'export-csv-button',
  props: {
    events: {
      type: Array,
      required: true,
    },
    place: {
      type: String,
      required: true,
    },
  },
  components: {
    RoundButton,
  },
  mixins: [InsectTypes, LocalizedTerms, Notifications],
  computed: {
    columns() {
      const stationTerm = t => this.term(`station.${t}`);

      const beforeInsectCounts = [
        { header: 'No', value: (_, index) => index + 1 },
        { header: this.term('sensor.place'), value: () => this.place },
        { header: stationTerm('trap_no'), value: event => event.trap_no },
        { header: stationTerm('installed_at'), value: event => moment(event.installed_at).format(DATE_FORMAT) },
        { header: stationTerm('collected_at'), value: event => moment(event.collected_at).format(DATE_FORMAT) },
        { header: stationTerm('days'), value: event => calcDays(event) },
        { header: stationTerm('month'), value: event => event.month },
        { header: stationTerm('upload_time'), value: event => moment.unix(event.upload_time).format(TIME_FORMAT) },
        { header: stationTerm('photo_count'), value: event => event.photoCount },
      ];
      let insectCounts = this._.map(this.INSECT_TYPES, (type, key) => (
        { header: type.label, value: event => event.insect_counts[key] || 0 }
      ));
      const withoutOther = insectCounts.filter(x => x.header !== this.INSECT_TYPES.others.label);
      const other = insectCounts.filter(x => x.header === this.INSECT_TYPES.others.label)[0];
      insectCounts = withoutOther.concat(other);

      const afterInsectCounts = [
        { header: stationTerm('insect_count'), value: event => event.insectCount },
        { header: stationTerm('index'), value: event => (event.insectCount / calcDays(event)).toFixed(2) },
      ];
      return this._.concat(beforeInsectCounts, insectCounts, afterInsectCounts);
    },
  },
  methods: {
    exportCsv() {
      if (Object.keys(this.events).length === 0) {
        this.notifyError('error.selectData');
      } else {
        const headerRow = this._.map(this.columns, 'header');
        const dataRows = this._.map(this.events, (event, index) =>
          this.columns.map(c => c.value(event, index)),
        );
        const rows = this._.concat([headerRow], dataRows);
        const csvString = rows.map(arrayToCsvRow).join('\n');
        exportCsv(csvString, `${this.place}.csv`);
      }
    },
  },
};
</script>
