import Vue from 'vue';
import Map from 'collections/map';
import * as types from '../constants/mutationTypes';

export const actions = {
  requestOrganizations({ commit }) {
    return Vue.http.get('/api/organizations').then((response) => {
      commit(types.SET_ORGANIZATIONS, response.data);
      return response.data;
    });
  },
  requestOrganization({ commit }, id) {
    return Vue.http.get(`/api/organizations/${id}`).then((response) => {
      commit(types.SET_ORGANIZATIONS, [response.data]);
      return response.data;
    });
  },
  requestOrganizationUsers({ commit }, id) {
    return Vue.http.get(`/api/organizations/${id}/users`).then((response) => {
      commit(types.SET_ORGANIZATION_USERS, { id, items: response.data });
      return response.data;
    });
  },
  removeOrganizationUser({ commit }, { id, email }) {
    return Vue.http.delete(`/api/organizations/${id}/users/${email}`).then((response) => {
      commit(types.REMOVE_ORGANIZATION_USER, { id, email });
      return response.data;
    });
  },
  addOrganizationUser({ commit }, { id, user }) {
    commit(types.ADD_ORGANIZATION_USER, { id, user });
  },
  sendOrganizationInvitation({ commit }, { id, email }) {
    return Vue.http.post(`/api/organizations/${id}/users`, { email }).then((response) => {
      const user = response.data;
      commit(types.ADD_ORGANIZATION_USER, { id, user });
      return user;
    });
  },
  createOrganization({ commit }, params) {
    return Vue.http.post('/api/organizations', params).then((response) => {
      commit(types.ADD_ORGANIZATION, response.data);
      return response.data;
    });
  },
  updateOrganization({ commit }, { id, params }) {
    return Vue.http.put(`/api/organizations/${id}`, params).then((response) => {
      commit(types.SET_ORGANIZATIONS, [response.data]);
      return response.data;
    });
  },
  appointOrganizationAdministrator({ commit, state }, { id, email }) {
    return Vue.http.put(`/api/organizations/${id}`, { organization_admin_email: email }).then((response) => {
      commit(types.SET_ORGANIZATIONS, [response.data]);
      const users = state.users[id] || [];
      commit(types.SET_ORGANIZATION_USERS, {
        id,
        items: users.map((user) => ({
          ...user,
          admin: user.email === email,
        })),
      });
      return response.data;
    });
  },
};

export const mutations = {
  ADD_ORGANIZATION(state, item) {
    state.items = state.items.concat([
      [item.id, item],
    ]);
  },
  ADD_ORGANIZATION_USER(state, { id, user }) {
    const items = state.users[id] || [];
    state.users = {
      ...state.users,
      [id]: [...items, user],
    };
  },
  SET_ORGANIZATIONS(state, items) {
    state.items = state.items.concat(
      items.map(item => [item.id, item]),
    );
  },
  SET_ORGANIZATION_USERS(state, { id, items }) {
    state.users = {
      ...state.users,
      [id]: items,
    };
  },
  REMOVE_ORGANIZATION_USER(state, { id, email }) {
    const items = state.users[id] || [];
    state.users = {
      ...state.users,
      [id]: items.filter((user) => user.email !== email),
    };
  },
};

export const getters = {
  getOrganizations(state) {
    return state.items;
  },
  getOrganization(state) {
    return id => state.items.get(id);
  },
  getOrganizationUsers(state) {
    return id => state.users[id] || [];
  },
};

export const state = () => ({
  items: new Map(),
  users: {},
});
