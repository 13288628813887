var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "document-list pb-4" }, [
    _c(
      "div",
      { staticClass: "document-list-btn" },
      [
        _c(
          "round-button",
          {
            staticClass: "document-list-round-btn ma-0",
            attrs: {
              icon: _vm.expanded
                ? "fa-chevron-circle-up"
                : "fa-chevron-circle-down",
            },
            on: {
              click: function ($event) {
                _vm.expanded = !_vm.expanded
              },
            },
          },
          [
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v(_vm._s(_vm.SENSOR_TYPE_NAMES[_vm.sensorType])),
            ]),
          ]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _vm.expanded
      ? _c(
          "div",
          [
            _vm._l(_vm.documents, function (item) {
              return _c(
                "div",
                { staticClass: "document-list-item pl-5 pt-2" },
                [
                  item.fileName
                    ? _c("div", [
                        _c("span", { staticClass: "font-weight-bold pr-3" }, [
                          _vm._v("・" + _vm._s(_vm.$t(item.title))),
                        ]),
                        _vm._v(" "),
                        _vm.isRecently(item.uploaded)
                          ? _c(
                              "span",
                              { staticClass: "document-list-item-new" },
                              [_vm._v("New")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                return _vm.download(item.fileName)
                              },
                            },
                          },
                          [_vm._v(_vm._s(item.fileName))]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "document-list-item-timestamp" },
                          [_vm._v(_vm._s(_vm.formatDate(item.uploaded)))]
                        ),
                      ])
                    : _vm._e(),
                ]
              )
            }),
            _vm._v(" "),
            _vm.sensorType == "station"
              ? _c(
                  "div",
                  { staticClass: "pl-5 pt-3" },
                  [
                    _c("youtube", {
                      attrs: {
                        "video-id": _vm.STATION_REPORT_FEATURE_VIDEO_ID,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }