<template>
  <data-table
    :headers="headers"
    :items="sortedRows"
    :options.sync="options"
    :no-data-text="noDataText"
    :loading="loading"
    externallySorted
    checkbox
    v-model="selectedRows"
  >
    <template #item.installed_at="{ item }">
      {{ formatDate(item.installed_at) }}
    </template>
    <template #item.collected_at="{ item }">
      {{ formatDate(item.collected_at) }}
    </template>
    <template #item.upload_time="{ item }">
      {{ formatTimestamp(item.upload_time) }}
    </template>
    <template #item.photoCount="{ item }">
      {{ hideWhileProcessing(item, item.photoCount) }}
    </template>
    <template #item.insectCount="{ item }">
      {{ hideWhileProcessing(item, item.insectCount) }}
    </template>
    <template #item.actions="{ item }">
      <span v-if="item.processing">
        {{ $t('processing') }}
      </span>
      <template v-else>
        <v-btn text icon @click.stop="viewEvent(item)" data-testid="view_button">
          <v-icon primary class="ma-2 material-icons">collections</v-icon>
        </v-btn>
        <v-btn text icon @click.stop="editEvent(item)" data-testid="edit_button">
          <v-icon primary class="ma-2">fa-pencil</v-icon>
        </v-btn>
      </template>
      <station-event-polling
        :event="item"
        @eventUpdated="event => $emit('eventUpdated', event)"
      />
    </template>
  </data-table>
</template>

<i18n lang="yaml">
ja:
  loading: '読込中'
  no_data: 'データがありません'
  time_format: 'YYYY年MM月DD日 HH:mm'
  date_format: 'YYYY年MM月DD日'
  processing: '解析中...'

en:
  loading: 'Now loading'
  no_data: 'No data available'
  time_format: 'YYYY-MM-DD HH:mm'
  date_format: 'YYYY-MM-DD'
  processing: 'Processing...'
</i18n>

<script>
import moment from 'moment';

import LocalizedTerms from '@/mixins/localizedTerms';
import DataTable from '@/components/atoms/DataTable';
import StationEventPolling from '@/components/StationEventPolling';

export default {
  name: 'station-place-event-table',
  components: {
    DataTable,
    StationEventPolling,
  },
  mixins: [LocalizedTerms],
  props: {
    events: Array,
    loading: Boolean,
  },
  data() {
    return {
      options: {
        sortBy: ['upload_time'],
        sortDesc: [true],
        itemsPerPage: 100,
      },
      selectedRows: [],
    };
  },
  computed: {
    headers() {
      return [
        { text: this.term('station.trap_no'), value: 'trap_no' },
        { text: this.term('station.month'), value: 'month' },
        { text: this.term('station.installed_at'), value: 'installed_at' },
        { text: this.term('station.collected_at'), value: 'collected_at' },
        { text: this.term('station.upload_time'), value: 'upload_time' },
        { text: this.term('station.photo_count'), value: 'photoCount' },
        { text: this.term('station.insect_count'), value: 'insectCount' },
        { text: '', value: 'actions', sortable: false },
      ];
    },
    eventRows() {
      return this.events.map(this.buildEventRow);
    },
    noDataText() {
      if (this.loading) {
        return this.$t('loading');
      }

      return this.$t('no_data');
    },
    sortedSelectedRows() {
      const selectedIds = new Set(this.selectedRows.map(row => row.id));
      return this.sortedRows.filter(row => selectedIds.has(row.id));
    },
    sortedRows() {
      const { sortBy, sortDesc } = this.options;
      return this._.orderBy(
        this.eventRows,
        sortBy,
        sortDesc.map(desc => (desc ? 'desc' : 'asc')),
      );
    },
  },
  methods: {
    buildEventRow(event) {
      return {
        ...event,
        isSelectable: !event.processing, // used by DataTable
        photoCount: this.photoCount(event),
        insectCount: this.insectCount(event),
      };
    },
    editEvent(eventRow) {
      this.$emit('editEvent', eventRow);
    },
    formatDate(date) {
      return moment(date).format(this.$t('date_format'));
    },
    formatTimestamp(timestamp) {
      return moment.unix(timestamp).format(this.$t('time_format'));
    },
    hideWhileProcessing(eventRow, value) {
      return eventRow.processing ? '-' : value;
    },
    insectCount(event) {
      return this._.sum(Object.values(event.insect_counts));
    },
    photoCount(event) {
      return Object.keys(event.attachments).filter(key => key.startsWith('photo')).length;
    },
    viewEvent(eventRow) {
      const index = this._.findIndex(this.sortedRows, ev => ev.id === eventRow.id);
      this.$emit('viewEvents', { events: this.sortedRows, index });
    },
  },
  watch: {
    sortedSelectedRows(value) {
      this.$emit('sortedSelectedEventsUpdated', value);
    },
  },
};
</script>

<style lang="sass" scoped>
</style>
