var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "entity-list-layout",
    {
      staticClass: "admin-account-list",
      attrs: {
        title: "ACCOUNT",
        count: _vm.accounts.length,
        query: _vm.search,
        "search-hint": _vm.$t("label.search"),
      },
      on: {
        "update:query": function ($event) {
          _vm.search = $event
        },
      },
      scopedSlots: _vm._u([
        {
          key: "title-area-content",
          fn: function () {
            return [
              _c(
                "round-button",
                { attrs: { icon: "add_circle", to: "/admin/accounts/new" } },
                [
                  _vm._v(
                    "\n      " + _vm._s(_vm.$t("label.add_account")) + "\n    "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("v-checkbox", {
                attrs: {
                  disabled: _vm.blocked,
                  "hide-details": "",
                  label: _vm.$t("label.deactivated_users_only"),
                },
                model: {
                  value: _vm.deactivated,
                  callback: function ($$v) {
                    _vm.deactivated = $$v
                  },
                  expression: "deactivated",
                },
              }),
              _vm._v(" "),
              _c("v-checkbox", {
                attrs: {
                  disabled: _vm.deactivated,
                  "hide-details": "",
                  label: _vm.$t("label.blocked_users_only"),
                },
                model: {
                  value: _vm.blocked,
                  callback: function ($$v) {
                    _vm.blocked = $$v
                  },
                  expression: "blocked",
                },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "p-data-list",
        {
          attrs: { data: _vm.accounts, pagination: _vm.pagination },
          on: {
            "update:pagination": function ($event) {
              _vm.pagination = $event
            },
          },
        },
        [
          _c("data-field", {
            attrs: { name: "No", headerClass: "column--no" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [_vm._v(_vm._s(props.index + 1))]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("data-field", {
            attrs: {
              name: _vm.$t("field.organization"),
              value: "organization.name",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "admin-organization",
                            params: { id: props.item.organization.id },
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(props.item.organization.name) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("data-field", {
            attrs: {
              name: _vm.$t("field.name"),
              value: "name",
              headerClass: "column--name",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "admin-account",
                            params: { email: props.item.email },
                          },
                        },
                      },
                      [_vm._v(_vm._s(props.item.name))]
                    ),
                    _vm._v(" "),
                    !props.item.activated
                      ? _c(
                          "i",
                          {
                            staticClass: "admin-account-list__deactived-label",
                          },
                          [
                            _vm._v(
                              "(" + _vm._s(_vm.$t("label.deactivated")) + ")"
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    props.item.blocked
                      ? _c(
                          "i",
                          { staticClass: "admin-account-list__blocked-label" },
                          [_vm._v("(" + _vm._s(_vm.$t("label.blocked")) + ")")]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("data-field", {
            attrs: {
              name: _vm.$t("field.email"),
              value: "email",
              headerClass: "column--email",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [_vm._v(_vm._s(props.item.email))]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("data-field", {
            attrs: {
              name: _vm.$t("field.invited_by"),
              value: "invitedBy",
              headerClass: "column--invited_by",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(props.item.invitedBy) + "\n      "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }