<template>
  <div class="zoom" ref="container">
    <div
      class="zoom-body"
      @panzoomchange="handleZoomChange"
      ref="body"
    >
      <slot></slot>
      <resize-observer @notify="notify" />
    </div>
    <div class="zoom-control-group" v-if="!disabled">
      <div class="zoom-control">
        <v-btn color="grey darken-1" x-small depressed fab @click="zoom(getMinScale())">
          <v-icon>fullscreen_exit</v-icon>
        </v-btn>
      </div>
      <div class="zoom-control">
        <v-btn color="grey darken-1" x-small depressed fab @click="zoomIn">
          <v-icon>add</v-icon>
        </v-btn>
      </div>
      <div class="zoom-control">
        <v-btn color="grey darken-1" x-small depressed fab @click="zoomOut">
          <v-icon>remove</v-icon>
        </v-btn>
      </div>
    </div>
    <resize-observer @notify="notify" />
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import Panzoom from '@panzoom/panzoom';
import { ResizeObserver } from 'vue-resize';

export const ZoomProvider = {
  data() {
    return {
      zoom: {
        x: 0,
        y: 0,
        scale: 1,
      },
    };
  },
  methods: {
    getZoom() {
      return this.zoom;
    },
    setZoom(value) {
      this.zoom = value;
    },
    resetZoom() {
      this.zoom = {
        x: 0,
        y: 0,
        scale: 1,
      };
    },
  },
  provide() {
    return {
      getZoom: this.getZoom,
      setZoom: this.setZoom,
    };
  },
};

const divide = (a, b, c) => {
  if (a && b) {
    return a / b;
  }
  return c;
};

const ZOOM_STEP = 0.5;

export default {
  name: 'Zoom',
  components: {
    ResizeObserver,
  },
  props: {
    scale: {
      type: Number,
      default: 1, // 1 ~ *
    },
    x: {
      type: Number,
      default: 0, // 0 ~ 1
    },
    y: {
      type: Number,
      default: 0, // 0 ~ 1
    },
    disabled: Boolean,
  },
  methods: {
    centerOn(x, y) {
      const scale = this.scale * this.getMinScale();
      const container = this.$refs.container;
      const clientWidth = container.clientWidth;
      const scrollWidth = container.scrollWidth;
      const clientHeight = container.clientHeight;
      const scrollHeight = container.scrollHeight;

      let rateX = 0;
      const halfClientWidth = clientWidth * 0.5;
      const containerWidth = scrollWidth * scale;
      const containerCenterX = containerWidth * x;
      if (containerCenterX < halfClientWidth) {
        rateX = 0;
      } else if (containerCenterX > (containerWidth - halfClientWidth)) {
        rateX = 1;
      } else {
        rateX = (containerCenterX - halfClientWidth) / (containerWidth - clientWidth);
      }

      let rateY = 0;
      const halfClientHeight = clientHeight * 0.5;
      const containerHeight = scrollHeight * scale;
      const containerCenterY = containerHeight * y;
      if (containerCenterY < halfClientHeight) {
        rateY = 0;
      } else if (containerCenterY > (containerHeight - halfClientHeight)) {
        rateY = 1;
      } else {
        rateY = (containerCenterY - halfClientHeight) / (containerHeight - clientHeight);
      }

      const { left, width, top, height } = this.getBoundingRect();
      const resultX = (width * -rateX) + left;
      const resultY = (height * -rateY) + top;
      this.panzoom.pan(resultX, resultY, { contain: '' });
    },
    getBoundingRect(rateScale = this.scale) {
      const { clientHeight: height, clientWidth: width } = this.$refs.container;
      const { clientHeight: bodyHeight, clientWidth: bodyWidth } = this.$refs.body;
      const scale = rateScale * this.getMinScale();
      const scaledHeight = bodyHeight * scale;
      const scaledWidth = bodyWidth * scale;
      const diffHeight = (scaledHeight - bodyHeight) * 0.5;
      const diffWidth = (scaledWidth - bodyWidth) * 0.5;
      // eslint-disable-next-line no-mixed-operators
      const maxX = (width - scaledWidth + diffWidth) / scale;
      const minX = diffWidth / scale;
      // eslint-disable-next-line no-mixed-operators
      const maxY = (height - scaledHeight + diffHeight) / scale;
      const minY = diffHeight / scale;
      return {
        left: minX,
        right: maxX,
        width: Math.abs(maxX - minX),
        top: minY,
        bottom: maxY,
        height: Math.abs(maxY - minY),
      };
    },
    getMinScale() {
      const { clientHeight: height, clientWidth: width } = this.$refs.container;
      const { clientHeight: bodyHeight, clientWidth: bodyWidth } = this.$refs.body;
      const hScale = divide(height, bodyHeight, 0);
      const wScale = divide(width, bodyWidth, 0);
      const { minScale } = this.panzoom.getOptions();
      return Math.max(minScale, wScale, hScale);
    },
    getPanzoomValues() {
      const scale = this.scale * this.getMinScale();
      const { left, width, top, height } = this.getBoundingRect();
      const x = (width * -this.x) + left;
      const y = (height * -this.y) + top;
      return { x, y, scale };
    },
    handleZoomChange(event) {
      const { clientHeight: bodyHeight, clientWidth: bodyWidth } = this.$refs.body;
      if (bodyHeight && bodyWidth) {
        const { x, y, scale } = event.detail;
        const minScale = this.getMinScale();
        const rateScale = scale / minScale;
        this.$emit('update:scale', rateScale);

        const { left, width, top, height } = this.getBoundingRect(rateScale);
        const rateX = -divide((x - left), width, 0);
        const rateY = -divide((y - top), height, 0);
        this.$emit('update:x', rateX);
        this.$emit('update:y', rateY);
      }
    },
    refresh() {
      const { x, y, scale } = this.getPanzoomValues();
      this.panzoom.setOptions({ silent: false });
      this.panzoom.zoom(scale, { silent: true });
      this.panzoom.pan(x, y, { contain: '' });
    },
    setDisabled(value = false) {
      this.panzoom.setOptions({
        disablePan: value,
        disableZoom: value,
        cursor: value ? 'normal' : 'move',
        excludeClass: value ? '' : null,
      });
    },
    zoom(value) {
      const {
        x,
        y,
        width,
        height,
      } = this.$refs.container.getBoundingClientRect();
      this.panzoom.zoomToPoint(value, {
        clientX: x + (width * 0.5),
        clientY: y + (height * 0.5),
      }, { force: true });
    },
    zoomIn() {
      const scale = this.panzoom.getScale();
      this.zoom(scale + ZOOM_STEP);
    },
    zoomOut() {
      const scale = this.panzoom.getScale();
      this.zoom(scale - ZOOM_STEP);
    },
  },
  computed: {
    _zoomParams() {
      return {
        x: this.x,
        y: this.y,
        scale: this.scale,
      };
    },
  },
  created() {
    const REFRESH_INTERVAL = 100;
    this.notify = debounce(this.refresh, REFRESH_INTERVAL);
  },
  mounted() {
    this.panzoom = Panzoom(this.$refs.body, {
      step: 0.1,
      contain: 'outside',
      maxScale: Number.MAX_SAFE_INTEGER,
      silent: true,
    });
    this.$refs.container.addEventListener('wheel', this.panzoom.zoomWithWheel);
    this.setDisabled(this.disabled);
  },
  beforeDestroy() {
    this.$refs.container.removeEventListener('wheel', this.panzoom.zoomWithWheel);
    this.panzoom.destroy();
  },
  watch: {
    disabled(value) {
      this.setDisabled(value);
    },
    _zoomParams() {
      const { clientHeight: bodyHeight, clientWidth: bodyWidth } = this.$refs.body;
      if (bodyHeight && bodyWidth) {
        this.notify();
      }
    },
  },
};
</script>

<style scoped lang="sass">
.zoom
  height: 100%
  width: 100%
  position: relative
  font-size: 1rem

  &-body
    display: inline-block
    font-size: 0

  &-control
    button.v-btn
      color: white
      margin: 0.5em 0 0

    &-group
      position: absolute
      right: 0.5em
      bottom: 0.5em
</style>
