export default {
  data() {
    return {
      i18n_locale: this.$i18n.locale,
    };
  },
  watch: {
    '$i18n.locale'(locale) {
      this.i18n_locale = locale;
    },
  },
};
