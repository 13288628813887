<template>
  <div class="d-flex flex-column">
    <round-button
      icon="fa-upload"
      :loading="uploading"
      :disabled="uploading"
      @click="$refs.fileInput.click()"
    >
      {{ $t('button_label') }}
      <input
        style="display: none"
        type="file"
        ref="fileInput"
        accept="text/csv"
        data-testid="csv_file"
        @change='onUpload'
      />
    </round-button>
    <a
      class="text-center"
      href="#"
      @click.prevent.stop="downloadSample"
    >
      {{ $t('download_csv_sample') }}
    </a>
    <card-dialog
      persistent
      :title="$t('result_dialog.title')"
      v-model="resultDialogVisible"
      width="400px"
    >
      <div v-if="importResult" style="max-height: 500px; overflow-y: scroll;">
        <section v-if="importResult.created.length > 0">
          <h3>{{ $t('result_dialog.added_places') }}:</h3>
          <ul>
            <li v-for="place in importResult.created">{{ place }}</li>
          </ul>
        </section>
        <section v-if="importResult.already_existing.length > 0">
          <h3>{{ $t('result_dialog.already_existing_places') }}:</h3>
          <ul>
            <li v-for="place in importResult.already_existing">{{ place }}</li>
          </ul>
        </section>
        <section v-if="importResult.errors.length > 0">
          <h3>{{ $t('result_dialog.unprocessable_lines') }}:</h3>
          <ul>
            <li v-for="error in importResult.errors">
              {{ error.row }}: {{ $t(`import_error.${error.type}`) }}
            </li>
          </ul>
        </section>
      </div>
      <template #actions>
        <dialog-action @click="closeResults">OK</dialog-action>
      </template>
    </card-dialog>
  </div>
</template>

<i18n lang="yaml">
ja:
  button_label: 設置場所インポート
  download_csv_sample: CSVのサンプルをダウンロード
  sample_filename: S型CSVサンプル
  result_dialog:
    title: インポートの結果
    added_places: 追加された設置場所
    already_existing_places: 既に存在している設置場所
    unprocessable_lines: 処理できなかった行
  import_error:
    too_many_columns: 列数が多すぎます
    duplicate: 前の行の名称と重複しています
    empty_file: CSVファイルは空です
    invalid_encoding: ファイルの文字コードが不正です

en:
  button_label: Installation Site Import
  download_csv_sample: Download CSV Sample
  sample_filename: s_type_csv_sample
  result_dialog:
    title: Import Results
    added_places: Added Places
    already_existing_places: Already Existing Places
    unprocessable_lines: Unprocessable Lines
  import_error:
    too_many_columns: Too Many Columns
    duplicate: Duplicate of earlier line
    empty_file: The CSV file is empty.
    invalid_encoding: "The file's encoding is invalid"
</i18n>

<script>
import { rowArrayToCSVString, exportCsv } from '@/libs/csv';
import { getBase64 } from '@/libs/upload';

import Notifications from '@/mixins/notifications';

import CardDialog from '@/components/atoms/CardDialog';
import DialogAction from '@/components/atoms/DialogAction';
import RoundButton from '@/components/atoms/RoundButton';

export default {
  name: 'csv-import-button',
  mixins: [
    Notifications,
  ],
  components: {
    CardDialog,
    DialogAction,
    RoundButton,
  },
  data() {
    return {
      uploading: false,
      resultDialogVisible: false,
      importResult: null,
    };
  },
  computed: {
    sampleRows() {
      switch (this.$i18n.locale) {
        case 'en':
          return [
            ['Place Name'],
            ['Sample Factory A'],
            ['Sample Factory B'],
          ];
        case 'ja':
          return [
            ['設置場所名称'],
            ['サンプル工場A'],
            ['サンプル工場B'],
          ];
        default:
          throw new Error('Unknown locale');
      }
    },
  },
  inject: ['importStationPlaceCsvBase64String'],
  methods: {
    closeResults() {
      this.resultDialogVisible = false;
      this.importResult = null;
    },
    downloadSample() {
      const csvString = rowArrayToCSVString(this.sampleRows);
      exportCsv(csvString, `${this.$t('sample_filename')}.csv`);
    },
    onUpload: async function () {
      const files = this.$refs.fileInput.files;

      if (files.length === 1) {
        this.uploading = true;

        const base64String = await getBase64(files[0]);

        try {
          this.importResult = await this.importStationPlaceCsvBase64String(base64String);
          this.resultDialogVisible = true;

          if (this.importResult.created.length > 0) {
            this.$emit('placesCreated');
          }
        } catch (error) {
          this.notifyError(`import_error.${error.message}`);
        }

        this.uploading = false;

        // Reset input so same file can be uploaded again immediately
        this.$refs.fileInput.value = '';
      }
    },
  },
};
</script>

<style scoped lang="sass">
</style>
