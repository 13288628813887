var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-snackbar",
    {
      attrs: { bottom: "", timeout: 0 },
      model: {
        value: _vm.pending,
        callback: function ($$v) {
          _vm.pending = $$v
        },
        expression: "pending",
      },
    },
    [
      _vm._v("\n  " + _vm._s(_vm.$t("label")) + "\n  "),
      _c(
        "v-progress-circular",
        {
          staticClass: "red--text",
          attrs: { rotate: -90, value: _vm.progress },
        },
        [_vm._v("\n    " + _vm._s(_vm.progress) + "\n  ")]
      ),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          attrs: { text: "" },
          nativeOn: {
            click: function ($event) {
              return _vm.cancel.apply(null, arguments)
            },
          },
        },
        [_vm._v(_vm._s(_vm.$t("cancel")))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }