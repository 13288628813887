<template>
  <div class="title text-center my-4">
    {{ $t('message') }}
  </div>
</template>

<i18n lang="yaml">
ja:
  message: '表示するセンサーがありません'

en:
  message: 'There are no sensors to display'
</i18n>


<script>
export default {
  name: 'no-sensors-message',
};
</script>
