<template>
  <v-radio-group class="rat-view-mode-selector" hide-details v-model="mode">
    <v-radio :label="$t('field.mode.original')" value="original" color="primary" :disabled="!allowedViewModes.original" />
    <v-radio :label="$t('field.mode.detection')" value="detection" color="primary" :disabled="!allowedViewModes.detection" />
  </v-radio-group>
</template>

<i18n lang="yaml">
ja:
  field:
    mode:
      detection: 囲みあり
      original: 囲みなし

en:
  field:
    mode:
      detection: With highlighted rat movements
      original: Original video
</i18n>

<script>
export default {
  name: 'view-mode-selector',
  props: {
    event: {
      type: Object,
      default() {
        return {};
      },
    },
    value: String,
  },
  computed: {
    allowedViewModes() {
      return {
        detection: !!this.event.attachments.video,
        original: !!this.event.attachments.original_video,
      };
    },
  },
  data() {
    return {
      mode: this.value,
    };
  },
  methods: {
    updateViewMode() {
      if (!this.allowedViewModes[this.mode]) {
        this.mode = Object.keys(this.allowedViewModes).find(mode => this.allowedViewModes[mode]);
      }
    },
  },
  mounted() {
    this.updateViewMode();
  },
  watch: {
    event() {
      this.updateViewMode();
    },
    mode(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style scoped lang="sass">
.rat-view-mode-selector
  padding-top: 0
</style>
