<template>
  <div class="sensor-user-list">
    <div class="sensor-user-list__title">{{ $t('label.users') }}</div>
    <template v-if="loading">
      <div>
        <v-progress-circular indeterminate :size="40" class="load-spinner primary--text"/>
        <span>{{ $t('label.loading') }}</span>
      </div>
    </template>
    <template v-else>
      <user-list
        :users="users"
        :no_users_message="$t('label.no_users')"
      >
        <template v-slot:list-items="{ user }">
          <template v-if="user.shared_by.name">
            <v-list-item class="text-caption" disabled>
              {{ $t('label.shared_by', { name: user.shared_by.name }) }}
            </v-list-item>
            <v-divider></v-divider>
          </template>
          <v-list-item class="red--text" @click="confirmRemoveUser(user)">
            {{ $t('button.remove') }}
          </v-list-item>
        </template>
      </user-list>
    </template>
    <round-button
      class="sensor-user-list__share-sensor mt-2"
      icon="fa-share-alt"
      fullWidth
      @click.stop="openInvitationDialog"
    >
      {{ $t('button.share') }}
    </round-button>
    <confirm-dialog
      v-model="confirmDialog.visible"
      :message="confirmDialog.message"
      @yes="confirmDialog.onConfirm"
    />
    <card-dialog v-model="addUserDialog.visible" :title="$t('label.share_sensor')" width="290px">
      <v-text-field
        ref="emailField"
        required
        autofocus
        v-model="invitationEmail"
        :label="$t('field.invitationEmail')"
        :hint="$t('msg.enter_email')"
        :rules="addUserDialog.emailField.rules"
      />
      <template #actions>
        <dialog-action @click.native="addUserDialog.visible = false">
          {{ $t('button.cancel') }}
        </dialog-action>
        <dialog-action
          @click="onUserAdd"
          :disabled="invitationEmail === '' || $refs.emailField.hasError"
        >
          {{ $t('button.invite') }}
        </dialog-action>
      </template>
    </card-dialog>
  </div>
</template>

<i18n lang="yaml">
ja:
  label:
    users: '共有ユーザー'
    no_users: '共有ユーザーがありません'
    share_sensor: 'センサーを共有する'
    waiting_for_registration: '登録まち'
    loading: '読み込み中…'
    shared_by: '{name} が共有しました'

  msg:
    remove_user_confirm: '"{name}"のアクセス権を取り消します、よろしいですか？'
    invite_user_confirm: '"{email}"のユーザーは存在しません。Pest-Visionへ招待しますか？'
    enter_email: '招待するユーザーのメールアドレスを入力してください。'
    share_success: 'ユーザーを招待しました。'
    share_failure: 'ユーザーを招待しようとして、エラーが発生しました。'
    remove_success: 'ユーザーのアクセス権を取り消しました。'
    remove_failure: 'ユーザーのアクセス権を取り消そうとして、エラーが発生しました。'

  button:
    share: 'センサーを共有'
    cancel: 'キャンセル'
    invite: '招待する'
    remove: '削除'

  field:
    invitationEmail: 'E-mail'

  errors:
    nonexisting_user: 'そのメールアドレスのユーザーは存在しません。'
    already_invited: 'そのユーザーは招待済みです。'
en:
  label:
    users: 'Shared with users'
    no_users: 'This sensor is not shared with any users'
    share_sensor: 'Share sensor'
    waiting_for_registration: 'Waiting for registration'
    loading: 'Loading...'
    shared_by: 'Shared_by {name}'

  msg:
    remove_user_confirm: 'This will revoke the access of "{name}". Are you sure?'
    invite_user_confirm: 'A user with the address "{email}" does not exist. Do you want send an invitation to Pest-Vision?'
    enter_email: 'Please enter the email address of the user to invite.'
    share_success: 'User was successfully invited.'
    share_failure: 'An error occurred while trying to invite the user.'
    remove_success: 'User was successfully removed.'
    remove_failure: 'An error occurred while trying to remove the user.'

  button:
    share: 'Share sensor'
    cancel: 'Cancel'
    invite: 'Invite'
    remove: 'Remove'

  field:
    invitationEmail: 'E-mail'

  errors:
    nonexisting_user: 'A user with that address does not exist.'
    already_invited: 'That user is already invited.'
</i18n>

<script>
import Notifications from '@/mixins/notifications';
import Validations from '@/components/Form/mixins/form-validations';
import CardDialog from '@/components/atoms/CardDialog';
import DialogAction from '@/components/atoms/DialogAction';
import RoundButton from '@/components/atoms/RoundButton';
import ConfirmDialog from '@/components/atoms/ConfirmDialog';
import UserList from '@/components/molecules/UserList';

export default {
  name: 'sensor-user-list',
  mixins: [
    Notifications,
    Validations,
  ],
  components: {
    CardDialog,
    ConfirmDialog,
    DialogAction,
    RoundButton,
    UserList,
  },
  props: ['sensorId'],
  data() {
    return {
      removeUserConfirmVisible: false,
      confirmDialog: {
        visible: false,
        message: '',
        onConfirm: () => {},
      },
      addUserDialog: {
        visible: false,
        emailField: {
          rules: [
            this.userAlreadyInvited,
            this.email(this.$t('field.invitationEmail')),
          ],
        },
      },
      invitationEmail: '',
      loading: true,
    };
  },
  computed: {
    users() {
      const items = this.$store.getters.getSensorUsers(this.sensorId);
      return items.map((user) => ({
        ...user,
        name: user.unregistered ? this.$i18n.t('label.waiting_for_registration') : user.name,
      }));
    },
  },
  created: async function () {
    await this.$store.dispatch('requestSensorUsers', this.sensorId);
    this.loading = false;
  },
  methods: {
    confirmRemoveUser(user) {
      const name = user.unregistered ? user.email : user.name;
      this.confirmDialog.message = this.$t('msg.remove_user_confirm', { name });
      this.confirmDialog.onConfirm = () => { this.onRemoveUser(user); };
      this.confirmDialog.visible = true;
    },
    onInviteUserToApp: async function () {
      try {
        await this.$store.dispatch('shareSensor', {
          sensorId: this.sensorId,
          email: this.invitationEmail,
          inviteToApp: true,
        });
        this.addUserDialog.visible = false;
        this.notifySuccess('msg.share_success');
      } catch (error) {
        this.notifyError('msg.share_failure');
      }
    },
    onRemoveUser: async function (user) {
      try {
        await this.$store.dispatch('unshareSensor', {
          sensorId: this.sensorId,
          email: user.email,
        });
        this.notifySuccess('msg.remove_success');
      } catch (error) {
        this.notifyError('msg.remove_failure');
      }
    },
    userAlreadyInvited() {
      return this.users.filter(u => u.email === this.invitationEmail).length > 0 ? this.$t('errors.already_invited') : true;
    },
    openInvitationDialog() {
      this.invitationEmail = '';
      this.addUserDialog.visible = true;
    },
    onUserAdd: async function () {
      try {
        await this.$store.dispatch('shareSensor', {
          sensorId: this.sensorId,
          email: this.invitationEmail,
        });
        this.addUserDialog.visible = false;
        this.notifySuccess('msg.share_success');
      } catch (error) {
        if (error.response.status === 422 && error.response.data.type === 'non_existing_user') {
          this.confirmDialog.message = this.$t('msg.invite_user_confirm', { email: this.invitationEmail });
          this.confirmDialog.onConfirm = this.onInviteUserToApp;
          this.confirmDialog.visible = true;
          return;
        }
        this.addUserDialog.visible = false;
        this.notifyError('msg.share_failure');
      }
    },
  },
};
</script>

<style scoped lang="sass">
.sensor-user-list
  &__title
    font-weight: bold
  &__unregistered-user
    font-style: italic
    color: red

  div
    .load-spinner, span
      display: inline-block
      vertical-align: middle

::v-deep
  .v-list-item
    &__content
      padding: 0
    &__action
      margin: 0
</style>
