import { render, staticRenderFns } from "./BaseDialog.vue?vue&type=template&id=25f94f96&scoped=true&"
import script from "./BaseDialog.vue?vue&type=script&lang=js&"
export * from "./BaseDialog.vue?vue&type=script&lang=js&"
import style1 from "./BaseDialog.vue?vue&type=style&index=1&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25f94f96",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/project/client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('25f94f96')) {
      api.createRecord('25f94f96', component.options)
    } else {
      api.reload('25f94f96', component.options)
    }
    module.hot.accept("./BaseDialog.vue?vue&type=template&id=25f94f96&scoped=true&", function () {
      api.rerender('25f94f96', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/atoms/BaseDialog.vue"
export default component.exports