var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "copyright-footer" },
    [
      _c("v-select", {
        staticClass: "language-select mr-2",
        attrs: {
          "hide-details": "",
          items: _vm.locale_options,
          value: _vm.$i18n.locale,
        },
        on: { input: _vm.updateLocale },
      }),
      _vm._v(" "),
      _c("address", [
        _c("span", [_vm._v("Copyright © Pest-Vision | ")]),
        _vm._v(" "),
        _c(
          "a",
          {
            attrs: { href: "javascript:void(0)" },
            on: {
              click: function ($event) {
                _vm.modal.terms = true
              },
            },
          },
          [_vm._v("\n      " + _vm._s(_vm.$t("menu.terms")) + "\n    ")]
        ),
      ]),
      _vm._v(" "),
      _c("terms-modal", {
        model: {
          value: _vm.modal.terms,
          callback: function ($$v) {
            _vm.$set(_vm.modal, "terms", $$v)
          },
          expression: "modal.terms",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }