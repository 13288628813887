var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "forgot-password" },
    [
      _c("img", {
        staticClass: "mb-4",
        attrs: { src: require("../assets/logo.png"), width: "140" },
      }),
      _vm._v(" "),
      _vm.success
        ? [
            _c("p", {
              domProps: { innerHTML: _vm._s(_vm.$t("label.reset_mail_sent")) },
            }),
            _vm._v(" "),
            _c(
              "v-btn",
              {
                attrs: {
                  color: "primary",
                  large: "",
                  block: "",
                  to: "/sign_in",
                },
              },
              [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("button.close")) + "\n      "
                ),
                _c("v-icon", { attrs: { dark: "", right: "" } }, [
                  _vm._v("fa-times-circle"),
                ]),
              ],
              1
            ),
          ]
        : [
            _vm.error === ""
              ? _c("p", [_vm._v(_vm._s(_vm.$t("label.enter_email")))])
              : _c("v-alert", { attrs: { type: "error" } }, [
                  _vm._v(_vm._s(_vm.error)),
                ]),
            _vm._v(" "),
            _c(
              "v-form",
              [
                _c("v-text-field", {
                  staticClass: "mb-3",
                  attrs: {
                    placeholder: _vm.$t("field.email"),
                    "hide-details": "",
                    "data-testid": "forgot-password__email",
                  },
                  model: {
                    value: _vm.model.email,
                    callback: function ($$v) {
                      _vm.$set(_vm.model, "email", $$v)
                    },
                    expression: "model.email",
                  },
                }),
                _vm._v(" "),
                _c(
                  "v-btn",
                  {
                    staticClass: "forgot-password__submit",
                    attrs: {
                      color: "primary",
                      large: "",
                      block: "",
                      "data-testid": "forgot-password__submit",
                    },
                    on: { click: _vm.submit },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("button.send")) +
                        "\n        "
                    ),
                    _c("v-icon", { attrs: { dark: "", right: "" } }, [
                      _vm._v("fa-arrow-circle-right"),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("v-divider", { staticClass: "my-4" }),
            _vm._v(" "),
            _c("p", [_vm._v(_vm._s(_vm.$t("label.already_registered")))]),
            _vm._v(" "),
            _c(
              "v-btn",
              {
                attrs: {
                  color: "primary",
                  rounded: "",
                  outlined: "",
                  to: "/sign_in",
                },
              },
              [_vm._v(_vm._s(_vm.$t("button.login")))]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }