var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.downloadOptions.length > 0
    ? _c(
        "v-menu",
        {
          attrs: { "offset-y": "" },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function (ref) {
                  var on = ref.on
                  var attrs = ref.attrs
                  return [
                    _c(
                      "toolbar-button",
                      _vm._g(
                        _vm._b(
                          {
                            attrs: {
                              icon: "fa-download",
                              disabled: _vm.disabled,
                            },
                          },
                          "toolbar-button",
                          attrs,
                          false
                        ),
                        on
                      ),
                      [
                        _vm._v(
                          "\n      " + _vm._s(_vm.$t("download")) + "\n    "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ],
            null,
            false,
            2915857042
          ),
        },
        [
          _vm._v(" "),
          _c(
            "v-list",
            _vm._l(_vm.downloadOptions, function (option) {
              return _c(
                "v-list-item",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$emit("download", option.type)
                    },
                  },
                },
                [_c("v-list-item-title", [_vm._v(_vm._s(option.label))])],
                1
              )
            }),
            1
          ),
        ],
        1
      )
    : _c(
        "toolbar-button",
        {
          attrs: { icon: "fa-download", disabled: _vm.disabled },
          nativeOn: {
            click: function ($event) {
              return _vm.$emit("download")
            },
          },
        },
        [_vm._v("\n  " + _vm._s(_vm.$t("download")) + "\n")]
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }