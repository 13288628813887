var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        "close-on-content-click": false,
        "offset-y": "",
        transition: "fade-transition",
      },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function (props) {
              return [_vm._t("activator", null, null, props)]
            },
          },
        ],
        null,
        true
      ),
      model: {
        value: _vm.datePickerVisible,
        callback: function ($$v) {
          _vm.datePickerVisible = $$v
        },
        expression: "datePickerVisible",
      },
    },
    [
      _vm._v(" "),
      _c(
        "v-date-picker",
        _vm._b(
          {
            attrs: {
              value: _vm.value,
              type: _vm.type,
              "no-title": "",
              scrollable: "",
              locale: _vm.$i18n.locale,
              "day-format": _vm.dayFormat,
            },
            on: { input: _vm.onSelect },
          },
          "v-date-picker",
          _vm.datePickerProps,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }