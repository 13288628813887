<template>
  <div class="d-md-flex">
    <div class="entity-form__form my-3">
      <v-form v-model="valid" ref="form">
        <slot name="form" :editing="editing" />
      </v-form>
    </div>
    <div class="entity-form__button-section my-6 mx-md-10 my-md-0" v-if="canEdit">
      <!-- TODO: Add edit/cancel/save default buttons -->
      <!-- TODO: Add slot for additional buttons -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'entity-form',
  props: {
    canEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editing: false,
      valid: false,
    };
  },
};
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'

.entity-form
  &__button-section
    ::v-deep button
      display: flex
      width: 100%
      margin: 12px 0

@media #{map-get($display-breakpoints, 'md-and-up')}
  .entity-form
    &__form
      width: 50%

    &__button-section
      width: 240px
</style>
