var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "icon", style: _vm.style }, [
    _c("div", { style: _vm.adjust }, [
      _vm.availableIcons.includes(_vm.type)
        ? _c("img", {
            class: _vm.iconClass,
            attrs: { src: _vm.iconUrl, alt: _vm.type },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.availableBadges.includes(_vm.badge)
        ? _c("div", { staticClass: "icon__badge" }, [
            _c("img", {
              class: _vm.badgeClass,
              attrs: { src: _vm.badgeUrl, alt: _vm.badge },
            }),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }