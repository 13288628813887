var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.event
    ? _c(
        "div",
        { staticClass: "station-event" },
        [
          _c("div", { staticClass: "d-flex align-baseline" }, [
            _c("div", { staticClass: "headline font-weight-bold mr-8" }, [
              _vm.event.installed_at && _vm.event.collected_at
                ? _c("div", { staticClass: "d-flex align-center" }, [
                    _c("div", { staticClass: "mr-8" }, [
                      _vm._v(_vm._s(_vm.event.trap_no)),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        _vm._s(
                          _vm._f("moment")(
                            _vm.event.installed_at,
                            _vm.$t("format.date")
                          )
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "mx-2" }, [_vm._v("~")]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        _vm._s(
                          _vm._f("moment")(
                            _vm.event.collected_at,
                            _vm.$t("format.date")
                          )
                        )
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "headline font-weight-bold flex" }, [
              _vm.event.month
                ? _c("div", { staticClass: "d-flex align-center" }, [
                    _c(
                      "div",
                      {
                        staticClass: "station-event__content align-center px-2",
                      },
                      [
                        _vm._v(
                          _vm._s(_vm._f("moment")(_vm.event.month, "YYYY-MM"))
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "ml-2" }, [
                      _vm._v(_vm._s(_vm.$t("month"))),
                    ]),
                    _vm._v(" "),
                    _vm.isNotRecentEvent
                      ? _c(
                          "div",
                          { staticClass: "station-event__edit-button" },
                          [
                            !_vm.event.updating
                              ? _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      text: "",
                                      disabled: _vm.isThumbnail,
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        _vm.editing = true
                                        _vm.pickedDetectionType = "all"
                                      },
                                    },
                                  },
                                  [
                                    _c("v-icon", [_vm._v("fa-edit")]),
                                    _vm._v(" "),
                                    _c("small", [
                                      _vm._v(_vm._s(_vm.$t("menu.edit"))),
                                    ]),
                                  ],
                                  1
                                )
                              : _c(
                                  "v-btn",
                                  { attrs: { text: "", disabled: "" } },
                                  [
                                    _c("v-icon", [_vm._v("fa-refresh")]),
                                    _vm._v(" "),
                                    _c("small", [
                                      _vm._v(_vm._s(_vm.$t("menu.updating"))),
                                    ]),
                                  ],
                                  1
                                ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex" }, [
            _c("div", { staticClass: "pb-3" }, [
              _vm._v(
                "\n      " +
                  _vm._s(
                    _vm._f("dateTime")(
                      _vm.event.upload_time,
                      _vm.$t("format.time")
                    )
                  ) +
                  "\n    "
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.event.processing
            ? _c("station-event-processing")
            : _c(
                "div",
                { staticClass: "d-flex" },
                [
                  _c("div", { staticClass: "pr-3 station-event-left-column" }, [
                    _c(
                      "div",
                      { staticClass: "station-event__photo" },
                      [
                        _c(
                          "picked-insects-draw",
                          {
                            attrs: {
                              imageSrc: _vm.current.original,
                              pickedDetectionType: _vm.pickedDetectionType,
                              currentPhotoKey: _vm.current.key,
                              event: _vm.event,
                            },
                          },
                          [
                            _c("loading-image", {
                              attrs: {
                                forceLoading: _vm.event.updating,
                                src:
                                  _vm.current.detections ||
                                  _vm.current.original,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "mt-2 pr-1 text-right text-caption" },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("count")) +
                            ": " +
                            _vm._s(_vm.photos.length) +
                            "\n      "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "d-flex flex-nowrap" }, [
                      _c("div", { staticClass: "flex-grow-0 pr-2" }, [
                        _c(
                          "div",
                          {
                            staticClass: "thumbnail tab",
                            class: { active: _vm.current === _vm.tab },
                            on: {
                              click: function ($event) {
                                _vm.selected = _vm.tab
                              },
                            },
                          },
                          [
                            _c("loading-image", {
                              attrs: { src: _vm.tab.thumbnail },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "thumbnail-slider-wrapper" },
                        [
                          _c("thumbnail-slider", {
                            attrs: { items: _vm.photos, keyName: "key" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item",
                                  fn: function (props) {
                                    return [
                                      props.index < _vm.processedImageCount
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "thumbnail",
                                              class: {
                                                active:
                                                  _vm.currentIndex ==
                                                  props.index,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.selectPhoto(
                                                    props.index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "thumbnail-head",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "thumbnail-number",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(props.index + 1)
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "thumbnail-count",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.getPhotoCount(
                                                            props.item.key
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("loading-image", {
                                                staticClass: "thumbnail-image",
                                                attrs: {
                                                  forceLoading:
                                                    _vm.event.updating,
                                                  src: props.item.thumbnail,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _c("processing-image"),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2743229299
                            ),
                            model: {
                              value: _vm.currentIndex,
                              callback: function ($$v) {
                                _vm.currentIndex = $$v
                              },
                              expression: "currentIndex",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "station-event__result station-event__content pa-2 station-event-right-column",
                    },
                    [
                      _c("chart-type-select", {
                        model: {
                          value: _vm.chartType,
                          callback: function ($$v) {
                            _vm.chartType = $$v
                          },
                          expression: "chartType",
                        },
                      }),
                      _vm._v(" "),
                      _vm.chartTypeIs("table")
                        ? _c("insect-count-table", {
                            staticClass: "station-event__result-table",
                            attrs: {
                              insectCounts: _vm.event.insect_counts,
                              showRadiobutton: !_vm.event.updating,
                              pickedDetectionType: _vm.pickedDetectionType,
                            },
                            on: {
                              "update:pickedDetectionType": function ($event) {
                                _vm.pickedDetectionType = $event
                              },
                              "update:picked-detection-type": function (
                                $event
                              ) {
                                _vm.pickedDetectionType = $event
                              },
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.chartTypeIs("bar")
                        ? _c("insect-count-by-type-bar-chart", {
                            attrs: { insectCounts: _vm.event.insect_counts },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.chartTypeIs("pie")
                        ? _c("top-5-insect-types-pie-chart", {
                            attrs: { insectCounts: _vm.event.insect_counts },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.isNotRecentEvent
                    ? _c("insect-detections-editor", {
                        attrs: {
                          event: _vm.event,
                          imageSrc: _vm.current.original,
                          detectionsUrl: _vm.detectionsUrl,
                          canViewDetectionDetail: true,
                        },
                        on: {
                          updated: function ($event) {
                            return _vm.$emit("pollEvent")
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function () {
                                return [
                                  _c("content-header", {
                                    staticClass: "w-100",
                                    attrs: {
                                      title: _vm.event.stationName,
                                      titleClass: "title my-1",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "icon",
                                          fn: function () {
                                            return [
                                              _c("icon", {
                                                attrs: { type: "fly_count" },
                                              }),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "subtitle",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "overflow-ellipsis title details",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n              " +
                                                      _vm._s(_vm.timestamp.date)
                                                  ),
                                                  _c("span", {
                                                    staticClass: "ml-3",
                                                  }),
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.timestamp.time +
                                                        " " +
                                                        _vm.getPhotoCount(
                                                          _vm.current.key
                                                        )
                                                    ) + "\n            "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      2218165862
                                    ),
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          1088173544
                        ),
                        model: {
                          value: _vm.editing,
                          callback: function ($$v) {
                            _vm.editing = $$v
                          },
                          expression: "editing",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }