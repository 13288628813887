var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("data-table", {
    attrs: {
      headers: _vm.headers,
      items: _vm.sortedRows,
      options: _vm.options,
      "no-data-text": _vm.noDataText,
      loading: _vm.loading,
      externallySorted: "",
      checkbox: "",
    },
    on: {
      "update:options": function ($event) {
        _vm.options = $event
      },
    },
    scopedSlots: _vm._u([
      {
        key: "item.installed_at",
        fn: function (ref) {
          var item = ref.item
          return [
            _vm._v(
              "\n    " + _vm._s(_vm.formatDate(item.installed_at)) + "\n  "
            ),
          ]
        },
      },
      {
        key: "item.collected_at",
        fn: function (ref) {
          var item = ref.item
          return [
            _vm._v(
              "\n    " + _vm._s(_vm.formatDate(item.collected_at)) + "\n  "
            ),
          ]
        },
      },
      {
        key: "item.upload_time",
        fn: function (ref) {
          var item = ref.item
          return [
            _vm._v(
              "\n    " + _vm._s(_vm.formatTimestamp(item.upload_time)) + "\n  "
            ),
          ]
        },
      },
      {
        key: "item.photoCount",
        fn: function (ref) {
          var item = ref.item
          return [
            _vm._v(
              "\n    " +
                _vm._s(_vm.hideWhileProcessing(item, item.photoCount)) +
                "\n  "
            ),
          ]
        },
      },
      {
        key: "item.insectCount",
        fn: function (ref) {
          var item = ref.item
          return [
            _vm._v(
              "\n    " +
                _vm._s(_vm.hideWhileProcessing(item, item.insectCount)) +
                "\n  "
            ),
          ]
        },
      },
      {
        key: "item.actions",
        fn: function (ref) {
          var item = ref.item
          return [
            item.processing
              ? _c("span", [
                  _vm._v("\n      " + _vm._s(_vm.$t("processing")) + "\n    "),
                ])
              : [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        text: "",
                        icon: "",
                        "data-testid": "view_button",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.viewEvent(item)
                        },
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "ma-2 material-icons",
                          attrs: { primary: "" },
                        },
                        [_vm._v("collections")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        text: "",
                        icon: "",
                        "data-testid": "edit_button",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.editEvent(item)
                        },
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "ma-2", attrs: { primary: "" } },
                        [_vm._v("fa-pencil")]
                      ),
                    ],
                    1
                  ),
                ],
            _vm._v(" "),
            _c("station-event-polling", {
              attrs: { event: item },
              on: {
                eventUpdated: function (event) {
                  return _vm.$emit("eventUpdated", event)
                },
              },
            }),
          ]
        },
      },
    ]),
    model: {
      value: _vm.selectedRows,
      callback: function ($$v) {
        _vm.selectedRows = $$v
      },
      expression: "selectedRows",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }