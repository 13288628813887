var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "card-dialog",
    {
      attrs: { width: 1200, persistent: "", transition: false },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function () {
            return [
              _c(
                "round-button",
                {
                  attrs: { icon: "insert_chart" },
                  on: { click: _vm.dialogHandle },
                },
                [_vm._v("\n      " + _vm._s(_vm.$t("button_label")) + "\n    ")]
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "actions",
          fn: function () {
            return [
              _c(
                "dialog-action",
                { staticClass: "close-button", on: { click: _vm.close } },
                [_vm._v("\n      " + _vm._s(_vm.$t("close")) + "\n    ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.dialogVisible,
        callback: function ($$v) {
          _vm.dialogVisible = $$v
        },
        expression: "dialogVisible",
      },
    },
    [
      _vm._v(" "),
      _c("select-box", {
        key: _vm.i18n_locale,
        staticClass: "insect-select-box",
        attrs: {
          itemMenuClass: "insect-items",
          label: _vm.$t("insects"),
          items: _vm.insects,
        },
        model: {
          value: _vm.insect,
          callback: function ($$v) {
            _vm.insect = $$v
          },
          expression: "insect",
        },
      }),
      _vm._v(" "),
      _vm.graphVisible
        ? _c("bar-chart", {
            attrs: {
              data: _vm.chartData,
              options: _vm.chartOptions,
              styles: { height: "450px" },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }