<template>
  <insect-positions-canvas
    :imageSrc="imageSrc"
    :positions="detectionsWithNewDetection"
    :allowPick="isPickMode"
    :hideInsectTypes="!withInsectTypes"
    v-on="canvasEvents"
    ref="canvas"
  >
    <img :src="imageSrc" width="100%" />
  </insect-positions-canvas>
</template>

<script>
import InsectPositionsCanvas from '@/components/_legacy/InsectPositionsCanvas';

const COLOR_NEW = 'royalblue';

export default {
  name: 'editor-canvas',
  components: {
    InsectPositionsCanvas,
  },
  props: {
    imageSrc: String,
    mode: {
      type: String,
      default: 'pick',
    },
    detections: {
      type: Array,
      default() {
        /*
         * Attributes
         * - symbol: number
         * - rect: {
         *     x1: number
         *     y1: number
         *     x2: number
         *     y2: number
         *   }
         */
        return [];
      },
    },
    newInsectType: String,
    withInsectTypes: Boolean,
  },
  computed: {
    canvasEvents() {
      if (this.isPickMode) {
        return {
          pick: (detections, clickEvent) => this.$emit('pick', detections, clickEvent),
        };
      }

      return this._.pickBy({
        mousedown: this.handleMouseDown,
        mousemove: this.isMouseDown
          ? this._.throttle(this.handleMouseMove, 1000 / 60)
          : null,
        mouseup: this.handleMouseUp,
      });
    },
    hasNewDetection() {
      return !this._.isEmpty(this.newDetection);
    },
    isAddMode() {
      return this.mode === 'add';
    },
    isPickMode() {
      return this.mode === 'pick';
    },
    detectionsWithNewDetection() {
      if (this.isPickMode || !this.hasNewDetection) {
        return this.data;
      }

      return [...this.data, this.newDetection];
    },
  },
  data() {
    return {
      isMouseDown: false,
      data: this.detections.clone(),
      newDetection: {},
    };
  },
  methods: {
    handleMouseDown({ x, y }) {
      this.isMouseDown = true;
      this.newDetection = {
        position: [x, y, x, y],
        color: COLOR_NEW,
        type: this.newInsectType,
      };
    },
    handleMouseMove({ x, y }) {
      const origin = this.newDetection.position;
      this.newDetection = {
        ...this.newDetection,
        position: [origin[0], origin[1], x, y],
      };
    },
    handleMouseUp() {
      this.isMouseDown = false;
      const { position: [x1, y1, x2, y2] } = this.newDetection;
      if (x1 !== x2 && y1 !== y2) {
        const rectX = x1 < x2 ? { x1, x2 } : { x1: x2, x2: x1 };
        const rectY = y1 < y2 ? { y1, y2 } : { y1: y2, y2: y1 };
        const item = {
          ...this.newDetection,
          position: [rectX.x1, rectY.y1, rectX.x2, rectY.y2],
          new: true,
          index: Date.now(),
        };
        this.$emit('add', item);
        this.newDetection = {};
      }
    },
    refresh() {
      this.$refs.canvas.refresh();
    },
  },
  watch: {
    detections(newDetections) {
      this.data = newDetections.clone();
    },
  },
};
</script>
