<template>
  <div class="sensor-form" v-if="show">
    <page-title-area>
      <entity-header :icon="sensor.type" v-if="new_record">
        {{ $t('label.new_sensor') }}
      </entity-header>
      <entity-header :details="sensor.place" v-else>
        <template #icon><sensor-icon :sensor="sensor" /></template>
        {{ sensor.name }}
      </entity-header>
      <template #right-floating-content>
        <counter :count="count" v-if="!new_record" />
      </template>
    </page-title-area>
    <div class="sensor-form__content" data-testid="SensorForm">
      <div class="sensor-form__form my-3">
        <v-form v-model="valid" ref="form">
          <form-group :required="state.editting">
            <template #label><span>{{ $t('field.identifier') }}</span></template>
            <template #input>
              <text-field
                class="sensor-form__form__id"
                :value="sensorId"
                @input="updateId"
                :edit="state.editting && new_record"
                :rules="rules.id"
                :hint="$t('hint.identifier')"
                :errorMessages="state.idErrors"
              />
            </template>
          </form-group>
          <form-group v-if="capabilities.receives_mails && !new_record">
            <template #label><span>{{ $t('field.mail_address') }}</span></template>
            <template #input><div>{{ sensor.id }}-{{ sensor.mail_hash }}@sensor.{{ domain }}</div></template>
          </form-group>
          <form-group :required="state.editting">
            <template #label><span>{{ $t('field.name') }}</span></template>
            <template #input><text-field v-model="model.name" :edit="state.editting" :rules="rules.name" /></template>
          </form-group>
          <form-group :required="state.editting">
            <template #label><span>{{ $t('field.installation_site') }}</span></template>
            <template #input><text-field v-model="model.place" :edit="state.editting" :rules="rules.place" /></template>
          </form-group>
          <form-group :required="state.editting">
            <template #label><span>{{ $t('field.installation_date') }}</span></template>
            <template #input>
              <date-field
                v-model="model.installed_on"
                displayFormat="YYYY/MM/DD"
                :edit="state.editting"
                :rules="rules.installed_on"
              />
            </template>
          </form-group>
          <form-group v-if="capabilities.threshold" :required="state.editting">
            <template #label><span>{{ $t('field.threshold') }}</span></template>
            <template #input><text-field type="number" v-model="model.threshold" :edit="state.editting" :rules="rules.threshold" /></template>
          </form-group>
          <form-group v-if="capabilities.threshold" :required="state.editting">
            <template #label><span>{{ $t('field.increase_threshold') }}</span></template>
            <template #input>
              <text-field
                type="number"
                v-model="model.increase_threshold"
                :edit="state.editting"
                :rules="rules.increase_threshold"
              />
            </template>
          </form-group>
          <form-group v-if="capabilities.replacement">
            <template #label><span>{{ $t('field.trap_replaced_on') }}</span></template>
            <template #input>
              <date-field
                v-model="model.trap_replaced_on"
                displayFormat="YYYY/MM/DD"
                clearable
                :edit="state.editting"
              />
            </template>
          </form-group>
          <form-group v-if="capabilities.replacement">
            <template #label><span>{{ $t('field.trap_effective_days') }}</span></template>
            <template #input>
              <text-field
                type="number"
                v-model="model.trap_effective_days"
                :edit="state.editting"
                :rules="rules.trap_effective_days"
              />
            </template>
          </form-group>
          <form-group v-if="capabilities.photoInterval">
            <template #label><span>{{ $t('field.photo_interval') }}</span></template>
            <template #input>
              <photo-interval-select-field
                v-model="model.photo_interval"
                :edit="state.editting"
              />
            </template>
          </form-group>
          <form-group>
            <template #label><span>{{ $t('field.memo') }}</span></template>
            <template #input><text-field v-model="model.memo" :edit="state.editting" /></template>
          </form-group>
          <template v-if="!admin">
            <form-group v-if="state.editting || shareable">
              <template #label><span>{{ $t('label.notifications') }}</span></template>
              <template #input><checkbox v-model="model.notice" :label="$t('notification.by_email')" :edit="state.editting" /></template>
            </form-group>
          </template>
          <form-group v-if="capabilities.health_check">
            <template #label><span>{{ $t('label.health_check') }}</span></template>
            <template #input>
              <div>
                <checkbox
                  v-model="model.health_check"
                  :label="$t('field.health_check_required')"
                  :edit="state.editting"
                />
                <sensor-status
                  :status="sensor.health_check_status"
                  :canDownload="admin"
                  class="mt-1"
                />
                <v-row justify="start" class="mt-3" no-gutters>
                  <span class="mr-2">{{ $t('label.last_active') }}</span>
                  <span>{{ formatTimestamp(sensor.last_active, 'YYYY/MM/DD HH:mm')}}</span>
                </v-row>
              </div>
            </template>
          </form-group>
          <form-group v-if="!new_record && sensor.created_by.name" data-testid="field_created-by">
            <template #label><span>{{ $t('field.created_by') }}</span></template>
            <template #input>
              {{ sensor.created_by.name }}
            </template>
          </form-group>
        </v-form>
      </div>
      <div class="sensor-form__btn-group">
        <template v-if="state.editting">
          <round-button
            class="sensor-form__btn sensor-form__save my-3"
            icon="fa-check-circle"
            fullWidth
            :disabled="state.checkingId"
            @click="onSave"
            data-testid="save"
          >
            {{ $t('button.save') }}
          </round-button >
          <round-button
            class="sensor-form__btn my-3"
            icon="fa-arrow-circle-left"
            fullWidth
            @click="onCancel"
          >
            {{ $t('button.cancel') }}
          </round-button>
          <round-button
            class="sensor-form__btn my-3"
            icon="fa-trash-o"
            fullWidth
            @click.native.stop="confirmDelete"
            v-if="!new_record"
          >
            {{ $t('button.delete') }}
          </round-button>
        </template>
        <template v-else>
          <round-button
            class="sensor-form__btn sensor-form__edit my-3"
            icon="fa-pencil-square-o"
            fullWidth
            @click="onEdit"
            v-if="editable"
            data-testid="edit"
          >
            {{ $t('button.edit') }}
          </round-button>
          <round-button
            class="sensor-form__btn my-3"
            icon="fa-trash-o"
            fullWidth
            @click.native.stop="confirmDelete"
            v-if="editable"
          >
            {{ $t('button.delete') }}
          </round-button>
          <round-button
            class="sensor-form__btn my-3"
            icon="fa-chevron-circle-down"
            fullWidth
            :to="`/activities?sensorId=${sensor.id}`"
          >
            {{ $t('button.show_all', { dataType: getTerm(sensorDefinition.dataType, { plural: true }) }) }}
          </round-button>
          <upload-button
            fullWidth
            :acceptedFiletypes="acceptedFiletypes"
            :sensorId="sensor.id"
            v-if="uploadable"
          >
            {{ $t('button.upload', { dataType: getTerm(sensorDefinition.dataType) }) }}
          </upload-button>
          <round-button
            class="sensor-form__btn my-3"
            icon="fa-refresh"
            fullWidth
            @click.native.stop="confirmGenerateMail"
            v-if="capabilities.receives_mails && editable"
          >
            {{ $t('button.generate_mail') }}
          </round-button>
          <round-button
            class="sensor-form__btn my-3"
            icon="fa-list"
            fullWidth
            :to="`/admin/activity_logs/Sensor-${sensor.id}`"
            v-if="showConnectionLogsButton"
          >
            {{ $t('button.show_connection_logs') }}
          </round-button>
          <sensor-user-list :sensorId="sensor.id" v-if="shareable" />
        </template>
      </div>
    </div>
    <confirm-dialog
      v-model="confirmDialog.visible"
      :message="confirmDialog.message"
      @yes="confirmDialog.onConfirm"
    />
  </div>
</template>

<i18n lang="yaml">
ja:
  field:
    identifier: '識別子'
    name: 'センサー名'
    installation_site: '設置場所'
    installation_date: '設置日'
    threshold: 'しきい値'
    increase_threshold: '増加量のしきい値'
    memo: 'メモ'
    health_check_required: '警告を表示する'
    mail_address: 'メールアドレス'
    conditions: '状態'
    trap_replaced_on: 捕虫紙交換日
    trap_effective_days: 捕虫紙有効日数
    photo_interval: 撮影間隔
    created_by: 作成者

  label:
    new_sensor: 'センサー登録'
    notifications: '通知'
    health_check: 'ライフチェック'
    last_active: '最終接続日時'

  term:
    images:
      singular: '画像'
      plural: '画像'
    videos:
      singular: '動画'
      plural: '動画'
    images_and_videos:
      singular: '画像/動画'
      plural: '画像/動画'

  notification:
    by_email: '通知をメールで送信'

  msg:
    delete_confirm: 'このセンサー情報を削除します、よろしいですか？'
    generate_mail_confirm: 'このセンサーのメールアドレスを再生成します。よろしいですか？'
    generate_mail_success: 'メールアドレスを再生成しました。'
    create_success: 'センサーを作成しました。'
    create_error: 'センサーの作成中にエラーが発生しました。'
    update_success: 'センサーを更新しました。'
    update_error: 'センサーの更新中にエラーが発生しました。'
    id_already_exists: '指定された識別子はすでに登録されています。'
    not_found: |
      指定されたセンサーが存在しません。
      識別子が間違っている、または共有が解除された可能性があります。

  button:
    cancel: 'キャンセル'
    delete: '削除'
    edit: '編集'
    ok: 'OK'
    upload: '{dataType}をアップロード'
    save: '保存'
    show_all: 'すべての{dataType}を表示'
    generate_mail: 'メールアドレスを再生成'
    show_connection_logs: 通信記録を表示

  hint:
    identifier: '半角英数字と_(アンダーバー)で入力してください'

  timestamp:
    none: なし
en:
  field:
    identifier: 'Identifier'
    name: 'Sensor name'
    installation_site: 'Installation site'
    installation_date: 'Installation date'
    threshold: 'Threshold'
    increase_threshold: 'Increase Threshold'
    memo: 'Notes'
    health_check_required: 'Require health check'
    mail_address: 'Mail address'
    conditions: 'Condition'
    trap_replaced_on: Trap replaced on
    trap_effective_days: Trap effective days
    photo_interval: Photo Interval
    created_by: Created by

  label:
    new_sensor: 'New sensor'
    notifications: 'Notifications'
    health_check: 'Health check'
    last_active: 'Last active'

  term:
    images:
      singular: 'Image'
      plural: 'Images'
    videos:
      singular: 'Video'
      plural: 'Videos'
    images_and_videos:
      singular: 'Image/Video'
      plural: 'Images/Videos'

  notification:
    by_email: 'E-mail notification'

  msg:
    delete_confirm: 'This sensor will be deleted. Are you sure?'
    generate_mail_confirm: 'The mail address of this sensor will be newly generated. Are you sure?'
    generate_mail_success: 'A new mail address was successfully generated.'
    create_success: 'Sensor was successfully created.'
    create_error: 'An error occurred while creating the sensor.'
    update_success: 'Sensor was successfully updated.'
    update_error: 'An error occurred while updating the sensor.'
    id_already_exists: 'This sensor id is already registered.'
    not_found: |
      The specified sensor does not exist.
      Either the wrong id was specified or your permissions on this sensor have been revoked.

  button:
    cancel: 'Cancel'
    delete: 'Delete'
    edit: 'Edit'
    ok: 'OK'
    upload: 'Upload {dataType}'
    save: 'Save'
    show_all: 'Show {dataType}'
    generate_mail: 'Generate mail address'
    show_connection_logs: Show connection logs

  hint:
    identifier: 'The identifier can contain alphanumeric characters or underscore'

  timestamp:
    none: Never
</i18n>

<script>
import moment from 'moment';

import SensorTypes from '@/mixins/sensorTypes';
import Notifications from '@/mixins/notifications';

import ConfirmDialog from '@/components/atoms/ConfirmDialog';
import EntityHeader from '@/components/atoms/EntityHeader';
import PageTitleArea from '@/components/atoms/PageTitleArea';
import RoundButton from '@/components/atoms/RoundButton';
import SensorStatus from '@/components/organisms/SensorStatus';
import Counter from '@/components/ContentHeader/Counter';
import SensorIcon from '@/components/SensorIcon';
import TermPicker from '@/components/TermPicker';
import FormGroup from '@/components/Form/FormGroup';
import TextField from '@/components/Form/TextField';
import DateField from '@/components/Form/DateField';
import Checkbox from '@/components/Form/Checkbox';
import Validations from '@/components/Form/mixins/form-validations';

import UploadButton from './UploadButton';
import SensorUserList from './SensorUserList';
import PhotoIntervalSelectField from './PhotoIntervalSelectField';

export default {
  name: 'sensor-form',
  mixins: [
    SensorTypes,
    Validations,
    Notifications,
  ],
  props: {
    admin: {
      type: Boolean,
      default: false,
    },
    new_record: {
      type: Boolean,
      default: false,
    },
  },
  beforeMount: async function () {
    if (this.$route.params.id) {
      try {
        await this.$store.dispatch('requestSensor', this.$route.params.id);
      } catch (error) {
        this.notifyError('msg.not_found');
      }
    }
  },
  components: {
    Checkbox,
    ConfirmDialog,
    Counter,
    DateField,
    EntityHeader,
    FormGroup,
    PageTitleArea,
    PhotoIntervalSelectField,
    RoundButton,
    SensorIcon,
    SensorStatus,
    SensorUserList,
    TermPicker,
    TextField,
    UploadButton,
  },
  computed: {
    acceptedFiletypes() {
      return (this.capabilities.upload || { accept: [] }).accept;
    },
    capabilities() {
      return this.sensorDefinition.capabilities;
    },
    count() {
      return this.sensor.type === this.SENSOR_TYPES.RAT
        ? `${this.sensor.undetected_event_count} / ${this.sensor.total_event_count}`
        : this.sensor.total_event_count;
    },
    domain() {
      return this.appConfig.DOMAIN;
    },
    editable() {
      return this.admin || this.sensor.permissions.includes('full');
    },
    isProduction() {
      return this.appConfig.DOMAIN === 'pest-vision.com';
    },
    sensor() {
      if (this.new_record) {
        const { type } = this.$route.query;
        this.checkNewSensorType(type);
        this.initializeModel(type);
        this.show = true;
        return { type };
      }
      return this.$store.getters.getSensor(this.$route.params.id);
    },
    sensorDefinition() {
      return this.SENSOR_DEFINITIONS[this.sensor.type];
    },
    sensorId() {
      return this.model.id;
    },
    shareable() {
      return this.editable;
    },
    showConnectionLogsButton() {
      // Only show on production because only production logs are collected.
      // On other environments the sensor id will probably not exist.
      return this.admin && this.isProduction;
    },
    uploadable() {
      return this.capabilities.upload && this.editable;
    },
    rules() {
      return {
        id: [
          this.required(this.$t('field.identifier')),
          this.format(this.$t('field.identifier'), /^[a-zA-Z0-9_]+$/),
        ],
        name: [this.required(this.$t('field.name'))],
        place: [this.required(this.$t('field.installation_site'))],
        installed_on: [this.required(this.$t('field.installation_date'))],
        threshold: [
          this.required(this.$t('field.threshold')),
          this.positive(this.$t('field.threshold')),
        ],
        increase_threshold: [
          this.required(this.$t('field.increase_threshold')),
          this.positive(this.$t('field.increase_threshold')),
        ],
        trap_effective_days: [
          this.within_range(this.$t('field.trap_effective_days'), 0, 60),
        ],
      };
    },
  },
  data() {
    return {
      show: false,
      valid: false,
      model: {},
      confirmDialog: {
        visible: false,
        message: '',
        onConfirm: () => {},
      },
      state: {
        editting: this.new_record,
        checkingId: false,
        idErrors: [],
      },
      checkAvailabilityWhenFinishedTyping: this._.debounce(this.checkAvailability, 500),
      conditions: {},
    };
  },
  inject: ['appConfig'],
  methods: {
    checkAvailability: async function () {
      if (!this.model.id) {
        return;
      }
      const available = await this.$store.dispatch('checkAvailability', this.model.id);
      this.state.idErrors = available ? [] : [this.$t('msg.id_already_exists')];
      this.state.checkingId = false;
    },
    checkNewSensorType(type) {
      if (!this.$store.getters.hasPermission(type)) {
        this.redirectToSensorList();
      }
    },
    confirmDelete() {
      this.confirmDialog.message = this.$t('msg.delete_confirm');
      this.confirmDialog.onConfirm = this.onDelete;
      this.confirmDialog.visible = true;
    },
    confirmGenerateMail() {
      this.confirmDialog.message = this.$t('msg.generate_mail_confirm');
      this.confirmDialog.onConfirm = this.onGenerateMail;
      this.confirmDialog.visible = true;
    },
    formatTimestamp(timestamp, format) {
      if (timestamp) {
        return moment.unix(timestamp).format(format);
      }
      return this.$t('timestamp.none');
    },
    getTerm(term, options = { plural: false }) {
      const termType = options.plural ? 'plural' : 'singular';
      return this.$t(`term.${term}.${termType}`);
    },
    initializeModel(type) {
      const result = {
        type,
        id: '',
        name: '',
        place: '',
        installed_on: null,
        memo: '',
        notice: false,
      };
      const { capabilities } = this.SENSOR_DEFINITIONS[type];
      if (capabilities.health_check) {
        result.health_check = false;
      }
      if (capabilities.threshold) {
        result.threshold = 0;
        result.increase_threshold = 0;
      }
      if (capabilities.replacement) {
        result.trap_effective_days = 30; // 30 days
      }
      if (capabilities.photoInterval) {
        result.photo_interval = 3600; // 1 hour
      }
      this.model = result;
    },
    isAlreadyExistsError(error) {
      return error.response.data.type === 'resource_already_exists';
    },
    onCancel() {
      if (this.new_record) {
        this.redirectToSensorList();
        return;
      }
      this.setModel();
      this.state.editting = false;
    },
    onDelete: async function () {
      await this.$store.dispatch('requestDestroySensor', this.sensor.id);
      this.redirectToSensorList();
    },
    onGenerateMail: async function () {
      await this.$store.dispatch('requestUpdateSensor', { id: this.sensor.id, data: { generate_hash: true } });
      this.notifySuccess('msg.generate_mail_success');
    },
    onEdit() {
      this.state.editting = true;
    },
    onSave: async function () {
      if (this.$refs.form.validate()) {
        const data = this.parseParams(this.model);
        if (this.new_record) {
          try {
            await this.$store.dispatch('requestCreateSensor', { ...data, type: this.sensor.type });
            this.notifySuccess('msg.create_success');
            this.redirectToSensor(data.id);
            this.state.editting = false;
          } catch (error) {
            if (this.isAlreadyExistsError(error)) {
              this.notifyError('msg.id_already_exists');
            } else {
              this.notifyError('msg.create_error');
            }
          }
        } else {
          try {
            await this.$store.dispatch('requestUpdateSensor', { id: this.sensor.id, data });
            this.notifySuccess('msg.update_success');
            this.redirectToSensor(this.sensor.id);
            this.state.editting = false;
          } catch (error) {
            this.notifyError('msg.update_error');
          }
        }
      }
    },
    parseParams(params) {
      const result = {
        ...params,
      };
      Object.keys(result).forEach((attribute) => {
        if (result[attribute] === this.sensor[attribute]) {
          delete result[attribute];
        }
      });
      return result;
    },
    redirectToSensorList() {
      if (this.admin) {
        this.$router.push('/admin/sensors');
      } else {
        this.$router.push('/sensors');
      }
    },
    redirectToSensor(id) {
      if (this.admin) {
        this.$router.push(`/admin/sensors/${id}`);
      } else {
        this.$router.push(`/sensors/${id}`);
      }
    },
    setModel() {
      if (this.sensor) {
        this.initializeModel(this.sensor.type);
        this.model = { ...this.model, ...this.sensor };
        if (this._.isNil(this.sensor.memo)) {
          this.model.memo = '';
        }
      }
    },
    updateId(id) {
      this.model.id = id;
      this.state.checkingId = true;
      this.checkAvailabilityWhenFinishedTyping();
    },
  },
  watch: {
    sensor(val) {
      this.setModel();
      this.show = !!val;
    },
  },
};
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'

.sensor-form
  &__btn-group
    margin: 24px 0

  ::v-deep #image-upload
    display: none

  ::v-deep .term-picker .menu
    width: 100%

  ::v-deep .v-select,
  ::v-deep .v-text-field
    .v-input__control .v-input__slot
      background-color: transparent

@media #{map-get($display-breakpoints, 'md-and-up')}
  .sensor-form
    &__content
      display: flex

    &__form
      width: 50%

    &__btn-group
      margin: 0 40px
      width: 240px

      .term-picker
        display: flex

        ::v-deep .menu
          width: 100%

        ::v-deep .v-btn
          margin: 0

    &__btn ::v-deep .v-btn__content
      padding: 0 32px
</style>
