var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "sensor-status" }, [
    _c(
      "ul",
      { attrs: { "data-testid": "HealthCheckStatusList" } },
      [
        _vm._.isEmpty(_vm.results)
          ? _c(
              "li",
              {
                staticClass: "sensor-status__item sensor-status__item--success",
              },
              [_vm._v("\n      " + _vm._s(_vm.$t("allgreen")) + "\n    ")]
            )
          : _vm._l(_vm.results, function (result) {
              return _c(
                "li",
                {
                  class:
                    "sensor-status__item sensor-status__item--" + result.status,
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(result.name) +
                      ": " +
                      _vm._s(result.message) +
                      "\n      "
                  ),
                ]
              )
            }),
        _vm._v(" "),
        _vm.firmwareVersion
          ? _c(
              "li",
              { staticClass: "sensor-status__item sensor-status__item--info" },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("firmware_version")) +
                    ": " +
                    _vm._s(_vm.firmwareVersion) +
                    "\n    "
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.ipAddress
          ? _c(
              "li",
              { staticClass: "sensor-status__item sensor-status__item--info" },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("ip_address")) +
                    ": " +
                    _vm._s(_vm.ipAddress) +
                    "\n    "
                ),
              ]
            )
          : _vm._e(),
      ],
      2
    ),
    _vm._v(" "),
    _vm.canDownload && !_vm._.isEmpty(_vm.results)
      ? _c(
          "a",
          {
            staticClass: "sensor-status__download",
            attrs: { href: _vm.download, download: "health_check.json" },
          },
          [
            _c("span", [
              _vm._v("\n      " + _vm._s(_vm.$t("download")) + "\n    "),
            ]),
            _vm._v(" "),
            _c("v-icon", [_vm._v("download")]),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }