import Vue from 'vue';
import Map from 'collections/map';
import * as types from '../constants/mutationTypes';

export const actions = {
  requestSensor({ commit }, id) {
    return Vue.http.get(`/api/sensors/${id}`).then((response) => {
      commit(types.SET_SENSOR, { id, item: response.data });
      return response;
    });
  },
  requestSensors({ commit }) {
    return Vue.http.get('/api/sensors').then((response) => {
      commit(types.SET_SENSORS, response.data);
      return response.data;
    });
  },
  requestCreateSensor({ commit }, data) {
    return Vue.http.post('/api/sensors', data).then((response) => {
      const { id } = response.data;
      commit(types.SET_SENSOR, { id, item: response.data });
      return response.data;
    });
  },
  requestUpdateSensor({ commit }, { id, data }) {
    return Vue.http.put(`/api/sensors/${id}`, data).then((response) => {
      commit(types.SET_SENSOR, { id, item: response.data });
      return response.data;
    });
  },
  requestDestroySensor({ commit }, id) {
    return Vue.http.delete(`/api/sensors/${id}`).then(() => {
      commit(types.DELETE_SENSOR, id);
    });
  },
  clearSensors({ commit }) {
    commit(types.CLEAR_SENSORS);
  },
  reloadSensor: async function ({ commit }, id) {
    commit(types.START_RELOAD_SENSOR, id);
  },
  onReloadSensorFinished: async function ({ commit }, id) {
    commit(types.STOP_RELOAD_SENSOR, id);
  },
  checkAvailability: async function (_, id) {
    const response = await Vue.http.get(`/api/sensors/${id}/availability`);
    return response.data.available;
  },
};

export const mutations = {
  SET_SENSOR(state, { id, item }) {
    state.items = state.items.concat([
      [id, item],
    ]);
  },
  SET_SENSORS(state, items) {
    state.items = new Map(items.map(item => [item.id, item]));
  },
  DELETE_SENSOR(state, id) {
    state.items = state.items.filter(item => item.id !== id);
  },
  CLEAR_SENSORS(state) {
    state.items = new Map();
  },
  START_RELOAD_SENSOR(state, id) {
    state.reloadingSensors = [...state.reloadingSensors, id];
  },
  STOP_RELOAD_SENSOR(state, id) {
    state.reloadingSensors = state.reloadingSensors.filter(reloadingId => reloadingId !== id);
  },
};

const SENSOR_TYPE_ORDERS = {
  insect_monitor_v2: 0,
  rat_monitor: 1,
  hykecam: 2,
};

export const getters = {
  getSensor(state) {
    return id => state.items.get(id);
  },
  getSensors(state) {
    return state.items.sorted((prev, next) => {
      if (prev.type !== next.type) {
        const prevOrder = SENSOR_TYPE_ORDERS[prev.type];
        const nextOrder = SENSOR_TYPE_ORDERS[next.type];
        return prevOrder > nextOrder ? 1 : -1;
      } else if (prev.place !== next.place) {
        return prev.place > next.place ? 1 : -1;
      } else if (prev.name !== next.name) {
        return prev.name > next.name ? 1 : -1;
      }
      return prev.id > next.id ? 1 : -1;
    });
  },
  shouldReloadSensor(state) {
    return id => state.reloadingSensors.includes(id);
  },
};

export const state = () => ({
  items: new Map(),
  reloadingSensors: [],
});
