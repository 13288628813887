var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("polling-channel", {
    attrs: {
      input: _vm.event,
      active: _vm.pollingActive,
      updateFunction: _vm.updateEvent,
    },
    on: { valueUpdated: _vm.onEventUpdate },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function (props) {
            return [_vm._t("default", null, { event: props.currentValue })]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }