var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "zoom",
    {
      ref: "zoom",
      class: { active: _vm.loaded },
      attrs: {
        scale: _vm.zoom,
        x: _vm.x,
        y: _vm.y,
        disabled: _vm.disablePanZoom,
      },
      on: {
        "update:scale": function ($event) {
          _vm.zoom = $event
        },
        "update:x": function ($event) {
          _vm.x = $event
        },
        "update:y": function ($event) {
          _vm.y = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "insect-positions-canvas",
          on: {
            contextmenu: function ($event) {
              $event.preventDefault()
              return _vm.showContext.apply(null, arguments)
            },
          },
        },
        [
          _c("loading-image", {
            attrs: { src: _vm.src },
            on: { loaded: _vm.handleLoadImage },
          }),
          _vm._v(" "),
          _c("canvas", {
            ref: "displayLayer",
            attrs: { height: _vm.naturalHeight, width: _vm.naturalWidth },
          }),
          _vm._v(" "),
          _c(
            "canvas",
            _vm._g(
              {
                ref: "editLayer",
                staticClass: "edit-layer",
                attrs: { height: _vm.naturalHeight, width: _vm.naturalWidth },
              },
              _vm.canvasInputEventHandlers
            )
          ),
          _vm._v(" "),
          _c(
            "v-menu",
            {
              attrs: {
                "position-x": _vm.context.x,
                "position-y": _vm.context.y,
                disabled: _vm.disableContextMenu,
                absolute: "",
                "offset-y": "",
              },
              model: {
                value: _vm.context.show,
                callback: function ($$v) {
                  _vm.$set(_vm.context, "show", $$v)
                },
                expression: "context.show",
              },
            },
            [
              _c(
                "v-list",
                [
                  _c(
                    "v-list-item",
                    {
                      attrs: { link: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("contextmenu:save", _vm._self)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("menu.save")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }