<template>
  <div class="dot-selector">
    <div
      :key="i"
      class="dot-selector__item"
      :class="{ 'dot-selector__item--active': i === value }"
      @click="handleClick(i)"
      v-for="(_n, i) of size"
    />
  </div>
</template>

<script>

export default {
  name: 'dot-selector',
  props: {
    value: Number, // Integer
    size: Number, // Integer
  },
  computed: {
  },
  methods: {
    handleClick(index) {
      this.$emit('change', index);
    },
  },
};
</script>

<style scoped lang="sass">
.dot-selector
  display: flex
  align-items: center

  &__item 
    color: #ccc
    height: 20px
    width: 20px
    margin: 0 5px
    cursor: pointer

    &--active 
      color: #000
      cursor: normal

    &::before
      content: '●'
</style>
