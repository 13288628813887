var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "organization-user-list" },
    [
      !_vm.loaded
        ? [
            _c(
              "div",
              [
                _c("v-progress-circular", {
                  staticClass: "load-spinner primary--text",
                  attrs: { indeterminate: "", size: 40 },
                }),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.$t("label.loading")))]),
              ],
              1
            ),
          ]
        : [
            _c("user-list", {
              attrs: {
                users: _vm.users,
                no_users_message: _vm.$t("label.no_users"),
                readonly: !_vm.managerable,
              },
              scopedSlots: _vm._u([
                {
                  key: "list-items",
                  fn: function (ref) {
                    var user = ref.user
                    return [
                      _c(
                        "v-list-item",
                        {
                          attrs: { disabled: !_vm.canAppointAsAdmin(user) },
                          on: {
                            click: function ($event) {
                              return _vm.appointAsAdmin(user)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("label.appoint")) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.userIsRemovable(user)
                        ? _c(
                            "v-list-item",
                            {
                              staticClass: "red--text",
                              on: {
                                click: function ($event) {
                                  return _vm.remove(user)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("label.remove")) +
                                  "\n        "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
          ],
      _vm._v(" "),
      _c("confirm-dialog", {
        attrs: { message: _vm.confirm.message },
        on: { yes: _vm.confirm.onConfirm },
        model: {
          value: _vm.confirm.visible,
          callback: function ($$v) {
            _vm.$set(_vm.confirm, "visible", $$v)
          },
          expression: "confirm.visible",
        },
      }),
      _vm._v(" "),
      _vm.managerable
        ? _c(
            "round-button",
            {
              staticClass: "mt-2",
              attrs: { icon: "fa-user-plus", fullWidth: "" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.showAddUserDialog.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n    " + _vm._s(_vm.$t("button.add_member")) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "card-dialog",
        {
          attrs: { title: _vm.$t("label.add_user"), width: "320px" },
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function () {
                return [
                  _c(
                    "dialog-action",
                    {
                      nativeOn: {
                        click: function ($event) {
                          _vm.dialog.visible = false
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("button.cancel")) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "dialog-action",
                    {
                      attrs: {
                        disabled: !_vm.selected || _vm.$refs.addUser.hasError,
                      },
                      on: { click: _vm.add },
                    },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.$t("button.add")) + "\n      "
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.dialog.visible,
            callback: function ($$v) {
              _vm.$set(_vm.dialog, "visible", $$v)
            },
            expression: "dialog.visible",
          },
        },
        [
          _vm.availablesLoaded
            ? _c("v-select", {
                ref: "addUser",
                attrs: {
                  required: "",
                  "hide-details": "",
                  dense: "",
                  "item-text": "name",
                  "item-value": "email",
                  items: _vm.availables,
                  placeholder: _vm.$t("field.select_account"),
                  "data-testid": "organization-user-list__select-user",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item",
                      fn: function (ref) {
                        var props = ref.props
                        var item = ref.item
                        return [
                          _c(
                            "div",
                            [
                              _c("v-list-item-title", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.name) +
                                    "\n          "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("v-list-item-subtitle", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(item.email) +
                                    "\n          "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  739644203
                ),
                model: {
                  value: _vm.selected,
                  callback: function ($$v) {
                    _vm.selected = $$v
                  },
                  expression: "selected",
                },
              })
            : _c(
                "div",
                { staticClass: "text-center mt-2" },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", size: 20 },
                  }),
                ],
                1
              ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "card-dialog",
        {
          attrs: { title: _vm.$t("label.add_user"), width: "290px" },
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function () {
                return [
                  _c(
                    "dialog-action",
                    {
                      nativeOn: {
                        click: function ($event) {
                          _vm.invitationDialog.visible = false
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("button.cancel")) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "dialog-action",
                    {
                      attrs: {
                        disabled:
                          _vm.invitationDialog.send_to === "" ||
                          _vm.$refs.invitationSendTo.hasError,
                      },
                      on: { click: _vm.sendInvitation },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("button.invite")) +
                          "\n      "
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.invitationDialog.visible,
            callback: function ($$v) {
              _vm.$set(_vm.invitationDialog, "visible", $$v)
            },
            expression: "invitationDialog.visible",
          },
        },
        [
          _c("v-text-field", {
            ref: "invitationSendTo",
            attrs: {
              required: "",
              autofocus: "",
              label: _vm.$t("field.invitationEmail"),
              hint: _vm.$t("msg.enter_email"),
              rules: _vm.invitationDialog.rules.send_to,
              "data-testid": "organization-user-list__invitation-send-to",
            },
            model: {
              value: _vm.invitationDialog.send_to,
              callback: function ($$v) {
                _vm.$set(_vm.invitationDialog, "send_to", $$v)
              },
              expression: "invitationDialog.send_to",
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }