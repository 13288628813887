var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex" },
    [
      _c("insect-type-pie-chart", {
        attrs: { insects: _vm.top5Insects, pieColors: _vm.colors },
      }),
      _vm._v(" "),
      _c("hr", { staticClass: "divider d-none d-md-block" }),
      _vm._v(" "),
      _c("insect-type-snippets", {
        staticClass: "d-none d-md-block",
        attrs: { insects: _vm.top5Insects, colors: _vm.colors },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }