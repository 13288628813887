<template>
  <div class="text-h6 d-flex">
    <div class="font-weight-bold mb-0 primary--text">
      {{ getPlanUserFacingLabel(user.current_plan) }}<br>
      <i18n path="uploads">
        <span :class="countColor">{{ trapsUsed }}</span>
      </i18n>
    </div>
    <v-tooltip min-width="10" bottom>
      <template #activator="{ on, attrs }">
        <v-icon class="pl-2" v-bind="attrs" v-on="on" color="grey">
          fa-question-circle-o
        </v-icon>
      </template>
      <span v-html="$t('explanation')" />
    </v-tooltip>
  </div>
</template>

<i18n lang="yaml">
ja:
  uploads: 'アップロード枚数{0}枚'
  explanation: 'アップロード枚数：<br>1回のアップロードに付き下記の通り計算<br>2画像以下＝1枚、3画像以上＝2枚'

en:
  uploads: '{0} traps uploaded'
  explanation: 'Calculations per upload:<br>2 or fewer shots = 1 trap, 3 or more shots = 2 traps'
</i18n>

<script>
import PlanOptions from '@/mixins/planOptions';

export default {
  name: 'station-plan-status',
  mixins: [
    PlanOptions,
  ],
  components: {},
  computed: {
    user() {
      return this.$store.getters.getCurrentUser;
    },
    trapsUsed() {
      return this.user.s_type_usage.traps_used;
    },
    countColor() {
      return this.user.s_type_usage.included_units_are_exceeded ?
        'red--text' :
        '';
    },
  },
};
</script>

<style scoped lang="sass">
</style>
