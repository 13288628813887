var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      staticClass: "fullscreen-exit",
      attrs: { color: "grey", text: "" },
      on: {
        click: function (ev) {
          return _vm.$emit("click", ev)
        },
      },
    },
    [_c("v-icon", [_vm._v("fa-close")])],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }