var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("date-picker-dropdown", {
    attrs: { type: "month", value: _vm.value },
    on: {
      input: function (value) {
        return _vm.$emit("input", value)
      },
    },
    scopedSlots: _vm._u([
      {
        key: "activator",
        fn: function (ref) {
          var on = ref.on
          var attrs = ref.attrs
          return [
            _c(
              "text-field",
              _vm._g(
                _vm._b(
                  {
                    attrs: {
                      value: _vm.value,
                      readonly: "",
                      "hide-details": "auto",
                      "data-testid": _vm.dataTestid,
                    },
                  },
                  "text-field",
                  Object.assign({}, _vm.$attrs, attrs),
                  false
                ),
                on
              )
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }