export const TERMS_TITLE = 'ペストビジョン利用規約';

export const TERMS_TEXT = `
この利用規約（以下「本規約」と記載）は、環境機器株式会社（以下「当社」と記載）がこのウェブサイト上で提供するサービス（以下、「本サービス」と記載）の利用条件を定めるものです。
登録ユーザーの皆様（以下、「ユーザー」と記載）には、本規約に従って、本サービスをご利用頂きます。

第 1 条(適用)
本規約は、ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。

第 2 条(利用登録)
1. 登録希望者が当社の定める方法によって利用登録を申請し、当社がこれを承認することによって、利用登録が完了するものとします。
2. 当社は、利用登録の申請者に以下の事由があると判断した場合、利用登録の申請を承認しないことがあり、その理由については一切の開示義務を負わないものとします。
  (1) 利用登録の申請に際して虚偽の事項を届け出た場合
  (2) 本規約に違反したことがある者からの申請である場合
  (3) その他、当社が利用登録を相当でないと判断した場合

第 3 条(ユーザーID およびパスワードの管理)
1. ユーザーは、自己の責任において、本サービスのユーザーID およびパスワードを管理するものとします。
2. ユーザーは、いかなる場合にも、ユーザーID およびパスワードを第三者に譲渡または貸与することはできません。当社は、ユーザーID とパスワードの組み合わせが登録情報と一致してログインされた場合には、そのユーザーID を登録しているユーザー自身による利用とみなします。

第 4 条(禁止事項)
ユーザーが本サービスの利用にあたり、以下の行為を禁止します。
  (1) 法令または公序良俗に違反する行為
  (2) 犯罪行為に関連する行為
  (3) 当社のサーバーまたはネットワークの機能を破壊したり、妨害したりする行為
  (4) 当社のサービスの運営を妨害するおそれのある行為
  (5) 他のユーザーに成りすます行為
  (6) 当社のサービスに関連して、反社会的勢力に対して直接または間接的に利益を供与する行為
  (7) その他、当社が不適切と判断する行為

第 5 条(本サービスの提供の停止など)
1. 当社は、以下のいずれかの事由があると判断した場合、ユーザーに事前通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
  (1) 本サービスにかかるコンピューターシステムの保守点検または更新を行う場合
  (2) 地震、落雷、火災、停電などの不可抗力により、本サービスの提供が困難となった場合
  (3) コンピューターまたは通信回線が事故により停止した場合
  (4) その他、当社が本サービスの提供が困難、システムの停止が必要と判断した場合
2. 当社は、本サービスの提供の停止または中断により、ユーザーまたは第三者が被ったいかなる不利益または損害について、理由を問わず一切の責任を負いません。

第 6 条(利用制限および登録抹消)
1. 当社は、以下の場合には事前通知なく、ユーザーに対して、本サービスの利用を制限し、またはユーザーとしての登録が抹消できるものとします。
  (1)本規約のいずれかの条項に違反した場合
  (2)登録事項に虚偽の事実があることが判明した場合
  (3)その他、当社が本サービスの利用を適当でないと判断した場合
2. 当社は、本条に基づき当社が行った行為によりユーザーに生じた損害について、一切の責任を負いません。

第 7 条(免責)
1. 通信回線やコンピュータなどの障害によるシステムの中断・遅滞・中止・データの消失、データへの不正アクセスにより生じた損害、その他当社のサービスに関して会員に生じた損害について、当社は一切責任を負わないものとします。
2. 当社は、当社のウェブページ・サーバ・ドメインなどから送られるメール・コンテンツに、コンピュータ・ウィルスなどの有害なものが含まれていないことを保証いたしません。
3. 会員が本規約等に違反したことによって生じた損害については、当社は一切の責任を負いません。

第 8 条(サービス内容の変更など)
当社は、ユーザーに通知することなく本サービスの内容を変更し、または本サービスの提供を中止することができるものとし、これによってユーザーに生じた損害について一切の責任を負いません。

第 9 条(本規約の改定)
当社は、本規約を任意に改定できるものとし、また、当社において本規約を補充する規約（以下「補充規約」といいます）を定めることができます。本規約の改定または補充は、改定後の本規約または補充規約を当社所定のサイトに掲示したときにその効力を生じるものとします。この場合、ユーザーは、改定後の規約および補充規約に従うものとします。

第 10 条(準拠法、管轄裁判所)
本規約に関して紛争が生じた場合、当社本店所在地を管轄する地方裁判所を第一審の専属的合意管轄裁判所とします。
`;
