<template>
  <bar-chart :data="chartData" :options="options" />
</template>

<i18n lang="yaml">
ja:
  indoor: '内部発生'
  outdoor: '外部侵入'
en:
  indoor: 'indoor'
  outdoor: 'outdoor'
</i18n>

<script>
import BarChart from '@/components/atoms/BarChart';

import { indoorColor, outdoorColor } from './colors';
import ChartHelpers from './mixins/chartHelpers';

const WEEKDAY_LABELS = {
  ja: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
  en: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
};

// Monday, Tuesday, ..., Sunday
const WEEKDAY_ORDER = [1, 2, 3, 4, 5, 6, 0];

export default {
  name: 'weekday-bar-chart',
  props: {
    events: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  components: {
    BarChart,
  },
  mixins: [ChartHelpers],
  data() {
    return {
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
            ticks: {
              precision: 0,
            },
          },
        },
      },
    };
  },
  computed: {
    chartData() {
      return {
        labels: this.weekdayLabels,
        datasets: [
          {
            backgroundColor: indoorColor,
            data: this.chartValues.indoor,
            label: this.$t('indoor'),
          },
          {
            backgroundColor: outdoorColor,
            data: this.chartValues.outdoor,
            label: this.$t('outdoor'),
          },
        ],
      };
    },
    chartValues() {
      return {
        outdoor: WEEKDAY_ORDER.map(weekday => this.weeklyRouteCounts[weekday].outdoor),
        indoor: WEEKDAY_ORDER.map(weekday => this.weeklyRouteCounts[weekday].indoor),
      };
    },
    weekdayLabels() {
      const labels = WEEKDAY_LABELS[this.$i18n.locale];
      return WEEKDAY_ORDER.map(weekday => labels[weekday]);
    },
    weeklyRouteCounts() {
      const result = this.weekdayLabels.map(() => ({ indoor: 0, outdoor: 0 }));
      this.events.forEach(({ timestamp, chartInfo: { insectCountIncreases } }) => {
        const weekday = new Date(timestamp * 1000).getDay();
        const routeCounts = this.calcRouteCounts(insectCountIncreases);
        Object.keys(routeCounts).forEach((route) => {
          result[weekday][route] += routeCounts[route];
        });
      });
      return result;
    },
  },
};
</script>

<style scoped lang="sass">
</style>
