var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "station-place-event-list" },
    [
      _c("content-header", {
        attrs: {
          title: _vm.$t("title"),
          titleClass: "display-1 font-weight-bold",
        },
      }),
      _vm._v(" "),
      _c("data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.devices,
          "no-data-text": _vm.noDataText,
          loading: _vm.loading,
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function (ref) {
              var item = ref.item
              return [
                _c("td", [
                  _c(
                    "a",
                    { attrs: { href: item.login_url, target: "_blank" } },
                    [_vm._v("\n          " + _vm._s(item.name) + "\n        ")]
                  ),
                ]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(item.ip_address))]),
                _vm._v(" "),
                _c("td", [_vm._v(_vm._s(item.serial_number))]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }