var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "entity-list-layout",
    {
      staticClass: "admin-organization-list",
      attrs: {
        title: "ORGANIZATION",
        count: _vm.organizations.length,
        query: _vm.search,
        "search-hint": _vm.$t("label.search"),
      },
      on: {
        "update:query": function ($event) {
          _vm.search = $event
        },
      },
      scopedSlots: _vm._u([
        {
          key: "title-area-content",
          fn: function () {
            return [
              _c(
                "round-button",
                {
                  staticClass: "my-2 mx-md-2",
                  attrs: { icon: "add_circle", to: "/admin/organizations/new" },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("label.add_organization")) +
                      "\n    "
                  ),
                ]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "p-data-list",
        {
          attrs: { data: _vm.organizations, pagination: _vm.pagination },
          on: {
            "update:pagination": function ($event) {
              _vm.pagination = $event
            },
          },
        },
        [
          _c("data-field", {
            attrs: { name: "No", headerClass: "column--no" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [_vm._v(_vm._s(props.index + 1))]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("data-field", {
            attrs: { name: _vm.$t("field.name"), value: "name", sortable: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "admin-organization",
                            params: { id: props.item.id },
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(props.item.name) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("data-field", {
            attrs: {
              name: _vm.$t("field.address"),
              value: "address",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [_vm._v(_vm._s(props.item.address))]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("data-field", {
            attrs: {
              name: _vm.$t("field.phone_number"),
              value: "phone_number",
              headerClass: "column--phone-number",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [_vm._v(_vm._s(props.item.phone_number))]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }