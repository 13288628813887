var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "insect-view-detections-editor" },
    [
      _c("editor-canvas", {
        ref: "canvas",
        attrs: {
          imageSrc: _vm.imageSrc,
          detections: _vm.detections,
          mode: _vm.editMode,
          withInsectTypes: _vm.withInsectTypes,
          newInsectType: _vm.defaultInsectType,
        },
        on: { pick: _vm.showForm, add: _vm.addDetection },
      }),
      _vm._v(" "),
      _c("insect-type-picker", {
        ref: "form",
        attrs: { withInsectTypes: _vm.withInsectTypes },
        on: { change: _vm.onChangeDetections, close: _vm.hideForm },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }