<template>
  <div class='loading-image' :class="{ 'loading-image--error': showError, 'loading-image--loading': showSpinner }">
    <img
      :src="src"
      @load="onLoad"
      @error="() => error = true"
      data-testid="image"
      v-show="showImage"
    />
    <v-progress-circular
      class="centered primary--text"
      indeterminate
      :size="50"
      :width="3"
      v-if="showSpinner"
    />
    <v-icon
      x-large
      class="centered red--text"
      data-testid="error"
      v-if="showError"
    >
      fa-times
    </v-icon>
  </div>
</template>

<script>
export default {
  name: 'loading-image',
  props: {
    src: String,
    forceLoading: Boolean,
  },
  computed: {
    showError() {
      return !this.forceLoading && this.error;
    },
    showImage() {
      return !(this.showSpinner || this.showError);
    },
    showSpinner() {
      return this.forceLoading || !(this.loaded || this.error);
    },
  },
  data: function () {
    return {
      error: !this.src,
      loaded: false,
    };
  },
  methods: {
    onLoad(event) {
      this.loaded = true;
      if (!this.forceLoading) {
        this.$emit('loaded', event);
      }
    },
  },
  watch: {
    src() {
      this.error = !this.src;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'

.loading-image
  position: relative

  img
    display: block
    height: 100%
    width: 100%
    object-fit: contain

  .centered
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)

  &--error
    background-color: darkgrey
</style>
