<template>
  <card-dialog
    :title="$t('confirmation')"
    :value="value"
    @input="val => $emit('input', val)"
    role="dialog"
    width="290px"
  >
    {{ message }}
    <template #actions>
      <dialog-action @click="handleAction('no')">{{ $t('no') }}</dialog-action>
      <dialog-action @click="handleAction('yes')">{{ $t('yes') }}</dialog-action>
    </template>
  </card-dialog>
</template>

<i18n lang="yaml">
ja:
  confirmation: 確認 
  no: いいえ
  yes: はい

en:
  no: No
  yes: Yes
  confirmation: Confirmation
</i18n>

<script>
import CardDialog from './CardDialog';
import DialogAction from './DialogAction';

export default {
  name: 'confirm-dialog',
  components: {
    CardDialog,
    DialogAction,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    message: String,
  },
  methods: {
    handleAction(action) {
      this.$emit(action);
      this.$emit('input', false);
    },
  },
};
</script>
