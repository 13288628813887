<template>
  <v-btn icon :small="small" :color="color" :disabled="disabled" v-on="$listeners">
    <v-icon :small="small">{{ icon }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'icon-button',
  props: {
    color: {
      type: String,
      default: 'primary',
    },
    icon: {
      type: String,
      required: true,
    },
    small: Boolean,
    disabled: Boolean,
  },
};
</script>
