var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-list" },
    [
      _vm.users.length > 0
        ? _c(
            "v-list",
            _vm._l(_vm.users, function (user) {
              return _c(
                "v-list-item",
                {
                  key: user.email,
                  staticClass: "user-list__user",
                  attrs: { role: "listitem" },
                },
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", [
                        _vm._v(
                          "\n          " +
                            _vm._s(user[_vm.nameKey]) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("v-list-item-subtitle", [
                        _vm._v(
                          "\n          " + _vm._s(user.email) + "\n        "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.readonly
                    ? _c(
                        "v-list-item-action",
                        [
                          !_vm.readonly
                            ? _c(
                                "v-menu",
                                {
                                  attrs: {
                                    "content-class": "user-list__menu",
                                    "offset-y": "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  {
                                                    attrs: {
                                                      text: "",
                                                      icon: "",
                                                      "data-testid":
                                                        "user-list__user-dots",
                                                    },
                                                  },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v("mdi-dots-vertical"),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _vm._v(" "),
                                  _c(
                                    "v-list",
                                    [
                                      _vm._t("list-items", null, {
                                        user: user,
                                      }),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            }),
            1
          )
        : [_c("div", [_vm._v(_vm._s(_vm.no_users_message))])],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }