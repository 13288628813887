import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const SUPPORT_LANGUAGES = {
  en: 'en',
  'en-US': 'en',
  ja: 'ja',
  'ja-JP': 'ja',
};

let language;
if (window.navigator.languages) {
  language = window.navigator.languages[0];
} else {
  language = window.navigator.userLanguage || window.navigator.language;
}

if (!SUPPORT_LANGUAGES[language]) {
  language = 'en';
}

export default new VueI18n({
  locale: SUPPORT_LANGUAGES[language],
  fallbackLocale: 'en',
});
