export default {
  data() {
    return {
      defaultPagination: {
        page: 1,
        itemsPerPage: 5,
      },
    };
  },
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    pagination: {
      type: Object,
      default: null,
    },
  },
  computed: {
    computedPagination() {
      return {
        ...this.defaultPagination,
        ...this.pagination,
      };
    },
    getPerPage() {
      return this.computedPagination.itemsPerPage;
    },
    hasPerPage() {
      return this.getPerPage > 0;
    },
    pageStart() {
      return this.hasPerPage ? (this.computedPagination.page - 1) * this.getPerPage : 0;
    },
    pageStop() {
      return this.hasPerPage ? this.computedPagination.page * this.getPerPage : this.items.length;
    },
    pagedItems() {
      return this.items.slice(this.pageStart, this.pageStop);
    },
  },
};
