import * as types from '../constants/mutationTypes';

export const actions = {
  notify({ commit }, notification) {
    commit(types.SET_NOTIFICATION, notification);
  },
  hideNotification({ commit }) {
    commit(types.CLEAR_NOTIFICATION);
  },
};

export const mutations = {
  SET_NOTIFICATION(state, notification) {
    state.notification = notification;
  },
  CLEAR_NOTIFICATION(state) {
    state.notification = {};
  },
};

export const getters = {
  getNotification(state) {
    return state.notification;
  },
};

export const state = () => ({
  notification: {},
});
