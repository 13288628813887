<template>
  <div class="sensor-type d-flex align-center">
    <icon :type="type" :size="36" v-if="type"/>
    <sensor-icon :sensor="sensor" :size="36" v-else />
    <span>{{ label }}</span>
  </div>
</template>

<i18n lang="yaml">
ja:
  insect_monitor_v2: '捕虫器センサー(3台)'

en:
  insect_monitor_v2: |-
    Insect sensors
    (3 camera)
</i18n>

<script>
import SensorTypes from '@/mixins/sensorTypes';

import Icon from '@/components/Icon';
import SensorIcon from '@/components/SensorIcon';


export default {
  name: 'sensor-type-label',
  mixins: [
    SensorTypes,
  ],
  components: {
    Icon,
    SensorIcon,
  },
  props: {
    type: {
      type: String,
      required: false,
    },
    sensor: {
      type: Object,
      required: false,
    },
  },
  computed: {
    label() {
      // To have special multiline label
      if (this.sensorType === 'insect_monitor_v2') {
        return this.$t('insect_monitor_v2');
      }

      return this.SENSOR_TYPE_NAMES[this.sensorType];
    },
    sensorType() {
      if (this.type) {
        return this.type;
      }

      return this.sensor.type;
    },
  },
};
</script>

<style scoped lang="sass">
.sensor-type
  // To keep the newline for the Insect Monitor V2 label  
  white-space: pre

  span
    font-size: 11px
    margin-left: 8px
</style>
