var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gallery-view d-flex align-stretch" },
    [
      _c(
        "div",
        {
          staticClass:
            "handle d-flex flex-column justify-center hidden-sm-and-down",
        },
        [
          _vm.hasPrevious
            ? _c(
                "v-btn",
                {
                  attrs: { text: "", disabled: _vm.disabled },
                  on: { click: _vm.previous },
                },
                [
                  _c("v-icon", { attrs: { "x-large": "" } }, [
                    _vm._v("fa-angle-left"),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        {
          staticClass: "content flex-grow-1 pa-0",
          attrs: { "no-gutters": "" },
        },
        [
          _c("v-col", { attrs: { cols: "12" } }, [_vm._t("top")], 2),
          _vm._v(" "),
          _c(
            "v-row",
            { staticStyle: { width: "100%" }, attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                {
                  staticClass: "pr-1",
                  attrs: { cols: "12", md: _vm.mainColumns },
                },
                [_vm._t("default")],
                2
              ),
              _vm._v(" "),
              _vm.hasSideContent
                ? _c(
                    "v-col",
                    {
                      staticClass: "pl-1",
                      attrs: { cols: "12", md: _vm.sideColumns },
                    },
                    [_vm._t("side")],
                    2
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "handle d-flex flex-column justify-center hidden-sm-and-down",
        },
        [
          _vm.hasNext
            ? _c(
                "v-btn",
                {
                  attrs: { text: "", disabled: _vm.disabled },
                  on: { click: _vm.next },
                },
                [
                  _c("v-icon", { attrs: { "x-large": "" } }, [
                    _vm._v("fa-angle-right"),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }