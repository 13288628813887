var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c("v-spacer"),
          _vm._v(" "),
          _c("icon-button", {
            attrs: { icon: "fa-angle-left" },
            on: { click: _vm.goToPreviousMonth },
          }),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "d-inline-flex align-center" },
            [
              _c("h1", [_vm._v(_vm._s(_vm.month))]),
              _vm._v(" "),
              _c("date-picker-dropdown", {
                attrs: { value: _vm.month, type: "month" },
                on: {
                  input: function (value) {
                    return _vm.$emit("update:month", value)
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function (ref) {
                      var on = ref.on
                      return [
                        _c(
                          "v-btn",
                          _vm._g({ attrs: { icon: "", color: "primary" } }, on),
                          [_c("v-icon", [_vm._v("fa-calendar")])],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("icon-button", {
            attrs: { icon: "fa-angle-right" },
            on: { click: _vm.goToNextMonth },
          }),
          _vm._v(" "),
          _c("v-spacer"),
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.places, function (ref) {
        var place = ref.place
        var sensors = ref.sensors
        return _c(
          "v-row",
          { key: place, staticClass: "mt-12" },
          [
            _c("v-col", { attrs: { cols: "12" } }, [
              _c("h1", { staticClass: "text-center" }, [_vm._v(_vm._s(place))]),
            ]),
            _vm._v(" "),
            _vm._l(sensors, function (sensor) {
              return _c(
                "v-col",
                { key: sensor.id, staticClass: "graph", attrs: { cols: "4" } },
                [
                  _c("loading-chart", {
                    attrs: {
                      sensor: sensor,
                      month: _vm.month,
                      "y-max": _vm.yMax,
                    },
                    on: { "max-count-updated": _vm.updateMaxCount },
                  }),
                ],
                1
              )
            }),
          ],
          2
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }