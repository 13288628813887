var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "div",
        { attrs: { "data-testid": "sensor_events" } },
        [
          _c("v-divider", { staticClass: "my-4" }),
          _vm._v(" "),
          _c(
            "content-header",
            {
              staticClass: "my-3",
              attrs: {
                titleClass: "title mr-2",
                description: _vm.sensorDetails,
                subtitle: _vm.sensor.place,
                to: "sensors/" + _vm.sensor.id,
                subtitleClass: "subheading",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "title",
                    fn: function () {
                      return [_vm._v(_vm._s(_vm.sensor.name))]
                    },
                    proxy: true,
                  },
                  {
                    key: "icon",
                    fn: function () {
                      return [
                        _c("sensor-icon", { attrs: { sensor: _vm.sensor } }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1699478521
              ),
            },
            [
              _vm._v(" "),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _vm.sensor.type === _vm.SENSOR_TYPES.RAT
                    ? _c("rat-graph-button", {
                        staticClass: "hidden-sm-and-down",
                        attrs: {
                          sensor: _vm.sensor,
                          requestParams: _vm.exportParams,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("sensor-events-export-button", {
                    staticClass: "ml-2 hidden-sm-and-down",
                    attrs: {
                      sensor: _vm.sensor,
                      requestParams: _vm.exportParams,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mb-4" },
            [
              _vm.detectedCount > 0
                ? _c(
                    "round-button",
                    {
                      staticClass: "expand-btn",
                      attrs: {
                        icon: _vm.expanded
                          ? "fa-chevron-circle-up"
                          : "fa-chevron-circle-down",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("update:expanded", !_vm.expanded)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n      " + _vm._s(_vm.$t("button.expand")) + "\n    "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { wrap: "" } },
            [
              _vm._l(_vm.displayedEvents, function (event) {
                return [
                  _c("event", {
                    key: event.id,
                    attrs: { event: event, editable: _vm.isEditable },
                    on: {
                      "event:selected": function ($event) {
                        return _vm.$emit("event:selected", event, _vm.events)
                      },
                      "event:hidden": function (v) {
                        return _vm.updateEventHidden(event, v)
                      },
                    },
                  }),
                ]
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "mt-4 bottom-buttons" }, [
            _c(
              "div",
              { ref: "listEnd", staticClass: "d-flex justify-content-between" },
              [
                _vm.eventsLoading
                  ? _c("v-progress-linear", {
                      attrs: {
                        color: "primary",
                        indeterminate: "",
                        rounded: "",
                        height: "6",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }