<template>
  <v-btn color="grey" text class="fullscreen-exit" @click="ev => $emit('click', ev)">
    <v-icon>fa-close</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'fullscreen-exit',
};
</script>

<style scoped lang="sass">
.fullscreen-exit
  min-width: 24px
  position: absolute !important
  top: 10px
  right: 10px
  z-index: 2147483647
</style>
