<template>
  <v-text-field
    outlined
    dense
    :data-testid="dataTestid"
    v-on="$listeners"
    v-bind="$attrs"
  />
</template>

<script>
export default {
  name: 'text-field',
  props: {
    'data-testid': String,
  },
};
</script>
