<template>
  <div v-if="place">
    <page-title-area>
      <h1>{{ $t('title') }}</h1>
      <template #side-content>
        <h2>{{ place.name }}</h2>
      </template>
    </page-title-area>
    <template v-for="event in sortedEvents">
      <div class="event">
        <station-event-polling
          :event="event"
          @eventUpdated="onEventUpdate"
          :key="event.id"
        >
          <template v-slot="{ event }">
            <div class="floating-buttons">
              <v-btn text icon @click.stop="editEvent(event)">
                <v-icon color="primary" class="ma-2">fa-pencil</v-icon>
              </v-btn>
            </div>
            <station-event :event="event" />
          </template>
        </station-event-polling>
      </div>
    </template>
    <station-event-editor
      v-model="editDialogVisible"
      :places="places"
      :event="currentEditingEvent"
      @update="reload"
    />
  </div>
  <page-load-spinner v-else />
</template>

<i18n lang="yaml">
ja:
  title: '新着解析結果'

en:
  title: 'Recent'
</i18n>

<script>
import PageLoadSpinner from '@/components/atoms/PageLoadSpinner';
import PageTitleArea from '@/components/atoms/PageTitleArea';
import StationEventEditor from '@/components/organisms/StationEventEditor';
import StationEvent from '@/components/StationEvent';
import StationEventPolling from '@/components/StationEventPolling';

export default {
  name: 'station-place-recent-events',
  components: {
    PageLoadSpinner,
    PageTitleArea,
    StationEvent,
    StationEventEditor,
    StationEventPolling,
  },
  props: {
    placeId: {
      type: String,
    },
  },
  computed: {
    sortedEvents() {
      return this._.orderBy(this.events, ['upload_time', 'trap_no'], ['desc', 'asc']);
    },
  },
  data() {
    return {
      place: null,
      loading: true,
      events: [],
      // Edit data
      editDialogVisible: false,
      currentEditingEvent: null,
      places: null,
    };
  },
  inject: ['fetchStationPlace', 'fetchStationPlaceRecentEvents', 'fetchStationPlaces'],
  mounted: async function () {
    this.place = await this.fetchStationPlace(this.placeId);
    this.loadStationPlaces(); // Use Store if too slow
    await this.loadEvents();
  },
  methods: {
    editEvent(event) {
      this.currentEditingEvent = { ...event };
      this.editDialogVisible = true;
    },
    loadEvents: async function () {
      this.loading = true;
      this.events = await this.fetchStationPlaceRecentEvents(this.placeId);
      this.loading = false;
    },
    loadStationPlaces: async function () {
      this.places = await this.fetchStationPlaces();
    },
    onEventUpdate(event) {
      this.events = this.events.map(ev => {
        if (ev.id === event.id) {
          return event;
        }

        return ev;
      });
    },
    reload() {
      this.$router.go({
        path: this.$router.currentRoute.path,
        force: true,
      });
    },
  },
};
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'

.event + .event
  margin-top: $spacer * 4

.floating-buttons
  float: right
  margin: 0
</style>
