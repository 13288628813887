var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "insect-count-difference" },
    [
      _c("span", { staticClass: "label" }, [_vm._v(_vm._s(_vm.$t("label")))]),
      _vm._v(" "),
      _c("span", { staticClass: "mx-1" }, [_vm._v(":")]),
      _vm._v(" "),
      _c("span", { staticClass: "value" }, [
        _vm._v(_vm._s(_vm._f("sign")(_vm.difference))),
      ]),
      _vm._v(" "),
      _c(
        "v-icon",
        {
          staticClass: "arrow",
          class: { increase: _vm.difference > 0, decrease: _vm.difference < 0 },
        },
        [_vm._v("\n    fa-long-arrow-right\n  ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }