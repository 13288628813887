<script>
import List from './List';
import Table from './Table';

export default {
  name: 'data-list',
  components: {
    DList: List,
    DTable: Table,
  },
  computed: {
    listProps() {
      return {
        items: this.data,
        getItemProps: this.getItemProps,
        pagination: this.pagination,
      };
    },
    pages() {
      return Math.ceil(this.data.length / this.pagination.itemsPerPage);
    },
  },
  methods: {
    renderChildren(h) {
      return this.$slots.default
        .filter(node => !!node.tag)
        .map(node => h('template', node.data, node.children));
    },
    renderPagination(h) {
      const self = this;
      return h('v-pagination', {
        props: {
          length: this.pages,
          value: this.pagination.page,
          color: 'grey',
        },
        on: {
          input: function (value) {
            self.$emit('update:pagination', { ...self.pagination, page: value });
          },
        },
      });
    },
  },
  render(h) {
    const self = this;
    return h('div', { class: 'data-list-container' }, [
      this.renderPagination(h),
      h('d-list', {
        class: {
          'my-3': true,
          'hidden-sm-and-up': self.responsive,
          'd-none': !self.responsive,
        },
        props: this.listProps,
      }, this.renderChildren(h)),
      h('d-table', {
        class: {
          'my-3': true,
          'hidden-xs-only': self.responsive,
        },
        props: this.listProps,
        on: {
          'update:pagination'(values) {
            self.$emit('update:pagination', values);
          },
        },
      }, this.renderChildren(h)),
      this.renderPagination(h),
    ]);
  },
  props: {
    data: Array,
    getItemProps: Function,
    pagination: {
      type: Object,
      default() {
        return {
          itemsPerPage: 20,
        };
      },
    },
    responsive: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
