<template>
  <div>
    <v-row align-md="center" no-gutters>
      <v-col cols="auto">
        <slot/>
      </v-col>
      <v-col
        class="side-content-md hidden-sm-and-down d-md-flex align-center flex-wrap"
      >
        <slot name="side-content" />
      </v-col>
      <!--
        We need this to push floating content to the right because side-content above will be hidden
        in mobile view
      -->
      <v-spacer class="hidden-md-and-up" />
      <v-col cols="auto">
        <slot name="right-floating-content" />
      </v-col>
    </v-row>
    <v-row class="side-content flex-column hidden-md-and-up" no-gutters>
      <slot name="side-content" />
    </v-row>
    <v-divider class="my-4"/>
  </div>
</template>

<script>
export default {
  name: 'page-title-area',
};
</script>

<style scoped lang="sass">
// From display-1 class
h1
  color: #1976d2
  font-size: 2.125rem
  line-height: 2.5rem
  letter-spacing: .0073529412em

// Use double class and !important to be strong enough to override margin classes (md-4 etc) on children
// ::v-deep to also match root elements in direct children that are vue components
.side-content.side-content::v-deep > *
  // Have some space between the items on small screens since they are vertically aligned
  margin-top: 8px !important
  margin-bottom: 0px !important

.side-content-md.side-content-md::v-deep > *
  // Have some space between the items on big screens since they are horizontally aligned
  margin-left: 16px !important

  margin-top: 0px !important
  margin-bottom: 0px !important

  flex-shrink: 0
  flex-grow: 0
</style>
