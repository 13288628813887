import planOptions from 'Data/plan_options.json';

const PLAN_BY_ID = {};

planOptions.forEach((plan) => {
  PLAN_BY_ID[plan.id] = plan;
});

export default {
  computed: {
    getPlanUserFacingLabel() {
      return planId => PLAN_BY_ID[planId][`user_facing_label_${this.$i18n.locale}`];
    },
    planOptions() {
      return planOptions.map(plan => ({
        value: plan.id,
        text: this.getPlanAdminLabel(plan.id),
      }));
    },
  },
  methods: {
    getPlanAdminLabel(planId) {
      return PLAN_BY_ID[planId].admin_label_ja;
    },
  },
};
