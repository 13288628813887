<template>
  <div class="d-inline-flex">
    <v-btn
      text
      icon
      class="ma-2"
      @click="onClick('table')"
      :color="getButtonColor('table')"
    >
      <v-icon x-large>fa-table</v-icon>
    </v-btn>
    <v-btn
      class="ma-2"
      icon
      text
      :color="getButtonColor('bar')"
      @click="onClick('bar')"
    >
      <v-icon x-large>fa-bar-chart</v-icon>
    </v-btn>
    <v-btn
      class="ma-2"
      icon
      text
      :color="getButtonColor('pie')"
      @click="onClick('pie')"
    >
      <v-icon x-large>fa-pie-chart</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'ChartTypeSelect',
  props: {
    value: String,
  },
  methods: {
    getButtonColor(type) {
      return this.value === type ? 'primary' : '';
    },
    onClick(value) {
      this.$emit('input', value);
    },
  },
};
</script>
