<template>
  <line-chart :data="chartData" :options="chartOptions" />
</template>

<i18n lang="yaml">
ja:
  day_format: 'M月D日'

en:
  day_format: 'MMM D'
</i18n>

<script>
import { merge } from 'lodash';
import moment from 'moment';

import theme from '@/libs/theme';
import { datesOfMonth } from '@/libs/time';

import LineChart from '@/components/atoms/LineChart';
import { dailyInsectCounts, alertPeriods } from './graphCalculations';

export const ALERT_STYLES = {
  totalCountAlert: {
    backgroundColor: '#edc09d',
    borderColor: '#edc09d',
  },
  increaseCountAlert: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    borderColor: 'crimson',
    borderWidth: 3,
  },
};

export default {
  name: 'daily-insect-count-chart',
  components: {
    LineChart,
  },
  props: {
    // { timestamp: number, count: number, alert: boolean, increaseAlert: boolean, sensorId: str }
    eventData: {
      type: Array,
      required: true,
    },
    // YYYY-MM
    month: {
      type: String,
      required: true,
    },
    title: {
      type: String,
    },
    yMax: {
      type: Number,
      required: true,
    },
    showAlertPeriods: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    alertPeriods() {
      if (!this.showAlertPeriods) {
        return [];
      }

      return alertPeriods(this.monthDates, this.eventData);
    },
    chartAlertPeriodAnnotations() {
      return this.alertPeriods.map(alertPeriod => ({
        type: 'box',
        xScaleID: 'xAxis',
        yScaleID: 'yAxis',
        xMin: this.labelForDate(alertPeriod.start),
        xMax: this.labelForDate(alertPeriod.end),
        ...this.annotationStyle(alertPeriod.type),
      }));
    },
    chartData() {
      return {
        labels: this.chartXAxisLabels,
        datasets: [
          {
            data: this.chartValues,
            pointBackgroundColor: theme.primaryColor,
            borderColor: theme.primaryColor,
            tension: 0, // straight lines
          },
        ],
      };
    },
    chartOptions() {
      return {
        plugins: {
          annotation: {
            drawTime: 'beforeDatasetsDraw',
            annotations: this.chartAlertPeriodAnnotations,
          },
          legend: {
            display: false,
          },
          title: {
            display: this.title !== undefined,
            text: this.title,
            position: 'bottom',
          },
        },
        scales: {
          xAxis: this.chartXAxis,
          yAxis: this.chartYAxis,
        },
        spanGaps: true,
      };
    },
    chartValues() {
      return dailyInsectCounts(this.monthDates, this.eventData);
    },
    chartXAxisLabels() {
      return this.monthDates.map(date => moment(date).format(this.$t('day_format')));
    },
    chartXAxis() {
      return this.buildAxis();
    },
    chartYAxis() {
      let stepSize = 5;
      if (this.yMax > 100) {
        stepSize = 20;
      }

      return this.buildAxis(
        {
          beginAtZero: true,
          suggestedMax: this.yMax,
          ticks: {
            stepSize,
          },
        },
      );
    },
    labelForDate() {
      return (date) => {
        const dateIndex = this.monthDates.indexOf(date);
        return this.chartXAxisLabels[dateIndex];
      };
    },
    monthDates() {
      return datesOfMonth(this.month);
    },
  },
  methods: {
    annotationStyle(type) {
      switch (type) {
        case 'cumulative':
          return ALERT_STYLES.totalCountAlert;
        case 'differential':
          return ALERT_STYLES.increaseCountAlert;
        default:
          return {};
      }
    },
    buildAxis(options) {
      return merge(
        {
          grid: {
            borderColor: 'rgb(102, 102, 102)',
            display: false,
            drawTicks: false,
          },
          ticks: {
            padding: 10,
          },
        },
        options,
      );
    },
    emitMaxCount() {
      this.$emit('max-count-updated', Math.max(...this.chartValues));
    },
  },
  mounted() {
    this.emitMaxCount();
  },
  watch: {
    chartValues() {
      this.emitMaxCount();
    },
  },
};
</script>
