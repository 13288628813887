var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-slide-group",
    {
      ref: "slideGroup",
      attrs: { "show-arrows": "always", "center-active": "", value: _vm.value },
    },
    _vm._l(_vm.items, function (item, index) {
      return _c(
        "v-slide-item",
        { key: item[_vm.keyName] },
        [_vm._t("item", null, { item: item, index: index })],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }