import Vue from 'vue';
import store from './store';
import router from './router';
import i18n from './i18n';
import vuetify from './vuetify';
import './vue-config';
import './styles/main.sass';

const startApplication = async function () {
  if (store.getters.isSignedIn) {
    try {
      await store.dispatch('getUserInfo');
    } catch (error) {
      // TODO: Show logout reason
      store.dispatch('signOut');
    }
  }

  /* eslint-disable no-new */
  new Vue({
    el: '#app',
    router,
    store,
    i18n,
    vuetify,
    render: h => h('router-view'),
  });
};

startApplication();
