<template>
  <div class="d-flex">
    <insect-type-pie-chart :insects="top5Insects" :pieColors="colors" />
    <hr class="divider d-none d-md-block" />
    <insect-type-snippets class="d-none d-md-block" :insects="top5Insects" :colors="colors" />
  </div>
</template>

<script>
import InsectTypes from '@/mixins/insectTypes';
import InsectTypePieChart from './InsectTypePieChart';
import InsectTypeSnippets from './InsectTypeSnippets';

const COLORS = [
  '#f44822',
  '#ffa629',
  '#ffcd2a',
  '#13ae5c',
  '#9747ff',
  '#b8b8b8', // Others
];

export default {
  name: 'top5-insects-panel',
  props: {
    events: {
      type: Array,
      required: true,
    },
  },
  mixins: [InsectTypes],
  components: {
    InsectTypePieChart,
    InsectTypeSnippets,
  },
  computed: {
    colors() {
      const colors = [...COLORS];
      const insects = this.top5Insects;
      // Delete unused top 5 colors before others color.
      colors.splice(insects.length - 1, colors.length - insects.length);
      return colors;
    },
    top5Insects() {
      return this.insectCounts;
    },
    insectCounts() {
      const others = this._.find(this.totalInsectCounts, { type: 'others' });
      const insectCountsWithoutOthers = this._.without(this.totalInsectCounts, others);
      const sortedInsectCounts = this._.orderBy(insectCountsWithoutOthers, ['count'], ['desc']);

      const top5 = sortedInsectCounts.slice(0, 5);
      const notTop5 = sortedInsectCounts.slice(5);
      const othersSummary = {
        name: others.name,
        count: others.count + this._.sumBy(notTop5, 'count'),
      };

      const result = top5;
      if (othersSummary.count > 0) {
        result.push(othersSummary);
      }

      return result;
    },
    totalInsectCounts() {
      const result = [];

      Object.entries(this.INSECT_TYPES).forEach(([type, { label }]) => {
        const route = this.INSECT_TYPES[type].route;
        let lastCount = 0;
        let count = 0;

        this.events.forEach((event) => {
          const insectCount = event.insect_counts[type] || 0;
          count += Math.max(0, insectCount - lastCount);
          lastCount = insectCount;
        });

        if (count > 0 || type === 'others') {
          result.push({
            type,
            name: label,
            route,
            count,
          });
        }
      });

      return result;
    },
  },
};
</script>

<style scoped lang="sass">
.divider
  height: auto
  border-left: 1px solid #e0e0e0
  margin-left: 32px
  margin-right: 32px
</style>
