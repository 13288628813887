<template>
  <v-menu
    :value="value"
    @input="onVisibilityChange"
    :close-on-content-click="false"
    content-class="dropdown-tooltip"
    transition="slide-y-transition"
    offset-y
    ref="menu"
  >
    <template #activator="props">
      <slot name="activator" v-bind="props" />
    </template>
    <slot />
  </v-menu>
</template>

<script>
export default {
  name: 'dropdown-menu',
  props: {
    value: Boolean,
    width: {
      type: Number,
      default: 0,
    },
  },
  computed: {
  },
  methods: {
    onVisibilityChange(value) {
      this.$emit('input', value);
      if (value) {
        this.$emit('activate');
      } else {
        this.$emit('deactivate');
      }
    },
  },
};
</script>

<style scoped lang="sass">
</style>
