<template>
  <v-icon :size="size">
    {{ getCauseIcon(cause) }}
  </v-icon>
</template>

<script>
export default {
  name: 'insect-cause-icon',
  props: {
    size: [Number, String],
    cause: String,
  },
  methods: {
    getCauseIcon(cause) {
      switch (cause) {
        case 'waterbody':
        case 'damp':
          return 'mdi-water';
        case 'garbage':
          return 'mdi-trash-can';
        case 'green_space':
          return 'mdi-grass';
        case 'stored_food':
          return 'mdi-barley';
        case 'mold':
          return 'mdi-virus';
        default:
          return '';
      }
    },
  },
};
</script>
