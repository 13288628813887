var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-inline-flex" },
    [
      _c(
        "v-btn",
        {
          staticClass: "ma-2",
          attrs: { text: "", icon: "", color: _vm.getButtonColor("table") },
          on: {
            click: function ($event) {
              return _vm.onClick("table")
            },
          },
        },
        [_c("v-icon", { attrs: { "x-large": "" } }, [_vm._v("fa-table")])],
        1
      ),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          staticClass: "ma-2",
          attrs: { icon: "", text: "", color: _vm.getButtonColor("bar") },
          on: {
            click: function ($event) {
              return _vm.onClick("bar")
            },
          },
        },
        [_c("v-icon", { attrs: { "x-large": "" } }, [_vm._v("fa-bar-chart")])],
        1
      ),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          staticClass: "ma-2",
          attrs: { icon: "", text: "", color: _vm.getButtonColor("pie") },
          on: {
            click: function ($event) {
              return _vm.onClick("pie")
            },
          },
        },
        [_c("v-icon", { attrs: { "x-large": "" } }, [_vm._v("fa-pie-chart")])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }