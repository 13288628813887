import Vue from 'vue';
import VueI18n from 'vue-i18n';

import mixinLocale from './common/locale';

Vue.use(VueI18n);

const messages = {
  en: {
    sensor: {
      place: 'Installation site',
    },
    station: {
      trap_no: 'Trap No',
      installed_at: 'Installed on',
      collected_at: 'Collected on',
      days: 'Days installed',
      month: 'Month',
      upload_time: 'Upload time',
      photo_count: 'Photo count',
      insect_count: 'Insect count',
      index: 'Index',
    },
  },
  ja: {
    sensor: {
      place: '設置場所',
    },
    station: {
      trap_no: 'トラップNo',
      installed_at: '設置日',
      collected_at: '回収日',
      days: '設置日数',
      month: '月',
      upload_time: 'アップロード日時',
      photo_count: '画像数',
      insect_count: '総頭数',
      index: '指数',
    },
  },
};

const i18n = new VueI18n({ locale: 'en', messages });

export default {
  computed: {
    term() {
      return key => i18n.t(key, this.i18n_locale);
    },
  },
  ...mixinLocale,
};
