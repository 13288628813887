var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-toolbar",
        { staticClass: "appbar", attrs: { elevation: "0" } },
        [
          _c("v-toolbar-title", { staticClass: "title mr-8" }, [
            _c("img", {
              staticClass: "logo py-3",
              attrs: { src: require("../assets/logo_sm.png") },
            }),
          ]),
          _vm._v(" "),
          _c(
            "v-toolbar-items",
            { staticClass: "hidden-sm-and-down" },
            _vm._l(_vm.items, function (item) {
              return _c(
                "v-btn",
                {
                  key: item.title,
                  attrs: { to: item.path, color: "primary", text: "" },
                },
                [_vm._v("\n        " + _vm._s(item.title) + "\n      ")]
              )
            }),
            1
          ),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "hidden-md-and-up",
              on: {
                click: function ($event) {
                  return _vm.toggleNavigation()
                },
              },
            },
            [
              _c(
                "v-btn",
                { staticClass: "appbar__nav-toggle", attrs: { icon: "" } },
                [_c("v-icon", [_vm._v(_vm._s(_vm.appBarIcon))])],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-toolbar-items",
            { staticClass: "hidden-sm-and-down mr-0" },
            [
              _c(
                "v-menu",
                {
                  attrs: { "offset-y": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "appbar__username",
                                  attrs: {
                                    color: "primary",
                                    text: "",
                                    "aria-label": "toggle",
                                  },
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v("fa-smile-o"),
                              ]),
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.userName) +
                                  "\n            "
                              ),
                              _c(
                                "v-icon",
                                { staticClass: "ml-4", attrs: { right: "" } },
                                [_vm._v("expand_more")]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _vm._v(" "),
                  _c(
                    "v-list",
                    {
                      staticClass: "appbar__menu-list",
                      attrs: { "aria-label": "menu" },
                    },
                    [
                      _c(
                        "v-list-item",
                        {
                          staticClass: "appbar__menu-list__item",
                          attrs: { to: _vm.accountPath },
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(_vm.accountLabel)),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item",
                        {
                          staticClass: "appbar__menu-list__item",
                          attrs: { to: _vm.organizationPath },
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(_vm.organizationLabel)),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item",
                        {
                          staticClass:
                            "appbar__menu-list__item appbar__menu-list__logout",
                          on: {
                            click: function ($event) {
                              return _vm.signOut()
                            },
                          },
                        },
                        [
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(_vm.logoutLabel)),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("navigation", {
        staticClass: "hidden-md-and-up",
        attrs: { admin: _vm.admin },
        model: {
          value: _vm.navigationVisible,
          callback: function ($$v) {
            _vm.navigationVisible = $$v
          },
          expression: "navigationVisible",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }