var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-radio-group",
    {
      staticClass: "rat-view-mode-selector",
      attrs: { "hide-details": "" },
      model: {
        value: _vm.mode,
        callback: function ($$v) {
          _vm.mode = $$v
        },
        expression: "mode",
      },
    },
    [
      _c("v-radio", {
        attrs: {
          label: _vm.$t("field.mode.original"),
          value: "original",
          color: "primary",
          disabled: !_vm.allowedViewModes.original,
        },
      }),
      _vm._v(" "),
      _c("v-radio", {
        attrs: {
          label: _vm.$t("field.mode.detection"),
          value: "detection",
          color: "primary",
          disabled: !_vm.allowedViewModes.detection,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }