var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    {
      class: { "green--text": !_vm.disabled, "darken-1": !_vm.disabled },
      attrs: { text: "", disabled: _vm.disabled },
      on: {
        click: function (ev) {
          return _vm.$emit("click", ev)
        },
      },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }