var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-select",
    _vm._b(
      {
        ref: "select",
        staticClass: "select-box",
        attrs: {
          value: _vm.value,
          rules: _vm.rules,
          "data-testid": _vm.dataTestid,
        },
        on: {
          input: function (v) {
            return _vm.$emit("input", v)
          },
        },
      },
      "v-select",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }