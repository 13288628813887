import { render, staticRenderFns } from "./SensorList.vue?vue&type=template&id=1cc87330&scoped=true&"
import script from "./SensorList.vue?vue&type=script&lang=js&"
export * from "./SensorList.vue?vue&type=script&lang=js&"
import style0 from "./SensorList.vue?vue&type=style&index=0&id=1cc87330&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1cc87330",
  null
  
)

/* custom blocks */
import block0 from "./SensorList.vue?vue&type=custom&index=0&blockType=i18n&lang=yaml"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/project/client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1cc87330')) {
      api.createRecord('1cc87330', component.options)
    } else {
      api.reload('1cc87330', component.options)
    }
    module.hot.accept("./SensorList.vue?vue&type=template&id=1cc87330&scoped=true&", function () {
      api.rerender('1cc87330', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/admin/SensorList.vue"
export default component.exports