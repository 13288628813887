var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.event.type === "station_v2"
    ? _c("station-v2-event", {
        ref: "stationEvent",
        attrs: { event: _vm.event },
        on: {
          pollEvent: function ($event) {
            return _vm.$emit("pollEvent")
          },
        },
      })
    : _c("station-v1-event", {
        ref: "stationEvent",
        attrs: { event: _vm.event },
        on: {
          pollEvent: function ($event) {
            return _vm.$emit("pollEvent")
          },
        },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }