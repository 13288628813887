var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("data-table", {
    attrs: {
      headers: _vm.headers,
      items: _vm.rows,
      options: _vm.options,
      "no-data-text": _vm.$t("no_data"),
      loading: _vm.loading,
    },
    on: {
      "update:options": function ($event) {
        _vm.options = $event
      },
    },
    scopedSlots: _vm._u([
      {
        key: "item.hidden",
        fn: function (ref) {
          var item = ref.item
          return [
            _c("v-simple-checkbox", {
              attrs: { value: item.hidden, "data-testid": "hidden_checkbox" },
              on: {
                input: function ($event) {
                  return _vm.$emit("toggleHidden", item)
                },
              },
            }),
          ]
        },
      },
      {
        key: "item.last_upload_time",
        fn: function (ref) {
          var item = ref.item
          return [
            _vm._v(
              "\n    " +
                _vm._s(_vm.formatTimestamp(item.last_upload_time)) +
                "\n  "
            ),
          ]
        },
      },
      {
        key: "item.recent_data",
        fn: function (ref) {
          var item = ref.item
          return [
            _c(
              "router-link",
              {
                staticClass: "text-body-1",
                attrs: { to: _vm.recentEventsUrl(item) },
              },
              [_vm._v("\n      " + _vm._s(_vm.$t("button.show")) + "\n    ")]
            ),
          ]
        },
      },
      {
        key: "item.all_data",
        fn: function (ref) {
          var item = ref.item
          return [
            _c(
              "router-link",
              {
                staticClass: "text-body-1",
                attrs: { to: _vm.eventListUrl(item) },
              },
              [_vm._v("\n      " + _vm._s(_vm.$t("button.show")) + "\n    ")]
            ),
          ]
        },
      },
      {
        key: "item.actions",
        fn: function (ref) {
          var item = ref.item
          return [
            _c(
              "v-btn",
              {
                attrs: { icon: "", "data-testid": "edit_button" },
                on: {
                  click: function ($event) {
                    return _vm.$emit("edit", item)
                  },
                },
              },
              [
                _c(
                  "v-icon",
                  {
                    staticClass: "ma-2 material-icons",
                    attrs: { primary: "" },
                  },
                  [_vm._v("edit")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "v-tooltip",
              {
                attrs: { left: "", disabled: item.deletable },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "div",
                            _vm._g(
                              _vm._b(
                                { staticClass: "d-inline-block" },
                                "div",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    icon: "",
                                    disabled: !item.deletable,
                                    "data-testid": "delete_button",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit("delete", item)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "ma-2 material-icons",
                                      attrs: { primary: "" },
                                    },
                                    [_vm._v("delete")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("cannot_delete_explanation")) +
                    "\n    "
                ),
              ]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }