<template>
  <div class="forgot-password">
    <img src="../assets/logo.png" class="mb-4" width="140">
    <template v-if="success">
      <p v-html="$t('label.reset_mail_sent')"></p>
      <v-btn color="primary" large block to="/sign_in">
        {{ $t('button.close') }}
        <v-icon dark right>fa-times-circle</v-icon>
      </v-btn>
    </template>
    <template v-else>
      <p v-if="error === ''">{{ $t('label.enter_email') }}</p>
      <v-alert type="error" v-else>{{error}}</v-alert>
      <v-form>
        <v-text-field
          class="mb-3"
          :placeholder="$t('field.email')"
          v-model="model.email"
          hide-details
          data-testid="forgot-password__email"
        />
        <v-btn
          class="forgot-password__submit"
          color="primary"
          large
          block
          @click="submit"
          data-testid="forgot-password__submit"
        >
          {{ $t('button.send') }}
          <v-icon dark right>fa-arrow-circle-right</v-icon>
        </v-btn>
      </v-form>
      <v-divider class="my-4"/>
      <p>{{ $t('label.already_registered') }}</p>
      <v-btn color="primary" rounded outlined to="/sign_in">{{ $t('button.login') }}</v-btn>
    </template>
  </div>
</template>

<i18n lang="yaml">
ja:
  label:
    enter_email: '登録時のメールアドレスを入力してください。'
    already_registered: 'すでにアカウントをお持ちの方'
    reset_mail_sent: 'ご入力されたアドレスへメールを送信しました。<br>メールの内容に沿って再設定手続きを行ってください。'

  error:
    email_missing: 'メールアドレスを入力してください。'
    nonexisting_user: 'そのメールアドレスのユーザーは存在しません。'

  field:
    email: 'E-mail'

  button:
    send: '送信'
    login: 'ログイン'
    close: '閉じる'

en:
  label:
    enter_email: 'Please enter the email you used to register.'
    already_registered: 'Already have an account?'
    reset_mail_sent: 'A mail was sent to the entered email address.<br>Please follow the instructions to reset your password.'

  error:
    email_missing: 'Please enter your email address.'
    nonexisting_user: 'A user with that address does not exist.'

  field:
    email: 'E-mail'

  button:
    send: 'Send'
    login: 'Log in'
    close: 'Close'
</i18n>

<script>
export default {
  name: 'forgot-password',
  data: function () {
    return {
      model: {
        email: '',
      },
      success: false,
      error: '',
    };
  },
  methods: {
    submit: async function () {
      if (!this.model.email) {
        this.error = this.$t('error.email_missing');
        return;
      }
      try {
        await this.$store.dispatch('requestPasswordReset', this.model.email);
        this.success = true;
      } catch (error) {
        if (error.response.status === 404) {
          this.error = this.$t('error.nonexisting_user');
        } else {
          this.error = error.response.data.message;
        }
      }
    },
  },
};
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'
$signin-text-color: black

.forgot-password
  hr
    background-color: map-get($material-theme, 'dividers')

  ::v-deep .v-text-field__slot input
    padding: 1.5rem

  ::v-deep .input-group__input
    padding: 8px 16px

  ::v-deep .input-group--text-field
    color: map-get($material-theme, 'text', 'primary')

  ::v-deep .v-btn--outline, .forgot .v-icon
    color: map-get($blue, 'darken-2')

  ::v-deep .alert
    border: 0
</style>
