var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    {
      staticClass: "content-header align-md-center flex-column flex-md-row",
      attrs: { "no-gutters": "" },
    },
    [
      _c(
        _vm.headerWrapper,
        {
          tag: "component",
          staticClass: "content-header__title",
          attrs: { to: _vm.to },
        },
        [
          _c(
            "v-row",
            { staticClass: "align-center", attrs: { "no-gutters": "" } },
            [
              this.$slots.icon
                ? _c("div", { staticClass: "mr-2" }, [_vm._t("icon")], 2)
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "overflow-hidden" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex align-md-baseline flex-column flex-md-row",
                  },
                  [
                    _c(
                      "div",
                      {
                        class: [
                          "primary--text",
                          "overflow-ellipsis",
                          _vm.titleClass,
                        ],
                      },
                      [
                        !!_vm.title
                          ? [_vm._v(_vm._s(_vm.title))]
                          : _vm.$slots.title
                          ? [_vm._t("title")]
                          : _vm._e(),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    !!_vm.subtitle && typeof _vm.subtitle === "string"
                      ? [
                          _c(
                            "div",
                            { class: ["overflow-ellipsis", _vm.subtitleClass] },
                            [_vm._v(_vm._s(_vm.subtitle))]
                          ),
                        ]
                      : [_vm._t("subtitle")],
                  ],
                  2
                ),
                _vm._v(" "),
                _c("div", { staticClass: "caption my-1 hidden-sm-and-down" }, [
                  _vm._v(_vm._s(_vm.description)),
                ]),
              ]),
              _vm._v(" "),
              _vm.hasCount
                ? _c("counter", {
                    staticClass: "hidden-md-and-up ml-auto",
                    attrs: { count: _vm.count },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "caption my-1 hidden-md-and-up" }, [
            _vm._v(_vm._s(_vm.description)),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _vm.hasCount
        ? _c("counter", {
            staticClass: "hidden-sm-and-down ml-auto",
            attrs: { count: _vm.count },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }