<template>
  <div class="pv-checkbox">
    <v-checkbox :label="label" :inputValue="inputValue" :value="value" @change="v => $emit('input', v)" hide-details :data-testid="dataTestid" v-if="edit" />
    <span v-else>
      <v-icon class="pv-checkbox__icon" left v-if="checked">check_box</v-icon>
      <v-icon class="pv-checkbox__icon" left v-else>check_box_outline_blank</v-icon>
      {{ label }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'pv-checkbox',
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    inputValue: {
      default: null,
    },
    value: {
      type: [String, Boolean],
    },
    'data-testid': String,
  },
  computed: {
    checked() {
      if (!this.value) {
        return this.inputValue;
      }
      return this.inputValue.includes(this.value);
    },
  },
  model: {
    prop: 'inputValue',
    event: 'input',
  },
};
</script>

<style scoped lang="sass">
.pv-checkbox
  ::v-deep
    .v-icon
      color: inherit !important
      font-size: 20px

    .v-input
      margin: 0
      padding: 0

    .v-label
      font-size: 14px
</style>
