var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex flex-column" },
    [
      _c(
        "round-button",
        {
          attrs: {
            icon: "fa-upload",
            loading: _vm.uploading,
            disabled: _vm.uploading,
          },
          on: {
            click: function ($event) {
              return _vm.$refs.fileInput.click()
            },
          },
        },
        [
          _vm._v("\n    " + _vm._s(_vm.$t("button_label")) + "\n    "),
          _c("input", {
            ref: "fileInput",
            staticStyle: { display: "none" },
            attrs: {
              type: "file",
              accept: "text/csv",
              "data-testid": "csv_file",
            },
            on: { change: _vm.onUpload },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "text-center",
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.downloadSample.apply(null, arguments)
            },
          },
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("download_csv_sample")) + "\n  ")]
      ),
      _vm._v(" "),
      _c(
        "card-dialog",
        {
          attrs: {
            persistent: "",
            title: _vm.$t("result_dialog.title"),
            width: "400px",
          },
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function () {
                return [
                  _c("dialog-action", { on: { click: _vm.closeResults } }, [
                    _vm._v("OK"),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.resultDialogVisible,
            callback: function ($$v) {
              _vm.resultDialogVisible = $$v
            },
            expression: "resultDialogVisible",
          },
        },
        [
          _vm.importResult
            ? _c(
                "div",
                {
                  staticStyle: {
                    "max-height": "500px",
                    "overflow-y": "scroll",
                  },
                },
                [
                  _vm.importResult.created.length > 0
                    ? _c("section", [
                        _c("h3", [
                          _vm._v(
                            _vm._s(_vm.$t("result_dialog.added_places")) + ":"
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "ul",
                          _vm._l(_vm.importResult.created, function (place) {
                            return _c("li", [_vm._v(_vm._s(place))])
                          }),
                          0
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.importResult.already_existing.length > 0
                    ? _c("section", [
                        _c("h3", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("result_dialog.already_existing_places")
                            ) + ":"
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "ul",
                          _vm._l(
                            _vm.importResult.already_existing,
                            function (place) {
                              return _c("li", [_vm._v(_vm._s(place))])
                            }
                          ),
                          0
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.importResult.errors.length > 0
                    ? _c("section", [
                        _c("h3", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("result_dialog.unprocessable_lines")
                            ) + ":"
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "ul",
                          _vm._l(_vm.importResult.errors, function (error) {
                            return _c("li", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(error.row) +
                                  ": " +
                                  _vm._s(_vm.$t("import_error." + error.type)) +
                                  "\n          "
                              ),
                            ])
                          }),
                          0
                        ),
                      ])
                    : _vm._e(),
                ]
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }