<template>
  <date-picker-dropdown :value="value" @input="value => $emit('input', value)">
    <template #activator="{ on, attrs }">
      <text-field
        :value="value"
        readonly
        hide-details="auto"
        v-on="on"
        v-bind="{ ...$attrs, ...attrs }"
      />
    </template>
  </date-picker-dropdown>
</template>

<script>
import DatePickerDropdown from '@/components/atoms/DatePickerDropdown';
import TextField from '@/components/atoms/TextField';

export default {
  name: 'month-field',
  components: {
    DatePickerDropdown,
    TextField,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
};
</script>
