import Vue from 'vue';
import VueI18n from 'vue-i18n';
import * as _ from 'lodash';
import mixinLocale from '@/mixins/common/locale';

const EMAIL_PATTERN = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: 'en',
  messages: {
    en: {
      format: '{name} must be valid',
      required: '{name} is required',
      passwords_do_not_match: 'Passwords do not match',
      password_length: 'Password must be between 8 and 30 characters',
      password_characters: 'Your password must contain lower case letters, upper case letters and numbers',
      positive: '{name} must be greater than equal to 0',
      within: '{name} must be between {min} and {max}',
    },
    ja: {
      format: '{name} は不正なフォーマットです',
      required: '{name} を入力してください',
      passwords_do_not_match: 'パスワードと致しません',
      password_length: 'パスワードは8文字以上30文字以内で入力してください',
      password_characters: 'パスワードには大文字と小文字、数字を1文字以上含めてください',
      positive: '{name} は0以上の値を入力してください',
      within: '{name} は{min}以上{max}以下の値を入力してください',
    },
  },
});

export default {
  methods: {
    required(name = '') {
      return value => (!!value || _.isNumber(value)) || i18n.t('required', this.i18n_locale, { name });
    },
    format(name = '', regex = /.*/) {
      return value => value === '' || regex.test(value) || i18n.t('format', this.i18n_locale, { name });
    },
    email(name = '') {
      return this.format(name, EMAIL_PATTERN);
    },
    match_password() {
      return value => (
        value === this.model.password ||
          i18n.t('passwords_do_not_match', this.i18n_locale)
      );
    },
    password_length() {
      return value => (
        value.length === 0 || (value.length >= 8 && value.length <= 30) ||
        i18n.t('password_length', this.i18n_locale)
      );
    },
    password_characters() {
      return value => (
        value.length === 0 || (/\d/.test(value) && /[a-z]/.test(value) && /[A-Z]/.test(value)) ||
        i18n.t('password_characters', this.i18n_locale)
      );
    },
    positive(name = '') {
      return value => value >= 0 || i18n.t('positive', this.i18n_locale, { name });
    },
    within_range(name, min, max) {
      return (value) => {
        if ((value >= min) && (value <= max)) {
          return true;
        }
        return i18n.t('within', this.i18n_locale, { name, min, max });
      };
    },
  },
  ...mixinLocale,
};
