var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "event-video" },
    [
      _c("video-player", {
        ref: "videoPlayer",
        attrs: { options: _vm.playerOptions },
      }),
      _vm._v(" "),
      _vm.fullscreen
        ? _c("fullscreen-exit", {
            ref: "exitFullscreenButton",
            on: {
              click: function ($event) {
                return _vm.$emit("exitFullscreen")
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }