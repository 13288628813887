var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "signin" },
    [
      _c("img", {
        staticClass: "mb-4",
        attrs: { src: require("../assets/logo.png"), width: "140" },
      }),
      _vm._v(" "),
      _vm.error
        ? _c(
            "v-alert",
            {
              staticClass: "signin__error text-left",
              attrs: { color: "red", type: "error" },
            },
            [_vm._v("\n    " + _vm._s(_vm.error) + "\n  ")]
          )
        : _c("p", [_vm._v(_vm._s(_vm.$t("label.enter_login")))]),
      _vm._v(" "),
      _c(
        "v-form",
        {
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c("v-text-field", {
            staticClass: "signin__email",
            attrs: { placeholder: _vm.$t("field.email"), "hide-details": "" },
            on: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.signIn.apply(null, arguments)
              },
            },
            model: {
              value: _vm.model.email,
              callback: function ($$v) {
                _vm.$set(_vm.model, "email", $$v)
              },
              expression: "model.email",
            },
          }),
          _vm._v(" "),
          _c("v-text-field", {
            staticClass: "signin__password",
            attrs: {
              placeholder: _vm.$t("field.password"),
              "hide-details": "",
              type: "password",
            },
            on: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.signIn.apply(null, arguments)
              },
            },
            model: {
              value: _vm.model.password,
              callback: function ($$v) {
                _vm.$set(_vm.model, "password", $$v)
              },
              expression: "model.password",
            },
          }),
          _vm._v(" "),
          _c("v-checkbox", {
            staticClass: "mt-8 mb-3",
            attrs: {
              label: _vm.$t("field.keep"),
              "hide-details": "",
              color: "primary",
            },
            model: {
              value: _vm.model.keep,
              callback: function ($$v) {
                _vm.$set(_vm.model, "keep", $$v)
              },
              expression: "model.keep",
            },
          }),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              staticClass: "signin__submit mb-5",
              attrs: {
                color: "primary",
                large: "",
                block: "",
                disabled: _vm.authenticating,
                loading: _vm.authenticating,
              },
              on: { click: _vm.signIn },
            },
            [
              _vm._v("\n      " + _vm._s(_vm.$t("button.login")) + "\n      "),
              _c("v-icon", { attrs: { dark: "", right: "" } }, [
                _vm._v("fa-arrow-circle-right"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          staticClass: "forgot mb-5",
          attrs: { text: "", to: "/forgot_password" },
        },
        [
          _c("v-icon", { attrs: { left: "" } }, [
            _vm._v("sentiment_very_dissatisfied"),
          ]),
          _vm._v("\n    " + _vm._s(_vm.$t("label.forgot_password")) + "\n  "),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }