<template>
  <v-app id="app-signed-out">
    <v-container fluid class="signin text-center">
      <div class="box">
        <router-view/>
      </div>
    </v-container>
    <copyright-footer class="text-center"/>
  </v-app>
</template>

<script>
import CopyrightFooter from './CopyrightFooter';

export default {
  name: 'app-signed-out',
  components: {
    CopyrightFooter,
  },
};
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'

#app-signed-out
  background-size: cover
  background-position: center
  background-image: url(../assets/background.jpg)
  color: black

  .container
    box-sizing: border-box
    padding-bottom: 108px
    min-height: 100vh

  .box
    max-width: 360px
    margin: 0 auto

    @media (min-width: 600px)
      margin-top: 7%

  .copyright-footer
    background-color: white
    box-sizing: border-box
    color: map-get($material-theme, 'text', 'primary')
    height: 84px
    margin-top: -84px
    padding: 1.5rem
</style>
