<template>
  <div class="pv-text-field">
    <v-text-field
      hide-details="auto"
      :value="value"
      @input="onInput"
      :type="type"
      :rules="rules"
      :hint="hint"
      :error-messages="errorMessages"
      :data-testid="dataTestid"
      v-if="edit"
      ref="field"
    />
    <span v-else>{{ value | format(type) }}</span>
  </div>
</template>

<script>

export default {
  name: 'pv-text-field',
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
    },
    rules: {
      type: Array,
      default() {
        return [];
      },
    },
    type: {
      type: String,
      default: 'text',
    },
    value: {
      default: '',
    },
    errorMessages: {
      type: Array,
      default() {
        return [];
      },
    },
    'data-testid': String,
  },
  filters: {
    format(value, type) {
      switch (type) {
        case 'password':
          return value.replace(/./g, '•');
        default:
          return value;
      }
    },
  },
  methods: {
    onInput(value) {
      const emitted = this.type === 'number' ? Number(value) : value;
      this.$emit('input', emitted);
    },
    validate() {
      return this.$refs.field.validate();
    },
  },
};
</script>

<style scoped lang="sass">
.pv-text-field
  ::v-deep
    .v-input
      margin: -6px 0 -5px
      padding: 0

    .v-input__slot
      border: 1px solid
      margin-bottom: 0

    input
      padding-left: 4px
      padding-right: 4px
      color: #666!important
</style>
