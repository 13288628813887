var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-footer", { attrs: { id: "bottom-navigation" } }, [
    _c("div", { staticClass: "logo hidden-sm-and-down" }, [
      _c("img", {
        attrs: { src: require("../assets/logo.png"), width: "134" },
      }),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "footer-info mr-0" },
      [
        _c(
          "ul",
          { staticClass: "hidden-sm-and-down navigation text-right mb-4" },
          [
            _vm._l(_vm.items, function (item) {
              return _c(
                "li",
                { key: item.title },
                [
                  _c("v-btn", { attrs: { text: "", to: item.path } }, [
                    _vm._v(_vm._s(item.title)),
                  ]),
                ],
                1
              )
            }),
            _vm._v(" "),
            _c(
              "li",
              [
                _c("v-btn", { attrs: { text: "", to: _vm.accountPath } }, [
                  _vm._v(_vm._s(_vm.accountLabel)),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              [
                _c("v-btn", { attrs: { text: "", to: "/contact" } }, [
                  _vm._v(_vm._s(_vm.$t("menu.contact"))),
                ]),
              ],
              1
            ),
          ],
          2
        ),
        _vm._v(" "),
        _c("copyright-footer", { staticClass: "text-center text-md-right" }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }