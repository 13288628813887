import moment from 'moment';
import { pickBy } from 'lodash';

export const datePeriodToTimestamps = ({ from, to }) => pickBy({
  from: from ? moment(from).unix() : null,
  to: to ? moment(to).add(1, 'days').unix() : null,
});

export const MONTH_FORMAT = 'YYYY-MM';
export const DATE_FORMAT = 'YYYY-MM-DD';

export const addToMonthString = (monthString, months) =>
  moment(monthString, MONTH_FORMAT).add(months, 'months').format(MONTH_FORMAT);

export const datesOfMonth = (monthString) => {
  const date = moment(monthString, MONTH_FORMAT);
  const days = date.daysInMonth();
  const result = [];
  for (let i = 1; i <= days; i += 1) {
    result.push(date.date(i).format(DATE_FORMAT));
  }
  return result;
};
