var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pv-date-field" },
    [
      _vm.edit
        ? _c(
            "v-menu",
            {
              staticClass: "pv-date-field__menu",
              attrs: {
                "close-on-content-click": false,
                "max-width": "290px",
                "offset-y": "",
                transition: "scale-transition",
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function (ref) {
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "v-text-field",
                          _vm._g(
                            _vm._b(
                              {
                                attrs: {
                                  value: _vm.formattedValue,
                                  rules: _vm.rules,
                                  clearable: _vm.clearable,
                                  "hide-details": "auto",
                                  readonly: "",
                                },
                                on: {
                                  "click:clear": function ($event) {
                                    return _vm.$emit("input", null)
                                  },
                                },
                              },
                              "v-text-field",
                              attrs,
                              false
                            ),
                            on
                          )
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                4227874363
              ),
              model: {
                value: _vm.open,
                callback: function ($$v) {
                  _vm.open = $$v
                },
                expression: "open",
              },
            },
            [
              _vm._v(" "),
              _c("v-date-picker", {
                attrs: {
                  value: _vm.pickerValue,
                  locale: "en",
                  "no-title": "",
                  "header-date-format": _vm.headerFormat,
                },
                on: { input: _vm.onPick },
              }),
            ],
            1
          )
        : _c("span", [_vm._v(_vm._s(_vm.formattedValue))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }