<template>
  <v-btn
    v-bind="$attrs"
    v-on="$listeners"
    class="bar-button"
    :class="{ 'v-btn--active': highlight }"
    color="primary"
    text
  >
    <v-icon :class="{ valueFalse: !value }">{{icon}}</v-icon>
    <small><slot/></small>
  </v-btn>
</template>

<script>
export default {
  name: 'toolbar-button',
  props: {
    highlight: Boolean,
    icon: String,
    value: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'
$bar-button-width: 64px

button.v-btn.bar-button
  height: 100%
  min-width: $bar-button-width
  margin: 0

  ::v-deep .v-btn__content
    flex-direction: column

  small
    margin-top: calc(#{$spacer} * 2)

  .v-icon
    color: map-get($blue, 'darken-2')

  &[disabled],
  &[disabled] .v-icon
    color: rgba(0, 0, 0, 0.26)!important

.valueFalse
  color: map-get($material-theme, 'text', 'disabled') !important
</style>
