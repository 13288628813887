var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "container", staticClass: "zoom" },
    [
      _c(
        "div",
        {
          ref: "body",
          staticClass: "zoom-body",
          on: { panzoomchange: _vm.handleZoomChange },
        },
        [
          _vm._t("default"),
          _vm._v(" "),
          _c("resize-observer", { on: { notify: _vm.notify } }),
        ],
        2
      ),
      _vm._v(" "),
      !_vm.disabled
        ? _c("div", { staticClass: "zoom-control-group" }, [
            _c(
              "div",
              { staticClass: "zoom-control" },
              [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "grey darken-1",
                      "x-small": "",
                      depressed: "",
                      fab: "",
                    },
                    on: {
                      click: function ($event) {
                        _vm.zoom(_vm.getMinScale())
                      },
                    },
                  },
                  [_c("v-icon", [_vm._v("fullscreen_exit")])],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "zoom-control" },
              [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "grey darken-1",
                      "x-small": "",
                      depressed: "",
                      fab: "",
                    },
                    on: { click: _vm.zoomIn },
                  },
                  [_c("v-icon", [_vm._v("add")])],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "zoom-control" },
              [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "grey darken-1",
                      "x-small": "",
                      depressed: "",
                      fab: "",
                    },
                    on: { click: _vm.zoomOut },
                  },
                  [_c("v-icon", [_vm._v("remove")])],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("resize-observer", { on: { notify: _vm.notify } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }