<template>
  <v-btn text :to="to">
    <slot/>
  </v-btn>
</template>

<script>
export default {
  name: 'button-wrapper',
  props: {
    to: String,
  },
};
</script>
