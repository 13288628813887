<template>
  <v-slide-group
    show-arrows="always"
    center-active
    :value="value"
    ref="slideGroup"
  >
    <v-slide-item
      v-for="(item, index) in items"
      :key="item[keyName]"
    >
      <slot
        name="item"
        :item="item"
        :index="index"
      />
    </v-slide-item>
  </v-slide-group>
</template>

<script>
export default {
  name: 'thumbnail-slider',
  props: {
    value: Number,
    keyName: String,
    items: {
      type: Array, // any
      default() {
        return [];
      },
    },
  },
  computed: {
    hasNext() {
      const { slideGroup } = this.$refs;
      if (slideGroup) {
        const { vSlideGroup } = slideGroup.$refs;
        if (vSlideGroup) {
          return vSlideGroup.hasNext;
        }
      }
      return false;
    },
  },
  mounted() {
    this.$watch(
      () => this.hasNext,
      (value) => {
        if (!value) {
          this.$emit('scrolledToEnd');
        }
      },
    );
    if (!this.hasNext) {
      this.$emit('scrolledToEnd');
    }
  },
};
</script>

<style scoped lang="sass">
</style>
