import FileSaver from 'file-saver';

export const exportCsv = (csvString, fileName) => {
  const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
  const blob = new Blob([bom, csvString], { type: 'text/csv' });
  FileSaver.saveAs(blob, fileName);
};

export const arrayToCsvRow = array => array.map(el => `"${el}"`).join(',');

export const rowArrayToCSVString = array => array.map(row => `${arrayToCsvRow(row)}\n`).join('');
