<template>
  <div class="information-tab">
    <page-title-area>
      <h1>INFORMATION</h1>
    </page-title-area>
    <template v-for="sensorType in sensorTypesInOrder">
      <document-list
        :sensorType="sensorType"
        :documents="documents[sensorType]"
        v-if="documents[sensorType]"
      />
    </template>
  </div>
</template>

<script>
import PageTitleArea from '@/components/atoms/PageTitleArea';
import SensorTypes from '@/mixins/sensorTypes';

import DocumentList from './DocumentList';

export default {
  name: 'information-tab',
  mixins: [SensorTypes],
  components: {
    DocumentList,
    PageTitleArea,
  },
  data() {
    return {
      documents: {},
    };
  },
  computed: {
    sensorTypesInOrder() {
      return [
        this.SENSOR_TYPES.FLY,
        this.SENSOR_TYPES.FLY_COUNT,
        this.SENSOR_TYPES.RAT,
        this.SENSOR_TYPES.STATION,
      ];
    },
  },
  methods: {
    getDocuments: async function () {
      return this.$http.get('/api/documents').then(response => response.data);
    },
  },
  mounted: async function () {
    this.documents = await this.getDocuments();
  },
};
</script>

<style lang="sass" scoped>
</style>
