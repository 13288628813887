<template>
  <insect-positions-canvas
    ref="canvas"
    :src="imageSrc"
    :detection="detection"
    @contextmenu:save="$emit('save', $event)"
  />
</template>

<i18n lang="yaml">
ja:
  msg:
    focus:
      not_found: 対象の虫はこの画像内で検出されませんでした。

en:
  msg:
    focus:
      not_found: Focused insect not found in this image.
</i18n>

<script>
import InsectTypes from '@/mixins/insectTypes';
import Notifications from '@/mixins/notifications';
import InsectPositionsCanvas from '@/components/InsectPositionsCanvas';

export default {
  name: 'picked-insects-draw',
  mixins: [InsectTypes, Notifications],
  components: {
    InsectPositionsCanvas,
  },
  props: {
    imageSrc: String,
    pickedDetectionType: {
      type: String,
      default: 'all',
    },
    detectionsUrl: String,
    hideCanvas: Boolean,
    originalImageWidth: Number,
    originalImageHeight: Number,
  },
  computed: {
    detection() {
      if (this.hideCanvas) {
        return { rects: [] };
      }

      if (this.pickedDetectionType === 'all') {
        return {
          width: this.originalImageWidth,
          height: this.originalImageHeight,
          rects: this.convert(this.positions),
        };
      }

      const unpicked = this._.reject(this.positions, { type: this.pickedDetectionType });
      return {
        width: this.originalImageWidth,
        height: this.originalImageHeight,
        rects: [
          ...this.convert(unpicked, 'rgba(255, 255, 255, 0.4)'),
          ...this.convert(this.pickedDetections),
        ],
      };
    },
    pickedDetections() {
      if (this.pickedDetectionType === 'all') {
        return this.positions;
      }

      const sorted = this._.sortBy(this.positions, ({ position }) => position[0]);
      return this._.filter(sorted, { type: this.pickedDetectionType });
    },
  },
  data() {
    return {
      positions: [],
      activeFetchCancelToken: this.$http.CancelToken.source(),
      focusIndex: 0,
    };
  },
  methods: {
    convert(positions, color = '#f00') {
      return positions.map((detection) => {
        const {
          position,
          type,
        } = detection;

        const insectType = this.INSECT_TYPES[type] || { symbol: '' };
        return {
          position,
          symbol: insectType.symbol,
          color,
        };
      });
    },
    focusDetection(detection) {
      const { position } = detection;
      const detectionCenterX = ((position[0] + position[2]) / 2) / this.originalImageWidth;
      const detectionCenterY = ((position[1] + position[3]) / 2) / this.originalImageHeight;

      const canvas = this.$refs.canvas;
      const zoom = canvas.$refs.zoom;
      zoom.centerOn(detectionCenterX, detectionCenterY);
    },
    // public
    focus(index = 0) {
      this.focusIndex = index;
      const detections = this.pickedDetections;
      if (detections.length > 0) {
        const detection = detections[index % detections.length];
        this.focusDetection(detection);
      } else {
        this.notifyWarning('msg.focus.not_found');
      }
    },
    focusNext() {
      this.focus(this.focusIndex + 1);
    },
    redraw() {
      this.$refs.canvas.draw();
    },
    refetch: async function () {
      await this.activeFetchCancelToken.cancel();
      if (this.detectionsUrl) {
        this.activeFetchCancelToken = this.$http.CancelToken.source();
        const response = await this.$http.get(this.detectionsUrl, {
          cancelToken: this.activeFetchCancelToken.token,
        });
        this.positions = response.data.positions;
      } else {
        this.positions = [];
      }
    },
    export: async function (name) {
      await this.$refs.canvas.export(name);
    },
  },
  inject: ['getZoom', 'setZoom'],
  watch: {
    detectionsUrl() {
      this.refetch();
    },
  },
  mounted() {
    this.refetch();
  },
};
</script>
