var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("icon-button", {
    class: { "value-false": !_vm.value },
    attrs: { icon: _vm.icon },
    on: {
      click: function ($event) {
        return _vm.$emit("input", !_vm.value)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }