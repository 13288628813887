<template>
  <div>
    <div v-if="place">
      <page-title-area>
        <h1>{{ place.name }}</h1>
      </page-title-area>
      <event-period-selector
        :loading="loading"
        :initial-value="initialEventPeriod"
        @submit="loadEvents"
        @clear="clearEvents"
      />
      <div class="d-flex align-baseline justify-end mt-4">
        <picked-event-chart-button :events="sortedSelectedEvents" />
        <span class="ml-1" />
        <export-excel-button :events="sortedSelectedEvents"/>
        <span class="ml-1" />
        <export-csv-button :events="sortedSelectedEvents" :place="place.name" />
      </div>
      <station-place-event-table
        :events="events"
        :loading="loading"
        @sortedSelectedEventsUpdated="updateSortedSelectedEvents"
        @editEvent="editEvent"
        @viewEvents="viewEvents"
        @eventUpdated="onEventUpdate"
      />
      <station-event-editor
        v-model="editDialogVisible"
        :places="places"
        :event="currentEditingEvent"
        @update="onEventUpdate"
      />
      <station-event-detail-view
        v-model="detailViewVisible"
        :event="currentDisplayedEvent"
        :hasPrevEvent="previousEvent && !previousEvent.processing"
        :hasNextEvent="nextEvent && !nextEvent.processing"
        @previous="changeDisplayedIndexBy(-1)"
        @next="changeDisplayedIndexBy(1)"
        @pollEvent="() => setEventUpdating(currentDisplayedEvent)"
        v-if="currentDisplayedEvent"
      />
    </div>
    <page-load-spinner v-else />
  </div>
</template>

<i18n lang="yaml">
ja:
  msg.download.failed: |
   データのダウンロードに失敗しました。
   期間を変更して再度お試しください。

en:
  msg.download.failed: |
    Failed to download data.
    Please try again after changing the period.
</i18n>

<script>
import moment from 'moment';

import Notifications from '@/mixins/notifications';
import PageLoadSpinner from '@/components/atoms/PageLoadSpinner';
import PageTitleArea from '@/components/atoms/PageTitleArea';
import StationEventEditor from '@/components/organisms/StationEventEditor';
import EventPeriodSelector from './EventPeriodSelector';
import ExportCsvButton from './ExportCsvButton';
import ExportExcelButton from './ExportExcelButton';
import PickedEventChartButton from './PickedEventChartButton';
import StationEventDetailView from './StationEventDetailView';
import StationPlaceEventTable from './StationPlaceEventTable';

export default {
  name: 'station-place-events',
  components: {
    EventPeriodSelector,
    ExportCsvButton,
    ExportExcelButton,
    PageLoadSpinner,
    PageTitleArea,
    PickedEventChartButton,
    StationEventDetailView,
    StationEventEditor,
    StationPlaceEventTable,
  },
  mixins: [
    Notifications,
  ],
  props: {
    placeId: {
      type: String,
    },
  },
  data() {
    return {
      place: null,
      loading: true,
      events: [],
      sortedSelectedEvents: [],
      // Edit data
      editDialogVisible: false,
      currentEditingEvent: null,
      places: null,
      // View data
      detailViewVisible: false,
      displayedEvents: [],
      displayedIndex: null,
    };
  },
  computed: {
    currentDisplayedEvent() {
      return this.displayedEvents[this.displayedIndex];
    },
    initialEventPeriod() {
      const halfYearsAgo = moment().subtract(6, 'months').format('YYYY-MM');
      const thisMonth = moment().format('YYYY-MM');
      return { from: halfYearsAgo, to: thisMonth };
    },
    nextEvent() {
      return this.currentDisplayedEvent && this.displayedEvents[this.displayedIndex + 1];
    },
    previousEvent() {
      return this.currentDisplayedEvent && this.displayedEvents[this.displayedIndex - 1];
    },
  },
  inject: ['fetchStationPlace', 'fetchStationPlaceEvents', 'fetchStationPlaces'],
  mounted: async function () {
    this.place = await this.fetchStationPlace(this.placeId);
    this.loadStationPlaces(); // Use Store if too slow
    await this.loadEvents(this.initialEventPeriod);
  },
  methods: {
    changeDisplayedIndexBy(value) {
      this.displayedIndex = this._.clamp(this.displayedIndex + value, 0, this.displayedEvents.length - 1);
    },
    clearEvents() {
      this.events = [];
    },
    editEvent(event) {
      this.currentEditingEvent = event;
      this.editDialogVisible = true;
    },
    loadEvents: async function (period) {
      this.loading = true;
      try {
        this.events = await this.fetchStationPlaceEvents(this.placeId, period);
      } catch (error) {
        this.notifyError('msg.download.failed');
      } finally {
        this.loading = false;
      }
    },
    loadStationPlaces: async function () {
      this.places = await this.fetchStationPlaces();
    },
    onEventUpdate(event) {
      this.events = this.updateEventList(this.events, event);
      if (this.displayedEvents) {
        this.displayedEvents = this.updateEventList(this.displayedEvents, event);
      }
      this.loadStationPlaces(); // Use Store if too slow
    },
    setEventUpdating(event) {
      this.onEventUpdate({ ...event, updating: true });
    },
    updateEventList(events, updatedEvent) {
      const result = [];
      events.forEach((ev) => {
        if (ev.id === updatedEvent.id) {
          if (updatedEvent.place_id === this.placeId) {
            result.push(updatedEvent);
          }
        } else {
          result.push(ev);
        }
      });
      return result;
    },
    updateSortedSelectedEvents(value) {
      this.sortedSelectedEvents = value;
    },
    viewEvents({ events, index }) {
      this.displayedEvents = events;
      this.displayedIndex = index;
      this.detailViewVisible = true;
    },
  },
};
</script>

<style lang="sass" scoped>
</style>
