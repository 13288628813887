var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex align-center" },
    [
      _c("icon-button", {
        attrs: { icon: "fa-angle-left", disabled: _vm.disabled },
        on: { click: _vm.goToPreviousMonth },
      }),
      _vm._v(" "),
      _c("div", [_vm._v(_vm._s(_vm.formattedValue))]),
      _vm._v(" "),
      _c("date-picker-dropdown", {
        attrs: { value: _vm.value, type: "month" },
        on: {
          input: function (value) {
            return _vm.$emit("value", value)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "activator",
            fn: function (ref) {
              var on = ref.on
              return [
                _c(
                  "v-btn",
                  _vm._g(
                    {
                      attrs: {
                        icon: "",
                        color: "primary",
                        disabled: _vm.disabled,
                      },
                    },
                    on
                  ),
                  [_c("v-icon", [_vm._v("fa-calendar")])],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("icon-button", {
        attrs: { icon: "fa-angle-right", disabled: _vm.disabled },
        on: { click: _vm.goToNextMonth },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }