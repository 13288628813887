<template>
  <line-chart :data="chartData" :options="chartOptions" />
</template>

<i18n lang="yaml">
ja:
  day_format: 'M月D日'

en:
  day_format: 'MMM D'
</i18n>

<script>
import { merge } from 'lodash';

import { datesOfMonth } from '@/libs/time';

import LineChart from '@/components/atoms/LineChart';
import { dailyInsectCounts, dailyAlerts } from '../chartCalculations';

export const ALERT_STYLES = {
  totalCountAlert: {
    backgroundColor: '#edc09d',
    borderColor: '#edc09d',
  },
  increaseCountAlert: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    borderColor: 'crimson',
    borderWidth: 3,
  },
};

// TODO: Add alert points as proper legend
//   - How? Fake empty data set? Put alert points in data set?

export default {
  name: 'daily-insect-count-chart',
  components: {
    LineChart,
  },
  props: {
    // { chartInfo: { alert: boolean, count: int, increaseAlert: boolean }, timestamp: int }
    events: {
      type: Array,
      required: true,
    },
    // { month: 'YYYY-MM', color: HTMLColor, label: str }
    graphs: {
      type: Array,
      required: true,
    },
    yMax: {
      type: Number,
      required: true,
    },
    showAlertPeriods: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    chartData() {
      return {
        labels: this.chartXAxisLabels,
        datasets: this.chartInsectCountDatasets,
      };
    },
    chartInsectCountDatasets() {
      return this.graphs.map(graph => {
        const dates = datesOfMonth(graph.month);

        const style = {
          pointBackgroundColor: graph.color,
          pointBorderColor: graph.color,
          pointBorderWidth: 2,
        };
        if (this.showAlertPeriods) {
          const alerts = dailyAlerts(dates, this.events);
          style.pointBackgroundColor = alerts.map(alert =>
            (alert.total ? ALERT_STYLES.totalCountAlert.backgroundColor : graph.color),
          );
          style.pointBorderColor = alerts.map(alert =>
            (alert.increase ? ALERT_STYLES.increaseCountAlert.borderColor : graph.color),
          );
          style.pointRadius = alerts.map(alert =>
            (alert.total || alert.increase ? 5 : 3),
          );
        }

        return {
          label: graph.label,
          data: dailyInsectCounts(dates, this.events),
          borderColor: graph.color,
          backgroundColor: graph.color,
          tension: 0, // straight lines
          ...style,
        };
      });
    },
    chartOptions() {
      return {
        plugins: {
          legend: {
            labels: {
              boxWidth: 12,
              boxHeight: 4,
            },
          },
          tooltip: {
            callbacks: {
              title: (context) => context.map(this.tooltipTitle),
            },
          },
        },
        scales: {
          xAxis: this.chartXAxis,
          yAxis: this.chartYAxis,
        },
        spanGaps: true,
      };
    },
    chartXAxisLabels() {
      return this.monthDates.map(date => this.$moment(date).format(this.$t('day_format')));
    },
    chartXAxis() {
      return this.buildAxis();
    },
    chartYAxis() {
      let stepSize = 5;
      if (this.yMax > 100) {
        stepSize = 20;
      }

      return this.buildAxis(
        {
          beginAtZero: true,
          suggestedMax: this.yMax,
          ticks: {
            stepSize,
          },
        },
      );
    },
    labelForDate() {
      return (date) => {
        const dateIndex = this.monthDates.indexOf(date);
        return this.chartXAxisLabels[dateIndex];
      };
    },
    month() {
      return this.graphs[0].month;
    },
    monthDates() {
      return datesOfMonth(this.month);
    },
  },
  methods: {
    annotationStyle(type) {
      switch (type) {
        case 'cumulative':
          return ALERT_STYLES.totalCountAlert;
        case 'differential':
          return ALERT_STYLES.increaseCountAlert;
        default:
          return {};
      }
    },
    buildAxis(options) {
      return merge(
        {
          grid: {
            borderColor: 'rgb(102, 102, 102)',
            display: false,
            drawTicks: false,
          },
          ticks: {
            padding: 10,
          },
        },
        options,
      );
    },
    emitMaxCount() {
      const datasetMaxes = this.chartInsectCountDatasets.map(({ data }) => Math.max(...data));
      this.$emit('max-count-updated', Math.max(...datasetMaxes));
    },
    tooltipTitle(context) {
      const graph = this.graphs[context.datasetIndex];
      const date = this.$moment(graph.month).date(context.dataIndex + 1);
      return date.format(this.$t('day_format'));
    },
  },
  mounted() {
    this.emitMaxCount();
  },
  watch: {
    chartInsectCountDatasets() {
      this.emitMaxCount();
    },
  },
};
</script>
