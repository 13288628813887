<template>
  <select-field
    :items="items"
    :value="value"
    :edit="edit"
    @input="v => $emit('input', v)"
    data-testid="photo_interval"
  />
</template>

<i18n lang="yaml">
ja:
  one_hour: '1時間'
  hours: '{value}時間'

en:
  one_hour: '1 hour'
  hours: '{value} hours'
</i18n>

<script>
import SelectField from '@/components/Form/SelectField';

const SECONDS_PER_HOUR = 60 * 60;

export default {
  name: 'photo-interval-select-field',
  components: {
    SelectField,
  },
  computed: {
    items() {
      const result = [{ text: this.$t('one_hour'), value: 1 * SECONDS_PER_HOUR }];
      [3, 6, 12, 24].forEach(hours => {
        result.push({ text: this.$t('hours', { value: hours }), value: hours * SECONDS_PER_HOUR });
      });
      return result;
    },
  },
  props: {
    value: Number,
    edit: Boolean,
  },
};
</script>
