<template>
  <div class='processing-image'>
    <v-progress-circular
      class="centered green--text darken-1"
      indeterminate
      :size="50"
      :width="3"
    />
    <div class="bottom-centered">{{ $t('processing') }}</div>
  </div>
</template>

<script>
export default {
  name: 'processing-image',
};
</script>

<i18n lang="yaml">
ja:
  processing: '解析中'

en:
  processing: 'Processing...'
</i18n>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'

.processing-image
  position: relative

  .centered
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)

  .bottom-centered
    position: absolute
    top: 100%
    left: 50%
    transform: translate(-50%, -100%)
</style>
