<template>
  <v-snackbar
    bottom
    v-model="pending"
    :timeout=0
  >
    {{ $t('label') }}
    <v-progress-circular
      :rotate="-90"
      :value="progress"
      class="red--text"
    >
      {{ progress }}
    </v-progress-circular>
    <v-btn text @click.native="cancel">{{ $t('cancel') }}</v-btn>
  </v-snackbar>
</template>

<i18n lang="yaml">
  ja:
    label: '報告書を出力中です...'
    cancel: 'キャンセル'

  en:
    label: 'Generating a report now...'
    cancel: 'Cancel'
</i18n>

<script>
export default {
  name: 'PendingDownload',
  computed: {
    pending() {
      return !!this.$store.getters.getJobId;
    },
    progress() {
      return this.$store.getters.getProgress;
    },
  },
  methods: {
    cancel() {
      this.$store.dispatch('cancelGenerateReport');
    },
  },
};
</script>
