import '@/chartjsSetup';

import { Chart } from 'chart.js';

export default (chartType) => ({
  props: {
    data: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    width: {
      type: Number,
      required: false,
      default: 400,
    },
    height: {
      type: Number,
      required: false,
      default: 400,
    },
    cssClasses: {
      type: String,
      default: '',
    },
    styles: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      chartWrapper: {},
    };
  },
  render(createElement) {
    return createElement(
      'div',
      {
        style: this.styles,
        class: this.cssClasses,
      },
      [
        createElement(
          'canvas',
          {
            attrs: {
              width: this.width,
              height: this.height,
            },
            ref: 'canvas',
          },
        ),
      ],
    );
  },
  methods: {
    refreshChart() {
      if (this.chartWrapper.chart) {
        this.chartWrapper.chart.destroy();
      }

      this.chartWrapper.chart = new Chart(
        this.$refs.canvas.getContext('2d'),
        {
          type: chartType,
          // Assign deep copies of data and options to prevent reactivity because that will not
          // work well together with Chart.js
          data: this._.cloneDeep(this.data),
          options: this._.cloneDeep(this.options),
        },
      );
    },
  },
  mounted() {
    this.refreshChart();
  },
  watch: {
    data: {
      handler() {
        this.refreshChart();
      },
      deep: true,
    },
    options: {
      handler() {
        this.refreshChart();
      },
      deep: true,
    },
  },
});
