<template>
  <div>
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'default-wrapper',
  props: {
    to: String,
  },
};
</script>
