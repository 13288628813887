<template>
  <entity-list-layout
    class="admin-account-list"
    title="ACCOUNT"
    :count="accounts.length"
    :query.sync="search"
    :search-hint="$t('label.search')"
  >
    <template #title-area-content>
      <round-button icon="add_circle" to="/admin/accounts/new">
        {{ $t('label.add_account') }}
      </round-button>
      <v-checkbox
        v-model="deactivated"
        :disabled="blocked"
        hide-details
        :label="$t('label.deactivated_users_only')"
      />
      <v-checkbox
        v-model="blocked"
        :disabled="deactivated"
        hide-details
        :label="$t('label.blocked_users_only')"
      />
    </template>
    <p-data-list :data="accounts" :pagination.sync="pagination">
      <data-field name="No" headerClass="column--no">
        <template v-slot="props">{{ props.index + 1 }}</template>
      </data-field>
      <data-field :name="$t('field.organization')" value="organization.name" sortable>
        <template v-slot="props">
          <router-link :to="{ name: 'admin-organization', params: { id: props.item.organization.id }}">
            {{ props.item.organization.name }}
          </router-link>
        </template>
      </data-field>
      <data-field :name="$t('field.name')" value="name" headerClass="column--name" sortable>
        <template v-slot="props">
          <router-link :to="{ name: 'admin-account', params: { email: props.item.email }}">{{ props.item.name }}</router-link>
          <i class="admin-account-list__deactived-label" v-if="!props.item.activated">({{$t( 'label.deactivated') }})</i>
          <i class="admin-account-list__blocked-label" v-if="props.item.blocked">({{$t( 'label.blocked') }})</i>
        </template>
      </data-field>
      <data-field :name="$t('field.email')" value="email" headerClass="column--email" sortable>
        <template v-slot="props">{{ props.item.email }}</template>
      </data-field>
      <data-field :name="$t('field.invited_by')" value="invitedBy" headerClass="column--invited_by" sortable>
        <template v-slot="props">
          {{ props.item.invitedBy }}
        </template>
      </data-field>
    </p-data-list>
  </entity-list-layout>
</template>

<i18n lang="yaml">
ja:
  label:
    search: '文字列で検索'
    add_account: 'アカウントを追加'
    deactivated: '無効'
    blocked: 'ブロック'
    deactivated_users_only: '無効なユーザーのみ'
    blocked_users_only: 'ブロックされたユーザーのみ'

  field:
    name: 'ユーザー名'
    email: 'E-mail'
    organization: '組織名'
    invited_by: '共有元'

en:
  label:
    search: 'Search by text'
    add_account: 'Add account'
    deactivated: 'deactivated'
    blocked: 'blocked'
    deactivated_users_only: 'Deactivated Users only'
    blocked_users_only: 'Blocked users only'

  field:
    name: 'Name'
    email: 'E-mail'
    organization: 'Organization'
    invited_by: 'Invited by'
</i18n>

<script>
import EntityListLayout from '@/components/atoms/EntityListLayout';
import RoundButton from '@/components/atoms/RoundButton';
import DataList, { Field } from '../DataList';

export default {
  name: 'admin-account-list',
  beforeCreate() {
    this.$store.dispatch('requestUsers');
  },
  components: {
    DataField: Field,
    EntityListLayout,
    PDataList: DataList,
    RoundButton,
  },
  computed: {
    accounts() {
      const accounts = this.$store.getters.getUsers;
      return accounts.map((account) => {
        const organization = account.organization || {};
        return {
          ...account,
          organization,
          invitedBy: this.invitedByToString(account.invited_by),
        };
      }).filter((account) => {
        if ((this.deactivated && account.activated) ||
          (this.blocked && !account.blocked)) {
          return false;
        }
        return this.userContains(this.search.toLowerCase(), account);
      });
    },
  },
  data() {
    return {
      pagination: {
        itemsPerPage: 20,
      },
      search: '',
      blocked: false,
      deactivated: false,
    };
  },
  methods: {
    userContains(keywords, user) {
      const organization = user.organization || {};
      const organizationName = (organization.name || '');

      // eslint-disable-next-line arrow-body-style
      return this._.every(keywords.split(' '), (keyword) => {
        return this._.some([
          user.name,
          user.email,
          organizationName,
          user.invitedBy,
        ], (value) => {
          const lowerValue = value.toLowerCase();
          return this._.includes(lowerValue, keyword.toLowerCase());
        });
      });
    },
    invitedByToString(invitedBy) {
      if (invitedBy) {
        const { name, organization_name: organizationName } = invitedBy;
        return `${organizationName || ''} (${name})`;
      }
      return '';
    },
  },
};
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'

.admin-account-list
  &__deactivated-label
    color: map-get($material-theme, 'text' ,'disabled')

  &__blocked-label
    color: map-get($colors, 'red', 'base')

  ::v-deep .v-input--checkbox
    padding-top: 0
    color: map-get($material-theme, 'text', 'primary')

  ::v-deep .column
    &--no
      width: 48px

    &--name,
    &--email
      width: 25%

    &--invited_by
      width: 120px
</style>
