var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("page-title-area", [_c("h1", [_vm._v(_vm._s(_vm.$t("title")))])]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "filter-area d-flex align-center mb-4 py-1" },
        [
          _c("month-picker", {
            attrs: { value: _vm.currentMonth, disabled: "" },
          }),
          _vm._v(" "),
          _c("select-box", {
            staticClass: "flex-grow-0",
            staticStyle: { width: "300px" },
            attrs: {
              items: _vm.sensorSelectItems,
              label: _vm.$t("field.sensor"),
              "data-testid": "sensor-select",
            },
            model: {
              value: _vm.selectedSensorId,
              callback: function ($$v) {
                _vm.selectedSensorId = $$v
              },
              expression: "selectedSensorId",
            },
          }),
          _vm._v(" "),
          _c("insect-type-select-box", {
            attrs: {
              "null-option": _vm.$t("all_insects_option"),
              label: _vm.$t("field.insect_type"),
              "data-testid": "insect-type-select",
            },
            model: {
              value: _vm.insectTypeFilter,
              callback: function ($$v) {
                _vm.insectTypeFilter = $$v
              },
              expression: "insectTypeFilter",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { sm: "6", md: "4", lg: "3", xl: "2" } },
            [
              _c(
                "v-card",
                { staticClass: "graph-area", staticStyle: { height: "200px" } },
                [
                  _c("div", [
                    _c("h2", [_vm._v(_vm._s(_vm.$t("total_count")))]),
                  ]),
                  _vm._v(" "),
                  _c("total-count-panel", {
                    attrs: {
                      events: _vm.panelData.totalCountPanel,
                      month: _vm.currentMonth,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "12", md: "8", lg: "8", xl: "7" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "graph-area d-flex flex-column",
                  staticStyle: { height: "200px" },
                },
                [
                  _c("div", [_c("h2", [_vm._v(_vm._s(_vm.$t("alert")))])]),
                  _vm._v(" "),
                  _c("hr", { staticClass: "mx-n4" }),
                  _vm._v(" "),
                  _c("notice-history", {
                    staticClass: "mx-n4 mb-n4",
                    attrs: {
                      events: _vm.panelData.noticeHistory,
                      sensors: _vm.sensors,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { sm: "12", lg: "12", xl: "6" } },
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "graph-area",
                          staticStyle: { height: "360px" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex" },
                            [
                              _c("h2", [
                                _vm._v(_vm._s(_vm.$t("insect_count"))),
                              ]),
                              _vm._v(" "),
                              _c("v-spacer"),
                              _vm._v(" "),
                              _vm.showAlertPeriods
                                ? _c("alert-legend")
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("daily-insect-count-chart", {
                            staticStyle: { height: "290px" },
                            attrs: {
                              events: _vm.panelData.dailyInsectCountChart,
                              graphs: _vm.comparedWithPreviousMonthGraphs,
                              "y-max": _vm.dailyInsectCountYMax,
                              "show-alert-periods": _vm.showAlertPeriods,
                            },
                            on: {
                              "max-count-updated": function (value) {
                                return _vm.updateMaxDailyInsectCount(0, value)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { sm: "6", md: "12", lg: "10", xl: "6" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "graph-area",
                  staticStyle: {
                    height: "360px",
                    "min-width": "440px",
                    "max-width": "920px",
                  },
                },
                [
                  _c("div", [_c("h2", [_vm._v(_vm._s(_vm.$t("top5")))])]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-space-between" },
                    [
                      _c("top5-insects-panel", {
                        attrs: { events: _vm.panelData.top5InsectsPanel },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-col", {
            staticClass: "d-xl-none",
            attrs: { sm: "12", lg: "2" },
          }),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "4" } },
            [
              _c(
                "v-card",
                { staticClass: "graph-area", staticStyle: { height: "320px" } },
                [
                  _c("div", [
                    _c("h2", [_vm._v(_vm._s(_vm.$t("hourly_insect_count")))]),
                  ]),
                  _vm._v(" "),
                  _c("hourly-bar-chart", {
                    staticClass: "count-graph",
                    staticStyle: { height: "250px" },
                    attrs: { events: _vm.panelData.hourlyBarChart },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "4" } },
            [
              _c(
                "v-card",
                { staticClass: "graph-area", staticStyle: { height: "320px" } },
                [
                  _c("div", [
                    _c("h2", [_vm._v(_vm._s(_vm.$t("weekly_insect_count")))]),
                  ]),
                  _vm._v(" "),
                  _c("weekday-bar-chart", {
                    staticClass: "count-graph",
                    staticStyle: { height: "250px" },
                    attrs: { events: _vm.panelData.weekdayBarChart },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "4" } },
            [
              _c(
                "v-card",
                { staticClass: "graph-area", staticStyle: { height: "320px" } },
                [
                  _c("div", [
                    _c("h2", [
                      _vm._v(_vm._s(_vm.$t("weekly_and_hourly_insect_count"))),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("weekday-hour-matrix-chart", {
                    staticStyle: { height: "250px" },
                    attrs: { events: _vm.panelData.weekdayHourMatrixChart },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }