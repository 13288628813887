<template>
  <div class="copyright-footer">
    <v-select
      class='language-select mr-2'
      hide-details
      :items="locale_options"
      :value="$i18n.locale"
      @input="updateLocale"
    />
    <address>
      <span>Copyright &copy; Pest-Vision | </span>
      <a href="javascript:void(0)" @click="modal.terms = true">
        {{ $t('menu.terms') }}
      </a>
    </address>
    <terms-modal v-model="modal.terms" />
  </div>
</template>

<i18n lang="yaml">
ja:
  menu:
    terms: '利用規約'
    reset_demo_events: 'デモ用イベントを未読にする'

en:
  menu:
    terms: 'Terms of service'
    reset_demo_events: 'Mark demo events unviewed'
</i18n>

<script>
import Locales from '@/mixins/locales';
import TermsModal from './TermsModal';

export default {
  name: 'copyright-footer',
  mixins: [Locales],
  components: {
    TermsModal,
  },
  computed: {
    demoMode() {
      return this.$store.getters.hasPermission('demo');
    },
  },
  data() {
    return {
      modal: {
        terms: false,
      },
    };
  },
  methods: {
    updateLocale(locale) {
      this.$root.$i18n.locale = locale;
      if (this.$store.getters.getCurrentUser.email) {
        this.$store.dispatch('updateUserInfo', { locale });
      }
    },
  },
};
</script>

<style scoped lang="sass">
.copyright-footer
  address,
  .language-select
    display: inline-block
    vertical-align: middle

  address
    font-style: normal

  .language-select
    padding: 0
    width: initial

    ::v-deep .v-select__selections input
      width: 4px
</style>
