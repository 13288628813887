<template>
  <div class="signin">
    <img src="../assets/logo.png" class="mb-4" width="140">
    <v-alert
      class="signin__error text-left"
      color="red"
      type="error"
      v-if="error"
    >
      {{error}}
    </v-alert>
    <p v-else>{{ $t('label.enter_login') }}</p>
    <v-form v-model="valid">
      <v-text-field
        class="signin__email"
        :placeholder="$t('field.email')"
        v-model="model.email"
        hide-details
        @keyup.enter="signIn"
      />
      <v-text-field
        class="signin__password"
        :placeholder="$t('field.password')"
        v-model="model.password"
        hide-details
        type="password"
        @keyup.enter="signIn"
      />
      <v-checkbox
        class="mt-8 mb-3"
        :label="$t('field.keep')"
        v-model="model.keep"
        hide-details
        color="primary"
      />
      <v-btn
        class="signin__submit mb-5"
        color="primary"
        large
        block
        :disabled="authenticating"
        :loading="authenticating"
        @click="signIn"
      >
        {{ $t('button.login') }}
        <v-icon dark right>fa-arrow-circle-right</v-icon>
      </v-btn>
    </v-form>
    <v-btn text to="/forgot_password" class="forgot mb-5">
      <v-icon left>sentiment_very_dissatisfied</v-icon>
      {{ $t('label.forgot_password') }}
    </v-btn>
  </div>
</template>

<i18n lang="yaml">
ja:
  label:
    enter_login: 'ログイン情報を入力してください'
    forgot_password: 'パスワードを忘れた方はこちら'

  error:
    invalid_email_password: 'メールアドレスもしくはパスワードが間違っています'
    deactivated_user: 'このアカウントは無効になっています。管理者にお問い合せください。'
    blocked: 'ログインの失敗回数が制限を超えたため、アカウントはブロックされました。管理者にお問い合せください。'
    email_missing: 'メールアドレスを入力してください。'
    password_missing: 'パスワードを入力してください。'
    email_unverified: 'メールアドレスの確認が完了していません。確認用メールの指示に従ってください。'

  field:
    email: 'E-mail'
    password: 'パスワード'
    keep: 'ログイン状態を保存する'

  button:
    login: 'ログイン'

en:
  label:
    enter_login: 'Enter your login information'
    forgot_password: 'Forgot your password?'

  error:
    invalid_email_password: 'Wrong email or password.'
    deactivated_user: 'This user is deactivated. Please contact an administrator.'
    blocked: 'Your account has been blocked after too many failed login attempts. Please contact an administrator.'
    email_missing: 'Please enter your email address.'
    password_missing: 'Please enter your password.'
    email_unverified: 'Your mail address must be verified. Please follow the instructions in the verification email.'

  field:
    email: 'E-mail'
    password: 'Password'
    keep: 'Keep me logged in'

  button:
    login: 'Log in'
</i18n>

<script>
export default {
  name: 'signin',
  computed: {
    userInfoRetrieved() {
      return this.$store.getters.getCurrentUser;
    },
  },
  data() {
    return {
      authenticating: false,
      error: '',
      model: {
        email: '',
        password: '',
        keep: false,
      },
      valid: false,
    };
  },
  methods: {
    signIn: async function () {
      if (!this.model.email) {
        this.error = this.$t('error.email_missing');
        return;
      }
      if (!this.model.password) {
        this.error = this.$t('error.password_missing');
        return;
      }
      this.authenticating = true;
      try {
        await this.$store.dispatch('signIn', this.model);
      } catch (error) {
        this.onLoginError(error);
      }
      this.authenticating = false;
    },
    onLoginError(error) {
      const errorCode = this._.get(error, 'response.data.error');

      if (['invalid_email_password', 'blocked', 'email_unverified', 'deactivated_user'].includes(errorCode)) {
        this.error = this.$t(`error.${errorCode}`);
      } else {
        this.error = error.message;
      }
    },
  },
  watch: {
    userInfoRetrieved: function () {
      if (this.$store.getters.isSignedIn) {
        this.$router.push(this.$route.query.redirectTo || '/');
      }
    },
  },
};
</script>

<style scoped lang="sass">
.signin
  ::v-deep .v-text-field__slot input
    padding: 1.5rem
</style>
