var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.event
    ? _c("div", { staticClass: "station-event" }, [
        _c("div", { staticClass: "d-flex align-baseline" }, [
          _c("div", { staticClass: "headline font-weight-bold mr-4" }, [
            _vm.event.installed_at && _vm.event.collected_at
              ? _c("div", { staticClass: "d-flex align-center" }, [
                  _c("div", { staticClass: "mr-8" }, [
                    _vm._v(_vm._s(_vm.event.trap_no)),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      _vm._s(
                        _vm._f("moment")(
                          _vm.event.installed_at,
                          _vm.$t("format.date")
                        )
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mx-2" }, [_vm._v("~")]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      _vm._s(
                        _vm._f("moment")(
                          _vm.event.collected_at,
                          _vm.$t("format.date")
                        )
                      )
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "headline font-weight-bold flex" }, [
            _vm.event.month
              ? _c("div", { staticClass: "d-flex align-center" }, [
                  _c(
                    "div",
                    { staticClass: "station-event__content align-center px-2" },
                    [
                      _vm._v(
                        _vm._s(_vm._f("moment")(_vm.event.month, "YYYY-MM"))
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "ml-2" }, [
                    _vm._v(_vm._s(_vm.$t("month"))),
                  ]),
                  _vm._v(" "),
                  _vm.isNotRecentEvent
                    ? _c(
                        "div",
                        { staticClass: "station-event__edit-button" },
                        [
                          _vm.downloadOptions.length > 0
                            ? _c(
                                "v-menu",
                                {
                                  attrs: { "offset-y": "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function (ref) {
                                          var on = ref.on
                                          var attrs = ref.attrs
                                          return [
                                            _c(
                                              "v-btn",
                                              _vm._g(
                                                _vm._b(
                                                  { attrs: { text: "" } },
                                                  "v-btn",
                                                  attrs,
                                                  false
                                                ),
                                                on
                                              ),
                                              [
                                                _c("v-icon", [
                                                  _vm._v("fa-download"),
                                                ]),
                                                _vm._v(" "),
                                                _c("small", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("menu.download")
                                                    )
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    712515933
                                  ),
                                },
                                [
                                  _vm._v(" "),
                                  _c(
                                    "v-list",
                                    _vm._l(
                                      _vm.downloadOptions,
                                      function (downloadOption) {
                                        return _c(
                                          "v-list-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.download(
                                                  downloadOption.type
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(downloadOption.label) +
                                                "\n              "
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "v-btn",
                                {
                                  attrs: { text: "" },
                                  on: { click: _vm.download },
                                },
                                [
                                  _c("v-icon", [_vm._v("fa-download")]),
                                  _vm._v(" "),
                                  _c("small", [
                                    _vm._v(_vm._s(_vm.$t("menu.download"))),
                                  ]),
                                ],
                                1
                              ),
                          _vm._v(" "),
                          !_vm.event.updating
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    text: "",
                                    disabled: !_vm.hasDetections,
                                  },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      _vm.editing = true
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", [_vm._v("fa-edit")]),
                                  _vm._v(" "),
                                  _c("small", [
                                    _vm._v(_vm._s(_vm.$t("menu.edit"))),
                                  ]),
                                ],
                                1
                              )
                            : _c(
                                "v-btn",
                                { attrs: { text: "", disabled: "" } },
                                [
                                  _c("v-icon", [_vm._v("fa-refresh")]),
                                  _vm._v(" "),
                                  _c("small", [
                                    _vm._v(_vm._s(_vm.$t("menu.updating"))),
                                  ]),
                                ],
                                1
                              ),
                        ],
                        1
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _c("div", [
          _c("div", { staticClass: "pb-3" }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm._f("dateTime")(
                    _vm.event.upload_time,
                    _vm.$t("format.time")
                  )
                ) +
                "\n    "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "div",
              [
                _vm.event.processing
                  ? _c("station-event-processing")
                  : _c("div", { staticClass: "d-flex flex-nowrap" }, [
                      _c(
                        "div",
                        { staticClass: "station-event__main" },
                        [
                          _c(
                            "v-carousel",
                            {
                              key: _vm.event.id,
                              attrs: {
                                "hide-delimiters": "",
                                touchless: "",
                                height: "auto",
                              },
                              model: {
                                value: _vm.selectedIndex,
                                callback: function ($$v) {
                                  _vm.selectedIndex = $$v
                                },
                                expression: "selectedIndex",
                              },
                            },
                            _vm._l(_vm.photos, function (photo) {
                              return _c(
                                "v-carousel-item",
                                { key: photo.id, attrs: { eager: "" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-center w-100",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "station-event__drawer",
                                        },
                                        [
                                          _c("picked-insects-draw", {
                                            ref: "drawers",
                                            refInFor: true,
                                            attrs: {
                                              imageSrc:
                                                _vm._f("imageSrc")(photo),
                                              detectionsUrl:
                                                _vm.makeDetectionsUrl(photo),
                                              pickedDetectionType:
                                                _vm.pickedDetectionType,
                                              originalImageWidth:
                                                _vm.ORIGINAL_IMAGE_WIDTH,
                                              originalImageHeight:
                                                _vm.ORIGINAL_IMAGE_HEIGHT,
                                              hideCanvas: _vm.viewOriginal,
                                            },
                                            on: { save: _vm.save },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "station-event__selector" },
                            [
                              _c("dot-selector", {
                                attrs: {
                                  value: _vm.selectedIndex,
                                  size: _vm.photos.length,
                                },
                                on: { change: _vm.selectPhoto },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "station-event__side" }, [
                        _c(
                          "div",
                          { staticClass: "station-event__table-area" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-center justify-end px-1",
                              },
                              [
                                _c("v-checkbox", {
                                  staticClass: "ma-0 pa-0 flex-grow-1",
                                  staticStyle: { "flex-grow": "1" },
                                  attrs: {
                                    label: _vm.$t("original"),
                                    color: "primary",
                                    "hide-details": "",
                                  },
                                  model: {
                                    value: _vm.viewOriginal,
                                    callback: function ($$v) {
                                      _vm.viewOriginal = $$v
                                    },
                                    expression: "viewOriginal",
                                  },
                                }),
                                _vm._v(" "),
                                _c("chart-type-select", {
                                  model: {
                                    value: _vm.chartType,
                                    callback: function ($$v) {
                                      _vm.chartType = $$v
                                    },
                                    expression: "chartType",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.chartTypeIs("table")
                              ? _c("insect-count-table", {
                                  staticClass: "station-event__result-table",
                                  attrs: {
                                    insectCounts: _vm.event.insect_counts,
                                    showRadiobutton: !_vm.event.updating,
                                    pickedDetectionType:
                                      _vm.pickedDetectionType,
                                    showFocusButton: "",
                                  },
                                  on: {
                                    "update:pickedDetectionType": function (
                                      $event
                                    ) {
                                      _vm.pickedDetectionType = $event
                                    },
                                    "update:picked-detection-type": function (
                                      $event
                                    ) {
                                      _vm.pickedDetectionType = $event
                                    },
                                    "click:focus": _vm.handleFocus,
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.chartTypeIs("bar")
                              ? _c("insect-count-by-type-bar-chart", {
                                  attrs: {
                                    insectCounts: _vm.event.insect_counts,
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.chartTypeIs("pie")
                              ? _c("top-5-insect-types-pie-chart", {
                                  attrs: {
                                    insectCounts: _vm.event.insect_counts,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "section",
                          {
                            staticClass: "pb-3 station-event__metadata",
                            attrs: { "data-testid": "Metadata" },
                          },
                          [
                            _c("ul", [
                              _vm.event.ip_address
                                ? _c("li", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("ip_address")) +
                                        ": " +
                                        _vm._s(_vm.event.ip_address) +
                                        "\n              "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.event.firmware_version
                                ? _c("li", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("firmware_version")) +
                                        ": " +
                                        _vm._s(_vm.event.firmware_version) +
                                        "\n              "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.event.serial_no
                                ? _c("li", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("serial_no")) +
                                        ": " +
                                        _vm._s(_vm.event.serial_no) +
                                        "\n              "
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
              ],
              1
            ),
            _vm._v(" "),
            _vm.isNotRecentEvent
              ? _c("insect-detections-editor", {
                  attrs: {
                    event: _vm.event,
                    imageSrc: _vm.current.display,
                    detectionsUrl: _vm.makeDetectionsUrl(_vm.current),
                    originalImageWidth: _vm.ORIGINAL_IMAGE_WIDTH,
                    originalImageHeight: _vm.ORIGINAL_IMAGE_HEIGHT,
                    canViewDetectionDetail: "",
                  },
                  on: {
                    updated: function ($event) {
                      return _vm.$emit("pollEvent")
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header",
                        fn: function () {
                          return [
                            _c("content-header", {
                              staticClass: "w-100",
                              attrs: {
                                title: _vm.event.stationName,
                                titleClass: "title my-1",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "icon",
                                    fn: function () {
                                      return [
                                        _c("icon", {
                                          attrs: { type: "fly_count" },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                  {
                                    key: "subtitle",
                                    fn: function () {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "overflow-ellipsis title details",
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.timestamp.date)
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "ml-3" },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(_vm.timestamp.time) +
                                                    "\n              "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                1628154775
                              ),
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    3436748950
                  ),
                  model: {
                    value: _vm.editing,
                    callback: function ($$v) {
                      _vm.editing = $$v
                    },
                    expression: "editing",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }