import { groupBy } from 'lodash';
import moment from 'moment';

export const dailyInsectCounts = (dates, events) => dates.map(date => {
  const dateEvents = eventsOnDate(date, events);

  if (dateEvents.length === 0) {
    return null;
  }

  const eventsBySensorId = groupBy(dateEvents, 'sensor_id');

  let result = 0;

  Object.values(eventsBySensorId).forEach(sensorEvents => {
    const counts = sensorEvents.map(({ chartInfo: { count } }) => count);
    result += Math.max(...counts);
  });

  return result;
});

export const dailyAlerts = (dates, events) => dates.map(date => {
  const dateEvents = eventsOnDate(date, events);

  return {
    total: dateEvents.some(({ chartInfo: { alert } }) => alert),
    increase: dateEvents.some(({ chartInfo: { increaseAlert } }) => increaseAlert),
  };
});

const eventsOnDate = (date, events) => {
  const minTimestamp = moment(date).unix();
  const maxTimestamp = moment(date).endOf('day').unix();

  return events.filter(({ timestamp }) =>
    timestamp >= minTimestamp && timestamp <= maxTimestamp,
  );
};

export const eventsInMonth = (month, events) => {
  const minTimestamp = moment(month).unix();
  const maxTimestamp = moment(month).endOf('month').unix();

  return events.filter(({ timestamp }) =>
    timestamp >= minTimestamp && timestamp <= maxTimestamp,
  );
};

export const eventsUntilDay = (day, events) =>
  events.filter(({ timestamp }) => moment.unix(timestamp).date() <= day);

export const calcInsectCountIncreases = (previousInsectCounts, insectCounts) => {
  const allInsects = new Set([
    ...Object.keys(insectCounts),
    ...Object.keys(previousInsectCounts),
  ]);

  const result = {};
  allInsects.forEach(insectType => {
    const count = insectCounts[insectType] || 0;
    const previousCount = previousInsectCounts[insectType] || 0;
    const increase = Math.max(count - previousCount, 0);
    if (increase > 0) {
      result[insectType] = increase;
    }
  });
  return result;
};
