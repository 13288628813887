<template>
  <transition name='terms-modal'>
    <div v-if='visible' class='terms-modal__wrapper' @click="cancel">
      <div class='terms-modal' @click.stop>
        <div class="terms-modal__header">
          <div class='terms-modal__title'>{{ terms_title }}</div>
          <v-btn text icon @click="cancel" class='terms-modal__close'><v-icon large>close</v-icon></v-btn>
        </div>
        <div class='terms-modal__body'>{{ terms_text }}</div>
        <div class="terms-modal__footer" v-if="onAccept">
          <v-checkbox
            class="terms-modal__accept"
            v-model="model.accept"
            :label="$t('label.accept')"
            color="primary"
          />
          <v-flex>
            <v-btn class="terms-modal__cancel" color="primary" large @click="cancel">
              {{ $t('button.cancel') }}<v-icon dark right>fa-arrow-circle-left</v-icon>
            </v-btn>
            <v-btn class="terms-modal__next" color="primary" large :disabled="!model.accept" @click="accept">
              {{ $t('button.next') }}<v-icon dark right>fa-arrow-circle-right</v-icon>
            </v-btn>
          </v-flex>
        </div>
      </div>
    </div>
  </transition>
</template>

<i18n lang="yaml">
ja:
  label:
    accept: '利用規約に同意する'

  button:
    cancel: 'キャンセル'
    next: '次へ'

en:
  label:
    accept: 'I accept the agreement'

  button:
    cancel: 'Cancel'
    next: 'Next'
</i18n>

<script>
import { TERMS_TITLE, TERMS_TEXT } from '@/constants/term_text';

export default {
  name: 'terms-modal',
  props: {
    visible: Boolean,
    onAccept: Function,
    onCancel: {
      type: Function,
      default() {},
    },
  },
  data() {
    return {
      model: {
        accept: false,
      },
      terms_text: TERMS_TEXT,
      terms_title: TERMS_TITLE,
    };
  },
  methods: {
    accept() {
      this.close();
      this.onAccept();
    },
    allowDocumentScroll(value) {
      const _ = this._;
      const html = document.documentElement;
      const classNames = _.split(html.className, ' ');
      if (!value && !_.includes(classNames, 'no-document-scroll')) {
        html.className = _.join(_.concat(classNames, 'no-document-scroll'), ' ');
      } else if (value) {
        html.className = _.join(_.pull(classNames, 'no-document-scroll'), ' ');
      }
    },
    cancel() {
      this.close();
      this.onCancel();
    },
    close() {
      this.$emit('change', false);
      this.allowDocumentScroll(true);
    },
  },
  model: {
    prop: 'visible',
    event: 'change',
  },
  watch: {
    visible(value) {
      this.allowDocumentScroll(!value);
    },
  },
};

</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'
$text-color: #666

.terms-modal
  color: $text-color
  background-color: white
  padding: 2rem 1rem
  margin: 1rem
  position: relative

  &__header
    margin: 2rem
    text-align: center
    border-bottom: solid 1px grey

  &__title
    font-size: 1.5rem

  &__close
    position: absolute
    top: 0
    right: 0
    margin: 1rem

  &__body
    font-size: 1rem
    margin: 0 auto
    padding: 1rem
    text-align: left
    white-space: pre-wrap

  &__footer
    margin-top: 2rem
    border-top: solid 1px grey
    text-align: center

    .v-btn
      width: 180px

  &__wrapper
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 10
    background-color: rgba(0, 0, 0, 0.4)
    overflow-y: auto

  ::v-deep .input-group--selection-controls
    &__ripple:before
      border: grey 3px solid

    label
      color: $text-color

.terms-modal-enter-active,
.terms-modal-leave-active
  transition: opacity 0.5s

.terms-modal-enter,
.terms-modal-leave-to
  opacity: 0

@media #{map-get($display-breakpoints, 'md-and-up')}
  .terms-modal
    max-width: 700px
    margin-left: auto
    margin-right: auto
    padding: 2rem 5rem
</style>

<style lang="sass">
.no-document-scroll
  overflow: hidden
</style>
