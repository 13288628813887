var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "round-button",
        _vm._b(
          {
            staticClass: "sensor-form__btn my-3",
            attrs: { icon: "fa-upload" },
            nativeOn: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$refs.uploader.click()
              },
            },
          },
          "round-button",
          _vm.$attrs,
          false
        ),
        [
          _vm._t("default"),
          _vm._v(" "),
          _c("input", {
            ref: "uploader",
            attrs: {
              type: "file",
              multiple: "",
              id: "image-upload",
              accept: _vm.acceptedFiletypes.join(", "),
              "data-testid": "upload",
            },
            on: { change: _vm.onUpload },
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "card-dialog",
        {
          attrs: {
            "content-class": "progress-dialog",
            value: _vm.progressDialogVisible,
            persistent: "",
            width: 600,
            title: _vm.$t("label.upload"),
          },
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function () {
                return [
                  _vm.allUploadFinished
                    ? _c(
                        "dialog-action",
                        {
                          staticClass: "progress-dialog__close",
                          on: {
                            click: function () {
                              _vm.progressDialogVisible = false
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("button.close")) +
                              "\n      "
                          ),
                        ]
                      )
                    : _c(
                        "dialog-action",
                        {
                          staticClass: "progress-dialog__cancel",
                          on: { click: _vm.cancelRemainingUploads },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(_vm.$t("button.cancel")) +
                              "\n      "
                          ),
                        ]
                      ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("v-row", { attrs: { "justify-center": "", "no-gutters": "" } }, [
            _c(
              "table",
              _vm._l(_vm.uploads, function (upload) {
                return _c("tr", [
                  _c("td", { staticClass: "filename" }, [
                    _vm._v(_vm._s(upload.filename)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      upload.message
                        ? _c(
                            "span",
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(upload.message) +
                                  "\n              "
                              ),
                              upload.confirmOverwrite
                                ? [
                                    _c("icon-button", {
                                      attrs: {
                                        icon: "fa-check",
                                        color: "green",
                                        "data-testid": "overwrite",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onOverwrite(upload)
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("icon-button", {
                                      attrs: { icon: "fa-times", color: "red" },
                                      on: {
                                        click: function () {
                                          _vm.onCancelOverwrite(upload)
                                        },
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          )
                        : _c("v-progress-linear", {
                            staticClass: "primary--text",
                            model: {
                              value: upload.progress,
                              callback: function ($$v) {
                                _vm.$set(upload, "progress", $$v)
                              },
                              expression: "upload.progress",
                            },
                          }),
                    ],
                    1
                  ),
                ])
              }),
              0
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }