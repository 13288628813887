<template>
  <card-dialog persistent v-model="dialogVisible" :width="1000" :title="$t('label.rat_appearance')">
    <template #activator="{ on, attrs }">
      <round-button icon="bar_chart" v-on="on" v-bind="attrs">
        {{ $t('button.appearance_graph') }}
      </round-button>
    </template>
    <div class="rat-graph pa-4">
      <v-progress-linear :indeterminate="!progress" :value="progress" v-if="!ready || !allEventsLoaded"/>
      <template v-else>
        <v-checkbox :label="$t('label.include_misdetections')" v-model="includeMisdetections" hide-details />
        <bar-chart :data="chartData" :options="chartOptions"/>
        <table class="value-table">
          <tr>
            <td class="label">{{ $t('table.total_events') }}</td><td class="value">{{ events.length }}</td>
            <td class="label">{{ $t('table.events_with_rats') }}</td><td class="value">{{ eventsWithRat.length }}</td>
            <td class="label">{{ $t('table.days_installed') }}</td><td class="value">{{ daysInstalled }}</td>
          </tr>
        </table>
      </template>
    </div>
    <template #actions>
      <dialog-action @click="close">{{ $t('button.close') }}</dialog-action>
    </template>
  </card-dialog>
</template>

<i18n lang="yaml">
ja:
  label:
    rat_appearance: 'ネズミの出現'
    include_misdetections: '誤検出を検出に含める'
  graph:
    x_axis_time: '時間'
    y_axis_number_of_videos: '撮影回数'
  table:
    total_events: '総イベント数'
    events_with_rats: 'ネズミ検出数'
    days_installed: '設置日数'
  button:
    appearance_graph: '出現グラフ'
    close: '閉じる'
  msg:
    load_failed: データの読み込みに失敗しました。

en:
  label:
    rat_appearance: 'Rat Appearance'
    include_misdetections: 'Include misdetections'
  graph:
    x_axis_time: 'Time'
    y_axis_number_of_videos: 'Number of videos'
  table:
    total_events: 'Total Events'
    events_with_rats: 'Events with rats'
    days_installed: 'Days installed'
  button:
    appearance_graph: 'Appearance Graph'
    close: 'Close'
  msg:
    load_failed: Failed to load data.
</i18n>

<script>
import moment from 'moment';

import EventContainer from '@/mixins/eventContainer';
import Notifications from '@/mixins/notifications';

import BarChart from '@/components/atoms/BarChart';
import CardDialog from '@/components/atoms/CardDialog';
import DialogAction from '@/components/atoms/DialogAction';
import RoundButton from '@/components/atoms/RoundButton';

export default {
  name: 'rat-graph-button',
  mixins: [EventContainer, Notifications],
  components: {
    BarChart,
    CardDialog,
    DialogAction,
    RoundButton,
  },
  props: {
    // for EventContainer
    sensor: {
      type: Object,
      required: true,
    },
    requestParams: {
      type: Object,
      required: true,
    },
  },
  computed: {
    sensorId() {
      return this.sensor.id;
    },
    chartData() {
      return {
        labels: this.hourLabels,
        datasets: [
          {
            backgroundColor: '#0068b6',
            data: this.countsByHour,
          },
        ],
      };
    },
    chartOptions() {
      return {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          xAxis: {
            title: {
              display: true,
              text: this.$t('graph.x_axis_time'),
            },
          },
          yAxis: {
            title: {
              display: true,
              text: this.$t('graph.y_axis_number_of_videos'),
            },
            beginAtZero: true,
            max: this.yMax,
            ticks: {
              stepSize: 5,
            },
          },
        },
      };
    },
    countsByHour() {
      const result = this.hourLabels.map(() => 0);
      if (this.allEventsLoaded) {
        this.eventsWithRat.forEach((ev) => {
          const hour = moment.unix(ev.timestamp).hour();
          result[hour] += 1;
        });
      }
      return result;
    },
    daysInstalled() {
      const latestEventTime = moment.unix(this._.max(this.events.map(ev => ev.timestamp)));
      const earliestEventTime = moment.unix(this._.min(this.events.map(ev => ev.timestamp)));
      return latestEventTime.diff(earliestEventTime, 'days') + 1;
    },
    eventsWithRat() {
      if (this.includeMisdetections) {
        return this.events.filter(ev => ev.detected);
      }
      return this.events.filter(ev => ev.detected && !ev.misdetection);
    },
    hourLabels() {
      return this._.range(24);
    },
    progress() {
      const { events } = this.eventsLoading;
      if (!this.oldestTimestamp || !events || events.length === 0) {
        return null;
      }
      const newestEvent = events[0];
      const totalTimeSpan = newestEvent.timestamp - this.oldestTimestamp;
      const lastEvent = events[events.length - 1];
      const loadedTimespan = newestEvent.timestamp - lastEvent.timestamp;

      return 100 * (loadedTimespan / totalTimeSpan);
    },
    yMax() {
      return Math.ceil(this._.max(this.countsByHour) / 5) * 5;
    },
  },
  data() {
    return {
      dialogVisible: false,
      includeMisdetections: false,
      ready: false,
      oldestTimestamp: null,
    };
  },
  methods: {
    close() {
      this.dialogVisible = false;
      this.ready = false;
      this.oldestTimestamp = null;
      if (!this.allEventsLoaded) {
        this.cancelEventLoading();
      }
    },
    loadGraph: async function () {
      try {
        await this.loadOldestEvent();
        await this.loadAllEvents();
        this.ready = true;
      } catch (error) {
        this.notifyError('msg.load_failed');
      }
    },
    loadOldestEvent: async function () {
      const response = await this.getEvents({ limit: 1, ascending: '1' });
      const oldestEvent = response.events[0] || {};
      this.oldestTimestamp = oldestEvent.timestamp;
      this.clearEvents();
    },
  },
  watch: {
    dialogVisible(wasOpened) {
      if (wasOpened) {
        this.loadGraph();
      }
    },
    requestParams() {
      this.clearEvents();
    },
  },
};
</script>

<style lang="sass">
@import 'vuetify/src/styles/styles.sass'
$table-color: map-deep-get($colors, 'grey', 'darken-1')
$table-border: 1px solid $table-color

.rat-graph
  .value-table
    width: 100%
    border: $table-border
    border-collapse: collapse

    td
      width: calc(100% / 6)
      border: $table-border
      text-align: center
      font-weight: bold

    .label
      background-color: $table-color
      color: white

    .value
      color: $table-color
</style>
