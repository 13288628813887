<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <round-button icon="add_circle" rounded v-bind="attrs" v-on="on">
        {{ $t('button.add_sensor') }}
        <span class="ml-1">&#9660;</span>
      </round-button>
    </template>
    <v-list>
      <v-list-item v-for="key in items" :key="key" :to="`/sensors/new?type=${key}`">
        <v-list-item-title>{{ SENSOR_TYPE_NAMES[key] }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<i18n lang="yaml">
ja:
  button:
    add_sensor: 'センサーを追加'

en:
  button:
    add_sensor: 'Create a sensor'
</i18n>

<script>
import SensorTypes from '@/mixins/sensorTypes';
import RoundButton from '@/components/atoms/RoundButton';

export default {
  name: 'create-sensor-button',
  mixins: [SensorTypes],
  components: {
    RoundButton,
  },
  computed: {
    items() {
      const excepts = ['station', 'mirakun_device'];
      const predicate = (key) => {
        const available = !excepts.includes(key);
        const hasPermission = this.$store.getters.hasPermission(key);
        return available && hasPermission;
      };
      return this._.filter(Object.values(this.SENSOR_TYPES), predicate);
    },
  },
};
</script>

<style scoped lang="sass">
</style>
