<template>
  <div class="query-filter pa-4"> <!-- TODO: Becomes pa-6 after upgrade to vuetify 2 -->
    <slot />
    <v-divider class="my-4 px-n6"/> <!-- TODO: Becomes my-6 after upgrade to vuetify 2 -->
    <div class="text-center">
      <round-button
        icon="cached"
        :loading="loading"
        :disabled="loading || !applyable"
        @click="$emit('apply')"
      >
        {{ $t('button.apply_filter') }}
      </round-button>
      <round-button
        icon="clear"
        :loading="loading"
        :disabled="loading || !clearable"
        @click="$emit('clear')"
      >
        {{ $t('button.clear_filter') }}
      </round-button>
    </div>
  </div>
</template>

<i18n lang="yaml">
ja:
  button:
    apply_filter: '読み込み'
    clear_filter: 'クリア'

en:
  button:
    apply_filter: 'Apply'
    clear_filter: 'Clear'
</i18n>

<script>
import RoundButton from '@/components/atoms/RoundButton';

export default {
  name: 'query-filter',
  components: {
    RoundButton,
  },
  props: {
    loading: Boolean,
    clearable: Boolean,
    applyable: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="sass" scoped>
  .query-filter
    background-color: #ccc
</style>
