var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-md-flex" }, [
    _c(
      "div",
      { staticClass: "entity-form__form my-3" },
      [
        _c(
          "v-form",
          {
            ref: "form",
            model: {
              value: _vm.valid,
              callback: function ($$v) {
                _vm.valid = $$v
              },
              expression: "valid",
            },
          },
          [_vm._t("form", null, { editing: _vm.editing })],
          2
        ),
      ],
      1
    ),
    _vm._v(" "),
    _vm.canEdit
      ? _c("div", {
          staticClass: "entity-form__button-section my-6 mx-md-10 my-md-0",
        })
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }