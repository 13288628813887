var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "information-tab" },
    [
      _c("page-title-area", [_c("h1", [_vm._v("INFORMATION")])]),
      _vm._v(" "),
      _vm._l(_vm.sensorTypesInOrder, function (sensorType) {
        return [
          _vm.documents[sensorType]
            ? _c("document-list", {
                attrs: {
                  sensorType: sensorType,
                  documents: _vm.documents[sensorType],
                },
              })
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }