<template>
  <div>
    <page-title-area>
      <h1>{{$t('title')}}</h1>
    </page-title-area>
    <div class="filter-area d-flex align-center mb-4 py-1">
      <month-picker :value="currentMonth" disabled />
      <select-box
        class="flex-grow-0"
        style="width: 300px"
        v-model="selectedSensorId"
        :items="sensorSelectItems"
        :label="$t('field.sensor')"
        data-testid="sensor-select"
      />
      <insect-type-select-box
        v-model="insectTypeFilter"
        :null-option="$t('all_insects_option')"
        :label="$t('field.insect_type')"
        data-testid="insect-type-select"
      />
    </div>
    <v-row dense>
      <v-col sm="6" md="4" lg="3" xl="2">
        <v-card class="graph-area" style="height: 200px">
          <div>
            <h2>{{ $t('total_count') }}</h2>
          </div>
          <total-count-panel
            :events="panelData.totalCountPanel"
            :month="currentMonth"
          />
        </v-card>
      </v-col>
      <v-col cols="12" sm="12" md="8" lg="8" xl="7">
        <v-card class="graph-area d-flex flex-column" style="height: 200px">
          <div>
            <h2>{{ $t('alert') }}</h2>
          </div>
          <hr class="mx-n4">
          <notice-history
            class="mx-n4 mb-n4"
            :events="panelData.noticeHistory"
            :sensors="sensors"
          />
        </v-card>
      </v-col>
      <v-col sm="12" lg="12" xl="6">
        <v-row dense>
          <v-col cols="12">
            <v-card class="graph-area" style="height: 360px">
              <div class="d-flex">
                <h2>{{ $t('insect_count') }}</h2>
                <v-spacer />
                <alert-legend v-if="showAlertPeriods"/>
              </div>
              <daily-insect-count-chart
                style="height: 290px"
                :events="panelData.dailyInsectCountChart"
                :graphs="comparedWithPreviousMonthGraphs"
                :y-max="dailyInsectCountYMax"
                :show-alert-periods="showAlertPeriods"
                @max-count-updated="value => updateMaxDailyInsectCount(0, value)"
              />
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col sm="6" md="12" lg="10" xl="6">
        <v-card class="graph-area" style="height: 360px;min-width: 440px;max-width: 920px;">
          <div>
            <h2>{{ $t('top5') }}</h2>
          </div>
          <div class="d-flex justify-space-between">
            <top5-insects-panel :events="panelData.top5InsectsPanel" />
          </div>
        </v-card>
      </v-col>
      <v-col sm="12" lg="2" class="d-xl-none" />
      <v-col cols="12" md="4">
        <v-card class="graph-area" style="height: 320px">
          <div>
            <h2>{{ $t('hourly_insect_count') }}</h2>
          </div>
          <hourly-bar-chart
            style="height: 250px"
            class="count-graph"
            :events="panelData.hourlyBarChart"
          />
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card class="graph-area" style="height: 320px">
          <div>
            <h2>{{ $t('weekly_insect_count') }}</h2>
          </div>
          <weekday-bar-chart
            style="height: 250px"
            class="count-graph"
            :events="panelData.weekdayBarChart"
          />
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card class="graph-area" style="height: 320px">
          <div>
            <h2>{{ $t('weekly_and_hourly_insect_count') }}</h2>
          </div>
          <weekday-hour-matrix-chart
            style="height: 250px"
            :events="panelData.weekdayHourMatrixChart"
          />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<i18n lang="yaml">
ja:
  current_month: 当月
  total_count: 総捕獲数
  insect_count: 捕獲数
  previous_month: 前月
  same_month_previous_year: 昨年同月
  hourly_insect_count: 時間帯別 捕獲数
  weekly_insect_count: 曜日別 捕獲数
  weekly_and_hourly_insect_count: 曜日 x 時間帯別 捕獲数
  sum: 合計 ({sensorCount}台)
  top5: 捕獲上位5種
  title: モニタリングサマリー
  all_insects_option: 全種合計
  alert: アラート
  field:
    sensor: センサー
    insect_type: 虫種

en:
  current_month: Current Month
  total_count: Total Insects Captured
  insect_count: Total Insects Captured
  previous_month: Previous Month
  same_month_previous_year: Previous Year
  hourly_insect_count: Hourly Insect Activity
  weekly_insect_count: Insect Activity by Weekday
  weekly_and_hourly_insect_count: Insect Activity by Weekday and Hour
  sum: Sum ({sensorCount} sensors)
  top5: Top 5 Species
  title: Monitoring Summary
  all_insects_option: Total of all species
  alert: Alerts
  field:
    sensor: Sensor
    insect_type: Insect Type
</i18n>

<script>
import _ from 'lodash';
import theme from '@/libs/theme';
import { addToMonthString } from '@/libs/time';

import MonthPicker from '@/components/atoms/MonthPicker';
import SelectBox from '@/components/atoms/SelectBox';
import PageTitleArea from '@/components/atoms/PageTitleArea';
import InsectTypeSelectBox from '@/components/molecules/InsectTypeSelectBox';
import NoticeHistory from './NoticeHistory';
import AlertLegend from './AlertLegend';
import { calcInsectCountIncreases, eventsInMonth } from './chartCalculations';
import DailyInsectCountChart from './DailyInsectCountChart';
import HourlyBarChart from './HourlyBarChart';
import Top5InsectsPanel from './Top5InsectsPanel';
import TotalCountPanel from './TotalCountPanel';
import WeekdayHourMatrixChart from './WeekdayHourMatrixChart';
import WeekdayBarChart from './WeekdayBarChart';

export const sortAndAddChartInfo = (events) => {
  const result = _.sortBy(events, 'timestamp').map((event) => ({
    ...event,
    chartInfo: {
      alert: event.sensor_threshold !== 0 && event.count >= event.sensor_threshold,
      increaseAlert: event.sensor_increase_threshold !== 0 &&
        event.count_difference >= event.sensor_increase_threshold,
      count: event.count,
      countIncrease: Math.max(event.count_difference, 0),
      insectCountIncreases: {},
    },
  }));

  for (let i = 1; i < result.length; i += 1) {
    const previousEvent = result[i - 1];
    const event = result[i];
    event.chartInfo.insectCountIncreases = calcInsectCountIncreases(
      previousEvent.insect_counts,
      event.insect_counts,
    );
  }

  return result;
};

export const applySensorFilter = (eventsBySensorId, selectedSensor) => {
  if (!selectedSensor) {
    return Object.values(eventsBySensorId).flat();
  }

  return eventsBySensorId[selectedSensor.id];
};

export const applyInsectTypeFilter = (events, insectType) => {
  if (!insectType) {
    return events;
  }

  const result = events.map((event) => {
    const filteredEvent = {
      ...event,
      chartInfo: {
        ...event.chartInfo,
        count: event.insect_counts[insectType] || 0,
        insectCountIncreases: {},
      },
    };

    const countIncrease = event.chartInfo.insectCountIncreases[insectType] || 0;
    if (countIncrease > 0) {
      filteredEvent.chartInfo.insectCountIncreases[insectType] = countIncrease;
    }

    return filteredEvent;
  });

  for (let i = 1; i < result.length; i += 1) {
    const event = result[i];
    const previousEvent = result[i - 1];
    event.chartInfo.countIncrease = Math.max(
      event.chartInfo.count - previousEvent.chartInfo.count,
      0,
    );
  }

  return result;
};

export default {
  name: 'dashboard',
  props: {
  },
  components: {
    AlertLegend,
    DailyInsectCountChart,
    HourlyBarChart,
    InsectTypeSelectBox,
    MonthPicker,
    NoticeHistory,
    PageTitleArea,
    SelectBox,
    Top5InsectsPanel,
    TotalCountPanel,
    WeekdayBarChart,
    WeekdayHourMatrixChart,
  },
  data() {
    return {
      currentMonth: this.$moment().format('YYYY-MM'),
      sensors: [],
      sortedEventsBySensorId: {},
      maxDailyInsectCounts: [0, 0],
      insectTypeFilter: null,
      selectedSensorId: null,
    };
  },
  mounted: async function () {
    this.sensors = await this.fetchSensors();
    this.sensors.forEach((sensor) => {
      this.fetchEvents(sensor.id);
    });
  },
  computed: {
    comparedWithPreviousMonthGraphs() {
      return [
        {
          month: this.currentMonth,
          color: theme.primaryColor,
          label: this.$t('current_month'),
        },
        {
          month: addToMonthString(this.currentMonth, -1),
          color: '#19d276',
          label: this.$t('previous_month'),
        },
        {
          month: addToMonthString(this.currentMonth, -12),
          color: 'black',
          label: this.$t('same_month_previous_year'),
        },
      ];
    },
    displayingSum() {
      return this.selectedSensorId === null;
    },
    dailyInsectCountYMax() {
      const totalMaxCount = Math.max(...this.maxDailyInsectCounts);

      let roundTo = 5;
      if (totalMaxCount >= 100) {
        roundTo = 20;
      }

      return this.roundToNext(totalMaxCount, roundTo);
    },
    panelData() {
      const allMonthEvents = applyInsectTypeFilter(
        applySensorFilter(
          this.sortedEventsBySensorId,
          this.selectedSensor,
        ),
        this.insectTypeFilter,
      );

      const currentMonthEvents = eventsInMonth(
        this.currentMonth,
        allMonthEvents,
      );

      return {
        totalCountPanel: allMonthEvents,
        noticeHistory: currentMonthEvents,
        dailyInsectCountChart: allMonthEvents,
        top5InsectsPanel: currentMonthEvents,
        hourlyBarChart: currentMonthEvents,
        weekdayBarChart: currentMonthEvents,
        weekdayHourMatrixChart: currentMonthEvents,
      };
    },
    selectedSensor() {
      if (!this.selectedSensorId) {
        return null;
      }

      return this._.find(this.sensors, { id: this.selectedSensorId });
    },
    sensorSelectItems() {
      return [
        { value: null, text: this.$t('sum', { sensorCount: this.sensors.length }) },
        ...this.sensors.map(sensor => ({ value: sensor.id, text: sensor.name })),
      ];
    },
    showAlertPeriods() {
      return !this.displayingSum;
    },
  },
  inject: {
    fetchSensors: {
      // TODO: Implement
      default: async () => [],
    },
    fetchSensorEvents: {
      // TODO: Implement
      default: async () => () => [],
    },
  },
  methods: {
    fetchEvents: async function (sensorId) {
      const events = this.fetchSensorEvents(sensorId);

      this.sortedEventsBySensorId = {
        ...this.sortedEventsBySensorId,
        [sensorId]: sortAndAddChartInfo(events),
      };
    },
    roundToNext(value, increment) {
      return Math.ceil(value / increment) * increment;
    },
    updateMaxDailyInsectCount(graphIndex, updatedValue) {
      const newValue = [...this.maxDailyInsectCounts];
      newValue[graphIndex] = updatedValue;
      this.maxDailyInsectCounts = newValue;
    },
  },
};
</script>

<style scoped lang="sass">
.filter-area
  background-color: #fff

  & > *
    margin-left: 8px
    margin-right: 8px

.graph-area
  padding: 16px

  h2
    font-size: 1.25rem
    font-weight: normal
    padding-bottom: 16px
</style>
