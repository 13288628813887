var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.place
    ? _c(
        "div",
        [
          _c(
            "page-title-area",
            {
              scopedSlots: _vm._u(
                [
                  {
                    key: "side-content",
                    fn: function () {
                      return [_c("h2", [_vm._v(_vm._s(_vm.place.name))])]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                672945520
              ),
            },
            [_c("h1", [_vm._v(_vm._s(_vm.$t("title")))])]
          ),
          _vm._v(" "),
          _vm._l(_vm.sortedEvents, function (event) {
            return [
              _c(
                "div",
                { staticClass: "event" },
                [
                  _c("station-event-polling", {
                    key: event.id,
                    attrs: { event: event },
                    on: { eventUpdated: _vm.onEventUpdate },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var event = ref.event
                            return [
                              _c(
                                "div",
                                { staticClass: "floating-buttons" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: { text: "", icon: "" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.editEvent(event)
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "ma-2",
                                          attrs: { color: "primary" },
                                        },
                                        [_vm._v("fa-pencil")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("station-event", { attrs: { event: event } }),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
            ]
          }),
          _vm._v(" "),
          _c("station-event-editor", {
            attrs: { places: _vm.places, event: _vm.currentEditingEvent },
            on: { update: _vm.reload },
            model: {
              value: _vm.editDialogVisible,
              callback: function ($$v) {
                _vm.editDialogVisible = $$v
              },
              expression: "editDialogVisible",
            },
          }),
        ],
        2
      )
    : _c("page-load-spinner")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }