<template>
  <v-btn
    rounded
    :block="fullWidth"
    :color="color"
    class="elevation-0"
    @click="e => $emit('click', e)"
    v-bind="$attrs"
  >
    <v-icon left v-if="icon">{{ icon }}</v-icon><slot/>
  </v-btn>
</template>

<script>
export default {
  name: 'round-button',
  props: {
    icon: {
      type: String,
      default: null,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'white',
    },
  },
};
</script>

<style lang="sass" scoped>
.v-btn.v-btn--rounded
  height: 36px
</style>
