<template>
  <div class="gallery-view d-flex align-stretch">
    <div class="handle d-flex flex-column justify-center hidden-sm-and-down">
      <v-btn text v-if="hasPrevious" @click="previous" :disabled="disabled">
        <v-icon x-large>fa-angle-left</v-icon>
      </v-btn>
    </div>
    <v-row class="content flex-grow-1 pa-0" no-gutters>
      <v-col cols="12">
        <slot name="top" />
      </v-col>
      <v-row style="width: 100%" no-gutters>
        <v-col class="pr-1" cols="12" :md="mainColumns">
          <slot />
        </v-col>
        <v-col class="pl-1" cols="12" :md="sideColumns" v-if="hasSideContent">
          <slot name="side" />
        </v-col>
      </v-row>
    </v-row>
    <div class="handle d-flex flex-column justify-center hidden-sm-and-down">
      <v-btn text v-if="hasNext" @click="next" :disabled="disabled">
        <v-icon x-large>fa-angle-right</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'layout',
  props: {
    disabled: Boolean,
    hasPrevious: Boolean,
    hasNext: Boolean,
    sideColumns: {
      type: Number,
      default: 4,
    },
  },
  computed: {
    hasSideContent() {
      return this.$slots.side;
    },
    mainColumns() {
      if (!this.hasSideContent) {
        return 12;
      }

      return 12 - this.sideColumns;
    },
  },
  methods: {
    previous() {
      this.$emit('previous');
    },
    next() {
      this.$emit('next');
    },
  },
};
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'

.gallery-view
  width: 100%

  .handle
    width: 5%
    max-width: 80px

    .v-btn
      height: 200px
      margin: 0

  .content
    max-width: 90%
    padding-right: 10px
    padding-left: 10px

    @media #{map-get($display-breakpoints, 'sm-and-down')}
      width: 100%
      max-width: 100%
</style>
