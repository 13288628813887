import Vue from 'vue';
import VueI18n from 'vue-i18n';

import mixinLocale from './common/locale';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'en',
  messages: {
    en: {
      normal_download: 'Normal download',
      training_data_download: 'Training data download',
    },
    ja: {
      normal_download: '通常ダウンロード',
      training_data_download: '教師データダウンロード',
    },
  },
});

export default {
  computed: {
    downloadOptions() {
      if (!this.$store.getters.assumedUser) {
        return [];
      }

      return [
        { type: 'normal', label: i18n.t('normal_download', this.i18n_locale) },
        { type: 'trainingData', label: i18n.t('training_data_download', this.i18n_locale) },
      ];
    },
  },
  ...mixinLocale,
};
