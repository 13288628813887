<template>
  <v-chip class="counter">
    {{count}}
  </v-chip>
</template>

<script>
export default {
  name: 'counter',
  props: {
    count: [String, Number],
  },
};
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'

.v-chip.counter
  background-color: map-get($colors, 'grey', 'base')!important
  color: white!important
  height: 24px
  margin: 0
  padding: .25rem 1rem
  text-align: center
</style>
