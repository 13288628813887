<template>
  <v-row class="processing" align="center">
    <v-col>
      <v-progress-circular
        indeterminate
        color="primary"
        :size="50"
        class="ma-2"
      />
      {{ $t('processing') }}
    </v-col>
  </v-row>
</template>

<i18n lang="yaml">
ja:
  processing: '解析中...'

en:
  processing: 'Processing...'
</i18n>

<script>
export default {
  name: 'station-event-processing',
};
</script>

<style scoped lang="sass">
.processing
  height: 300px
  font-size: 32px

  .col > *
    vertical-align: middle
</style>
