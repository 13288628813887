<template>
  <div class="station-event" v-if="event">
    <div class="d-flex align-baseline">
      <div class="headline font-weight-bold mr-8">
        <div class="d-flex align-center" v-if="event.installed_at && event.collected_at">
          <div class="mr-8">{{event.trap_no}}</div>
          <div>{{event.installed_at | moment($t('format.date'))}}</div>
          <div class="mx-2">~</div>
          <div>{{event.collected_at | moment($t('format.date'))}}</div>
        </div>
      </div>
      <div class="headline font-weight-bold flex">
        <div class="d-flex align-center" v-if="event.month">
          <div class="station-event__content align-center px-2">{{event.month | moment('YYYY-MM')}}</div>
          <div class="ml-2">{{$t('month')}}</div>
          <div class="station-event__edit-button" v-if="isNotRecentEvent" >
            <v-btn text v-if="!event.updating" :disabled="isThumbnail" @click.native.stop="editing = true; pickedDetectionType = 'all'">
              <v-icon>fa-edit</v-icon>
              <small>{{ $t('menu.edit') }}</small>
            </v-btn>
            <v-btn text v-else disabled>
              <v-icon >fa-refresh</v-icon>
              <small>{{ $t('menu.updating') }}</small>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex">
      <div class="pb-3">
        {{event.upload_time | dateTime($t('format.time'))}}
      </div>
    </div>
    <station-event-processing v-if="event.processing" />
    <div class="d-flex" v-else>
      <div class="pr-3 station-event-left-column">
        <div class="station-event__photo">
          <picked-insects-draw
            :imageSrc="current.original"
            :pickedDetectionType="pickedDetectionType"
            :currentPhotoKey="current.key"
            :event="event"
          >
            <loading-image :forceLoading="event.updating" :src="current.detections || current.original" />
          </picked-insects-draw>
        </div>
        <div class="mt-2 pr-1 text-right text-caption">
          {{$t('count')}}: {{photos.length}}
        </div>
        <div class="d-flex flex-nowrap">
          <div class="flex-grow-0 pr-2">
            <div
              class="thumbnail tab"
              :class="{ active: current === tab }"
              @click="selected = tab">
              <loading-image :src="tab.thumbnail" />
            </div>
          </div>
          <div class="thumbnail-slider-wrapper">
            <thumbnail-slider
              :items="photos"
              v-model="currentIndex"
              keyName="key"
            >
              <template #item="props">
                <div
                  class="thumbnail"
                  :class="{ active: currentIndex == props.index }"
                  @click="selectPhoto(props.index)"
                  v-if="props.index < processedImageCount"
                >
                  <div class="thumbnail-head">
                    <div class="thumbnail-number">{{props.index + 1}}</div>
                    <div class="thumbnail-count">{{getPhotoCount(props.item.key)}}</div>
                  </div>
                  <loading-image
                    class="thumbnail-image"
                    :forceLoading="event.updating"
                    :src="props.item.thumbnail"
                  />
                </div>
                <processing-image v-else />
              </template>
            </thumbnail-slider>
          </div>
        </div>
      </div>
      <div class="station-event__result station-event__content pa-2 station-event-right-column">
        <chart-type-select v-model="chartType" />
        <insect-count-table
          class="station-event__result-table"
          :insectCounts="event.insect_counts"
          :showRadiobutton="!event.updating"
          :pickedDetectionType.sync="pickedDetectionType"
          v-if="chartTypeIs('table')"
        />
        <insect-count-by-type-bar-chart
          :insectCounts="event.insect_counts"
          v-if="chartTypeIs('bar')"
        />
        <top-5-insect-types-pie-chart
          :insectCounts="event.insect_counts"
          v-if="chartTypeIs('pie')"
        />
      </div>
      <insect-detections-editor
        v-if="isNotRecentEvent"
        :event="event"
        :imageSrc="current.original"
        :detectionsUrl="detectionsUrl"
        :canViewDetectionDetail="true"
        v-model="editing"
        @updated="$emit('pollEvent')">
        <template #header>
          <content-header class="w-100" :title="event.stationName" titleClass="title my-1">
            <!-- アイコン表示の為 fly_count を渡す-->
            <template #icon><icon type="fly_count" /></template>
            <template #subtitle>
              <div class="overflow-ellipsis title details">
                {{ timestamp.date }}<span class="ml-3" />{{ `${timestamp.time} ${getPhotoCount(current.key)}`}}
              </div>
            </template>
          </content-header>
        </template>
      </insect-detections-editor>
    </div>
  </div>
</template>

<i18n lang="yaml">
ja:
  title: '新着解析結果'
  count: '合計'
  month: '月分'
  format:
    date: 'YYYY年MM月DD日'
    time: 'YYYY年MM月DD日 HH:mm'
  menu:
    edit: '修正する'
    updating: '更新中'
en:
  title: 'Recent'
  count: 'Total'
  month: ' '
  format:
    date: 'YYYY-MM-DD'
    time: 'YYYY-MM-DD HH:mm'
  menu:
    edit: 'Edit'
    updating: 'Updating'
</i18n>

<script>
import moment from 'moment';

import ChartTypeSelect from '@/components/atoms/ChartTypeSelect';
import InsectCountByTypeBarChart from '@/components/organisms/InsectCountByTypeBarChart';
import Top5InsectTypesPieChart from '@/components/organisms/Top5InsectTypesPieChart';
import InsectDetectionsEditor from '@/components/_legacy/InsectDetectionsEditor';
import PickedInsectsDraw from '@/components/_legacy/PickedInsectsDraw';
import ContentHeader from '@/components/ContentHeader';
import Icon from '@/components/Icon';
import InsectCountTable from '@/components/InsectCountTable';
import LoadingImage from '@/components/LoadingImage';
import ProcessingImage from '@/components/ProcessingImage';
import ThumbnailSlider from '@/components/ThumbnailSlider';

import StationEventProcessing from './StationEventProcessing';

export default {
  name: 'station-v1-event',
  components: {
    ChartTypeSelect,
    ContentHeader,
    Icon,
    InsectCountByTypeBarChart,
    InsectCountTable,
    InsectDetectionsEditor,
    LoadingImage,
    PickedInsectsDraw,
    ProcessingImage,
    StationEventProcessing,
    ThumbnailSlider,
    Top5InsectTypesPieChart,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  computed: {
    current() {
      if (this.selected) {
        return this.selected;
      }
      return this.findFirstPhoto();
    },
    currentIndex() {
      if (this.selected) {
        return this.photos.findIndex(photo => this.selected === photo);
      }
      return 0;
    },
    firstUnprocessedIndex() {
      return this._.findIndex(this.photos, photo => !this.isPhotoReady(photo));
    },
    processedImageCount() {
      return this.firstUnprocessedIndex === -1 ? this.photos.length : this.firstUnprocessedIndex;
    },
    photos() {
      const { tab: _tab, ...photos } = this.event.attachments;
      const withKey = this._.map(photos, (value, key) => ({ key, ...value }));

      return this._.sortBy(withKey, ['key']);
    },
    tab() {
      return this.event.attachments.tab || {};
    },
    isNotRecentEvent() {
      return !this.$route.path.match(/recent/);
    },
    isThumbnail() {
      if (this.selected) {
        return !('key' in this.selected);
      }
      return false;
    },
    timestamp() {
      return {
        date: moment.unix(this.event.upload_time).format('YYYY.M.D'),
        time: moment.unix(this.event.upload_time).format('HH:mm'),
      };
    },
    detectionsUrl() {
      return `/api/v2/events/${this.event.id}/insect_detections/${this.current.key}`;
    },
  },
  data() {
    return {
      selected: null,
      chartType: 'table',
      pickedDetectionType: 'all',
      editing: false,
    };
  },
  methods: {
    isPhotoReady(photo) {
      return photo.thumbnail && photo.detections;
    },
    selectPhoto(photoIndex) {
      if (photoIndex < this.processedImageCount) {
        this.selected = this.photos[photoIndex];
      }
    },
    findFirstPhoto() {
      return (this.processedImageCount === 0 ? this.tab : this._.first(this.photos)) || {};
    },
    getPhotoCount(key) {
      const { photo_counts: counts = {} } = this.event;
      return counts[key];
    },
    chartTypeIs(view) {
      return this.chartType === view;
    },
    resetView() {
      this.chartType = 'table';
    },
  },
  filters: {
    dateTime(value, format) {
      return moment(value * 1000).format(format);
    },
  },
  watch: {
    event() {
      this.$nextTick(() => {
        this.selected = this.findFirstPhoto();
        this.pickedDetectionType = 'all';
      });
    },
  },
};
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'
$right-column-width: 450px
$tab-image-width: 150px
$carousel-handle-width: 80px

.station-event
  &__edit-button
    margin: 0 0 0 auto

    .v-btn
      margin: 0
      height: auto
      min-width:64px

    ::v-deep .v-btn__content
      flex-direction: column

    .v-icon
      color: map-get($blue, 'darken-2')

    small
      line-height: 2em

  &-left-column
    min-width: calc(100% - #{$right-column-width})

  &-right-column
    max-width: $right-column-width
    min-width: $right-column-width

  &__content
    border: 2px solid #ccc
    background-color: #fff

  &__photo
    overflow: auto
    text-align: center
    font-size: 0

    ::v-deep > .insect-positions-canvas
      height: 60vh

  .loading-image, .processing-image, .picked-insects-draw
    height: 100%
    min-height: 100px
    width: 100%
    object-fit: contain

.thumbnail-slider-wrapper
  min-width: 1px

.thumbnail
  height: 160px
  min-width: 240px
  max-width: 240px
  cursor: pointer
  opacity: 0.8
  border: 3px solid
  border-color: transparent

  &-head
    background-color: #666
    color: #fff
    font-size: 12px
    display: flex
    justify-content: space-between
    padding: 0 4px

  &.tab
    min-width: 160px

  &.active
    border-color: #0068B6
    opacity: 1.0

  &:not(.tab) ::v-deep img
    object-fit: cover

.details
  padding-left: 3rem
  padding-right: 3rem

.col
  padding: 0
</style>
