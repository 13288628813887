<template>
  <bar-chart :data="chartData" :options="chartOptions" />
</template>

<script>
import palette from 'google-palette';
import BarChart from '@/components/atoms/BarChart';
import InsectTypes from '@/mixins/insectTypes';

export default {
  components: {
    BarChart,
  },
  mixins: [InsectTypes],
  props: {
    insectCounts: {
      type: Object,
      required: true,
    },
  },
  computed: {
    insectsWithoutOther() {
      const insects = this._.map(this.insectCounts, (count, key) => ({
        ...this.INSECT_TYPES[key],
        count,
      }));
      const withoutOther = insects.filter(x => x.label !== this.INSECT_TYPES.others.label);
      return this.addColor(withoutOther);
    },
    insectsSortedByCount() {
      return this.insectsWithoutOther.sort((a, b) => {
        if (a.count < b.count) return 1;
        if (a.count > b.count) return -1;
        if (a.symbol > b.symbol) return 1;
        if (a.symbol < b.symbol) return -1;
        return 0;
      });
    },
    generateChartData() {
      const labels = [];
      const counts = [];
      const colors = [];
      this.insectsSortedByCount.forEach((insect) => {
        labels.push(insect.label);
        counts.push(insect.count);
        colors.push(insect.color);
      });
      return { label: labels, count: counts, color: colors };
    },
    chartData() {
      return {
        labels: this.generateChartData.label,
        datasets: [
          {
            backgroundColor: this.generateChartData.color,
            data: this.generateChartData.count,
          },
        ],
      };
    },
    chartOptions() {
      return {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          xAxis: {
            ticks: {
              autoSkip: false,
            },
          },
          yAxis: {
            beginAtZero: true,
          },
        },
      };
    },
  },
  methods: {
    addColor(insects) {
      const colors = palette('mpn65', insects.length).map(hex => `#${hex}`);
      insects.sort((a, b) => (a.symbol < b.symbol ? 1 : -1));
      insects.forEach((v, i) => { v.color = colors[i]; return 0; });
      return insects;
    },
  },
};
</script>
