var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "loading-image",
      class: {
        "loading-image--error": _vm.showError,
        "loading-image--loading": _vm.showSpinner,
      },
    },
    [
      _c("img", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showImage,
            expression: "showImage",
          },
        ],
        attrs: { src: _vm.src, "data-testid": "image" },
        on: {
          load: _vm.onLoad,
          error: function () {
            return (_vm.error = true)
          },
        },
      }),
      _vm._v(" "),
      _vm.showSpinner
        ? _c("v-progress-circular", {
            staticClass: "centered primary--text",
            attrs: { indeterminate: "", size: 50, width: 3 },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showError
        ? _c(
            "v-icon",
            {
              staticClass: "centered red--text",
              attrs: { "x-large": "", "data-testid": "error" },
            },
            [_vm._v("\n    fa-times\n  ")]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }