<template>
  <div class="pv-date-field">
    <v-menu
      :close-on-content-click="false"
      class="pv-date-field__menu"
      max-width="290px"
      offset-y
      transition="scale-transition"
      v-if="edit"
      v-model="open"
    >
      <template #activator="{ on, attrs }">
        <v-text-field
          :value="formattedValue"
          :rules="rules"
          :clearable="clearable"
          hide-details="auto"
          readonly
          @click:clear="$emit('input', null)"
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <v-date-picker
        :value="pickerValue"
        locale="en"
        no-title
        :header-date-format="headerFormat"
        @input="onPick"
      />
    </v-menu>
    <span v-else>{{ formattedValue }}</span>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'pv-date-field',
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default() {
        return [];
      },
    },
    value: {
      default: null,
    },
    format: {
      type: String,
      default: 'YYYY-MM-DD',
    },
    displayFormat: {
      type: String,
      default: 'YYYY-MM-DD',
    },
    clearable: Boolean,
  },
  computed: {
    formattedValue() {
      return this.value && moment(this.value).format(this.displayFormat);
    },
    pickerValue() {
      if (!this.value) {
        return null;
      }
      const momentValue = moment(this.value);
      if (momentValue.isValid()) {
        return momentValue.format(this.format);
      }
      return null;
    },
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    headerFormat(date) {
      return moment(date).format('YYYY/MM');
    },
    onPick(value) {
      this.open = false;
      this.$emit('input', moment(value).format(this.format));
    },
  },
  watch: {
    edit() {
      this.open = false;
    },
  },
};
</script>

<style scoped lang="sass">
.pv-date-field
  ::v-deep .v-text-field
    margin: -6px 0 -5px
    padding: 0

    .v-input__slot
      border: 1px solid
      margin-bottom: 0

    input
      padding-left: 4px
      padding-right: 4px
      color: #666!important
</style>
