var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.assumedUser
    ? _c("v-alert", { attrs: { type: "warning" } }, [
        _c("span", [
          _vm._v(
            _vm._s(
              _vm.$t("logged_in_as_other_user", {
                userEmail: _vm.assumedUser.email,
              })
            )
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }