<template>
  <bar-chart :data="chartData" :options="chartOptions" />
</template>

<i18n lang="yaml">
ja:
  indoor: '内部発生'
  outdoor: '外部侵入'
en:
  indoor: 'indoor'
  outdoor: 'outdoor'
</i18n>

<script>
import BarChart from '@/components/atoms/BarChart';

import { indoorColor, outdoorColor } from './colors';
import ChartHelpers from './mixins/chartHelpers';

const HOUR_LABELS = ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'];

export default {
  name: 'hourly-bar-chart',
  props: {
    events: {
      type: Array,
      required: true,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    BarChart,
  },
  mixins: [ChartHelpers],
  computed: {
    chartData() {
      return {
        labels: HOUR_LABELS,
        datasets: [
          {
            backgroundColor: indoorColor,
            data: this.chartValues.indoor,
            label: this.$t('indoor'),
          },
          {
            backgroundColor: outdoorColor,
            data: this.chartValues.outdoor,
            label: this.$t('outdoor'),
          },
        ],
      };
    },
    chartOptions() {
      return this._.merge({
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
            ticks: {
              precision: 0,
            },
          },
        },
      }, this.options);
    },
    chartValues() {
      return {
        outdoor: HOUR_LABELS.map((_hour, index) => this.hourlyRouteCounts[index].outdoor),
        indoor: HOUR_LABELS.map((_hour, index) => this.hourlyRouteCounts[index].indoor),
      };
    },
    hourlyRouteCounts() {
      const result = HOUR_LABELS.map(() => ({ indoor: 0, outdoor: 0 }));
      this.events.forEach(({ timestamp, chartInfo: { insectCountIncreases } }) => {
        const hour = new Date(timestamp * 1000).getHours();
        const routeCounts = this.calcRouteCounts(insectCountIncreases);
        Object.keys(routeCounts).forEach((route) => {
          result[hour][route] += routeCounts[route];
        });
      });
      return result;
    },
  },
};
</script>

<style scoped lang="sass">
</style>
