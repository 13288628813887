var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-toolbar",
    { staticClass: "gallery-view-toolbar", attrs: { height: "auto" } },
    [
      _c(
        "v-toolbar-title",
        { staticClass: "d-flex align-center" },
        [
          _c("content-header", {
            staticClass: "w-100",
            attrs: { title: _vm.sensor.name, titleClass: "title my-1" },
            scopedSlots: _vm._u([
              {
                key: "icon",
                fn: function () {
                  return [_c("sensor-icon", { attrs: { sensor: _vm.sensor } })]
                },
                proxy: true,
              },
              {
                key: "subtitle",
                fn: function () {
                  return [
                    _c(
                      "div",
                      { staticClass: "overflow-ellipsis title details" },
                      [
                        _vm._v("\n          " + _vm._s(_vm.timestamp.date)),
                        _c("span", { staticClass: "ml-3" }),
                        _vm._v(
                          _vm._s(
                            _vm.timestamp.time + " " + (_vm.event.count || "")
                          ) + "\n        "
                        ),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider", { staticClass: "hidden-md-and-up" }),
      _vm._v(" "),
      _c(
        "v-toolbar-items",
        [
          _vm._t("default"),
          _vm._v(" "),
          _vm.editable
            ? _c(
                "toolbar-button",
                {
                  attrs: {
                    icon: "fa-star",
                    value: _vm.event.pickup,
                    disabled: !!_vm.event.updating,
                  },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.update({ pickup: !_vm.event.pickup })
                    },
                  },
                },
                [_vm._v("\n      " + _vm._s(_vm.$t("menu.pickup")) + "\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.editable
            ? _c("tag-picker", {
                staticClass: "d-flex align-center",
                attrs: { "enable-add": "" },
                on: {
                  submit: function (tags) {
                    return _vm.update({ tags: tags })
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "toolbar-button",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    icon: "fa-tag",
                                    disabled: !!_vm.event.updating,
                                  },
                                },
                                "toolbar-button",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("menu.tag")) +
                                  "\n        "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  4197103049
                ),
                model: {
                  value: _vm.event.tags,
                  callback: function ($$v) {
                    _vm.$set(_vm.event, "tags", $$v)
                  },
                  expression: "event.tags",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c("download-toolbar-button", {
            attrs: {
              "download-options": _vm.downloadOptions,
              disabled: !_vm.canDownload,
            },
            on: {
              download: function (type) {
                return _vm.$emit("download", type)
              },
            },
          }),
          _vm._v(" "),
          _c(
            "toolbar-button",
            {
              attrs: { icon: "fa-close" },
              nativeOn: {
                click: function ($event) {
                  return _vm.close.apply(null, arguments)
                },
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("menu.close")) + "\n    ")]
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }