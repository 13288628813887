var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "insect-positions-canvas" },
    [
      _vm._t("default"),
      _vm._v(" "),
      _c(
        "canvas",
        _vm._g(
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.hideCanvas,
                expression: "!hideCanvas",
              },
            ],
            ref: "canvas",
          },
          _vm.canvasEvents
        )
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }