import { render, staticRenderFns } from "./InsectPositionsCanvas.vue?vue&type=template&id=89ae414c&scoped=true&"
import script from "./InsectPositionsCanvas.vue?vue&type=script&lang=js&"
export * from "./InsectPositionsCanvas.vue?vue&type=script&lang=js&"
import style0 from "./InsectPositionsCanvas.vue?vue&type=style&index=0&id=89ae414c&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89ae414c",
  null
  
)

/* custom blocks */
import block0 from "./InsectPositionsCanvas.vue?vue&type=custom&index=0&blockType=i18n&lang=yaml"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/project/client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('89ae414c')) {
      api.createRecord('89ae414c', component.options)
    } else {
      api.reload('89ae414c', component.options)
    }
    module.hot.accept("./InsectPositionsCanvas.vue?vue&type=template&id=89ae414c&scoped=true&", function () {
      api.rerender('89ae414c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/InsectPositionsCanvas.vue"
export default component.exports