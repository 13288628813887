<template>
  <v-btn
    :class="{ 'green--text': !disabled, 'darken-1': !disabled }"
    text
    :disabled="disabled"
    @click="ev => $emit('click', ev)"
  >
    <slot/>
  </v-btn>
</template>

<script>
export default {
  name: 'dialog-action',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
