import Vue from 'vue';
import Vuex from 'vuex';
import * as accessToken from './accessToken';
import * as sensor from './sensor';
import * as event from './event';
import * as user from './user';
import * as sensorUser from './sensorUser';
import * as notification from './notification';
import * as pendingDownload from './pendingDownload';
import * as organization from './organization';


Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    accessToken,
    event,
    sensor,
    user,
    sensorUser,
    notification,
    pendingDownload,
    organization,
  },
});
