<template>
  <div class="user-list">
    <v-list v-if="users.length > 0">
      <v-list-item class="user-list__user" v-for="user in users" :key="user.email" role="listitem">
        <v-list-item-content>
          <v-list-item-title>
            {{ user[nameKey] }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ user.email }}
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action v-if="!readonly">
          <v-menu content-class="user-list__menu" offset-y v-if="!readonly">
            <template v-slot:activator="{ on, attrs }">
              <v-btn text icon v-bind="attrs" v-on="on" data-testid="user-list__user-dots">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <slot name="list-items" :user="user" />
            </v-list>
          </v-menu>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <template v-else>
      <div>{{ no_users_message }}</div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'user-list',
  props: {
    users: {
      type: Array,
      default() {
        return [];
      },
    },
    nameKey: {
      type: String,
      default: 'name',
    },
    no_users_message: {
      type: String,
      default: 'no data',
    },
    readonly: Boolean,
  },
};
</script>

<style scoped lang="sass">
.user-list
  &__menu
    ::v-deep
      .v-list
        padding: 4px

      .v-list-item
        min-height: 36px
</style>
