var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("content-header", {
        attrs: { title: _vm.title, titleClass: "display-1 font-weight-bold" },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex my-4" },
        [
          _vm.isToday
            ? _c(
                "round-button",
                {
                  staticClass: "mr-2",
                  attrs: { loading: _vm.loading, disabled: _vm.loading },
                  on: { click: _vm.fetchLatestLogs },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("buttons.fetch_newer")) +
                      "\n    "
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("date-picker-dropdown", {
            attrs: { value: _vm.date, "date-picker-props": { max: _vm.today } },
            on: {
              input: function (date) {
                return _vm.openDayLogs(date)
              },
            },
            scopedSlots: _vm._u([
              {
                key: "activator",
                fn: function (ref) {
                  var on = ref.on
                  var attrs = ref.attrs
                  return [
                    _c(
                      "round-button",
                      _vm._g(_vm._b({}, "round-button", attrs, false), on),
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("buttons.show_day_logs")) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.logItems,
          loading: _vm.loading,
          "no-data-text": _vm.$t("no_data"),
          "disable-sort": "",
          "items-per-page": -1,
          "hide-default-footer": "",
          "hide-pagination": "",
          "show-expand": "",
        },
        scopedSlots: _vm._u([
          {
            key: "item.type",
            fn: function (ref) {
              var item = ref.item
              return [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("types." + item.type)) + "\n    "
                ),
              ]
            },
          },
          {
            key: "item.details",
            fn: function (ref) {
              var item = ref.item
              return [
                _c("code", { staticClass: "short-details" }, [
                  _vm._v("\n        " + _vm._s(item.shortDetails) + "\n      "),
                ]),
              ]
            },
          },
          {
            key: "expanded-item",
            fn: function (ref) {
              var item = ref.item
              return [
                _c("td", { attrs: { colspan: "4" } }, [
                  _c("a", { attrs: { href: item.url, target: "_blank" } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("show_in_aws")) +
                        "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "details" }, [
                    _c("code", [_c("pre", [_vm._v(_vm._s(item.details))])]),
                  ]),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }