var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "round-button",
    { attrs: { icon: "file_download" }, on: { click: _vm.exportCsv } },
    [_vm._v("\n  " + _vm._s(_vm.$t("label")) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }