var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex align-center" }, [
    _c("div", { staticClass: "legend-box", style: _vm.styles.totalCountAlert }),
    _vm._v(" "),
    _c("span", [_vm._v(_vm._s(_vm.$t("total_count_alert")))]),
    _vm._v(" "),
    _c("div", {
      staticClass: "legend-box",
      style: _vm.styles.increaseCountAlert,
    }),
    _vm._v(" "),
    _c("span", [_vm._v(_vm._s(_vm.$t("increase_count_alert")))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }