<template>
  <zoom :class="{ active: loaded }" :scale.sync="zoom" :x.sync="x" :y.sync="y" :disabled="disablePanZoom" ref="zoom">
    <div class="insect-positions-canvas" @click.right.prevent="showContext">
      <loading-image :src="src" @loaded="handleLoadImage" />
      <canvas
        :height="naturalHeight"
        :width="naturalWidth"
        ref="displayLayer"
      />
      <canvas
        :height="naturalHeight"
        :width="naturalWidth"
        v-on="canvasInputEventHandlers"
        class="edit-layer"
        ref="editLayer"
      />
      <v-menu
        v-model="context.show"
        :position-x="context.x"
        :position-y="context.y"
        :disabled="disableContextMenu"
        absolute
        offset-y
      >
        <v-list>
          <v-list-item link @click="$emit('contextmenu:save', _self)">
            {{ $t('menu.save') }}
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </zoom>
</template>

<i18n lang="yaml">
ja:
  menu:
    save: 画像を保存

en:
  menu:
    save: 'Save image'
</i18n>

<script>
import * as _ from 'lodash';
import { saveAs } from 'file-saver';
import LoadingImage from '@/components/LoadingImage';
import Zoom from '@/components/Zoom';

const cross2d = (vec1, vec2) => {
  const [x1, y1] = vec1;
  const [x2, y2] = vec2;
  return (x1 * y2) - (x2 * y1);
};

const isContain = (rect, point) => {
  const [x1, y1, x2, y2] = rect;
  const [px, py] = point;
  // NOTE:
  // 2次元(X,Y)の外積は必ずZ方向へのスカラー(Scalar)となる。
  // 任意の点から長方形の頂点4つへのベクトルの外積のすべてが同じ符号であれば、
  // その任意の点は長方形の内側にあることとなる。
  return _.uniq([
    cross2d([x1 - px, y1 - py], [x1 - px, y2 - py]) > 0,
    cross2d([x1 - px, y2 - py], [x2 - px, y2 - py]) > 0,
    cross2d([x2 - px, y2 - py], [x2 - px, y1 - py]) > 0,
    cross2d([x2 - px, y1 - py], [x1 - px, y1 - py]) > 0,
  ]).length === 1;
};

export default {
  name: 'insect-positions-canvas',
  components: {
    Zoom,
    LoadingImage,
  },
  props: {
    /*
     * Attributes
     * - height: Number
     * - width: Number
     * - rects: {
     *   - symbol: Insect type
     *   - position: [x1, y1, x2, y2]
     *   - color: Color (optional, default: red)
     *   - lineWith: Number (optional, default: 2)
     *   - fontSize: Number (optional, default: 14)
     * }[]
     */
    detection: {
      type: Object,
      default() {
        return {
          height: null,
          width: null,
          rects: [],
        };
      },
    },
    src: {
      type: String,
      default: '',
    },
    mode: {
      type: String, // 'add', 'pick' or ''
      default: '',
    },
    disableContextMenu: Boolean,
    disablePanZoom: Boolean,
  },
  data() {
    return {
      loaded: false,
      naturalHeight: 0,
      naturalWidth: 0,
      new: null, // [x1, y1, x2, y2]
      listener: null,
      context: {
        show: false,
        x: 0,
        y: 0,
      },
    };
  },
  computed: {
    canvasInputEventHandlers() {
      // NOTE: converts to original image coordinates
      const wrapper = func => (event) => {
        const { offsetX, offsetY } = event;
        func({
          detectionX: (offsetX * this.detectionWidth) / this.$refs.editLayer.clientWidth,
          detectionY: (offsetY * this.detectionHeight) / this.$refs.editLayer.clientHeight,
        }, event);
      };
      switch (this.mode) {
        case 'add':
          return {
            mousedown: wrapper(this.mousedown),
            mousemove: this._.throttle(wrapper(this.mousemove), 1000 / 60),
            mouseup: wrapper(this.mouseup),
          };
        case 'pick':
          return {
            click: wrapper(this.pick),
          };
        default:
          return null;
      }
    },
    detectionHeight() {
      return this.detection.height || this.naturalHeight;
    },
    detectionWidth() {
      return this.detection.width || this.naturalWidth;
    },
    // for inject
    x: {
      get() {
        const { x } = this.getZoom();
        return x;
      },
      set(value) {
        const prev = this.getZoom();
        this.setZoom({ ...prev, x: value });
      },
    },
    y: {
      get() {
        const { y } = this.getZoom();
        return y;
      },
      set(value) {
        const prev = this.getZoom();
        this.setZoom({ ...prev, y: value });
      },
    },
    zoom: {
      get() {
        const { scale } = this.getZoom();
        return scale;
      },
      set(value) {
        const prev = this.getZoom();
        this.setZoom({ ...prev, scale: value });
      },
    },
  },
  methods: {
    clear(canvas) {
      const canvas2d = canvas.getContext('2d');
      canvas2d.clearRect(0, 0, canvas.width, canvas.height);
    },
    drawRect(canvas, rect) {
      const canvas2d = canvas.getContext('2d');
      const {
        position,
        symbol = '',
        color = '#f00',
        lineWidth = 2,
        fontSize = 14,
      } = rect;
      const heightScale = this.naturalHeight / this.detectionHeight;
      const widthScale = this.naturalWidth / this.detectionWidth;

      let scale = 1;
      if (this.naturalWidth > 0 && canvas.clientWidth > 0) {
        scale = this.naturalWidth / canvas.clientWidth;
      }
      scale = Math.max(scale / this.zoom, 2);
      canvas2d.lineWidth = lineWidth * scale;
      canvas2d.font = `bold ${fontSize * scale}px sans-serif`;
      canvas2d.textBaseline = 'top';
      canvas2d.strokeStyle = color;
      canvas2d.fillStyle = color;

      const [x1, y1, x2, y2] = position;
      canvas2d.strokeRect(
        x1 * widthScale,
        y1 * heightScale,
        (x2 - x1) * widthScale,
        (y2 - y1) * heightScale,
      );

      const margin = 2;
      const textX = (Math.min(x1, x2) * widthScale) + margin;
      const textY = (Math.min(y1, y2) * heightScale) + margin;
      canvas2d.fillText(symbol, textX, textY);
    },
    handleLoadImage(event) {
      const { naturalHeight, naturalWidth } = event.target;
      this.naturalHeight = naturalHeight;
      this.naturalWidth = naturalWidth;
      this.loaded = true;
      setTimeout(() => {
        this.draw(this.$refs.displayLayer);
      });
    },
    mousedown({ detectionX, detectionY }) {
      this.new = [detectionX, detectionY, detectionX, detectionY];
      this.clear(this.$refs.editLayer);
      this.drawRect(this.$refs.editLayer, { position: this.new, color: '#00f' });
    },
    mousemove({ detectionX, detectionY }) {
      if (this.new) {
        const [x1, y1] = this.new;
        this.new = [
          x1,
          y1,
          detectionX,
          detectionY,
        ];
        this.clear(this.$refs.editLayer);
        this.drawRect(this.$refs.editLayer, { position: this.new, color: '#00f' });
      }
    },
    mouseup({ detectionX, detectionY }, event) {
      if (this.new) {
        this.clear(this.$refs.editLayer);
        const [x1, y1] = this.new;
        this.new = [
          x1,
          y1,
          detectionX,
          detectionY,
        ];
        this.drawRect(this.$refs.editLayer, { position: this.new, color: '#00f' });
        this.$emit('add', [...this.new], event);
        this.new = null;
      }
    },
    pick({ detectionX, detectionY }, event) {
      this.clear(this.$refs.editLayer);

      const { rects } = this.detection;
      const clickedRects = rects.filter((rect) => {
        const { position } = rect;
        return isContain(position, [detectionX, detectionY]);
      });
      if (clickedRects.length > 0) {
        clickedRects.forEach((rect) => {
          this.drawRect(this.$refs.editLayer, {
            ...rect,
            color: '#00f',
            lineWidth: 2.1,
          });
        });
        this.$emit('pick', clickedRects, event);
      }
    },
    showContext(event) {
      const clientX = event.clientX;
      const clientY = event.clientY;
      this.$nextTick(() => {
        this.context = {
          x: clientX,
          y: clientY,
          show: true,
        };
      });
    },
    // public
    draw(canvas = this.$refs.displayLayer) {
      const { rects } = this.detection;
      rects.forEach((rect) => {
        this.drawRect(canvas, rect);
      });
    },
    export: async function (name = 'export.png') {
      const canvas = document.createElement('canvas');
      canvas.setAttribute('height', this.naturalHeight);
      canvas.setAttribute('width', this.naturalWidth);

      const image = await this.loadImage(this.src);
      const canvas2d = canvas.getContext('2d');
      canvas2d.drawImage(image, 0, 0);
      this.draw(canvas);

      await this.wait(10);
      const blob = await this.createBlob(canvas);
      saveAs(blob, name);
    },
    // private
    loadImage(src) {
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.crossOrigin = 'anonymous';
        image.onload = () => resolve(image);
        image.onerror = error => reject(error);
        // to disable cache
        const now = new Date();
        image.src = `${src}?${now.getTime()}`;
      });
    },
    createBlob(canvas) {
      return new Promise((resolve, reject) => {
        try {
          canvas.toBlob((blob) => {
            resolve(blob);
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    wait(msec) {
      return new Promise((resolve) => {
        setTimeout(() => resolve(), msec);
      });
    },
  },
  inject: ['getZoom', 'setZoom'],
  created() {
    this.refresh = _.debounce(() => {
      this.clear(this.$refs.displayLayer);
      this.draw(this.$refs.displayLayer);
    }, 100);
  },
  mounted() {
    this.listener = this._.throttle(() => {
      this.draw();
    }, 1000 / 30);
    window.addEventListener('resize', this.listener);
  },
  destroyed() {
    window.removeEventListener('resize', this.listener);
    this.listener = null;
  },
  watch: {
    detection() {
      this.refresh();
    },
    zoom() {
      this.refresh();
    },
  },
};
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'

.insect-positions-canvas
  position: relative
  display: inline-block

  canvas
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 1
    height: 100%
    width: auto
    display: block

    &.edit-layer
      z-index: 10

  ::v-deep > .loading-image
    height: 100%

    img
      width: auto
      height: 100%
      object-fit: cover

::v-deep.v-menu__content
  position: absolute

  .v-list
    padding: 4px 0

    &-item
      min-height: 24px

::v-deep.zoom:not(.active)
  .zoom-body
    transform: none !important

  .zoom-control-group
    display: none
</style>
