<template>
  <div class="event-video">
    <video-player ref="videoPlayer" :options="playerOptions"/>
    <fullscreen-exit @click="$emit('exitFullscreen')" v-if="fullscreen" ref='exitFullscreenButton'/>
  </div>
</template>

<script>
import { videoPlayer } from 'vue-video-player';
import FullscreenExit from '@/components/atoms/FullscreenExit';

export default {
  name: 'event-video',
  props: {
    fullscreen: {
      type: Boolean,
      default: false,
    },
    thumbnail: String,
    src: String,
  },
  components: {
    videoPlayer,
    FullscreenExit,
  },
  data() {
    return {
      playerOptions: {
        // component options
        start: 0,
        playsinline: true,
        fluid: true,
        // videojs options
        controlBar: {
          remainingTimeDisplay: false,
        },
        muted: true,
        playbackRates: [1.0, 1.5, 2.0],
        inactivityTimeout: 0,
        language: 'ja',
        aspectRatio: '4:3',
        sources: [{
          type: 'video/mp4',
          src: this.src,
        }],
        poster: this.thumbnail,
      },
    };
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  mounted() {
    this.player.on('fullscreenchange', () => {
      if (this.player.isFullscreen()) {
        this.$emit('enterFullscreen');
      } else {
        this.$emit('exitFullscreen');
      }
    });
  },
  beforeDestroy() {
    this.player.off('fullscreenchange');
  },
  watch: {
    fullscreen(value) {
      if (value) {
        this.player.requestFullscreen();
        this.$nextTick().then(() => {
          // Add fullscreen button to video-js container so it can be clicked during fullscreen mode
          document.querySelector('.video-js').appendChild(this.$refs.exitFullscreenButton.$el);
        });
      } else {
        this.player.exitFullscreen();
      }
    },
    src() {
      this.player.src({ type: 'video/mp4', src: this.src });
    },
    thumbnail() {
      this.player.poster(this.thumbnail);
    },
  },
};
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'
$videojs-controlbar-height: 42px

.event-video
  ::v-deep .video-js
    padding-bottom: $videojs-controlbar-height

    .vjs
      &-tech,
      &-poster
        height: calc(100% - #{$videojs-controlbar-height})

      &-loading-spinner,
      &-big-play-button
        top: calc(50% - (#{$videojs-controlbar-height} / 2))

      &-control-bar
        display: flex
        opacity: 1
</style>
