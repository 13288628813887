var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "card-dialog",
    {
      attrs: {
        value: _vm.value,
        title: _vm.$t("label.edit_place"),
        width: 500,
      },
      on: {
        input: function (val) {
          return _vm.$emit("input", val)
        },
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c("dialog-action", { on: { click: _vm.close } }, [
                _vm._v(_vm._s(_vm.$t("button.cancel"))),
              ]),
              _vm._v(" "),
              _c(
                "dialog-action",
                {
                  attrs: {
                    disabled:
                      !_vm.place || _vm.saving || !_vm.valid || _vm.unchanged,
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.save.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.saving
                          ? _vm.$t("button.saving")
                          : _vm.$t("button.save")
                      ) +
                      "\n    "
                  ),
                ]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "v-form",
        {
          ref: "form",
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(
            "form-field",
            { attrs: { label: _vm.$t("field.name") } },
            [
              _c("text-field", {
                attrs: {
                  rules: [_vm.noDuplicates, _vm.required(_vm.$t("field.name"))],
                  "data-testid": "name",
                },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }