var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "card-dialog",
    {
      attrs: {
        persistent: "",
        width: 1000,
        title: _vm.$t("label.rat_appearance"),
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "round-button",
                _vm._g(
                  _vm._b(
                    { attrs: { icon: "bar_chart" } },
                    "round-button",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("button.appearance_graph")) +
                      "\n    "
                  ),
                ]
              ),
            ]
          },
        },
        {
          key: "actions",
          fn: function () {
            return [
              _c("dialog-action", { on: { click: _vm.close } }, [
                _vm._v(_vm._s(_vm.$t("button.close"))),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.dialogVisible,
        callback: function ($$v) {
          _vm.dialogVisible = $$v
        },
        expression: "dialogVisible",
      },
    },
    [
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "rat-graph pa-4" },
        [
          !_vm.ready || !_vm.allEventsLoaded
            ? _c("v-progress-linear", {
                attrs: { indeterminate: !_vm.progress, value: _vm.progress },
              })
            : [
                _c("v-checkbox", {
                  attrs: {
                    label: _vm.$t("label.include_misdetections"),
                    "hide-details": "",
                  },
                  model: {
                    value: _vm.includeMisdetections,
                    callback: function ($$v) {
                      _vm.includeMisdetections = $$v
                    },
                    expression: "includeMisdetections",
                  },
                }),
                _vm._v(" "),
                _c("bar-chart", {
                  attrs: { data: _vm.chartData, options: _vm.chartOptions },
                }),
                _vm._v(" "),
                _c("table", { staticClass: "value-table" }, [
                  _c("tr", [
                    _c("td", { staticClass: "label" }, [
                      _vm._v(_vm._s(_vm.$t("table.total_events"))),
                    ]),
                    _c("td", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.events.length)),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "label" }, [
                      _vm._v(_vm._s(_vm.$t("table.events_with_rats"))),
                    ]),
                    _c("td", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.eventsWithRat.length)),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "label" }, [
                      _vm._v(_vm._s(_vm.$t("table.days_installed"))),
                    ]),
                    _c("td", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.daysInstalled)),
                    ]),
                  ]),
                ]),
              ],
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }