<template>
  <v-alert type="warning" v-if="assumedUser">
    <span>{{ $t('logged_in_as_other_user', { userEmail: assumedUser.email }) }}</span>
  </v-alert>
</template>

<i18n lang="yaml">
  ja:
    logged_in_as_other_user: '{userEmail}としてログイン中'

  en:
    logged_in_as_other_user: 'You are logged in as {userEmail}'
</i18n>

<script>
export default {
  name: 'LoggedInAsOtherUserWarning',
  computed: {
    assumedUser() {
      return this.$store.getters.assumedUser;
    },
  },
};
</script>

<style scoped lang="sass">
.v-alert
  z-index: 1000
  position: fixed
  bottom: 20px
  left: 25%
  right: 25%
</style>
