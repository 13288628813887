var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "div",
        { staticClass: "sensor-form" },
        [
          _c(
            "page-title-area",
            {
              scopedSlots: _vm._u(
                [
                  {
                    key: "right-floating-content",
                    fn: function () {
                      return [
                        !_vm.new_record
                          ? _c("counter", { attrs: { count: _vm.count } })
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                1478667609
              ),
            },
            [
              _vm.new_record
                ? _c("entity-header", { attrs: { icon: _vm.sensor.type } }, [
                    _vm._v(
                      "\n      " + _vm._s(_vm.$t("label.new_sensor")) + "\n    "
                    ),
                  ])
                : _c(
                    "entity-header",
                    {
                      attrs: { details: _vm.sensor.place },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "icon",
                            fn: function () {
                              return [
                                _c("sensor-icon", {
                                  attrs: { sensor: _vm.sensor },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        2041488927
                      ),
                    },
                    [_vm._v("\n      " + _vm._s(_vm.sensor.name) + "\n    ")]
                  ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "sensor-form__content",
              attrs: { "data-testid": "SensorForm" },
            },
            [
              _c(
                "div",
                { staticClass: "sensor-form__form my-3" },
                [
                  _c(
                    "v-form",
                    {
                      ref: "form",
                      model: {
                        value: _vm.valid,
                        callback: function ($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid",
                      },
                    },
                    [
                      _c("form-group", {
                        attrs: { required: _vm.state.editting },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("field.identifier"))),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "input",
                              fn: function () {
                                return [
                                  _c("text-field", {
                                    staticClass: "sensor-form__form__id",
                                    attrs: {
                                      value: _vm.sensorId,
                                      edit:
                                        _vm.state.editting && _vm.new_record,
                                      rules: _vm.rules.id,
                                      hint: _vm.$t("hint.identifier"),
                                      errorMessages: _vm.state.idErrors,
                                    },
                                    on: { input: _vm.updateId },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          2603779422
                        ),
                      }),
                      _vm._v(" "),
                      _vm.capabilities.receives_mails && !_vm.new_record
                        ? _c("form-group", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("field.mail_address"))
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "input",
                                  fn: function () {
                                    return [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(_vm.sensor.id) +
                                            "-" +
                                            _vm._s(_vm.sensor.mail_hash) +
                                            "@sensor." +
                                            _vm._s(_vm.domain)
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              3637841135
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("form-group", {
                        attrs: { required: _vm.state.editting },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("field.name"))),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "input",
                              fn: function () {
                                return [
                                  _c("text-field", {
                                    attrs: {
                                      edit: _vm.state.editting,
                                      rules: _vm.rules.name,
                                    },
                                    model: {
                                      value: _vm.model.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.model, "name", $$v)
                                      },
                                      expression: "model.name",
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          2404166898
                        ),
                      }),
                      _vm._v(" "),
                      _c("form-group", {
                        attrs: { required: _vm.state.editting },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("field.installation_site"))
                                    ),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "input",
                              fn: function () {
                                return [
                                  _c("text-field", {
                                    attrs: {
                                      edit: _vm.state.editting,
                                      rules: _vm.rules.place,
                                    },
                                    model: {
                                      value: _vm.model.place,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.model, "place", $$v)
                                      },
                                      expression: "model.place",
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          2966132349
                        ),
                      }),
                      _vm._v(" "),
                      _c("form-group", {
                        attrs: { required: _vm.state.editting },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("field.installation_date"))
                                    ),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "input",
                              fn: function () {
                                return [
                                  _c("date-field", {
                                    attrs: {
                                      displayFormat: "YYYY/MM/DD",
                                      edit: _vm.state.editting,
                                      rules: _vm.rules.installed_on,
                                    },
                                    model: {
                                      value: _vm.model.installed_on,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.model, "installed_on", $$v)
                                      },
                                      expression: "model.installed_on",
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          2618952740
                        ),
                      }),
                      _vm._v(" "),
                      _vm.capabilities.threshold
                        ? _c("form-group", {
                            attrs: { required: _vm.state.editting },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("field.threshold"))
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "input",
                                  fn: function () {
                                    return [
                                      _c("text-field", {
                                        attrs: {
                                          type: "number",
                                          edit: _vm.state.editting,
                                          rules: _vm.rules.threshold,
                                        },
                                        model: {
                                          value: _vm.model.threshold,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.model,
                                              "threshold",
                                              $$v
                                            )
                                          },
                                          expression: "model.threshold",
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              708695631
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.capabilities.threshold
                        ? _c("form-group", {
                            attrs: { required: _vm.state.editting },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("field.increase_threshold")
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "input",
                                  fn: function () {
                                    return [
                                      _c("text-field", {
                                        attrs: {
                                          type: "number",
                                          edit: _vm.state.editting,
                                          rules: _vm.rules.increase_threshold,
                                        },
                                        model: {
                                          value: _vm.model.increase_threshold,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.model,
                                              "increase_threshold",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "model.increase_threshold",
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              4267807892
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.capabilities.replacement
                        ? _c("form-group", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("field.trap_replaced_on")
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "input",
                                  fn: function () {
                                    return [
                                      _c("date-field", {
                                        attrs: {
                                          displayFormat: "YYYY/MM/DD",
                                          clearable: "",
                                          edit: _vm.state.editting,
                                        },
                                        model: {
                                          value: _vm.model.trap_replaced_on,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.model,
                                              "trap_replaced_on",
                                              $$v
                                            )
                                          },
                                          expression: "model.trap_replaced_on",
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              4131575310
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.capabilities.replacement
                        ? _c("form-group", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("field.trap_effective_days")
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "input",
                                  fn: function () {
                                    return [
                                      _c("text-field", {
                                        attrs: {
                                          type: "number",
                                          edit: _vm.state.editting,
                                          rules: _vm.rules.trap_effective_days,
                                        },
                                        model: {
                                          value: _vm.model.trap_effective_days,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.model,
                                              "trap_effective_days",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "model.trap_effective_days",
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              3643889997
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.capabilities.photoInterval
                        ? _c("form-group", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("field.photo_interval"))
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "input",
                                  fn: function () {
                                    return [
                                      _c("photo-interval-select-field", {
                                        attrs: { edit: _vm.state.editting },
                                        model: {
                                          value: _vm.model.photo_interval,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.model,
                                              "photo_interval",
                                              $$v
                                            )
                                          },
                                          expression: "model.photo_interval",
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              2425874795
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("form-group", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("field.memo"))),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "input",
                              fn: function () {
                                return [
                                  _c("text-field", {
                                    attrs: { edit: _vm.state.editting },
                                    model: {
                                      value: _vm.model.memo,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.model, "memo", $$v)
                                      },
                                      expression: "model.memo",
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3633202189
                        ),
                      }),
                      _vm._v(" "),
                      !_vm.admin
                        ? [
                            _vm.state.editting || _vm.shareable
                              ? _c("form-group", {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("label.notifications")
                                                )
                                              ),
                                            ]),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "input",
                                        fn: function () {
                                          return [
                                            _c("checkbox", {
                                              attrs: {
                                                label: _vm.$t(
                                                  "notification.by_email"
                                                ),
                                                edit: _vm.state.editting,
                                              },
                                              model: {
                                                value: _vm.model.notice,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.model,
                                                    "notice",
                                                    $$v
                                                  )
                                                },
                                                expression: "model.notice",
                                              },
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    3249351046
                                  ),
                                })
                              : _vm._e(),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.capabilities.health_check
                        ? _c("form-group", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("label.health_check"))
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "input",
                                  fn: function () {
                                    return [
                                      _c(
                                        "div",
                                        [
                                          _c("checkbox", {
                                            attrs: {
                                              label: _vm.$t(
                                                "field.health_check_required"
                                              ),
                                              edit: _vm.state.editting,
                                            },
                                            model: {
                                              value: _vm.model.health_check,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.model,
                                                  "health_check",
                                                  $$v
                                                )
                                              },
                                              expression: "model.health_check",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("sensor-status", {
                                            staticClass: "mt-1",
                                            attrs: {
                                              status:
                                                _vm.sensor.health_check_status,
                                              canDownload: _vm.admin,
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "v-row",
                                            {
                                              staticClass: "mt-3",
                                              attrs: {
                                                justify: "start",
                                                "no-gutters": "",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "mr-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "label.last_active"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.formatTimestamp(
                                                      _vm.sensor.last_active,
                                                      "YYYY/MM/DD HH:mm"
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              3679550597
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.new_record && _vm.sensor.created_by.name
                        ? _c("form-group", {
                            attrs: { "data-testid": "field_created-by" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("field.created_by"))
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "input",
                                  fn: function () {
                                    return [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(_vm.sensor.created_by.name) +
                                          "\n          "
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              946687854
                            ),
                          })
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "sensor-form__btn-group" },
                [
                  _vm.state.editting
                    ? [
                        _c(
                          "round-button",
                          {
                            staticClass:
                              "sensor-form__btn sensor-form__save my-3",
                            attrs: {
                              icon: "fa-check-circle",
                              fullWidth: "",
                              disabled: _vm.state.checkingId,
                              "data-testid": "save",
                            },
                            on: { click: _vm.onSave },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("button.save")) +
                                "\n        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "round-button",
                          {
                            staticClass: "sensor-form__btn my-3",
                            attrs: {
                              icon: "fa-arrow-circle-left",
                              fullWidth: "",
                            },
                            on: { click: _vm.onCancel },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("button.cancel")) +
                                "\n        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        !_vm.new_record
                          ? _c(
                              "round-button",
                              {
                                staticClass: "sensor-form__btn my-3",
                                attrs: { icon: "fa-trash-o", fullWidth: "" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.confirmDelete.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.$t("button.delete")) +
                                    "\n        "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    : [
                        _vm.editable
                          ? _c(
                              "round-button",
                              {
                                staticClass:
                                  "sensor-form__btn sensor-form__edit my-3",
                                attrs: {
                                  icon: "fa-pencil-square-o",
                                  fullWidth: "",
                                  "data-testid": "edit",
                                },
                                on: { click: _vm.onEdit },
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.$t("button.edit")) +
                                    "\n        "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.editable
                          ? _c(
                              "round-button",
                              {
                                staticClass: "sensor-form__btn my-3",
                                attrs: { icon: "fa-trash-o", fullWidth: "" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.confirmDelete.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.$t("button.delete")) +
                                    "\n        "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "round-button",
                          {
                            staticClass: "sensor-form__btn my-3",
                            attrs: {
                              icon: "fa-chevron-circle-down",
                              fullWidth: "",
                              to: "/activities?sensorId=" + _vm.sensor.id,
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$t("button.show_all", {
                                    dataType: _vm.getTerm(
                                      _vm.sensorDefinition.dataType,
                                      { plural: true }
                                    ),
                                  })
                                ) +
                                "\n        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.uploadable
                          ? _c(
                              "upload-button",
                              {
                                attrs: {
                                  fullWidth: "",
                                  acceptedFiletypes: _vm.acceptedFiletypes,
                                  sensorId: _vm.sensor.id,
                                },
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      _vm.$t("button.upload", {
                                        dataType: _vm.getTerm(
                                          _vm.sensorDefinition.dataType
                                        ),
                                      })
                                    ) +
                                    "\n        "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.capabilities.receives_mails && _vm.editable
                          ? _c(
                              "round-button",
                              {
                                staticClass: "sensor-form__btn my-3",
                                attrs: { icon: "fa-refresh", fullWidth: "" },
                                nativeOn: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.confirmGenerateMail.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(_vm.$t("button.generate_mail")) +
                                    "\n        "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.showConnectionLogsButton
                          ? _c(
                              "round-button",
                              {
                                staticClass: "sensor-form__btn my-3",
                                attrs: {
                                  icon: "fa-list",
                                  fullWidth: "",
                                  to:
                                    "/admin/activity_logs/Sensor-" +
                                    _vm.sensor.id,
                                },
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      _vm.$t("button.show_connection_logs")
                                    ) +
                                    "\n        "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.shareable
                          ? _c("sensor-user-list", {
                              attrs: { sensorId: _vm.sensor.id },
                            })
                          : _vm._e(),
                      ],
                ],
                2
              ),
            ]
          ),
          _vm._v(" "),
          _c("confirm-dialog", {
            attrs: { message: _vm.confirmDialog.message },
            on: { yes: _vm.confirmDialog.onConfirm },
            model: {
              value: _vm.confirmDialog.visible,
              callback: function ($$v) {
                _vm.$set(_vm.confirmDialog, "visible", $$v)
              },
              expression: "confirmDialog.visible",
            },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }