var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dot-selector" },
    _vm._l(_vm.size, function (_n, i) {
      return _c("div", {
        key: i,
        staticClass: "dot-selector__item",
        class: { "dot-selector__item--active": i === _vm.value },
        on: {
          click: function ($event) {
            return _vm.handleClick(i)
          },
        },
      })
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }