var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { staticClass: "pest-vision v-application v-application--is-ltr" },
    [
      _c("app-bar", { attrs: { admin: _vm.admin } }),
      _vm._v(" "),
      _c(
        "main",
        { staticClass: "pest-vision__main" },
        [
          _c(
            "v-container",
            {
              staticClass: "pest-vision__container",
              attrs: { fluid: "", "py-10": "" },
            },
            [_c("router-view")],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("bottom-navigation"),
      _vm._v(" "),
      _c("scroll-button"),
      _vm._v(" "),
      _c("notification"),
      _vm._v(" "),
      _c("pending-download"),
      _vm._v(" "),
      _c("logged-in-as-other-user-warning"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }