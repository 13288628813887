<template>
  <query-filter
    :loading="loading"
    :applyable="validPeriod"
    clearable
    @apply="$emit('submit', params)" 
    @clear="$emit('clear')"
  >
    <div class="d-flex align-baseline justify-center">
      <div class="mx-2">{{$t('period')}}</div>
      <div class="mx-2"><month-field v-model="params.from" /></div>
      <div class="mx-2">〜</div>
      <div class="mx-2"><month-field v-model="params.to" /></div>
    </div>
    <div class="d-flex align-center justify-center">
      <div class="error--text">
        <p class="mt-2 mb-0" v-if="errorMessage">{{errorMessage}}</p>
      </div>
    </div>
  </query-filter>
</template>

<i18n lang="yaml">
ja:
  period: '期間'
  err_period: '指定できる期間は最長３年間です'
  err_from: '期間の始まりを指定してください'

en:
  period: 'Period'
  err_period: 'You can specify a maximum period of 3 years'
  err_from: 'Please specify the beginning of the term'
</i18n>

<script>
import moment from 'moment';

import MonthField from '@/components/molecules/MonthField';
import QueryFilter from '@/components/molecules/QueryFilter';

export default {
  name: 'event-period-selector',
  components: {
    MonthField,
    QueryFilter,
  },
  props: {
    initialValue: Object,
    loading: Boolean,
  },
  computed: {
    errorMessage() {
      if (this.validPeriod) {
        return null;
      }
      if (!this.params.from) {
        return this.$t('err_from');
      }
      return this.$t('err_period');
    },
    validPeriod() {
      if (this.params.from !== null && this.params.to !== null) {
        const from = moment(this.params.from, 'YYYY-MM');
        const to = moment(this.params.to, 'YYYY-MM');
        return to.isSameOrAfter(from) && Math.abs(to.year() - from.year()) <= 2;
      }
      return this.params.from !== null && this.params.to === null;
    },
  },
  data() {
    return {
      params: this.initialValue,
    };
  },
};
</script>

<style lang="sass" scoped>
</style>
