<template>
  <v-row align="center" no-gutters>
    <slot name="icon">
      <icon :type="icon" v-if="icon" />
    </slot>
    <v-col class="d-md-flex align-md-center">
      <h1 class="headline primary--text" :class="{ 'ml-2': hasIcon }"><slot /></h1>
      <span class="ml-2" v-if="details">{{ details }}</span>
    </v-col>
  </v-row>
</template>

<script>
import Icon from '@/components/Icon';

export default {
  name: 'entity-header',
  components: {
    Icon,
  },
  props: {
    icon: {
      type: String,
      required: false,
    },
    details: {
      type: String,
      required: false,
    },
  },
  computed: {
    hasIcon() {
      return this.icon || this.$slots.icon;
    },
  },
};
</script>

<style lang="sass" scoped>
</style>
