<template>
  <v-snackbar
    class="notification"
    top
    :color="type"
    :timeout="timeout"
    v-model="visible"
  >{{ notification.message }}</v-snackbar>
</template>

<script>
export default {
  name: 'notification',
  computed: {
    notification() {
      return this.$store.getters.getNotification;
    },
    timeout() {
      return this.notification.timeout || 6000;
    },
    type() {
      if (['success', 'error', 'info', 'warning'].includes(this.notification.type)) {
        return this.notification.type;
      }
      return 'info';
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  filters: {
    trim(str = '') {
      return str.trim();
    },
  },
  watch: {
    notification(val) {
      if (val.message) {
        this.visible = true;
      }
    },
    visible(val) {
      if (!val) {
        this.$store.dispatch('hideNotification');
      }
    },
  },
};
</script>

<style scoped lang="sass">
.notification
  top: 50px
  height: auto

  ::v-deep .v-snack__content
    height: auto
    white-space: pre-line
</style>
