export default {
  choubae_ka: require('./choubae_ka.jpg'),
  nomibae_ka: require('./nomibae_ka.jpg'),
  shoujoubae_ka: require('./shoujoubae_ka.jpg'),
  yusurika_ka: require('./yusurika_ka.jpg'),
  kurobanekinokobae_ka: require('./kurobanekinokobae_ka.jpg'),
  haneari_rui: require('./haneari_rui.jpg'),
  shoga_rui: require('./shoga_rui.jpg'),
  shibanmushi_ka: require('./shibanmushi_ka.jpg'),
  ka_ka: require('./ka_ka.jpg'),
  oogatabae_rui: require('./oogatabae_rui.jpg'),
  funkobae_ka: require('./funkobae_ka.jpg'),
  nisekebae_ka: require('./nisekebae_ka.jpg'),
  tobigera_moku: require('./tobigera_moku.jpg'),
  gaganbo_rui: require('./gaganbo_rui.jpg'),
  hanekakushi_ka: require('./hanekakushi_ka.jpg'),
  nukaka_ka: require('./nukaka_ka.jpg'),
  tamabae_ka: require('./tamabae_ka.jpg'),
  katsuobushimushi_ka: require('./katsuobushimushi_ka.jpg'),
  chatatemushi_rui: require('./chatatemushi_rui.jpg'),
  kogatahachi_rui: require('./kogatahachi_rui.jpg'),
  aburamushi: require('./aburamushi.jpg'),
  unnka_yokobai: require('./unnka_yokobai.jpg'),
  azamiuma: require('./azamiuma.jpg'),
  kinokobae: require('./kinokobae.jpg'),
  togehanebae: require('./togehanebae.jpg'),
}
