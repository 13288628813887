var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.site
    ? _c(
        "div",
        [
          _c(
            "page-title-area",
            [
              _c(
                "entity-header",
                {
                  attrs: {
                    details: "(" + _vm.SENSOR_TYPE_NAMES[_vm.site.type] + ")",
                  },
                },
                [_vm._v("\n      " + _vm._s(_vm.site.name) + "\n    ")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("entity-form", {
            scopedSlots: _vm._u(
              [
                {
                  key: "form",
                  fn: function (ref) {
                    var editing = ref.editing
                    return [
                      _c("form-group", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("field.name"))),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "input",
                              fn: function () {
                                return [
                                  _c("text-field", {
                                    attrs: { edit: editing },
                                    model: {
                                      value: _vm.model.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.model, "name", $$v)
                                      },
                                      expression: "model.name",
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _vm._v(" "),
                      _vm.hasThreshold
                        ? [
                            _c("form-group", {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.$t("field.threshold"))
                                          ),
                                        ]),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                  {
                                    key: "input",
                                    fn: function () {
                                      return [
                                        _c("text-field", {
                                          attrs: {
                                            type: "number",
                                            edit: editing,
                                          },
                                          model: {
                                            value: _vm.model.threshold,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.model,
                                                "threshold",
                                                $$v
                                              )
                                            },
                                            expression: "model.threshold",
                                          },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _vm._v(" "),
                            _c("form-group", {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("field.increase_threshold")
                                            )
                                          ),
                                        ]),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                  {
                                    key: "input",
                                    fn: function () {
                                      return [
                                        _c("text-field", {
                                          attrs: {
                                            type: "number",
                                            edit: editing,
                                          },
                                          model: {
                                            value: _vm.model.increase_threshold,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.model,
                                                "increase_threshold",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "model.increase_threshold",
                                          },
                                        }),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _c("form-group", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("field.memo"))),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "input",
                              fn: function () {
                                return [
                                  _c("text-field", {
                                    attrs: { edit: editing },
                                    model: {
                                      value: _vm.model.memo,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.model, "memo", $$v)
                                      },
                                      expression: "model.memo",
                                    },
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _vm._v(" "),
                      !_vm.admin
                        ? _c("form-group", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("field.email_notifications")
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "input",
                                  fn: function () {
                                    return [
                                      _c("checkbox", {
                                        attrs: { edit: editing },
                                        model: {
                                          value:
                                            _vm.model.receive_notifications,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.model,
                                              "receive_notifications",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "model.receive_notifications",
                                        },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("form-group", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("field.sensor"))),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "input",
                              fn: function () {
                                return [
                                  _vm.site.sensor
                                    ? _c("div", [
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                attrs: {
                                                  to: _vm.sensorRouterLinkTarget(
                                                    _vm.site.sensor.id
                                                  ),
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                " +
                                                    _vm._s(
                                                      _vm.site.sensor.name
                                                    ) +
                                                    "\n              "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("div", [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "font-weight-bold mr-2",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("label.installed_on")
                                                ) + ":"
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.sensorInstalledOn)
                                            ),
                                          ]),
                                        ]),
                                        _vm._v(" "),
                                        _vm.sensorSupportsHealthCheck
                                          ? _c(
                                              "div",
                                              [
                                                _vm.sensor
                                                  ? _c(
                                                      "div",
                                                      [
                                                        _c("sensor-status", {
                                                          staticClass: "mt-1",
                                                          attrs: {
                                                            status:
                                                              _vm.sensor
                                                                .health_check_status,
                                                            canDownload:
                                                              _vm.admin,
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-row",
                                                          {
                                                            staticClass: "mt-3",
                                                            attrs: {
                                                              justify: "start",
                                                              "no-gutters": "",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "font-weight-bold mr-2",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "label.last_active"
                                                                    )
                                                                  ) + ":"
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "span",
                                                              {
                                                                attrs: {
                                                                  "data-testid":
                                                                    "sensor_last_active",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.sensorLastActive
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : _c("v-progress-circular", {
                                                      staticClass:
                                                        "primary--text",
                                                      attrs: {
                                                        indeterminate: "",
                                                        size: 40,
                                                      },
                                                    }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _vm._v(" "),
                      _vm.admin
                        ? _c("form-group", {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("field.organization_name")
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                  proxy: true,
                                },
                                {
                                  key: "input",
                                  fn: function () {
                                    return [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "admin-organization",
                                              params: {
                                                id: _vm.site.organization.id,
                                              },
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n            " +
                                              _vm._s(
                                                _vm.site.organization.name
                                              ) +
                                              "\n          "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          })
                        : _vm._e(),
                    ]
                  },
                },
              ],
              null,
              false,
              1040569125
            ),
          }),
        ],
        1
      )
    : _c("page-load-spinner")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }