<template>
  <div>
    <page-title-area>
      <h1>{{ title }}</h1>
      <template #side-content>
        <v-text-field
          class="search-field py-0"
          prepend-icon="search"
          hide-details
          :placeholder="searchHint"
          :value="query"
          @input="value => $emit('update:query', value)"
          data-testid="search"
          v-if="searchHint"
        />
        <slot name="title-area-content" />
      </template>
      <template #right-floating-content>
        <counter :count="count" />
      </template>
    </page-title-area>
    <slot />
  </div>
</template>

<script>
import PageTitleArea from '@/components/atoms/PageTitleArea';
import Counter from '@/components/ContentHeader/Counter'; // TODO: Make Counter to atom

export default {
  name: 'entity-list-layout',
  components: {
    Counter,
    PageTitleArea,
  },
  props: {
    title: String,
    count: Number,
    query: {
      type: String,
      required: false,
    },
    searchHint: {
      type: String,
      required: false,
    },
  },
};
</script>

<style scoped lang="sass">
.search-field
  min-width: 240px
</style>
