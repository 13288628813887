<template>
  <entity-list-layout
    class="admin-organization-list"
    title="ORGANIZATION"
    :count="organizations.length"
    :query.sync="search"
    :search-hint="$t('label.search')"
  >
    <template #title-area-content>
      <round-button class="my-2 mx-md-2" icon="add_circle" to="/admin/organizations/new">
        {{ $t('label.add_organization') }}
      </round-button>
    </template>
    <p-data-list :data="organizations" :pagination.sync="pagination">
      <data-field name="No" headerClass="column--no">
        <template v-slot="props">{{ props.index + 1 }}</template>
      </data-field>
      <data-field :name="$t('field.name')" value="name" sortable>
        <template v-slot="props">
          <router-link :to="{ name: 'admin-organization', params: { id: props.item.id }}">
            {{ props.item.name }}
          </router-link>
        </template>
      </data-field>
      <data-field :name="$t('field.address')" value="address" sortable>
        <template v-slot="props">{{ props.item.address }}</template>
      </data-field>
      <data-field :name="$t('field.phone_number')" value="phone_number" headerClass="column--phone-number" sortable>
        <template v-slot="props">{{ props.item.phone_number }}</template>
      </data-field>
    </p-data-list>
  </entity-list-layout>
</template>

<i18n lang="yaml">
ja:
  label:
    search: '文字列で検索'
    add_organization: '組織を追加'

  field:
    name: '組織名'
    address: '住所'
    phone_number: 'TEL'

en:
  label:
    search: 'Search by text'
    add_organization: 'Add organization'

  field:
    name: 'Organization name'
    address: 'Address'
    phone_number: 'TEL'
</i18n>

<script>
import EntityListLayout from '@/components/atoms/EntityListLayout';
import RoundButton from '@/components/atoms/RoundButton';
import DataList, { Field } from '../DataList';

export default {
  name: 'admin-organization-list',
  beforeCreate() {
    this.$store.dispatch('requestOrganizations');
  },
  components: {
    DataField: Field,
    EntityListLayout,
    PDataList: DataList,
    RoundButton,
  },
  computed: {
    organizations() {
      const organizations = this.$store.getters.getOrganizations;
      const lowerSearchText = this.search.toLowerCase();
      return organizations.filter((organization) => (
        this.organizationContains(lowerSearchText, organization)
      )).toArray();
    },
  },
  data() {
    return {
      pagination: {
        itemsPerPage: 20,
        sortBy: 'name',
      },
      search: '',
    };
  },
  methods: {
    organizationContains(keywords, organization) {
      // eslint-disable-next-line arrow-body-style
      return this._.every(keywords.split(' '), (keyword) => {
        return this._.some([
          organization.name,
          organization.address,
          organization.phone_number,
        ], (value) => {
          const lowerValue = value.toLowerCase();
          return this._.includes(lowerValue, keyword.toLowerCase());
        });
      });
    },
  },
};
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'

.admin-organization-list
  ::v-deep [role=columnheader]
    width: 50%

  ::v-deep .column
    &--no
      width: 48px

    &--phone-number
      min-width: 140px
</style>
