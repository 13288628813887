<template>
  <div :class="{ fullscreen: value }">
    <slot />
    <fullscreen-exit @click.stop="$emit('input', false)" v-if="value" />
  </div>
</template>

<script>
import FullscreenExit from './FullscreenExit';

export default {
  name: 'fullscreen',
  components: {
    FullscreenExit,
  },
  props: {
    value: Boolean,
  },
};
</script>

<style scoped lang="sass">
.fullscreen
  background-color: black
  margin: 0
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  z-index: 1000
  display: flex
  align-items: center
  justify-content: center

  ::v-deep > :not(.fullscreen-exit)
    width: 100%
    height: 100%

  ::v-deep img,
  ::v-deep canvas
    margin: auto
    height: 100%
    object-fit: contain
</style>
