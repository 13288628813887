var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "insect-view-detections-editor" },
    [
      _c("insect-positions-canvas", {
        ref: "canvas",
        attrs: {
          src: _vm.imageSrc,
          mode: _vm.editMode,
          detection: _vm.detection,
          disablePanZoom: _vm.editMode != "control",
          "disable-context": "",
        },
        on: { pick: _vm.showForm, add: _vm.addDetection },
      }),
      _vm._v(" "),
      _c("insect-type-picker", {
        ref: "form",
        attrs: {
          disabled: _vm.editMode == "control",
          withInsectTypes: _vm.options.withInsectTypes,
        },
        on: { change: _vm.onChangeDetections, close: _vm.hideForm },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }