<template>
  <insect-positions-canvas ref="canvas" :imageSrc="imageSrc" :positions="pickedDetections" :hideCanvas="hideCanvas || !imageSrc">
    <slot/>
  </insect-positions-canvas>
</template>

<script>
import InsectPositionsCanvas from '@/components/_legacy/InsectPositionsCanvas';

export default {
  name: 'picked-insects-draw',
  components: {
    InsectPositionsCanvas,
  },
  props: {
    imageSrc: String,
    pickedDetectionType: String,
    currentPhotoKey: String,
    hideCanvas: Boolean,
    event: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    detectionsUrl() {
      if (!this.event || !this.currentPhotoKey) {
        return null;
      }
      return `/api/v2/events/${this.event.id}/insect_detections/${this.currentPhotoKey}`;
    },
    pickedDetections() {
      if (this.pickedDetectionType === 'all') {
        return this.detections;
      }
      return this.detections.filter(x => x.type === this.pickedDetectionType);
    },
  },
  data() {
    return {
      detections: [],
    };
  },
  methods: {
    retrieveDetectionResult: async function () {
      if (this.imageSrc && this.detectionsUrl) {
        this.detections = [];
        const response = await this.$http.get(this.detectionsUrl);
        this.detections = response.data.positions;
      } else {
        this.detections = [];
      }
    },
  },
  mounted() {
    this.$watch(
      () => [this.event, this.currentPhotoKey],
      () => this.retrieveDetectionResult(),
    );
    this.retrieveDetectionResult();
  },
  watch: {
    pickedDetectionType() {
      // 選択されている虫が変わるたびに描画を更新する必要がある
      this.$refs.canvas.refresh();
    },
  },
};
</script>
