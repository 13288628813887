var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "insect-type-snippets my-n2" },
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        _vm._l(_vm.snippets, function (snippet, index) {
          return _c(
            "v-col",
            {
              key: snippet.name,
              staticClass: "snippet px-3 my-2",
              attrs: { cols: "4" },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex align-center text-nowrap mb-1" },
                [
                  _c("span", {
                    staticClass: "insect-color",
                    style: { backgroundColor: _vm.colors[index] },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "insect-name ml-1" }, [
                    _vm._v(_vm._s(snippet.name)),
                  ]),
                  _vm._v(" "),
                  snippet.type
                    ? _c("icon-button", {
                        staticClass: "insect-info ml-auto",
                        attrs: { small: "", icon: "info" },
                        on: {
                          click: function ($event) {
                            return _vm.showInsectInfo(snippet.type)
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "sample-image mb-1" }, [
                _vm.insectImages[snippet.type]
                  ? _c("img", {
                      attrs: { src: _vm.insectImages[snippet.type] },
                    })
                  : _c("span", [_vm._v("No image")]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex align-center justify-space-between" },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex align-center justify-start" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "route",
                          style: {
                            backgroundColor: _vm.routeColors[snippet.route],
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("route." + snippet.route)) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.getCauses(snippet.type), function (cause) {
                        return _c("insect-cause-icon", {
                          key: cause,
                          attrs: { size: "18", cause: cause },
                        })
                      }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm._f("numeric")(snippet.count)))]),
                ]
              ),
            ]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "card-dialog",
        {
          attrs: { width: "600px" },
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function () {
                return [
                  _c(
                    "dialog-action",
                    {
                      on: {
                        click: function ($event) {
                          _vm.insectExplanationVisible = false
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.close")))]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.insectExplanationVisible,
            callback: function ($$v) {
              _vm.insectExplanationVisible = $$v
            },
            expression: "insectExplanationVisible",
          },
        },
        [
          _c("insect-description", {
            attrs: { "insect-type": _vm.insectExplanationType },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }