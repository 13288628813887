<template>
  <v-btn text class="gallery-view-fullscreen-enter" @click.native.stop="$emit('input', true)" v-if='!value'>
    <v-icon>fa-expand</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'GalleryViewFullscreenEnter',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="sass">
.gallery-view-fullscreen-enter
  min-width: 24px
</style>
