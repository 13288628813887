<!--
  ポーリングの処理を隔離するコンポーネント。

  使い方：
  <polling-event :input="inputValue" :updateFunction="pollNewValue">
    <template scope="props">
      <other-component :value="props.currentValue"/>
    </template>
  </polling-event>
-->

<template>
  <div>
    <slot :currentValue="currentValue"/>
  </div>
</template>

<script>
export default {
  name: 'PollingChannel',
  props: {
    input: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,  // ポーリングするかどうか
      default: true,
    },
    updateFunction: {
      type: Function,  // inputの最新の値を取得し、返す
      required: true,
    },
    interval: {
      type: Number,
      default: 10000,
    },
  },
  data() {
    return {
      currentValue: this.input,
      timeoutId: null,
    };
  },
  destroyed() {
    this.stop();
  },
  methods: {
    async updateValue() {
      if (this.timeoutId) {
        this.currentValue = await this.updateFunction();
        this.$emit('valueUpdated', this.currentValue);
        if (this.timeoutId) {
          this.start();
        }
      }
    },
    start() {
      this.timeoutId = setTimeout(this.updateValue, this.interval);
    },
    stop() {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
        this.timeoutId = null;
      }
    },
  },
  mounted() {
    if (this.active) {
      this.start();
    }
  },
  watch: {
    active(value) {
      if (value) {
        this.start();
      } else {
        this.stop();
      }
    },
    input(value) {
      this.currentValue = value;
    },
  },
};
</script>
