import Vue from 'vue';
import VueI18n from 'vue-i18n';

import mixinLocale from './common/locale';

Vue.use(VueI18n);

const messages = {
  ja: {
    menu: {
      activity: 'アクティビティ',
      sensors: 'センサー',
      station: 'S型',
      information: 'インフォメーション',
      mirakun_device: '自動投薬機',
      sensor_list: 'センサー一覧',
      account_list: 'アカウント一覧',
      organization_list: '組織一覧',
      account: 'アカウント情報',
      logout: 'ログアウト',
      organization: '組織情報',
      installation_sites: '設置場所',
      installation_site_list: '設置場所一覧',
    },
  },
  en: {
    menu: {
      activity: 'Activity',
      sensors: 'Sensors',
      station: 'S-type',
      information: 'Information',
      mirakun_device: 'Chemical treatment system',
      sensor_list: 'Sensor List',
      account_list: 'Account List',
      organization_list: 'Organization List',
      account: 'Account',
      logout: 'Log out',
      organization: 'Organization',
      installation_sites: 'Installation Sites',
      installation_site_list: 'Installation Site List',
    },
  },
};

const i18n = new VueI18n({ locale: 'en', messages });

export default {
  props: {
    admin: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentUser() {
      return this.$store.getters.getCurrentUser;
    },
    userName() {
      return this.currentUser.name;
    },
    accountPath() {
      return this.admin ? `/admin/accounts/${this.currentUser.email}` : '/account';
    },
    organizationPath() {
      return this.admin ? `/admin/organizations/${this.currentUser.owner_id}` : '/organization';
    },
    accountLabel() {
      return i18n.t('menu.account', this.i18n_locale);
    },
    organizationLabel() {
      return i18n.t('menu.organization', this.i18n_locale);
    },
    logoutLabel() {
      return i18n.t('menu.logout', this.i18n_locale);
    },
    items() {
      if (this.admin) {
        return [
          { title: i18n.t('menu.sensor_list', this.i18n_locale), path: '/admin/sensors' },
          { title: i18n.t('menu.installation_site_list', this.i18n_locale), path: '/admin/installation_sites' },
          { title: i18n.t('menu.organization_list', this.i18n_locale), path: '/admin/organizations' },
          { title: i18n.t('menu.account_list', this.i18n_locale), path: '/admin/accounts' },
        ];
      }

      const canSeeSType = this.$store.getters.hasPermission('station');
      const canSeeMirakunDevice = this.$store.getters.hasPermission('mirakun_device') &&
            this.currentUser.mirakun_user_id;
      const canSeeInstallationSites = this.currentUser.active_features.includes('sensor_installation_sites');

      return this._.compact([
        { title: i18n.t('menu.activity', this.i18n_locale), path: '/activities' },
        canSeeSType && { title: i18n.t('menu.station', this.i18n_locale), path: '/station' },
        canSeeMirakunDevice && { title: i18n.t('menu.mirakun_device', this.i18n_locale), path: '/mirakun_device' },
        { title: i18n.t('menu.sensors', this.i18n_locale), path: '/sensors' },
        canSeeInstallationSites && { title: i18n.t('menu.installation_sites', this.i18n_locale), path: '/installation_sites' },
        { title: i18n.t('menu.information', this.i18n_locale), path: '/information' },
      ]);
    },
    isSignedInAsAnotherUser() {
      return !!this.$store.getters.assumedUser;
    },
  },
  inject: ['resetIdentity'],
  methods: {
    signOut: async function () {
      if (this.isSignedInAsAnotherUser) {
        await this.resetIdentity();
        this.$router.push('/admin/accounts');
      } else {
        this.$store.dispatch('signOut');
        this.$router.push('/sign_in');
      }
    },
  },
  ...mixinLocale,
};
