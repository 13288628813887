import Vue from 'vue';
import Map from 'collections/map';
import SortedArraySet from 'collections/sorted-array-set';
import * as types from '../constants/mutationTypes';

export const actions = {
  requestSensorUsers({ commit }, sensorId) {
    return Vue.http.get(`/api/sensors/${sensorId}/users`).then((response) => {
      commit(types.RECEIVE_SENSOR_USERS, { sensorId, items: response.data });
      return response;
    });
  },
  unshareSensor({ commit, dispatch }, { sensorId, email }) {
    const uri = `/api/sensors/${sensorId}/users/${encodeURI(email)}`;
    return Vue.http.delete(uri).then(() => {
      dispatch('requestSensorUsers', sensorId);
    });
  },
  shareSensor({ commit, dispatch }, { sensorId, email, inviteToApp }) {
    const params = { email };
    if (inviteToApp) {
      params.invite_to_app = true;
    }
    return Vue.http.post(`/api/sensors/${sensorId}/users`, params).then((response) => {
      dispatch('requestSensorUsers', sensorId);
      return response;
    });
  },
};

const userEqual = ({ email }, { otherEmail }) => email === otherEmail;
const userCompare = ({ email }, { otherEmail }) => {
  if (email === otherEmail) {
    return 0;
  }
  return email < otherEmail ? -1 : 1;
};

const preprocess = sensorUser => ({
  ...sensorUser,
  permissions: new SortedArraySet(sensorUser.permissions),
});

export const mutations = {
  RECEIVE_SENSOR_USERS(state, { sensorId, items }) {
    state.items = state.items.concat([
      [sensorId, new SortedArraySet(items.map(preprocess), userEqual, userCompare)],
    ]);
  },
  CLEAR_SENSORS(state) {
    state.items = new Map();
  },
};

export const getters = {
  getSensorUsers(state) {
    return (sensorId) => {
      const result = state.items.get(sensorId);
      return result ? result.array : [];
    };
  },
};

export const state = () => ({
  items: new Map(),
});
