var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-snackbar",
    {
      staticClass: "notification",
      attrs: { top: "", color: _vm.type, timeout: _vm.timeout },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [_vm._v(_vm._s(_vm.notification.message))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }