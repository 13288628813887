var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pv-text-field" },
    [
      _vm.edit
        ? _c("v-text-field", {
            ref: "field",
            attrs: {
              "hide-details": "auto",
              value: _vm.value,
              type: _vm.type,
              rules: _vm.rules,
              hint: _vm.hint,
              "error-messages": _vm.errorMessages,
              "data-testid": _vm.dataTestid,
            },
            on: { input: _vm.onInput },
          })
        : _c("span", [_vm._v(_vm._s(_vm._f("format")(_vm.value, _vm.type)))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }