var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { attrs: { "align-md": "center", "no-gutters": "" } },
        [
          _c("v-col", { attrs: { cols: "auto" } }, [_vm._t("default")], 2),
          _vm._v(" "),
          _c(
            "v-col",
            {
              staticClass:
                "side-content-md hidden-sm-and-down d-md-flex align-center flex-wrap",
            },
            [_vm._t("side-content")],
            2
          ),
          _vm._v(" "),
          _c("v-spacer", { staticClass: "hidden-md-and-up" }),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [_vm._t("right-floating-content")],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        {
          staticClass: "side-content flex-column hidden-md-and-up",
          attrs: { "no-gutters": "" },
        },
        [_vm._t("side-content")],
        2
      ),
      _vm._v(" "),
      _c("v-divider", { staticClass: "my-4" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }