var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.value
    ? _c(
        "v-btn",
        {
          staticClass: "gallery-view-fullscreen-enter",
          attrs: { text: "" },
          nativeOn: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.$emit("input", true)
            },
          },
        },
        [_c("v-icon", [_vm._v("fa-expand")])],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }