var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "d-flex align-center justify-start mb-5" },
      [
        _c("pie-chart", {
          staticStyle: { height: "200px", width: "200px" },
          attrs: { data: _vm.chartData, options: _vm.chartOptions },
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ml-6" },
          _vm._l(_vm.insects, function (insectCount, index) {
            return _c("div", { key: index, staticClass: "legend" }, [
              _c("span", {
                staticClass: "insect-color",
                style: { backgroundColor: _vm.pieColors[index] },
              }),
              _vm._v("\n        " + _vm._s(insectCount.name) + "\n      "),
            ])
          }),
          0
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "d-flex align-center justify-start",
        staticStyle: { width: "100%" },
      },
      [
        _c("div", { staticClass: "route-bar-label text-right" }, [
          _vm._v(_vm._s(_vm.$t("indoor_short"))),
        ]),
        _vm._v(" "),
        _c("bar-chart", {
          staticClass: "route-bar",
          attrs: {
            data: _vm.categoryChartData,
            options: _vm.categoryChartOptions,
          },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "route-bar-label" }, [
          _vm._v(_vm._s(_vm.$t("outdoor_short"))),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }