var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "threshold-alert-status",
      attrs: { "data-testid": "threshold-alert-status" },
    },
    [
      _vm.showAlert
        ? [
            _c(
              "div",
              { staticClass: "d-flex align-center flex-grow-1" },
              [
                _c("div", { staticClass: "flex-grow-1 mr-3 text-nowrap" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t("untilAlert", {
                          value: _vm.insectsUntilSumAlert,
                        })
                      ) +
                      "\n      "
                  ),
                ]),
                _vm._v(" "),
                _c("v-progress-linear", {
                  attrs: {
                    color: _vm.insectsUntilSumAlert > 0 ? "primary" : "warning",
                    value: (_vm.event.count * 100) / _vm.event.sensor_threshold,
                    height: "15",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "d-flex align-center my-1",
                attrs: { "data-testid": "threshold-alert-status__message" },
              },
              [
                _c("div", {
                  staticClass: "threshold-alert-status__led mr-2",
                  class: "threshold-alert-status__led--" + _vm.alertStatus,
                }),
                _vm._v("\n      " + _vm._s(_vm.alertMessage) + "\n    "),
              ]
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex flex-wrap my-1" }, [
        _c("div", [
          _vm._v(_vm._s(_vm.$t("sensor_threshold")) + " "),
          _c("span", { staticClass: "mx-1" }, [_vm._v(":")]),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("summary")) +
              " = " +
              _vm._s(_vm.event.sensor_threshold) +
              " / " +
              _vm._s(_vm.$t("increase")) +
              " = " +
              _vm._s(_vm.event.sensor_increase_threshold) +
              "\n    "
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.updatableThreshold
        ? [
            _c(
              "div",
              { staticClass: "mt-2" },
              [
                _vm.editable
                  ? _c(
                      "v-btn",
                      {
                        staticClass: "px-7",
                        attrs: {
                          depressed: "",
                          rounded: "",
                          small: "",
                          color: "white",
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.openDialog.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("button.update_threshold")) +
                            "\n      "
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "v-dialog",
              {
                attrs: {
                  "max-width": "300",
                  "content-class": "threshold-alert-status__dialog",
                },
                model: {
                  value: _vm.dialog,
                  callback: function ($$v) {
                    _vm.dialog = $$v
                  },
                  expression: "dialog",
                },
              },
              [
                _vm.dialog
                  ? _c(
                      "v-form",
                      {
                        model: {
                          value: _vm.valid,
                          callback: function ($$v) {
                            _vm.valid = $$v
                          },
                          expression: "valid",
                        },
                      },
                      [
                        _c("v-text-field", {
                          attrs: {
                            type: "number",
                            autofocus: "",
                            label: _vm.$t("threshold"),
                            rules: _vm.rules["threshold"],
                          },
                          model: {
                            value: _vm.model.threshold,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "threshold", $$v)
                            },
                            expression: "model.threshold",
                          },
                        }),
                        _vm._v(" "),
                        _c("v-text-field", {
                          attrs: {
                            type: "number",
                            label: _vm.$t("increase_threshold"),
                            rules: _vm.rules["increase_threshold"],
                          },
                          model: {
                            value: _vm.model.increase_threshold,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "increase_threshold", $$v)
                            },
                            expression: "model.increase_threshold",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex justify-end" },
                  [
                    _c(
                      "v-btn",
                      {
                        staticClass: "ma-0 ",
                        attrs: {
                          color: "primary",
                          disabled: !_vm.valid || !_vm.isThresholdChanged,
                        },
                        on: { click: _vm.submit },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("button.update")) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }