var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.sensor.trap_replaced_on
    ? _c("div", { staticClass: "replacement-notification" }, [
        _c("div", { staticClass: "replacement-notification__box" }, [
          _vm._v(
            "\n    " +
              _vm._s(
                _vm.$t("status", {
                  date: _vm.replaced,
                  days: _vm.daysRemaining,
                })
              ) +
              "\n  "
          ),
        ]),
        _vm._v(" "),
        _vm.daysExceeded
          ? _c(
              "div",
              {
                staticClass:
                  "replacement-notification__box replacement-notification__box--warning",
              },
              [
                _vm._v(
                  "\n    " +
                    _vm._s(_vm.$t("warning", { days: _vm.daysExceeded })) +
                    "\n  "
                ),
              ]
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }