var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("select-field", {
    attrs: {
      items: _vm.items,
      value: _vm.value,
      edit: _vm.edit,
      "data-testid": "photo_interval",
    },
    on: {
      input: function (v) {
        return _vm.$emit("input", v)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }