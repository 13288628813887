var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "card-dialog",
    {
      attrs: {
        persistent: "",
        "content-class": "insect-count-line-charts-dialog",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "round-button",
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        color: "white",
                        icon: "show_chart",
                        disabled: _vm.applicableSensors.length == 0,
                      },
                    },
                    "round-button",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("display_insect_graphs")) +
                      "\n    "
                  ),
                ]
              ),
            ]
          },
        },
        {
          key: "actions",
          fn: function () {
            return [
              _c("dialog-action", { on: { click: _vm.close } }, [
                _vm._v(_vm._s(_vm.$t("close"))),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.dialogVisible,
        callback: function ($$v) {
          _vm.dialogVisible = $$v
        },
        expression: "dialogVisible",
      },
    },
    [
      _vm._v(" "),
      _c(
        "div",
        [
          _c("chart-list", {
            attrs: { month: _vm.month, sensors: _vm.applicableSensors },
            on: {
              "update:month": function ($event) {
                _vm.month = $event
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }