var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex flex-column align-center" }, [
    _c(
      "div",
      { staticClass: "total-count", attrs: { "data-testid": "total-count" } },
      [
        _vm._v(
          "\n    " +
            _vm._s(_vm.formatTotalCount(_vm.currentMonthTotal)) +
            "\n  "
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        class: _vm.relativePercentageClasses(_vm.changeFromPreviousMonth),
        attrs: { "data-testid": "percentage-vs-previous-month" },
      },
      [
        _vm._v(
          "\n    " +
            _vm._s(_vm.formatRelativePercentage(_vm.changeFromPreviousMonth)) +
            "\n    "
        ),
        _c("span", { staticClass: "value-direction" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.valueDirectionGlyph(_vm.changeFromPreviousMonth)) +
              "\n    "
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("div", [_vm._v(_vm._s(_vm.$t("vs_previous_month")))]),
    _vm._v(" "),
    _c(
      "div",
      {
        class: _vm.relativePercentageClasses(_vm.changeFromPreviousYear),
        attrs: { "data-testid": "percentage-vs-previous-year" },
      },
      [
        _vm._v(
          "\n    " +
            _vm._s(_vm.formatRelativePercentage(_vm.changeFromPreviousYear)) +
            "\n    "
        ),
        _c("span", { staticClass: "value-direction" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.valueDirectionGlyph(_vm.changeFromPreviousYear)) +
              "\n    "
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("div", [_vm._v(_vm._s(_vm.$t("vs_previous_year")))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }