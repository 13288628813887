var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "terms-modal" } }, [
    _vm.visible
      ? _c(
          "div",
          { staticClass: "terms-modal__wrapper", on: { click: _vm.cancel } },
          [
            _c(
              "div",
              {
                staticClass: "terms-modal",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "terms-modal__header" },
                  [
                    _c("div", { staticClass: "terms-modal__title" }, [
                      _vm._v(_vm._s(_vm.terms_title)),
                    ]),
                    _vm._v(" "),
                    _c(
                      "v-btn",
                      {
                        staticClass: "terms-modal__close",
                        attrs: { text: "", icon: "" },
                        on: { click: _vm.cancel },
                      },
                      [
                        _c("v-icon", { attrs: { large: "" } }, [
                          _vm._v("close"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "terms-modal__body" }, [
                  _vm._v(_vm._s(_vm.terms_text)),
                ]),
                _vm._v(" "),
                _vm.onAccept
                  ? _c(
                      "div",
                      { staticClass: "terms-modal__footer" },
                      [
                        _c("v-checkbox", {
                          staticClass: "terms-modal__accept",
                          attrs: {
                            label: _vm.$t("label.accept"),
                            color: "primary",
                          },
                          model: {
                            value: _vm.model.accept,
                            callback: function ($$v) {
                              _vm.$set(_vm.model, "accept", $$v)
                            },
                            expression: "model.accept",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "v-flex",
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "terms-modal__cancel",
                                attrs: { color: "primary", large: "" },
                                on: { click: _vm.cancel },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("button.cancel"))
                                ),
                                _c(
                                  "v-icon",
                                  { attrs: { dark: "", right: "" } },
                                  [_vm._v("fa-arrow-circle-left")]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "v-btn",
                              {
                                staticClass: "terms-modal__next",
                                attrs: {
                                  color: "primary",
                                  large: "",
                                  disabled: !_vm.model.accept,
                                },
                                on: { click: _vm.accept },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.$t("button.next"))
                                ),
                                _c(
                                  "v-icon",
                                  { attrs: { dark: "", right: "" } },
                                  [_vm._v("fa-arrow-circle-right")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }