var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-dialog",
    _vm._g(
      _vm._b(
        {
          attrs: { value: _vm.value },
          on: {
            input: function (val) {
              return _vm.$emit("input", val)
            },
          },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function (props) {
                  return [_vm._t("activator", null, null, props)]
                },
              },
            ],
            null,
            true
          ),
        },
        "base-dialog",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "headline" }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
          _vm._v(" "),
          _c("v-card-text", [_vm._t("default")], 2),
          _vm._v(" "),
          this.$slots.actions
            ? _c(
                "v-card-actions",
                [_c("v-spacer"), _vm._v(" "), _vm._t("actions")],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }