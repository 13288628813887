var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "div",
        { staticClass: "organization-form" },
        [
          _c(
            "page-title-area",
            [
              _c("entity-header", [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.newRecord
                        ? _vm.$t("label.create_organization")
                        : _vm.organization.name
                    ) +
                    "\n    "
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "organization-form__content" }, [
            _c(
              "div",
              { staticClass: "organization-form__form my-3" },
              [
                _c(
                  "v-form",
                  {
                    ref: "form",
                    model: {
                      value: _vm.valid,
                      callback: function ($$v) {
                        _vm.valid = $$v
                      },
                      expression: "valid",
                    },
                  },
                  [
                    _c("form-group", {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("field.name"))),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "input",
                            fn: function () {
                              return [
                                _c("text-field", {
                                  staticClass: "organization-form__form__name",
                                  attrs: {
                                    edit: _vm.editing,
                                    rules: [_vm.required(_vm.$t("field.name"))],
                                    "data-testid": "name",
                                  },
                                  model: {
                                    value: _vm.model.name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.model, "name", $$v)
                                    },
                                    expression: "model.name",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        2344737209
                      ),
                    }),
                    _vm._v(" "),
                    _c("form-group", {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("field.address"))),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "input",
                            fn: function () {
                              return [
                                _c("text-field", {
                                  staticClass:
                                    "organization-form__form__address",
                                  attrs: {
                                    edit: _vm.editing,
                                    rules: [
                                      _vm.required(_vm.$t("field.address")),
                                    ],
                                    "data-testid": "address",
                                  },
                                  model: {
                                    value: _vm.model.address,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.model, "address", $$v)
                                    },
                                    expression: "model.address",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1854572488
                      ),
                    }),
                    _vm._v(" "),
                    _c("form-group", {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _c("span", [
                                  _vm._v(_vm._s(_vm.$t("field.phone_number"))),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "input",
                            fn: function () {
                              return [
                                _c("text-field", {
                                  staticClass:
                                    "organization-form__form__phone_number",
                                  attrs: {
                                    edit: _vm.editing,
                                    rules: [
                                      _vm.required(
                                        _vm.$t("field.phone_number")
                                      ),
                                    ],
                                    "data-testid": "phone_number",
                                  },
                                  model: {
                                    value: _vm.model.phone_number,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.model, "phone_number", $$v)
                                    },
                                    expression: "model.phone_number",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        2005589086
                      ),
                    }),
                    _vm._v(" "),
                    _vm.fieldIsEditable("allowed_sensor_types")
                      ? _c("form-group", {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("field.allowed_sensor_types")
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "input",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      [
                                        _vm._l(_vm.SENSOR_TYPES, function (t) {
                                          return [
                                            _c("checkbox", {
                                              key: t + "-checkbox",
                                              attrs: {
                                                label: _vm.SENSOR_TYPE_NAMES[t],
                                                value: t,
                                                "data-testid":
                                                  "allowed_sensor_types[" +
                                                  t +
                                                  "]",
                                                edit: _vm.editing,
                                              },
                                              model: {
                                                value:
                                                  _vm.model
                                                    .allowed_sensor_types,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.model,
                                                    "allowed_sensor_types",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "model.allowed_sensor_types",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("br", { key: t + "-br" }),
                                          ]
                                        }),
                                      ],
                                      2
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            2244159975
                          ),
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.admin
                      ? [
                          _vm.stationSensorEnabled
                            ? [
                                _c("form-group", {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(_vm.$t("field.plan"))
                                              ),
                                            ]),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "input",
                                        fn: function () {
                                          return [
                                            _c(
                                              "div",
                                              [
                                                _c("select-field", {
                                                  staticClass:
                                                    "organization-form__form__plan",
                                                  attrs: {
                                                    rules: [
                                                      _vm.required(
                                                        _vm.$t("field.plan")
                                                      ),
                                                    ],
                                                    items: _vm.planOptions,
                                                    edit: _vm.editing,
                                                    "data-testid": "next_plan",
                                                  },
                                                  model: {
                                                    value: _vm.model.next_plan,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.model,
                                                        "next_plan",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "model.next_plan",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    1633106170
                                  ),
                                }),
                              ]
                            : _vm._e(),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.fieldIsEditable("active_features")
                      ? _c("form-group", {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$t("field.active_features"))
                                      ),
                                    ]),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "input",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      [
                                        _vm._l(
                                          _vm.availableOrganizationFeatures,
                                          function (feature) {
                                            return [
                                              _c("checkbox", {
                                                key: feature + "-checkbox",
                                                attrs: {
                                                  label: _vm.$t(
                                                    "checkbox." + feature
                                                  ),
                                                  value: feature,
                                                  "data-testid":
                                                    "active_features[" +
                                                    feature +
                                                    "]",
                                                  edit: _vm.editing,
                                                },
                                                model: {
                                                  value:
                                                    _vm.model.active_features,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.model,
                                                      "active_features",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "model.active_features",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("br", {
                                                key: feature + "-br",
                                              }),
                                            ]
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            1134075427
                          ),
                        })
                      : _vm._e(),
                  ],
                  2
                ),
                _vm._v(" "),
                !_vm.newRecord
                  ? _c("organization-user-list", {
                      staticClass: "mt-3",
                      attrs: {
                        admin: _vm.admin,
                        "organization-id": _vm.organization.id,
                        organization: _vm.organization,
                      },
                      on: { appointAsAdmin: _vm.appointAsAdmin },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _vm.editable
              ? _c(
                  "div",
                  { staticClass: "organization-form__btn-group" },
                  [
                    _vm.editing
                      ? [
                          _c(
                            "round-button",
                            {
                              staticClass:
                                "organization-form__btn organization-form__btn___save my-3",
                              attrs: {
                                icon: "fa-check-circle",
                                fullWidth: "",
                                title: "save",
                              },
                              on: { click: _vm.onSave },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("button.save")) +
                                  "\n        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "round-button",
                            {
                              staticClass:
                                "organization-form__btn organization-form__btn___cancel my-3",
                              attrs: {
                                icon: "fa-arrow-circle-left",
                                fullWidth: "",
                              },
                              on: { click: _vm.onCancel },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("button.cancel")) +
                                  "\n        "
                              ),
                            ]
                          ),
                        ]
                      : [
                          _c(
                            "round-button",
                            {
                              staticClass:
                                "organization-form__btn organization-form__btn___edit my-3",
                              attrs: {
                                icon: "fa-pencil-square-o",
                                fullWidth: "",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.editing = true
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm.$t("button.edit")) +
                                  "\n        "
                              ),
                            ]
                          ),
                        ],
                  ],
                  2
                )
              : _vm._e(),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }