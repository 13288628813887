const LOAD_STEP = 100;

/* Input props/computed properties:
  sensorId: {
    type: String,
    required: true,
  },
  requestParams: {
    type: Object,
  },
 */
export default {
  data() {
    return {
      allEventsLoaded: false,
      events: [],
      eventsLoading: false,
      eventContainerInternal: {
        cancelled: false,
        lastRetrieved: null,
      },
    };
  },
  methods: {
    clearEvents() {
      this.events = [];
      this.eventContainerInternal = {
        lastRetrieved: null,
        cancelled: false,
      };
      this.allEventsLoaded = false;
      this.eventsLoading = false;
    },
    getEvents(additionalParams) {
      const params = {
        ...(this.requestParams || {}),
        ...(additionalParams || {}),
      };

      return this.$store.dispatch('getEvents', {
        sensorId: this.sensorId,
        params,
      });
    },
    loadAllEvents: async function (options = {}) {
      const { until } = options;

      await this.loadEvents(async () => {
        this.eventsLoading = { events: [...this.events] };
        let lastRetrieved = this.eventContainerInternal.lastRetrieved;

        const additionalParams = { limit: LOAD_STEP };
        if (until) {
          additionalParams.from = until;
        }
        do {
          if (lastRetrieved) {
            additionalParams.to = lastRetrieved;
          }
          // eslint-disable-next-line no-await-in-loop
          const response = await this.getEvents(additionalParams);
          this.eventsLoading = {
            events: this._.uniqBy([...this.eventsLoading.events, ...response.events], 'id'),
          };
          lastRetrieved = response.last_evaluated;
        } while (lastRetrieved && !this.eventContainerInternal.cancelled);

        if (this.eventContainerInternal.cancelled) {
          return [];
        }

        this.allEventsLoaded = !until;
        this.eventContainerInternal.lastRetrieved = until;

        return this._.orderBy(this.eventsLoading.events, 'timestamp', 'desc');
      });
    },
    loadEvents: async function (doLoad) {
      if (this.allEventsLoaded || this.eventsLoading) {
        return;
      }

      this.eventsLoading = true;
      try {
        this.events = await doLoad();
        this.eventsLoading = false;
      } catch (error) {
        this.eventsLoading = false;
        throw error;
      }
    },
    loadNextEvents: async function (n) {
      await this.loadEvents(async () => {
        const additionalParams = { limit: n };
        if (this.eventContainerInternal.lastRetrieved) {
          additionalParams.to = this.eventContainerInternal.lastRetrieved;
        }
        const response = await this.getEvents(additionalParams);

        this.eventContainerInternal = {
          ...this.eventContainerInternal,
          lastRetrieved: response.last_evaluated,
        };
        this.allEventsLoaded = !response.last_evaluated;

        return [...this.events, ...response.events];
      });
    },
    cancelEventLoading: function () {
      this.eventContainerInternal.cancelled = true;
    },
  },
};
