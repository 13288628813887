<template>
  <div class="threshold-alert-status">
    <div class="threshold-alert-status__field threshold-alert-status__field--until-alert" v-if="event.latest">
      <div class="mr-3">
        {{ $t('untilAlert', { value: insectsUntilAlert }) }}
      </div>
      <div class="threshold-alert-status__progress">
        <div class="flex">
          <v-progress-linear
            class="mb-1"
            :color="insectsUntilAlert > 0 ? 'primary' : 'warning'"
            :value="event.count * 100 / event.sensor_threshold"
            height="15"
          />
          <v-btn
            depressed
            rounded
            small
            color="white"
            @click.stop="dialog = true; model = event.sensor_threshold"
            v-if="editable"
          >
            {{ $t('button.update_threshold') }}
          </v-btn>
        </div>
        <div
          class="threshold-alert-status__led"
          :class="{
            'threshold-alert-status__led--red': insectsUntilAlert <= 0,
            'threshold-alert-status__led--green': insectsUntilAlert > 0,
          }"
        />
      </div>
    </div>
    <div class="threshold-alert-status__field">
      {{ `${$t('sensor_threshold')} : ${event.sensor_threshold}` }}
    </div>
    <v-dialog v-model="dialog" max-width="300" content-class="threshold-alert-status__dialog">
      <v-form v-model="valid">
        <v-text-field
          v-model="model"
          type="number"
          autofocus
          :label="$t('threshold')"
          :rules="rules"
          v-if="dialog"
        />
      </v-form>
      <div class="d-flex justify-end">
        <v-btn
          color="primary"
          class="ma-0 "
          @click="submit"
          :disabled="!valid || model === event.sensor_threshold"
        >
          {{ $t('button.update') }}
        </v-btn>
      </div>
    </v-dialog>
  </div>
</template>

<i18n lang="yaml">
ja:
  untilAlert: "アラートが出るまでの頭数: {value}"
  threshold: "しきい値"
  sensor_threshold: アップロード時のしきい値
  button:
    update_threshold: しきい値再設定
    update: 更新
  msg:
    update_success: しきい値を更新しました
    update_error: しきい値の更新中にエラーが発生しました

en:
  untilAlert: "{value} more insects until alert."
  threshold: "Threshold"
  sensor_threshold: Threshold when photo was uploaded
  button:
    update_threshold: Update threshold
    update: Update
  msg:
    update_success: 'Threshold was successfully updated.'
    update_error: 'An error occurred while updating the threshold.'
</i18n>

<script>
import Notifications from '@/mixins/notifications';
import Validations from '@/components/Form/mixins/form-validations';

export default {
  name: 'threshold-alert-status',
  props: {
    sensor: {
      type: Object,
      default() {
        return {};
      },
    },
    event: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  mixins: [Validations, Notifications],
  computed: {
    editable() {
      return this.sensor.permissions.includes('full');
    },
    insectsUntilAlert() {
      const { count, sensor_threshold: threshold } = this.event;
      if (count > threshold) {
        return 0;
      }
      return threshold - count;
    },
  },
  data() {
    return {
      dialog: false,
      model: 0,
      valid: false,
      rules: [
        this.required(this.$t('threshold')),
        this.positive(this.$t('threshold')),
      ],
    };
  },
  methods: {
    submit: async function () {
      try {
        const params = {
          id: this.sensor.id,
          data: {
            threshold: Number(this.model),
          },
        };
        await this.$store.dispatch('requestUpdateSensor', params);
        this.notifySuccess('msg.update_success');
        this.dialog = false;
      } catch (error) {
        this.notifyError('msg.update_error');
      }
    },
  },
};
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'

.threshold-alert-status
  &__field--until-alert
    display: flex
    flex-wrap: wrap
    align-items: flex-start !important

  &__progress
    min-width: 200px
    max-width: 300px
    display: flex
    flex-wrap: nowrap
    flex-grow: 1
    margin-top: 0.25rem
    margin-bottom: 0.5rem

    .v-btn
      margin-bottom: 0
      width: 100%

  &__led
    height: 50px
    width: 50px
    background-image: url(../../../assets/logo-no.png)
    background-size: cover
    background-repeat: no-repeat
    background-position: center
    margin-left: 0.5rem

    &--green
      background-image: url(../../../assets/logo-green.png)

    &--red
      background-image: url(../../../assets/logo-red.png)
</style>

<style lang="sass">
.threshold-alert-status
  &__dialog
    background-color: #fff
    padding: 1rem
    width: 300px
</style>
