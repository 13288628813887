<script>
export default {
  name: 'd-table',
  render(h) {
    const self = this;
    return h('div', { class: 'data-list data-list--table' }, [h('v-data-table', {
      props: {
        headers: self.headers(),
        items: self.items,
        ...self.pagination,
        'hide-default-footer': true,
      },
      on: {
        'update:options': val => this.$emit('update:pagination', val),
      },
      scopedSlots: {
        item({ index, item }) {
          const children = self.$slots.default.map(node => h('td', null, node.data.scopedSlots.default({ index, item })));
          return h('tr', self.getItemProps(item, { class: 'data-list__item' }, index), children);
        },
      },
    })]);
  },
  methods: {
    headers() {
      return this.$slots.default.map(node => ({
        text: node.data.attrs.name,
        value: node.data.attrs.value,
        class: node.data.attrs.headerClass,
        align: node.data.attrs.align || 'left',
        sortable: node.data.attrs.sortable !== undefined,
      }));
    },
  },
  props: {
    getItemProps: {
      type: Function,
      default: (item, props, index) => props, // eslint-disable-line no-unused-vars
    },
    items: Array,
    pagination: Object,
  },
};
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'

::v-deep .v-data-table
  th[role="columnheader"]
    color: white!important
    background-color: map-get($colors, 'grey', 'base')

    .v-icon
      color: white !important

    &[aria-sort="none"] .v-icon
      display: none

  tbody
    tr:nth-child(odd):hover,
    tr:nth-child(even):hover
      background-color: map-get($grey, 'lighten-2')!important

    tr:nth-child(odd)
      background-color: map-get($grey, 'lighten-3')

  th + th,
  td + td
    border-left: map-get($grey, 'lighten-3') solid 1px
</style>
