export default {
  methods: {
    notifyError(message, timeout) {
      this.$store.dispatch('notify', { type: 'error', message: this.$t(message), timeout });
    },
    notify(message, timeout) {
      this.$store.dispatch('notify', { message: this.$t(message), timeout });
    },
    notifySuccess(message, timeout) {
      this.$store.dispatch('notify', { type: 'success', message: this.$t(message), timeout });
    },
    notifyWarning(message, timeout) {
      this.$store.dispatch('notify', { type: 'warning', message: this.$t(message), timeout });
    },
  },
};
