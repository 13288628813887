var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "processing", attrs: { align: "center" } },
    [
      _c(
        "v-col",
        [
          _c("v-progress-circular", {
            staticClass: "ma-2",
            attrs: { indeterminate: "", color: "primary", size: 50 },
          }),
          _vm._v("\n    " + _vm._s(_vm.$t("processing")) + "\n  "),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }