import moment from 'moment';
import { SENSOR_DEFINITIONS, SENSOR_TYPES } from '@/mixins/sensorTypes';

export const buildDefaultQuery = () => ({
  sensor: {
    id: null,
    name: '',
    place: '',
    type: null,
    onlyBookmarked: false,
  },
  event: {
    exceptMisdetection: false,
    misdetection: false,
    pickup: false,
    tags: [],
    term: { from: null, to: null },
    exceeded: false,
    includeHidden: false,
  },
});

export const filterSensors = (sensors, query) => {
  const filter = (sensor) => {
    const sensorQuery = query.sensor || {};

    if (sensorQuery.id && sensor.id !== sensorQuery.id) {
      return false;
    }

    if (sensorQuery.name && !caseInsensitiveIncludes(sensor.name, sensorQuery.name)) {
      return false;
    }

    if (sensorQuery.place && !caseInsensitiveIncludes(sensor.place, sensorQuery.place)) {
      return false;
    }

    if (sensorQuery.type && sensor.type !== sensorQuery.type) {
      return false;
    }

    if (sensorQuery.onlyBookmarked && !sensor.bookmarked) {
      return false;
    }

    const eventQuery = query.event || {};
    const sensorTypeDefinition = SENSOR_DEFINITIONS[sensor.type];
    if (eventQuery.exceeded && !sensorTypeDefinition.capabilities.threshold) {
      return false;
    }

    const ratMonitorOnlyQuery = eventQuery.misdetection || eventQuery.exceptMisdetection;
    if (ratMonitorOnlyQuery && sensor.type !== SENSOR_TYPES.RAT) {
      return false;
    }

    return true;
  };

  return sensors.filter(filter);
};

export const parseToValidQuery = (query) => {
  const defaultQuery = buildDefaultQuery();
  const sensor = query.sensor || {};
  const event = query.event || {};
  const term = event.term || {};

  return {
    sensor: {
      ...defaultQuery.sensor,
      ...sensor,
    },
    event: {
      ...defaultQuery.event,
      ...event,
      term: {
        from: moment(term.from || '').isValid() ? term.from : null,
        to: moment(term.to || '').isValid() ? term.to : null,
      },
    },
  };
};

const caseInsensitiveIncludes = (str, query) => str.toLowerCase().includes(query.toLowerCase());
