<template>
  <icon-button
    :icon="icon"
    :class="{ 'value-false': !value }"
    @click="$emit('input', !value)"
  />
</template>

<script>
import IconButton from '@/components/atoms/IconButton';

export default {
  name: 'toggle-icon-button',
  components: {
    IconButton,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'

.value-false
  ::v-deep .v-icon.v-icon // need specificity to override default button style
    color: map-get($material-theme, 'text', 'disabled')
</style>
