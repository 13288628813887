<template>
  <div>
    <div class="d-flex align-center justify-start mb-5">
      <pie-chart
        style="height: 200px; width: 200px;"
        :data="chartData"
        :options="chartOptions"
      />
      <div class="ml-6">
        <div class="legend" :key="index" v-for="(insectCount, index) in insects">
          <span
            class="insect-color"
            :style="{ backgroundColor: pieColors[index] }"
          />
          {{ insectCount.name }}
        </div>
      </div>
    </div>
    <div class="d-flex align-center justify-start" style="width: 100%;">
      <div class="route-bar-label text-right">{{ $t('indoor_short') }}</div>
      <bar-chart
        class="route-bar"
        :data="categoryChartData"
        :options="categoryChartOptions"
        />
      <div class="route-bar-label">{{ $t('outdoor_short') }}</div>
    </div>
  </div>
</template>

<i18n lang="yaml">
ja:
  indoor: 内部発生
  indoor_short: 内部
  outdoor: 外部発生
  outdoor_short: 外部
en:
  indoor: indoor
  indoor_short: indoor
  outdoor: outdoor
  outdoor_short: outdoor
</i18n>

<script>
import BarChart from '@/components/atoms/BarChart';
import PieChart from '@/components/atoms/PieChart';

import { indoorColor, outdoorColor } from '../colors';

export default {
  name: 'insect-type-pie-chart',
  components: {
    BarChart,
    PieChart,
  },
  props: {
    insects: {
      type: Array, // [{ name: string, type: string, route: string, count: number }]
      required: true,
    },
    pieColors: {
      type: Array,
      required: true,
    },
  },
  computed: {
    categoryChartData() {
      return {
        labels: [''],
        datasets: [
          {
            backgroundColor: indoorColor,
            label: this.$t('indoor'),
            data: [this.categoryPercentages.indoor],
          },
          {
            backgroundColor: outdoorColor,
            label: this.$t('outdoor'),
            data: [this.categoryPercentages.outdoor],
          },
        ],
      };
    },
    categoryChartOptions() {
      return {
        indexAxis: 'y',
        plugins: {
          datalabels: {
            display: true,
            color: 'white',
            font: {
              size: 14,
              weight: 'bold',
            },
            formatter: function (value) {
              return `${value.toFixed(0)}%`;
            },
          },
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        scales: {
          x: {
            display: false,
            max: 100,
            stacked: true,
          },
          y: {
            stacked: true,
            display: false,
          },
        },
      };
    },
    categoryPercentages() {
      const counts = { indoor: 0, outdoor: 0 };

      this.insects.forEach(({ count, route }) => {
        counts[route] += count;
      });

      const total = counts.indoor + counts.outdoor;

      return {
        indoor: (counts.indoor / total) * 100,
        outdoor: (counts.outdoor / total) * 100,
      };
    },
    chartData() {
      return {
        labels: this._.map(this.insects, 'name'),
        datasets: [
          {
            backgroundColor: this.pieColors,
            data: this._.map(this.insects, 'count'),
          },
        ],
      };
    },
    chartOptions() {
      return {
        plugins: {
          legend: {
            display: false,
            // BarChartとPieChartの動きを統一する為、クリックイベントを無効化
            onClick: function () {},
          },
        },
        borderWidth: 0,
      };
    },
  },
};
</script>

<style scoped lang="sass">
.insect-color
  display: inline-block
  width: 1rem
  height: 1rem
  margin-bottom: -0.2rem

.route-bar
  width: calc(100% - (68px * 2))
  height: 50px

.route-bar-label
  max-width: 68px
  font-weight: bold
  padding-left: 8px
  padding-right: 8px
  white-space: nowrap

.legend
  margin-bottom: 0.5em
  white-space: nowrap
</style>
