<template>
  <v-flex class="event pa-2" md3 xs12>
    <event-detail-header :event="event" />
    <div class="thumbnail-wrapper" @click.stop="$emit('event:selected')" data-testid="thumbnail">
      <loading-image
        :src="thumbnail"
        @loaded="() => loaded = true"
      />
      <v-icon class="play-video-icon"dark x-large v-if="hasVideo & loaded" data-testid="has video" >
        fa-play-circle
      </v-icon>
      <div class="info-icon-list">
        <v-icon class="info-icon" color="primary" data-testid="pickup" v-if="event.pickup">
          fa-star
        </v-icon>
        <v-icon class="info-icon fill-white--circle" color="primary" data-testid="misdetection" v-if="event.misdetection">
          fa-frown-o
        </v-icon>
        <v-icon class="info-icon" color="primary" data-testid="has tags" v-if="!_.isEmpty(event.tags)">
          fa-tag
        </v-icon>
      </div>
      <v-btn
        icon
        :class="{ 'hide-button': true, 'hide-button__hidden': event.hidden }"
        color="primary"
        data-testid="visible"
        @click.stop="$emit('event:hidden', !event.hidden)"
        :disabled="!editable"
      >
        <v-icon>{{ event.hidden ? 'fa-eye-slash' : 'fa-eye' }}</v-icon>
      </v-btn>
    </div>
  </v-flex>
</template>

<script>
import EventDetailHeader from '@/components/EventDetailHeader';
import LoadingImage from '@/components/LoadingImage';

export default {
  name: 'event',
  props: {
    event: Object,
    editable: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    LoadingImage,
    EventDetailHeader,
  },
  computed: {
    hasVideo() {
      const { get } = this._;
      const { attachments } = this.event;
      const video = get(attachments, 'video') || get(attachments, 'original_video');
      return !!video;
    },
    thumbnail() {
      const { get } = this._;
      const { attachments } = this.event;
      return get(attachments, 'thumbnail') ||
        get(attachments, 'photo');
    },
  },
  data() {
    return {
      loaded: false,
    };
  },
};
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'

.event
  .thumbnail-wrapper
    cursor: pointer
    position: relative
    width: 100%

    .loading-image
      &--loading,
      &--error
        min-height: 200px

  .play-video-icon
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)

  .info-icon-list
    bottom: 10px
    left: 5px
    position: absolute

  .info-icon
    text-shadow: 1px 1px 1px #fff, -1px 1px 1px #fff, 1px -1px 1px #fff, -1px -1px 1px #fff

  .info-icon + .info-icon
    margin-left: 2px

  .hide-button
    width: 24px
    height: 24px
    margin: 0px
    bottom: 10px
    right: 5px
    position: absolute
    background-color: white

    .v-icon
      font-size: 18px

    &__hidden
      color: map-get($material-theme, 'text', 'alternate') !important

  .fill-white--circle
    background: white
    border-radius: 10px
    height: 20px
    margin: 0 2px
    width: 20px
</style>
