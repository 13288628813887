// SENSORS
export const SET_SENSOR = 'SET_SENSOR';
export const SET_SENSORS = 'SET_SENSORS';
export const DELETE_SENSOR = 'DELETE_SENSOR';
export const CLEAR_SENSORS = 'CLEAR_SENSORS';
export const START_RELOAD_SENSOR = 'START_RELOAD_SENSOR';
export const STOP_RELOAD_SENSOR = 'STOP_RELOAD_SENSOR';

// SENSOR_USERS
export const RECEIVE_SENSOR_USERS = 'RECEIVE_SENSOR_USERS';

// EVENTS
export const PUSH_UPDATED_EVENT = 'PUSH_UPDATED_EVENT';
export const POP_UPDATED_EVENT = 'POP_UPDATED_EVENT';

// USERS
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const CLEAR_CURRENT_USER = 'CLEAR_CURRENT_USER';
export const SET_USER = 'SET_USER';
export const SET_USERS = 'SET_USERS';
export const CLEAR_USERS = 'CLEAR_USERS';
export const ASSUME_IDENTITY = 'ASSUME_IDENTITY';

// TOKEN
export const SET_TOKEN = 'SET_TOKEN';
export const CLEAR_TOKEN = 'CLEAR_TOKEN';

// NOTIFICATION
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';

// PENDING DOWNLOAD
export const SET_JOB_ID = 'SET_JOB_ID';
export const UPDATE_PROGRESS = 'UPDATE_PROGRESS';
export const CLEAR_PENDING_DOWNLOAD = 'CLEAR_PENDING_DOWNLOAD';

// ORGANIZATIONS
export const SET_ORGANIZATIONS = 'SET_ORGANIZATIONS';
export const ADD_ORGANIZATION = 'ADD_ORGANIZATION';
export const SET_ORGANIZATION_USERS = 'SET_ORGANIZATION_USERS';
export const ADD_ORGANIZATION_USER = 'ADD_ORGANIZATION_USER';
export const REMOVE_ORGANIZATION_USER = 'REMOVE_ORGANIZATION_USER';
