<template>
  <div class="notice-history">
    <div v-for="notice in notices" :key="notice.id" class="notice" :class="notice.type">
      <div class="badge">
        <v-icon>mdi-alert</v-icon>
        <span class="mx-auto">{{$t(`label.${notice.type}`)}}</span>
      </div>
      <div class="message">
        <div class="mr-2">{{ notice.date }}</div>
        <div>{{ notice.message }}</div>
      </div>
    </div>
  </div>
</template>

<i18n lang="yaml">
ja:
  message:
    total: '[{sensor}] で総数アラート発生 (捕獲数:{count} / しきい値:{threshold})'
    increase: '[{sensor}] で増加数アラート (増加数:{count} / しきい値:{threshold})'
  label:
    total: '総数'
    increase: '増加'

en:
  message:
    total: 'Total alert on [{sensor}] (count:{count} / threshold:{threshold})'
    increase: 'Increase alert on [{sensor}] (count:{count} / threshold:{threshold})'
  label:
    total: 'Total'
    increase: 'Increase'
</i18n>

<script>

const removeConsecutiveNotices = (notices) => {
  const uniqueNotices = [];
  let prevType = null;
  notices.forEach((notice) => {
    if (prevType === notice.type) {
      return;
    }
    uniqueNotices.push(notice);
    prevType = notice.type;
  });
  return uniqueNotices;
};

export default {
  name: 'notice-history',
  props: {
    events: {
      type: Array,
      required: true,
      default: () => [],
    },
    sensors: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  computed: {
    // ------------------------------
    // {
    //   type: 'total' | 'increase' | 'fixed',
    //   datetime: '2000/01/01 00:00',
    //   message: 'test',
    // }
    notices() {
      const alerts = [
        // Remove 'fixed' notices
        ...this._.filter(this.alertNotices, { type: 'total' }),
        ...this.increaseNotices,
      ];
      return this._.orderBy(alerts, ['date'], ['desc']);
    },
    alertNotices() {
      const notices = [];
      this.sensors.forEach((sensor) => {
        const sensorEvents = this.events.filter((event) => event.sensor_id === sensor.id);

        let prevAlertEvent = null;
        const events = sensorEvents.filter((event) => {
          if (event.sensor_threshold > 0 && event.count >= event.sensor_threshold) {
            prevAlertEvent = event;
            return true;
          } else if (prevAlertEvent && event.count < event.sensor_threshold) {
            prevAlertEvent = null;
            return true;
          }
          return false;
        });

        const results = events.map((event) => {
          const type = event.count >= event.sensor_threshold ? 'total' : 'fixed';
          return this.buildNotice(type, {
            sensorId: event.sensor_id,
            timestamp: event.timestamp,
            count: event.count,
            threshold: event.sensor_threshold,
          });
        });

        notices.push(...removeConsecutiveNotices(results));
      });

      return notices;
    },
    increaseNotices() {
      const events = this.events.filter((event) =>
        event.sensor_increase_threshold > 0 &&
          event.count_difference >= event.sensor_increase_threshold,
      );
      return events.map((event) => this.buildNotice('increase', {
        sensorId: event.sensor_id,
        timestamp: event.timestamp,
        count: event.count_difference,
        threshold: event.sensor_increase_threshold,
      }));
    },
  },
  methods: {
    getSensorName(sensorId) {
      const sensor = this.sensors.find((s) => s.id === sensorId);
      return sensor ? sensor.name : '';
    },
    buildNotice(type, { timestamp, count, threshold, sensorId }) {
      return {
        type,
        date: this.$moment.unix(timestamp).format('YYYY/MM/DD HH:mm'),
        message: this.$t(`message.${type}`, {
          sensor: this.getSensorName(sensorId),
          count,
          threshold,
        }),
      };
    },
  },
};
</script>

<style scoped lang="sass">
.notice-history
  overflow-y: auto

.notice
  display: flex
  align-items: center
  padding: 0.5em 1em
  margin: 0.5em 0
  font-size: 13px

  .message
    display: flex
    flex-wrap: wrap

  &:nth-child(even)
    background-color: #eaeaea

  &.total .badge
    background-color: #fb0000

  &.increase .badge
    background-color: #fb8c00

.badge
  display: flex
  align-items: center
  border-radius: 2em
  color: white
  padding: 0 1em
  margin-right: 1em
  width: 100px

  .v-icon
    color: inherit
    font-size: 1.1em
    margin-right: 0.25em
</style>
