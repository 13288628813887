<template>
  <div class="d-flex align-center">
    <icon-button
      icon="fa-angle-left"
      @click="goToPreviousMonth"
      :disabled="disabled"
    />
    <div>{{ formattedValue }}</div>
    <date-picker-dropdown
      :value="value"
      @input="value => $emit('value', value)"
      type="month"
    >
      <template #activator="{ on }">
        <v-btn icon color="primary" v-on="on" :disabled="disabled">
          <v-icon>fa-calendar</v-icon>
        </v-btn>
      </template>
    </date-picker-dropdown>
    <icon-button
      icon="fa-angle-right"
      @click="goToNextMonth"
      :disabled="disabled"
    />
  </div>
</template>

<i18n lang="yaml">
ja:
  format: 'YYYY年MM月'

en:
  format: 'MMM YYYY'
</i18n>

<script>
import moment from 'moment';

import DatePickerDropdown from '@/components/atoms/DatePickerDropdown';
import IconButton from '@/components/atoms/IconButton';

export default {
  name: 'month-picker',
  components: {
    DatePickerDropdown,
    IconButton,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
  },
  computed: {
    formattedValue() {
      return moment(this.value).format(this.$t('format'));
    },
  },
  methods: {
    goToPreviousMonth() {
      this.$emit('input', moment(this.value).subtract(1, 'month').format('YYYY-MM'));
    },
    goToNextMonth() {
      this.$emit('input', moment(this.value).add(1, 'month').format('YYYY-MM'));
    },
  },
};
</script>

<style scoped lang="sass">
</style>
