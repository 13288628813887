<template>
  <div>
    <v-row align="center">
      <v-col cols="auto">
        <h3>{{ description.department_name }}</h3>
      </v-col>
      <v-col cols="auto">
        <span class="insect-route">{{ $t(`route.${route}`) }}</span>
        <div class="insect-cause ml-1" :key="cause" v-for="cause in causes">
          <insect-cause-icon size="14" :cause="cause" />
          <span>{{ $t(`cause.${cause}`) }}</span>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <img :src="insectPhotos[insectType]" class="photo" />
      </v-col>
      <v-col cols="8">
        {{ description.description }}
      </v-col>
    </v-row>
  </div>
</template>

<i18n lang="yaml">
ja:
  cause:
    damp: 湿潤
    garbage: 腐食
    waterbody: 水系
    green_space: 緑地
    stored_food: 食品
    mold: 菌
  route:
    indoor: 内部発生
    outdoor: 外部侵入

en:
  cause:
    damp: Damp
    garbage: Garbage
    waterbody: Waterbody
    green_space: Green space
    stored_food: Stored food
    mold: Mold
  route:
    indoor: Indoor
    outdoor: Outdoor
</i18n>

<script>
import insectDescriptions from 'Data/insect_descriptions.json';
import insectPhotos from 'Data/insect_photos';

import InsectTypes from '@/mixins/insectTypes';
import InsectCauseIcon from '@/components/atoms/InsectCauseIcon';

export default {
  name: 'insect-description',
  props: {
    insectType: String,
  },
  components: {
    InsectCauseIcon,
  },
  mixins: [InsectTypes],
  data() {
    return {
      insectDescriptions,
      insectPhotos,
    };
  },
  computed: {
    route() {
      const insectType = this.INSECT_TYPES[this.insectType] || {};
      return insectType.route || '';
    },
    causes() {
      const insectType = this.INSECT_TYPES[this.insectType] || {};
      return insectType.causes || [];
    },
    description() {
      return this.insectDescriptions[this.insectType][this.$i18n.locale];
    },
  },
};
</script>

<style scoped lang="sass">
.photo
  width: 100%

.insect-route
  font-weight: bold
  color: white
  padding: 5px
  border-radius: 5px
  background-color: #5e803f

.insect-cause
  font-weight: bold
  display: inline-flex
  align-items: baseline
  text-decoration: underline
  padding-right: 4px
  height: 10px
</style>
