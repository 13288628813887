<template>
  <div class="insect-view-detections-editor">
    <editor-canvas
      :imageSrc="imageSrc"
      :detections="detections"
      :mode="editMode"
      @pick="showForm"
      @add="addDetection"
      ref="canvas"
      :withInsectTypes="withInsectTypes"
      :newInsectType="defaultInsectType"
    />
    <insect-type-picker
      @change="onChangeDetections"
      @close="hideForm"
      :withInsectTypes="withInsectTypes"
      ref="form"
    />
  </div>
</template>

<script>
import InsectTypes from '@/mixins/insectTypes';
import InsectTypePicker from './InsectTypePicker';
import EditorCanvas from './EditorCanvas';

const COLOR_DEFAULT = 'red';
const COLOR_PICKED = 'royalblue';
const COLOR_REMOVE = 'white';

export default {
  name: 'detections-editor',
  mixins: [InsectTypes],
  props: {
    editMode: String,
    imageSrc: String,
    withInsectTypes: Boolean,
    defaultInsectType: String,
    value: {
      type: Array,
      default() {
        /*
         * Sample attributes
         *
         * - index: 1
         * - position: {}
         * - symbol: 'ka'
         */
        return [];
      },
    },
  },
  components: {
    EditorCanvas,
    InsectTypePicker,
  },
  computed: {
    detections() {
      return this.value.map(value => ({
        ...value,
        rect: {
          x1: value.position[0],
          y1: value.position[1],
          x2: value.position[2],
          y2: value.position[3],
        },
        color: this.getDetectionColor(value),
      }));
    },
  },
  data() {
    return {
      pickedItems: [],
    };
  },
  methods: {
    isPicked(value) {
      return !!this.pickedItems.find(item => item.index === value.index);
    },
    getDetectionColor(item) {
      if (this.isPicked(item)) {
        return COLOR_PICKED;
      } else if (item.remove) {
        return COLOR_REMOVE;
      }

      return COLOR_DEFAULT;
    },
    hideForm() {
      this.pickedItems = [];
      this.$refs.canvas.refresh();
    },
    addDetection(item) {
      const newDetection = { ...item };
      if (this.withInsectTypes) {
        this._.merge(newDetection, {
          ...this.INSECT_TYPES[this.defaultInsectType],
          type: this.defaultInsectType,
        });
      }
      this.onChangeDetections([newDetection]);
      this.$refs.canvas.refresh();
    },
    showForm(items, event) {
      this.pickedItems = items;
      const canvas = this.$refs.canvas.$el;
      this.$refs.form.show(items, {
        top: event.offsetY + canvas.offsetTop,
        left: event.offsetX + canvas.offsetLeft,
      });
    },
    onChangeDetections(detections) {
      const _ = this._;
      let added = _.filter(detections, { new: true });
      added = _.reject(added, item => _.find(this.value, { index: item.index }));
      added = _.map(added, item => ({ ..._.omit(item, 'new'), added: true }));
      const modified = this.value.map(value => _.find(detections, { index: value.index }) || value);
      this.$emit('input', _.reject(modified.concat(added), { remove: true, added: true }));
    },
  },
};
</script>

<style scoped lang="sass">
</style>
