<template>
  <div>
    <round-button
      class="sensor-form__btn my-3"
      icon="fa-upload"
      @click.native.stop="$refs.uploader.click()"
      v-bind="$attrs"
    >
      <slot/>
      <input
        type="file"
        multiple
        id="image-upload"
        ref="uploader"
        :accept="acceptedFiletypes.join(', ')"
        data-testid="upload"
        @change='onUpload'
      />
    </round-button>
    <card-dialog
      content-class="progress-dialog"
      :value="progressDialogVisible"
      persistent
      :width="600"
      :title="$t('label.upload')"
    >
      <v-row justify-center no-gutters>
        <table>
          <tr v-for="upload in uploads">
            <td class="filename">{{ upload.filename }}</td>
            <td>
              <span v-if="upload.message">
                {{ upload.message }}
                <template v-if="upload.confirmOverwrite">
                  <icon-button
                    icon="fa-check"
                    color="green"
                    data-testid="overwrite"
                    @click="onOverwrite(upload)"
                  />
                  <icon-button
                    icon="fa-times"
                    color="red"
                    @click="() => { onCancelOverwrite(upload); }"
                  />
                </template>
              </span>
              <v-progress-linear
                class="primary--text"
                v-model="upload.progress"
                v-else
              />
            </td>
          </tr>
        </table>
      </v-row>
      <template #actions>
        <dialog-action
          class="progress-dialog__close"
          @click="() => { progressDialogVisible = false; }"
          v-if="allUploadFinished"
        >
          {{ $t('button.close') }}
        </dialog-action>
        <dialog-action
          class="progress-dialog__cancel"
          @click="cancelRemainingUploads"
          v-else
        >
          {{ $t('button.cancel') }}
        </dialog-action>
      </template>
    </card-dialog>
  </div>
</template>

<i18n lang="yaml">
ja:
  button:
    close: '閉じる'
    cancel: 'キャンセル'

  label:
    upload: 'アップロード'

  msg:
    upload_success: 'ファイルをアップロードしました。'
    upload_failure: 'アップロード中にエラーが発生しました。しばらくしてからもう一度お試しください。'
    upload_wrong_filetype: 'ファイルの形式は対応していません。'
    upload_overwrite_confirm: '既に検出データが存在します。上書きしますか？'
    upload_canceled: 'アップロードはキャンセルされました。'

en:
  button:
    close: 'Close'
    cancel: 'Cancel'

  label:
    upload: 'Upload'

  msg:
    upload_success: 'File was successfully uploaded.'
    upload_failure: 'An error occurred during the upload. Please try again after a while.'
    upload_wrong_filetype: 'File type is not allowed.'
    upload_overwrite_confirm: 'An event already exists. Do you want to overwrite it?'
    upload_canceled: 'Upload was canceled.'
</i18n>

<script>
import { getFileTimestamp } from '@/libs/upload';
import CardDialog from '@/components/atoms/CardDialog';
import DialogAction from '@/components/atoms/DialogAction';
import IconButton from '@/components/atoms/IconButton';
import RoundButton from '@/components/atoms/RoundButton';

export default {
  name: 'upload-button',
  props: {
    acceptedFiletypes: Array,
    sensorId: String,
  },
  components: {
    CardDialog,
    DialogAction,
    IconButton,
    RoundButton,
  },
  computed: {
    allUploadFinished() {
      return this.uploads.every(u => u.finished);
    },
  },
  data() {
    return {
      progressDialogVisible: false,
      uploads: [],
    };
  },
  methods: {
    cancelRemainingUploads() {
      this.uploads.forEach((upload) => {
        if (!upload.finished) {
          if (upload.cancelHandler) {
            upload.cancelHandler.cancel(this.$t('msg.upload_canceled'));
          }
          upload.finished = true;
        }
      });
    },
    clearSelectedFile() {
      this.$refs.uploader.value = null;
    },
    checkFile: async function (upload) {
      if (!this.acceptedFiletypes.includes(upload.file.type)) {
        upload.message = this.$t('msg.upload_wrong_filetype', { filename: upload.file.name });
        upload.finished = true;
        return;
      }

      upload.timestamp = await getFileTimestamp(upload.file);

      try {
        upload.uploadUrl = await this.$store.dispatch('getUploadUrl', {
          sensorId: this.sensorId,
          timestamp: upload.timestamp,
          contentType: upload.file.type,
          overwrite: false,
        });
        this.startUpload(upload);
      } catch (error) {
        if (error.response && error.response.status === 422) {
          upload.confirmOverwrite = true;
          upload.message = this.$t('msg.upload_overwrite_confirm');
        }
      }
    },
    onCancelOverwrite(upload) {
      upload.confirmOverwrite = false;
      upload.message = this.$t('msg.upload_canceled');
      upload.finished = true;
    },
    onOverwrite: async function (upload) {
      upload.confirmOverwrite = false;
      upload.message = null;
      upload.uploadUrl = await this.$store.dispatch('getUploadUrl', {
        sensorId: this.sensorId,
        timestamp: upload.timestamp,
        contentType: upload.file.type,
        overwrite: true,
      });
      this.startUpload(upload);
    },
    onUpload() {
      const files = this.$refs.uploader.files;
      const uploads = [];

      for (let i = 0; i < files.length; i += 1) {
        uploads.push({
          file: files[i],
          filename: files[i].name,
          timestamp: null,
          progress: 0,
          message: null,
          confirmOverwrite: false,
          finished: false,
          cancelHandler: null,
          uploadUrl: null,
        });
      }
      this.clearSelectedFile();

      this.uploads = uploads;

      this.progressDialogVisible = true;
      this.uploads.forEach(upload => this.checkFile(upload));
    },
    startUpload: async function (upload) {
      try {
        const { file, uploadUrl } = upload;

        upload.cancelHandler = this.$http.CancelToken.source();
        await this.$http.put(uploadUrl, file, {
          headers: {
            'Content-Type': file.type,
          },
          cancelToken: upload.cancelHandler.token,
          onUploadProgress: (progressEvent) => {
            upload.progress = (progressEvent.loaded * 100) / progressEvent.total;
          },
        });
        upload.message = this.$t('msg.upload_success');
      } catch (error) {
        upload.message = this.$t('msg.upload_failure');
      }
      upload.finished = true;
    },
  },
};
</script>

<style scoped lang="sass">
.progress-dialog
  table
    width: 100%

    .filename
      width: 35%
</style>
