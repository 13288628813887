<template>
  <div class="text-center">
    <v-progress-circular
      indeterminate
      :size="100"
      class="primary--text"
    />
  </div>
</template>

<script>
export default {
  name: 'page-load-spinner',
};
</script>

<style scoped lang="sass">
</style>
