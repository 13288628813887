<template>
  <div v-if="site">
    <page-title-area>
      <entity-header :details="`(${SENSOR_TYPE_NAMES[site.type]})`">
        {{ site.name }}
      </entity-header>
    </page-title-area>
    <entity-form>
      <template #form="{ editing }">
        <form-group>
          <template #label><span>{{ $t('field.name') }}</span></template>
          <template #input>
            <text-field
              v-model="model.name"
              :edit="editing"
            />
          </template>
        </form-group>
        <template v-if="hasThreshold">
          <form-group>
            <template #label><span>{{ $t('field.threshold') }}</span></template>
            <template #input>
              <text-field
                type="number"
                v-model="model.threshold"
                :edit="editing"
              />
            </template>
          </form-group>
          <form-group>
            <template #label><span>{{ $t('field.increase_threshold') }}</span></template>
            <template #input>
              <text-field
                type="number"
                v-model="model.increase_threshold"
                :edit="editing"
              />
            </template>
          </form-group>
        </template>
        <form-group>
          <template #label><span>{{ $t('field.memo') }}</span></template>
          <template #input>
            <text-field
              v-model="model.memo"
              :edit="editing"
            />
          </template>
        </form-group>
        <form-group v-if="!admin">
          <template #label><span>{{ $t('field.email_notifications') }}</span></template>
          <template #input>
            <checkbox
              v-model="model.receive_notifications"
              :edit="editing"
            />
          </template>
        </form-group>
        <form-group>
          <template #label><span>{{ $t('field.sensor') }}</span></template>
          <template #input>
            <div v-if="site.sensor">
              <div>
                <router-link :to="sensorRouterLinkTarget(site.sensor.id)">
                  {{ site.sensor.name }}
                </router-link>
              </div>
              <div>
                <span class="font-weight-bold mr-2">{{ $t('label.installed_on') }}:</span>
                <span>{{ sensorInstalledOn }}</span>
              </div>
              <div v-if="sensorSupportsHealthCheck">
                <div v-if="sensor">
                  <sensor-status
                    :status="sensor.health_check_status"
                    :canDownload="admin"
                    class="mt-1"
                  />
                  <v-row justify="start" class="mt-3" no-gutters>
                    <span class="font-weight-bold mr-2">{{ $t('label.last_active') }}:</span>
                    <span data-testid="sensor_last_active">{{ sensorLastActive }}</span>
                  </v-row>
                </div>
                <v-progress-circular indeterminate :size="40" class="primary--text" v-else />
              </div>
            </div>
          </template>
        </form-group>
        <form-group v-if="admin">
          <template #label><span>{{ $t('field.organization_name') }}</span></template>
          <template #input>
            <router-link :to="{ name: 'admin-organization', params: { id: site.organization.id } }">
              {{ site.organization.name }}
            </router-link>
          </template>
        </form-group>
      </template>
    </entity-form>
  </div>
  <page-load-spinner v-else />
</template>

<i18n lang="yaml">
ja:
  field:
    name: 設置場所名
    threshold: しきい値
    increase_threshold: 増加量のしきい値
    memo: メモ
    email_notifications: メール通知
    sensor: センサー
    organization_name: 組織名

  label:
    installed_on: 設置日
    last_active: 最終接続日時
    no_timestamp: なし

en:
  field:
    name: Name
    threshold: Threshold
    increase_threshold: Increase Threshold
    memo: Notes
    email_notifications: E-mail notifications
    sensor: Sensor
    organization_name: Organization Name

  label:
    installed_on: Installed On
    last_active: Last active
    no_timestamp: Never
</i18n>

<script>
import moment from 'moment';

import SensorTypes from '@/mixins/sensorTypes';

import EntityForm from '@/components/atoms/EntityForm';
import EntityHeader from '@/components/atoms/EntityHeader';
import PageLoadSpinner from '@/components/atoms/PageLoadSpinner';
import PageTitleArea from '@/components/atoms/PageTitleArea';
import SensorStatus from '@/components/organisms/SensorStatus';
import Checkbox from '@/components/Form/Checkbox';
import FormGroup from '@/components/Form/FormGroup';
import TextField from '@/components/Form/TextField';

export default {
  name: 'installation-site',
  mixins: [
    SensorTypes,
  ],
  props: {
    admin: Boolean,
    id: String,
  },
  components: {
    Checkbox,
    EntityForm,
    EntityHeader,
    FormGroup,
    PageLoadSpinner,
    PageTitleArea,
    SensorStatus,
    TextField,
  },
  data() {
    return {
      site: null,
      model: {
        name: 'abc',
      },
    };
  },
  computed: {
    hasThreshold() {
      return this.sensorCapabilities.threshold;
    },
    sensor() {
      if (!this.site || !this.site.sensor) {
        return null;
      }

      return this.$store.getters.getSensor(this.site.sensor.id);
    },
    sensorCapabilities() {
      if (!this.site) {
        return {};
      }

      const sensorDefinition = this.SENSOR_DEFINITIONS[this.site.type];
      return sensorDefinition.capabilities;
    },
    sensorInstalledOn() {
      if (!this.site || !this.site.sensor) {
        return '';
      }

      return moment(this.site.sensor.installed_at).format('YYYY/MM/DD');
    },
    sensorLastActive() {
      if (!this.sensor) {
        return '';
      }

      if (!this.sensor.last_active) {
        return this.$t('label.no_timestamp');
      }

      return moment.unix(this.sensor.last_active).format('YYYY/MM/DD HH:mm');
    },
    sensorSupportsHealthCheck() {
      return this.sensorCapabilities.health_check;
    },
  },
  mounted: async function () {
    this.site = await this.fetchInstallationSite(this.id);
    this.loading = false;
    if (this.site.sensor && this.sensorSupportsHealthCheck) {
      this.$store.dispatch('requestSensor', this.site.sensor.id);
    }
  },
  inject: ['fetchInstallationSite'],
  methods: {
    buildModel(site) {
      const result = {
        name: site.name,
        memo: site.memo,
        receive_notifications: site.receive_notifications,
      };

      if (this.hasThreshold) {
        result.threshold = site.threshold;
        result.increase_threshold = site.increase_threshold;
      }

      return result;
    },
    sensorRouterLinkTarget(sensorId) {
      const routeName = this.admin ? 'admin-sensor' : 'sensor';
      return { name: routeName, params: { id: sensorId } };
    },
  },
  watch: {
    site(value) {
      this.model = this.buildModel(value);
    },
  },
};
</script>

<style scoped lang="sass">
</style>
