<template>
  <div>
    <v-toolbar class="appbar" elevation="0">
      <v-toolbar-title class="title mr-8">
        <img src="../assets/logo_sm.png" class="logo py-3">
      </v-toolbar-title>
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn
          v-for="item in items"
          :key="item.title"
          :to="item.path"
          color="primary"
          text
        >
          {{ item.title }}
        </v-btn>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      <span class='hidden-md-and-up' @click="toggleNavigation()">
        <v-btn class="appbar__nav-toggle" icon><v-icon>{{ appBarIcon }}</v-icon></v-btn>
      </span>
      <v-toolbar-items class="hidden-sm-and-down mr-0">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="appbar__username"
              color="primary"
              text
              v-bind="attrs"
              v-on="on"
              aria-label="toggle"
            >
              <v-icon left>fa-smile-o</v-icon>
              {{userName}}
              <v-icon right class="ml-4">expand_more</v-icon>
            </v-btn>
          </template>
          <v-list class="appbar__menu-list" aria-label="menu">
            <v-list-item class="appbar__menu-list__item" :to="accountPath">
              <v-list-item-title>{{ accountLabel }}</v-list-item-title>
            </v-list-item>
            <v-list-item class="appbar__menu-list__item" :to="organizationPath">
              <v-list-item-title>{{ organizationLabel }}</v-list-item-title>
            </v-list-item>
            <v-list-item class="appbar__menu-list__item appbar__menu-list__logout" @click="signOut()">
              <v-list-item-title>{{ logoutLabel }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-toolbar>
    <navigation class="hidden-md-and-up" v-model="navigationVisible" :admin="admin"/>
  </div>
</template>

<script>
import Navigation from '@/mixins/navigation';
import NavigationComponent from './Navigation';

export default {
  name: 'app-bar',
  mixins: [
    Navigation,
  ],
  components: {
    Navigation: NavigationComponent,
  },
  data() {
    return {
      navigationVisible: false,
    };
  },
  computed: {
    appBarIcon() {
      return this.navigationVisible ? 'close' : 'menu';
    },
  },
  methods: {
    toggleNavigation() {
      this.navigationVisible = !this.navigationVisible;
    },
  },
};
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'
.appbar
  padding-left: $container-padding-x
  padding-right: $container-padding-x

  .title, .logo
    height: 100%

  &__username
    text-transform: none

  &__menu-list
    text-transform: uppercase

  @media (min-width: 1024px)
    padding-left: calc(#{$container-padding-x} * 5)
    padding-right: calc(#{$container-padding-x} * 5)

  ::v-deep .v-toolbar__content
    padding-left: 0
    padding-right: 0

  ::v-deep .v-menu
    &__activator
      height: 100%

    .v-btn
      height: 100%
      margin: 0
</style>
