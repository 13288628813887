<template>
  <div v-if="eventsLoading" class="d-flex flex-column">
    <h2 class="text-center">{{ sensor.name }}</h2>
    <v-progress-circular
      indeterminate
      size="100"
      class="primary--text ma-auto"
    />
  </div>
  <daily-insect-count-chart
    v-else
    :event-data="events"
    :month="month"
    :y-max="yMax"
    @max-count-updated="value => $emit('max-count-updated', value)"
  />
</template>

<script>
import moment from 'moment';
import EventContainer from '@/mixins/eventContainer';
import DailyInsectCountChart from '@/components/organisms/DailyInsectCountChart';

export default {
  name: 'loading-insect-count-line-chart',
  mixins: [EventContainer],
  components: {
    DailyInsectCountChart,
  },
  props: {
    sensor: {
      type: Object,
      required: true,
    },
    month: {
      type: String,
      required: true,
    },
    yMax: {
      type: Number,
      required: true,
    },
  },
  computed: {
    startDate() {
      return moment(this.month, 'YYYY-MM').startOf('month').format('YYYY-MM-DD');
    },
    endDate() {
      return moment(this.month, 'YYYY-MM').endOf('month').format('YYYY-MM-DD');
    },
  },
  inject: ['getSensorEvents'],
  methods: {
    // overwrite eventContainer's method
    getEvents(params) {
      return this.getSensorEvents(this.sensor.id, {
        from: moment(this.startDate).startOf('day').unix(),
        to: moment(this.endDate).add(1, 'day').startOf('day').unix(),
        ...params,
      });
    },
  },
  mounted() {
    this.loadAllEvents();
  },
  destroyed() {
    this.cancelEventLoading();
  },
  watch: {
    month() {
      this.clearEvents();
      this.loadAllEvents();
    },
  },
};
</script>
