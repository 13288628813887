var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: { fullscreen: _vm.value } },
    [
      _vm._t("default"),
      _vm._v(" "),
      _vm.value
        ? _c("fullscreen-exit", {
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$emit("input", false)
              },
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }