var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "page-title-area",
        {
          scopedSlots: _vm._u([
            {
              key: "right-floating-content",
              fn: function () {
                return [_c("station-plan-status")]
              },
              proxy: true,
            },
          ]),
        },
        [_c("h1", [_vm._v("S-TYPE")])]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex align-baseline justify-end mt-4" },
        [_c("csv-import-button", { on: { placesCreated: _vm.loadPlaces } })],
        1
      ),
      _vm._v(" "),
      _c("station-place-table", {
        attrs: { places: _vm.places, loading: _vm.loading },
        on: {
          edit: _vm.editPlace,
          delete: _vm.confirmDelete,
          toggleHidden: _vm.toggleHidden,
        },
      }),
      _vm._v(" "),
      _c("confirm-dialog", {
        attrs: {
          message: _vm.$t("confirm_delete", {
            placeName: _vm.deleteTarget.name,
          }),
        },
        on: {
          yes: function ($event) {
            return _vm.deletePlace(_vm.deleteTarget)
          },
        },
        model: {
          value: _vm.deleteConfirmDialogVisible,
          callback: function ($$v) {
            _vm.deleteConfirmDialogVisible = $$v
          },
          expression: "deleteConfirmDialogVisible",
        },
      }),
      _vm._v(" "),
      _c("station-place-editor", {
        attrs: { place: _vm.editTarget },
        on: { update: _vm.updateTable },
        model: {
          value: _vm.editorVisible,
          callback: function ($$v) {
            _vm.editorVisible = $$v
          },
          expression: "editorVisible",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }