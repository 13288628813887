var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tag-picker" },
    [
      _c(
        "dropdown-menu",
        {
          attrs: { width: 400 },
          on: { activate: _vm.show },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function (props) {
                  return [_vm._t("activator", null, null, props)]
                },
              },
            ],
            null,
            true
          ),
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "tag-picker__content" },
            [
              _vm._l(_vm.model.tags, function (tag) {
                return [
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "elevation-0 tag-picker__tag tag-picker__tag__selected",
                      attrs: { block: "" },
                      on: {
                        click: function () {
                          return _vm.removeTag(tag)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "tag-picker__tag__name" }, [
                        _vm._v(_vm._s(tag)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-icon",
                        {
                          staticClass: "tag-picker__tag__icon",
                          attrs: { right: "" },
                        },
                        [_vm._v("fa-remove")]
                      ),
                    ],
                    1
                  ),
                ]
              }),
              _vm._v(" "),
              _vm.model.tags.length > 0
                ? _c("v-divider", { staticClass: "my-2" })
                : _vm._e(),
              _vm._v(" "),
              _c("v-text-field", {
                staticClass: "tag-picker__search",
                attrs: {
                  "hide-details": "",
                  placeholder: _vm.placeholder,
                  "prepend-icon": "search",
                },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
              _vm._v(" "),
              _vm.enableAdd || _vm.selectableTags.length > 0
                ? _c("v-divider", { staticClass: "my-2" })
                : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.selectableTags, function (tag) {
                return [
                  _c(
                    "v-btn",
                    {
                      staticClass:
                        "elevation-0 tag-picker__tag tag-picker__tag__available",
                      attrs: { block: "" },
                      on: {
                        click: function () {
                          return _vm.addTag(tag)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "tag-picker__tag__name" }, [
                        _vm._v(_vm._s(tag)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-icon",
                        {
                          staticClass: "tag-picker__tag__icon",
                          attrs: { right: "" },
                        },
                        [_vm._v("fa-plus")]
                      ),
                    ],
                    1
                  ),
                ]
              }),
              _vm._v(" "),
              _vm.addTagVisible
                ? [
                    _c(
                      "v-btn",
                      {
                        staticClass:
                          "elevation-0 tag-picker__tag tag-picker__tag__add",
                        attrs: { block: "" },
                        on: {
                          click: function () {
                            return _vm.addTag(_vm.search)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "tag-picker__tag__name" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("label.add_specific_tag", {
                                t: _vm.search,
                              })
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "v-icon",
                          {
                            staticClass: "tag-picker__tag__icon",
                            attrs: { right: "" },
                          },
                          [_vm._v("fa-plus")]
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "tag-picker__actions" }, [
                _c(
                  "div",
                  { staticClass: "tag-picker__action" },
                  [
                    _c(
                      "round-button",
                      {
                        staticClass: "tag-picker__cancel",
                        attrs: { icon: "fa-arrow-circle-left", fullWidth: "" },
                        on: { click: _vm.onCancel },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("button.cancel")) +
                            "\n          "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "tag-picker__action" },
                  [
                    _c(
                      "round-button",
                      {
                        staticClass: "tag-picker__submit",
                        attrs: {
                          icon: "fa-check-circle",
                          fullWidth: "",
                          disabled: !_vm.existTags,
                        },
                        on: { click: _vm.onSubmit },
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("button.ok")) +
                            "\n          "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }