var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "insect-positions-canvas",
    _vm._g(
      {
        ref: "canvas",
        attrs: {
          imageSrc: _vm.imageSrc,
          positions: _vm.detectionsWithNewDetection,
          allowPick: _vm.isPickMode,
          hideInsectTypes: !_vm.withInsectTypes,
        },
      },
      _vm.canvasEvents
    ),
    [_c("img", { attrs: { src: _vm.imageSrc, width: "100%" } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }