<template>
  <div class="organization-user-list">
    <template v-if="!loaded">
      <div>
        <v-progress-circular indeterminate :size="40" class="load-spinner primary--text"/>
        <span>{{ $t('label.loading') }}</span>
      </div>
    </template>
    <template v-else>
      <user-list
        :users="users"
        :no_users_message="$t('label.no_users')"
        :readonly="!managerable"
      >
        <template v-slot:list-items="{ user }">
          <v-list-item
            @click="appointAsAdmin(user)"
            :disabled="!canAppointAsAdmin(user)"
          >
            {{ $t('label.appoint') }}
          </v-list-item>
          <v-list-item
            class="red--text"
            @click="remove(user)"
            v-if="userIsRemovable(user)">
            {{ $t('label.remove') }}
          </v-list-item>
        </template>
      </user-list>
    </template>
    <confirm-dialog
      v-model="confirm.visible"
      :message="confirm.message"
      @yes="confirm.onConfirm"
    />
    <round-button
      class="mt-2"
      icon="fa-user-plus"
      fullWidth
      @click.stop="showAddUserDialog"
      v-if="managerable"
    >
      {{ $t('button.add_member') }}
    </round-button>
    <card-dialog v-model="dialog.visible" :title="$t('label.add_user')" width="320px">
      <v-select
        required
        hide-details
        dense
        item-text="name"
        item-value="email"
        v-model="selected"
        :items="availables"
        :placeholder="$t('field.select_account')"
        ref="addUser"
        data-testid="organization-user-list__select-user"
        v-if="availablesLoaded"
      >
        <template v-slot:item="{ props, item }">
          <div>
            <v-list-item-title>
              {{ item.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ item.email }}
            </v-list-item-subtitle>
          </div>
        </template>
      </v-select>
      <div class="text-center mt-2" v-else>
        <v-progress-circular indeterminate :size="20" />
      </div>
      <template #actions>
        <dialog-action @click.native="dialog.visible = false">
          {{ $t('button.cancel') }}
        </dialog-action>
        <dialog-action
          @click="add"
          :disabled="!selected || $refs.addUser.hasError"
        >
          {{ $t('button.add') }}
        </dialog-action>
      </template>
    </card-dialog>
    <card-dialog v-model="invitationDialog.visible" :title="$t('label.add_user')" width="290px">
      <v-text-field
        ref="invitationSendTo"
        required
        autofocus
        v-model="invitationDialog.send_to"
        :label="$t('field.invitationEmail')"
        :hint="$t('msg.enter_email')"
        :rules="invitationDialog.rules.send_to"
        data-testid="organization-user-list__invitation-send-to"
      />
      <template #actions>
        <dialog-action @click.native="invitationDialog.visible = false">
          {{ $t('button.cancel') }}
        </dialog-action>
        <dialog-action
          @click="sendInvitation"
          :disabled="invitationDialog.send_to === '' || $refs.invitationSendTo.hasError"
        >
          {{ $t('button.invite') }}
        </dialog-action>
      </template>
    </card-dialog>
  </div>
</template>

<i18n lang="yaml">
ja:
  label:
    no_users: 'メンバーはいません'
    add_user: 'メンバーを追加する'
    loading: '読み込み中'
    remove: '削除'
    appoint: '代表ユーザーにする'
    name_with_admin: '{value} (代表)'
    waiting_for_registration: '登録まち'

  msg:
    remove_user_confirm: '"{name}"をメンバーから外します、よろしいですか？'
    appoint_as_admin_confirm: '代表ユーザーを"{name}"に移動します、よろしいですか？'
    adding_success: 'メンバーへ追加しました。'
    remove_success: 'メンバーから外しました。'
    update_failure: '更新中にエラーが発生しました。'
    enter_email: '招待するユーザーのメールアドレスを入力してください。'
    invitation_success: '招待を送信しました。'
    invitation_failure: '招待中にエラーが発生しました。'
    already_invited: 'そのユーザーは招待済みです。'
    already_associated: 'そのユーザーは他の組織に所属しています。'

  button:
    add: '追加'
    cancel: 'キャンセル'
    add_member: 'メンバーを追加'
    invite: '招待する'

  field:
    select_account: 'アカウントを選択'
    invitationEmail: 'E-mail'

en:
  label:
    no_users: 'No users in this organization'
    add_user: 'Add user as member'
    loading: 'Loading'
    remove: 'Remove'
    appoint: 'Appoint as admin'
    name_with_admin: '{value} (Admin)'
    waiting_for_registration: 'Waiting for registration'

  msg:
    remove_user_confirm: '"{name}" will be removed from this organization. Are you sure?'
    appoint_as_admin_confirm: 'This will transfer the admin privileges for this organization to "{name}". Are you sure?'
    adding_success: 'User was successfully added.'
    remove_success: 'User was successfully removed.'
    update_failure: 'An error occured while updating.'
    enter_email: 'Please enter the email address of the user to invite.'
    invitation_success: 'Invitation was successfully sent.'
    invitation_failure: 'An error occured while sending the invitation.'
    already_invited: 'The user is already invited.'
    already_associated: 'The user is already associated with another organization.'

  button:
    add: 'Add'
    cancel: 'Cancel'
    add_member: 'Add user'
    invite: 'Invite'

  field:
    select_account: 'Select account'
    invitationEmail: 'E-mail'
</i18n>

<script>
import Notifications from '@/mixins/notifications';
import Validations from '@/components/Form/mixins/form-validations';
import CardDialog from '@/components/atoms/CardDialog';
import DialogAction from '@/components/atoms/DialogAction';
import RoundButton from '@/components/atoms/RoundButton';
import ConfirmDialog from '@/components/atoms/ConfirmDialog';
import UserList from '@/components/molecules/UserList';

export default {
  name: 'organization-user-list',
  mixins: [
    Notifications,
    Validations,
  ],
  components: {
    CardDialog,
    ConfirmDialog,
    DialogAction,
    RoundButton,
    UserList,
  },
  props: {
    admin: Boolean,
    organization: {
      type: Object,
      default: () => ({}),
    },
  },
  created: async function () {
    if (this.admin) {
      this.$store.dispatch('requestUsers');
    }
    await this.$store.dispatch('requestOrganizationUsers', this.organization.id);
    this.loading = false;
  },
  data() {
    return {
      confirm: {
        visible: false,
        message: '',
        onConfirm: () => {},
      },
      dialog: {
        visible: false,
      },
      invitationDialog: {
        send_to: '',
        visible: false,
        rules: {
          send_to: [
            this.userAlreadyInvited,
            this.email(this.$t('field.invitationEmail')),
          ],
        },
      },
      selected: '',
      loading: true,
    };
  },
  computed: {
    availables() {
      const users = this.$store.getters.getUsers;
      return this._.sortBy(users.valuesArray(), 'name')
        .filter((user) => {
          const permissions = user.permissions || [];
          const isNotAssociated = !user.owner_id;
          const isNotAdmin = !permissions.includes('admin');
          return isNotAssociated && isNotAdmin;
        });
    },
    availablesLoaded() {
      const users = this.$store.getters.getUsers;
      return users.length > 0;
    },
    managerable() {
      const { email: adminEmail } = this.organizationAdmin;
      const { email } = this.$store.getters.getCurrentUser;

      return adminEmail === email || this.admin;
    },
    users() {
      const users = this.$store.getters.getOrganizationUsers(this.organization.id);
      const mapped = users.map((user) => {
        const name = user.name || this.$t('label.waiting_for_registration');
        return {
          ...user,
          name: user.admin ? this.$t('label.name_with_admin', { value: name }) : name,
        };
      });
      return this._.orderBy(mapped, ['admin', 'invitation', 'name', 'email'], ['desc', 'asc', 'asc', 'asc']);
    },
    loaded() {
      return !this.loading;
    },
    organizationAdmin() {
      const { admin = {} } = this.organization;
      return admin;
    },
  },
  methods: {
    appointAsAdmin(user) {
      this.confirm.message = this.$t('msg.appoint_as_admin_confirm', { name: user.name });
      this.confirm.onConfirm = () => this.$emit('appointAsAdmin', user);
      this.confirm.visible = true;
    },
    canAppointAsAdmin(user) {
      return !user.admin && !user.invitation;
    },
    remove(user) {
      this.confirm.message = this.$t('msg.remove_user_confirm', { name: user.name });
      this.confirm.onConfirm = async () => {
        try {
          await this.$store.dispatch('removeOrganizationUser', {
            id: this.organization.id,
            email: user.email,
          });
          this.notifySuccess('msg.remove_success');
        } catch (error) {
          this.notifyError('msg.update_failure');
        }
      };
      this.confirm.visible = true;
    },
    showAddUserDialog() {
      if (this.admin) {
        this.selected = '';
        this.dialog.visible = true;
      } else {
        this.invitationDialog.send_to = '';
        this.invitationDialog.visible = true;
      }
    },
    add: async function () {
      try {
        await this.$store.dispatch('sendOrganizationInvitation', {
          id: this.organization.id,
          email: this.selected,
        });
        this.notifySuccess('msg.adding_success');
        this.dialog.visible = false;
      } catch (error) {
        this.notifyError('msg.update_failure');
      }
    },
    sendInvitation: async function () {
      try {
        const organizationUser = await this.$store.dispatch('sendOrganizationInvitation', {
          id: this.organization.id,
          email: this.invitationDialog.send_to,
        });

        if (organizationUser.invitation) {
          this.notifySuccess('msg.invitation_success');
        } else {
          this.notifySuccess('msg.adding_success');
        }
        this.invitationDialog.visible = false;
        this.invitationDialog.send_to = '';
      } catch (error) {
        const type = this._.get(error, 'response.data.type', 'invitation_failure');
        this.notifyError(`msg.${type}`);
      }
    },
    userAlreadyInvited() {
      const inputEmail = this.invitationDialog.send_to;
      const users = this.$store.getters.getOrganizationUsers(this.organization.id);
      return users.filter(u => u.email === inputEmail).length > 0 ? this.$t('msg.already_invited') : true;
    },
    userIsRemovable(user) {
      const { email } = this.$store.getters.getCurrentUser;
      return user.email !== email;
    },
  },
};
</script>

<style scoped lang="sass">
.organization-user-list
  div
    .load-spinner, span
      display: inline-block
      vertical-align: middle
</style>
