var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      ref: "menu",
      attrs: {
        value: _vm.value,
        "close-on-content-click": false,
        "content-class": "dropdown-tooltip",
        transition: "slide-y-transition",
        "offset-y": "",
      },
      on: { input: _vm.onVisibilityChange },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function (props) {
              return [_vm._t("activator", null, null, props)]
            },
          },
        ],
        null,
        true
      ),
    },
    [_vm._v(" "), _vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }