<template>
  <v-row class="form-field">
    <v-col cols="4" class="d-flex align-center">
      <span>{{ label }}</span>
      <span v-if="required" class="red--text">*</span>
    </v-col>
    <v-col cols="8">
      <slot/>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'form-field',
  props: {
    label: {
      type: String,
      required: true,
    },
    required: Boolean,
  },
};
</script>

<style scoped lang="sass">
.form-field
  ::v-deep .v-text-field__details
    margin-bottom: -10px !important
</style>
