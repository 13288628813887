var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "activity-list" },
    [
      _c(
        "page-title-area",
        {
          scopedSlots: _vm._u([
            {
              key: "side-content",
              fn: function () {
                return [
                  _c(
                    "v-btn",
                    { attrs: { text: "" }, on: { click: _vm.expandSensors } },
                    [
                      _c("v-icon", { attrs: { left: "", color: "primary" } }, [
                        _vm._v("fa-chevron-circle-down"),
                      ]),
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("button.expand_all")) +
                          "\n      "
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [_c("h1", [_vm._v("ACTIVITY")])]
      ),
      _vm._v(" "),
      _c(
        "query-filter",
        {
          attrs: {
            loading: _vm.loading,
            clearable: _vm.hasEvents() || _vm.hasFilter,
          },
          on: { apply: _vm.executeQuery, clear: _vm.clearQuery },
        },
        [
          _c(
            "v-row",
            { staticClass: "mb-4", attrs: { dense: "" } },
            [
              _c(
                "v-col",
                [
                  _c("v-select", {
                    staticClass: "query-filter__input--type",
                    attrs: {
                      items: _vm.sensorTypeItems,
                      placeholder: _vm.$t("field.type"),
                      disabled: _vm.hasSensorId,
                      "hide-details": "",
                      clearable: "",
                      outlined: "",
                      "prepend-icon": "location_searching",
                    },
                    model: {
                      value: _vm.form.sensor.type,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.sensor, "type", $$v)
                      },
                      expression: "form.sensor.type",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                [
                  _c("autocomplete-text-field", {
                    attrs: {
                      "allow-new-values": "",
                      clearable: "",
                      label: _vm.$t("field.installation_site"),
                      items: _vm.placeItems,
                      disabled: _vm.hasSensorId,
                      icon: "location_on",
                      "data-testid": "sensor_place_query",
                    },
                    model: {
                      value: _vm.form.sensor.place,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.sensor, "place", $$v)
                      },
                      expression: "form.sensor.place",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                [
                  _c("autocomplete-text-field", {
                    attrs: {
                      "allow-new-values": "",
                      clearable: "",
                      label: _vm.$t("field.sensor_name"),
                      items: _vm.nameItems,
                      icon: "location_searching",
                      disabled: _vm.hasSensorId,
                      "data-testid": "sensor_name_query",
                    },
                    model: {
                      value: _vm.form.sensor.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.sensor, "name", $$v)
                      },
                      expression: "form.sensor.name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                [
                  _c("term-picker", {
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function (ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-text-field",
                              _vm._g(
                                _vm._b(
                                  {
                                    attrs: {
                                      placeholder: _vm.$t("field.time_period"),
                                      "hide-details": "",
                                      outlined: "",
                                      dense: "",
                                      "prepend-inner-icon": "fa-calendar",
                                      readonly: "",
                                      value: _vm.termLabel,
                                    },
                                  },
                                  "v-text-field",
                                  attrs,
                                  false
                                ),
                                on
                              )
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.form.event.term,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.event, "term", $$v)
                      },
                      expression: "form.event.term",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                [
                  _c("tag-picker", {
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function (ref) {
                          var on = ref.on
                          var attrs = ref.attrs
                          return [
                            _c(
                              "v-text-field",
                              _vm._g(
                                _vm._b(
                                  {
                                    attrs: {
                                      placeholder: _vm.$t("field.tag"),
                                      "prepend-inner-icon": "local_offer",
                                      "hide-details": "",
                                      readonly: "",
                                      outlined: "",
                                      dense: "",
                                    },
                                    model: {
                                      value: _vm.tagsLabel,
                                      callback: function ($$v) {
                                        _vm.tagsLabel = $$v
                                      },
                                      expression: "tagsLabel",
                                    },
                                  },
                                  "v-text-field",
                                  attrs,
                                  false
                                ),
                                on
                              )
                            ),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.form.event.tags,
                      callback: function ($$v) {
                        _vm.$set(_vm.form.event, "tags", $$v)
                      },
                      expression: "form.event.tags",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "filter__checkbox text-md-center" }, [
            _c(
              "div",
              { staticClass: "checkbox-box" },
              [
                _c("v-checkbox", {
                  attrs: { "hide-details": "" },
                  model: {
                    value: _vm.form.event.pickup,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.event, "pickup", $$v)
                    },
                    expression: "form.event.pickup",
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "checkbox-box__label" },
                  [
                    _c("v-icon", [_vm._v("star")]),
                    _c("span", [_vm._v(_vm._s(_vm.$t("field.pickup")))]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "checkbox-box" },
              [
                _c("v-checkbox", {
                  attrs: { "hide-details": "" },
                  model: {
                    value: _vm.form.event.misdetection,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.event, "misdetection", $$v)
                    },
                    expression: "form.event.misdetection",
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "checkbox-box__label" },
                  [
                    _c("v-icon", [_vm._v("sentiment_very_dissatisfied")]),
                    _vm._v(_vm._s(_vm.$t("field.false_positives"))),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "checkbox-box" },
              [
                _c("v-checkbox", {
                  attrs: { "hide-details": "" },
                  model: {
                    value: _vm.form.event.exceptMisdetection,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.event, "exceptMisdetection", $$v)
                    },
                    expression: "form.event.exceptMisdetection",
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "checkbox-box__label" }, [
                  _vm._v(_vm._s(_vm.$t("field.omit_false_positives"))),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "checkbox-box" },
              [
                _c("v-checkbox", {
                  attrs: { "hide-details": "" },
                  model: {
                    value: _vm.form.event.exceeded,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.event, "exceeded", $$v)
                    },
                    expression: "form.event.exceeded",
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "checkbox-box__label" }, [
                  _vm._v(_vm._s(_vm.$t("field.exceeding_threshold"))),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "checkbox-box" },
              [
                _c("v-checkbox", {
                  attrs: { "hide-details": "" },
                  model: {
                    value: _vm.form.event.includeHidden,
                    callback: function ($$v) {
                      _vm.$set(_vm.form.event, "includeHidden", $$v)
                    },
                    expression: "form.event.includeHidden",
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "checkbox-box__label" },
                  [
                    _c("v-icon", [_vm._v("fa-eye-slash")]),
                    _vm._v(_vm._s(_vm.$t("field.include_hidden"))),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.featureIsActive("bookmark_sensors")
              ? _c(
                  "div",
                  { staticClass: "checkbox-box" },
                  [
                    _c("v-checkbox", {
                      attrs: {
                        "hide-details": "",
                        "data-testid": "bookmarked_sensors_checkbox",
                      },
                      model: {
                        value: _vm.form.sensor.onlyBookmarked,
                        callback: function ($$v) {
                          _vm.$set(_vm.form.sensor, "onlyBookmarked", $$v)
                        },
                        expression: "form.sensor.onlyBookmarked",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "checkbox-box__label" },
                      [
                        _c("v-icon", [_vm._v("mdi-bookmark")]),
                        _vm._v(_vm._s(_vm.$t("field.bookmarked_sensors"))),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "text-right pt-4" },
        [
          _c("insect-count-line-charts-button", {
            attrs: {
              sensors: _vm.visibleSensors,
              "request-params": _vm.requestParams,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.sensors, function (sensor) {
        return _c("sensor-events", {
          key: sensor.id,
          ref: "sensorEvents",
          refInFor: true,
          attrs: {
            sensor: sensor,
            requestParams: _vm.requestParams,
            expanded: _vm.expandedSensors.includes(sensor.id),
          },
          on: {
            "update:expanded": function (v) {
              return _vm.updateExpanded(sensor.id, v)
            },
            "event:selected": function (selectedEvent, events) {
              return _vm.showEvents(events, selectedEvent, sensor)
            },
            becameVisible: function () {
              return _vm.setVisible(sensor.id)
            },
          },
        })
      }),
      _vm._v(" "),
      !_vm.loading && _vm.sensors.length == 0
        ? _c("no-sensors-message")
        : _vm._e(),
      _vm._v(" "),
      _c("gallery-view", {
        attrs: {
          displayData: _vm.galleryData,
          requestParams: _vm.requestParams,
        },
        model: {
          value: _vm.galleryVisible,
          callback: function ($$v) {
            _vm.galleryVisible = $$v
          },
          expression: "galleryVisible",
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }