<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-tooltip top :disabled="!overlimit">
        <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
          <div class="export-excel-button" v-on="tooltipOn" v-bind="tooltipAttrs">
            <round-button
              icon="file_download"
              :disabled="pending || buttonDisable || overlimit"
              @click="startReportGeneration()"
            >
              <span v-if="!pending">{{ $t('report_download') }}</span>
              <span v-else>{{ $t('pending') }}</span>
            </round-button>
            <round-button
              :disabled="pending || buttonDisable || overlimit"
              v-bind="attrs"
              v-on="on"
            >
              <v-progress-circular v-if="buttonDisable" indeterminate class="primary--text"></v-progress-circular>
              <span v-else="!pending">&#9660;</span>
            </round-button>
          </div>
        </template>
        <span>{{ $t('tooltip.selectRow')}}</span>
      </v-tooltip>
    </template>
    <v-list>
      <v-list-item link @click="startOldReportGeneration()">
        {{ $t('report_download_old') }}
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<i18n lang="yaml">
  ja:
    report_download: '報告書を出力'
    report_download_old: '以前の形式で報告書を出力'
    pending: '報告書を出力中'
    error:
      selectData: '報告書を作成するデータを選択して下さい'
    tooltip:
      selectRow: '対象データは100件以下にしてください'

  en:
    report_download: 'Report Download'
    report_download_old: 'Old Format (Japanese)'
    pending: 'Report Downloading'
    error:
      selectData: 'Select the data for which you want to create a report.'
    tooltip:
      selectRow: 'Report Download cannnot exceed 100 rows.'
</i18n>

<script>
import InsectTypes from '@/mixins/insectTypes';
import Notifications from '@/mixins/notifications';
import RoundButton from '@/components/atoms/RoundButton';

export default {
  name: 'export-excel-button',
  mixins: [InsectTypes, Notifications],
  props: {
    events: {
      type: Array,
      required: true,
    },
  },
  components: {
    RoundButton,
  },
  data() {
    return {
      buttonDisable: false,
    };
  },
  computed: {
    pending() {
      return !!this.$store.getters.getJobId;
    },
    overlimit() {
      return this.events.length > 100;
    },
  },
  methods: {
    startOldReportGeneration: async function () {
      await this.startReportGeneration({ old: true });
    },
    startReportGeneration: async function (options) {
      if (Object.keys(this.events).length === 0) {
        this.notifyError('error.selectData');
        return;
      }

      this.buttonDisable = true;
      const jobId = await this.$store.dispatch('postGenerateReport', {
        events: this.events.map(e => e.id),
        locale: this.$i18n.locale,
        ...(options || { old: false }),
      });

      this.$store.dispatch('polling', jobId);
      this.buttonDisable = false;
    },
  },
};
</script>

<style scoped lang="sass">
.export-excel-button
  display: inline-flex

  .v-btn
    margin: 0

    &:first-child
      border-radius: 28px 0 0 28px
      padding-right: 0 !important

    &:last-child
      border-radius: 0 28px 28px 0
      min-width: 48px
      padding-left: 0 !important

.v-menu
  display: none

.v-menu__content
  position: absolute

  .v-list
    margin-top: 2px

  .v-list-item
    min-height: 24px
</style>
