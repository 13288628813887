var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { staticClass: "form-field" },
    [
      _c(
        "v-col",
        { staticClass: "d-flex align-center", attrs: { cols: "4" } },
        [
          _c("span", [_vm._v(_vm._s(_vm.label))]),
          _vm._v(" "),
          _vm.required
            ? _c("span", { staticClass: "red--text" }, [_vm._v("*")])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("v-col", { attrs: { cols: "8" } }, [_vm._t("default")], 2),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }