import Vue from 'vue';
import Cookies from 'js-cookie';

import * as types from '../constants/mutationTypes';

export const actions = {
  async signIn({ commit, dispatch }, { email, password, keep }) {
    const response = await Vue.http.post('/api/signin', { email, password });
    commit(types.SET_TOKEN, { token: response.data.access_token, keep });
    await dispatch('getUserInfo');
  },
  signOut({ commit, dispatch }) {
    commit(types.CLEAR_TOKEN);
    dispatch('clearUserInfo');
    dispatch('clearUsers');
    dispatch('clearSensors');
  },
};

export const mutations = {
  SET_TOKEN(state, { token, keep }) {
    state.token = token;

    Cookies.set('token', state.token, { expires: keep ? 1 : '' });
  },
  CLEAR_TOKEN(state) {
    state.token = null;

    Cookies.remove('token');
  },
};

export const getters = {
  isSignedIn(state) {
    return !!state.token;
  },
  getAccessToken(state) {
    return state.token;
  },
};

export const state = () => ({
  token: Cookies.getJSON('token'),
});
