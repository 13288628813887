var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pv-checkbox" },
    [
      _vm.edit
        ? _c("v-checkbox", {
            attrs: {
              label: _vm.label,
              inputValue: _vm.inputValue,
              value: _vm.value,
              "hide-details": "",
              "data-testid": _vm.dataTestid,
            },
            on: {
              change: function (v) {
                return _vm.$emit("input", v)
              },
            },
          })
        : _c(
            "span",
            [
              _vm.checked
                ? _c(
                    "v-icon",
                    { staticClass: "pv-checkbox__icon", attrs: { left: "" } },
                    [_vm._v("check_box")]
                  )
                : _c(
                    "v-icon",
                    { staticClass: "pv-checkbox__icon", attrs: { left: "" } },
                    [_vm._v("check_box_outline_blank")]
                  ),
              _vm._v("\n    " + _vm._s(_vm.label) + "\n  "),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }