var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: { "offset-y": "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function (ref) {
            var on = ref.on
            var attrs = ref.attrs
            return [
              _c(
                "v-tooltip",
                {
                  attrs: { top: "", disabled: !_vm.overlimit },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function (ref) {
                          var tooltipOn = ref.on
                          var tooltipAttrs = ref.attrs
                          return [
                            _c(
                              "div",
                              _vm._g(
                                _vm._b(
                                  { staticClass: "export-excel-button" },
                                  "div",
                                  tooltipAttrs,
                                  false
                                ),
                                tooltipOn
                              ),
                              [
                                _c(
                                  "round-button",
                                  {
                                    attrs: {
                                      icon: "file_download",
                                      disabled:
                                        _vm.pending ||
                                        _vm.buttonDisable ||
                                        _vm.overlimit,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.startReportGeneration()
                                      },
                                    },
                                  },
                                  [
                                    !_vm.pending
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.$t("report_download"))
                                          ),
                                        ])
                                      : _c("span", [
                                          _vm._v(_vm._s(_vm.$t("pending"))),
                                        ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "round-button",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          disabled:
                                            _vm.pending ||
                                            _vm.buttonDisable ||
                                            _vm.overlimit,
                                        },
                                      },
                                      "round-button",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _vm.buttonDisable
                                      ? _c("v-progress-circular", {
                                          staticClass: "primary--text",
                                          attrs: { indeterminate: "" },
                                        })
                                      : _c("span", [_vm._v("▼")]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                },
                [
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.$t("tooltip.selectRow")))]),
                ]
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "v-list",
        [
          _c(
            "v-list-item",
            {
              attrs: { link: "" },
              on: {
                click: function ($event) {
                  return _vm.startOldReportGeneration()
                },
              },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("report_download_old")) + "\n    "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }