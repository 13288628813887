var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "threshold-alert-status" },
    [
      _vm.event.latest
        ? _c(
            "div",
            {
              staticClass:
                "threshold-alert-status__field threshold-alert-status__field--until-alert",
            },
            [
              _c("div", { staticClass: "mr-3" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.$t("untilAlert", { value: _vm.insectsUntilAlert })
                    ) +
                    "\n    "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "threshold-alert-status__progress" }, [
                _c(
                  "div",
                  { staticClass: "flex" },
                  [
                    _c("v-progress-linear", {
                      staticClass: "mb-1",
                      attrs: {
                        color:
                          _vm.insectsUntilAlert > 0 ? "primary" : "warning",
                        value:
                          (_vm.event.count * 100) / _vm.event.sensor_threshold,
                        height: "15",
                      },
                    }),
                    _vm._v(" "),
                    _vm.editable
                      ? _c(
                          "v-btn",
                          {
                            attrs: {
                              depressed: "",
                              rounded: "",
                              small: "",
                              color: "white",
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                _vm.dialog = true
                                _vm.model = _vm.event.sensor_threshold
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("button.update_threshold")) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", {
                  staticClass: "threshold-alert-status__led",
                  class: {
                    "threshold-alert-status__led--red":
                      _vm.insectsUntilAlert <= 0,
                    "threshold-alert-status__led--green":
                      _vm.insectsUntilAlert > 0,
                  },
                }),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "threshold-alert-status__field" }, [
        _vm._v(
          "\n    " +
            _vm._s(
              _vm.$t("sensor_threshold") + " : " + _vm.event.sensor_threshold
            ) +
            "\n  "
        ),
      ]),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "300",
            "content-class": "threshold-alert-status__dialog",
          },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-form",
            {
              model: {
                value: _vm.valid,
                callback: function ($$v) {
                  _vm.valid = $$v
                },
                expression: "valid",
              },
            },
            [
              _vm.dialog
                ? _c("v-text-field", {
                    attrs: {
                      type: "number",
                      autofocus: "",
                      label: _vm.$t("threshold"),
                      rules: _vm.rules,
                    },
                    model: {
                      value: _vm.model,
                      callback: function ($$v) {
                        _vm.model = $$v
                      },
                      expression: "model",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex justify-end" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "ma-0 ",
                  attrs: {
                    color: "primary",
                    disabled:
                      !_vm.valid || _vm.model === _vm.event.sensor_threshold,
                  },
                  on: { click: _vm.submit },
                },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.$t("button.update")) + "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }