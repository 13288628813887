var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.position > 0
    ? _c(
        "v-btn",
        {
          staticClass: "scroll-button hidden-md-and-up",
          attrs: { primary: "", fab: "", dark: "" },
          on: {
            click: function ($event) {
              return _vm.scrollToTop()
            },
          },
        },
        [_c("v-icon", [_vm._v("keyboard_arrow_up")])],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }