<template>
  <div class="station-place-event-list">
    <content-header :title="$t('title')" titleClass="display-1 font-weight-bold" />
    <data-table
      :headers="headers"
      :items="devices"
      :no-data-text="noDataText"
      :loading="loading"
    >
      <template #item="{ item }">
        <td>
          <a :href="item.login_url" target="_blank">
            {{ item.name }}
          </a>
        </td>
        <td>{{ item.ip_address }}</td>
        <td>{{ item.serial_number }}</td>
      </template>
    </data-table>
  </div>
</template>

<i18n lang="yaml">
ja:
  title: 自動投薬機
  no_data: データがありません
  invalid_user_id: mirakun.comのIDが正しく設定されていません
  headers:
    name: 名称
    ip_address: IPアドレス
    serial_number: シリアル番号

en:
  title: Chemical treatment system
  no_data: No data
  invalid_user_id: Your mirakun.com User ID is invalid
  headers:
    name: Name
    ip_address: IP Address
    serial_number: Serial Number
</i18n>

<script>
import DataTable from '@/components/atoms/DataTable';
import ContentHeader from '@/components/ContentHeader';

export default {
  name: 'mirakun-devices',
  components: {
    ContentHeader,
    DataTable,
  },
  inject: ['fetchMirakunDevices'],
  mounted: async function () {
    try {
      this.devices = await this.fetchMirakunDevices();
      this.loading = false;
      if (this.devices.length === 0) {
        this.noDataText = this.$t('no_data');
      }
    } catch (error) {
      if (error.message === 'invalid_user_id') {
        this.loading = false;
        this.noDataText = this.$t('invalid_user_id');
      }
    }
  },
  computed: {
    headers() {
      return [
        { text: this.$t('headers.name'), value: 'name' },
        { text: this.$t('headers.ip_address'), value: 'ip_address' },
        { text: this.$t('headers.serial_number'), value: 'serial_number' },
      ];
    },
  },
  data() {
    return {
      options: {
        sortBy: ['name'],
        sortDesc: [false],
        itemsPerPage: 100,
      },
      devices: [],
      loading: true,
      noDataText: null,
    };
  },
};
</script>

<style lang="sass" scoped>
</style>
