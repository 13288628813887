<template>
  <div class="insect-positions-canvas">
    <slot />
    <canvas ref="canvas" v-on="canvasEvents" v-show="!hideCanvas"/>
  </div>
</template>

<script>
import InsectTypes from '@/mixins/insectTypes';

export default {
  name: 'insect-positions-canvas',
  mixins: [InsectTypes],
  props: {
    imageSrc: String,
    /*
     * Attributes
     * - type: Insect type
     * - position: [x1, y1, x2, y2]
     * - color: Color (optional, default: red)
     */
    positions: Array,
    allowPick: {
      type: Boolean,
      default: false,
    },
    hideInsectTypes: {
      type: Boolean,
      default: false,
    },
    hideCanvas: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    canvasEvents() {
      if (this.allowPick) {
        return {
          click: this.pickDetections,
        };
      }
      return {
        mousedown: this.forwardMouseEvent('mousedown'),
        mousemove: this.forwardMouseEvent('mousemove'),
        mouseup: this.forwardMouseEvent('mouseup'),
      };
    },
    ctx() {
      return this.$refs.canvas.getContext('2d');
    },
    height() {
      return this.image ? this.image.naturalHeight : 0;
    },
    width() {
      return this.image ? this.image.naturalWidth : 0;
    },
  },
  data() {
    return {
      image: null,
    };
  },
  methods: {
    clear() {
      this.ctx.clearRect(0, 0, this.width, this.height);
    },
    convertPosition(mouseRelativeX, mouseRelativeY) {
      return {
        x: (mouseRelativeX * this.width) / this.$refs.canvas.clientWidth,
        y: (mouseRelativeY * this.height) / this.$refs.canvas.clientHeight,
      };
    },
    drawDetections() {
      this.ctx.drawImage(this.image, 0, 0);

      const symbolMargin = 2;
      const scale = this.width / (this.$refs.canvas.clientWidth || this.width);
      this.ctx.lineWidth = Math.max(2 * scale, 2);
      const fontSize = 14 * scale;
      this.ctx.font = `bold ${fontSize}px sans-serif`;
      this.ctx.textBaseline = 'top';
      this.positions.forEach(({ type, position, color }) => {
        this.ctx.strokeStyle = color || '#f00';
        this.ctx.fillStyle = color || '#f00';
        const [x1, y1, x2, y2] = position;
        this.ctx.strokeRect(x1, y1, x2 - x1, y2 - y1);
        if (!this.hideInsectTypes) {
          const textX = x1 + symbolMargin;
          const textY = y1 + symbolMargin;
          const symbol = type ? this.INSECT_TYPES[type].symbol : '';
          this.ctx.fillText(symbol, textX, textY);
        }
      });
    },
    forwardMouseEvent(eventName) {
      return ev =>
        this.$emit(eventName, this.convertPosition(ev.offsetX, ev.offsetY));
    },
    isContained({ x, y }, [x1, y1, x2, y2]) {
      return this._.every([
        x >= x1,
        y >= y1,
        x <= x2,
        y <= y2,
      ]);
    },
    pickDetections(clickEvent) {
      const { offsetX, offsetY } = clickEvent;
      const position = this.convertPosition(offsetX, offsetY);
      const result = [];
      this.positions.forEach((item) => {
        if (this.isContained(position, item.position)) {
          result.push(item);
        }
      });
      if (!this._.isEmpty(result)) {
        this.$emit('pick', result, clickEvent);
      }
    },
    refresh() {
      this.clear();
      if (this.image) {
        this.drawDetections();
      }
    },
    reloadImage() {
      this.image = new Image();
      this.image.src = this.imageSrc;
      this.image.onload = () => {
        this.$refs.canvas.width = this.image.width;
        this.$refs.canvas.height = this.image.height;
        this.refresh();
      };
    },
  },
  watch: {
    imageSrc() {
      this.reloadImage();
    },
    positions() {
      if (!this.image) {
        this.reloadImage();
      } else {
        this.refresh();
      }
    },
  },
};
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'

.insect-positions-canvas
  height: 100%
  width: 100%
  position: relative

  ::v-deep > *
    display: block
    height: 100%
    width: 100%
    object-fit: contain

  canvas
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
</style>
