import Vue from 'vue';
import * as types from '../constants/mutationTypes';

const sleep = seconds => new Promise(resolve => setTimeout(() => resolve(), seconds));

export const actions = {
  getEvent: async (context, { id }) => {
    const response = await Vue.http.get(`/api/v2/events/${id}`);
    return response.data;
  },
  getEvents: async (context, { sensorId, params }) => {
    if (params.from && params.to && params.from >= params.to) {
      return { events: [] };
    }

    const response = await Vue.http.get(`/api/sensors/${sensorId}/events`, { params });
    return {
      events: response.data.events.map(ev => ({
        ...ev,
        tags: ev.tags.sort(),
      })),
      last_evaluated: response.data.last_evaluated,
    };
  },
  getUploadUrl: async function (context, { sensorId, timestamp, overwrite, contentType }) {
    const response = await Vue.http.post(`/api/sensors/${sensorId}/upload`, {
      contentType,
      timestamp,
      overwrite,
    });
    return response.data.url;
  },
  updateSensorEvent: async function ({ dispatch }, { event, attributes }) {
    dispatch('notifyEventUpdate', { ...event, ...attributes });
    const { data } = await Vue.http.patch(`/api/v2/events/${event.id}`, attributes);
    dispatch('notifyEventUpdate', data);
    return data;
  },
  pollUpdatingEvent: async ({ dispatch }, params) => {
    let { event } = params;

    dispatch('notifyEventUpdate', { ...event, updating: true });

    /* eslint-disable no-await-in-loop */
    let retries = 0;
    while (retries <= 5) {
      await sleep(2000);
      event = await dispatch('getEvent', { id: event.id });

      if (!event.updating) {
        break;
      }
      retries += 1;
    }
    /* eslint-enable no-await-in-loop */

    dispatch('notifyEventUpdate', event);

    return event;
  },
  notifyEventUpdate: async ({ commit }, event) => {
    commit(types.PUSH_UPDATED_EVENT, event);
    await Vue.nextTick();
    commit(types.POP_UPDATED_EVENT);
  },
  deleteEvent: async ({ dispatch }, { event }) => {
    await Vue.http.delete(`/api/v2/events/${event.id}`);
  },
};

export const mutations = {
  PUSH_UPDATED_EVENT(state, event) {
    state.updatedEventQueue = [...state.updatedEventQueue, event];
  },
  POP_UPDATED_EVENT(state) {
    if (state.updatedEventQueue.length > 0) {
      state.updatedEventQueue.splice(-1, 1);
    }
  },
};

export const getters = {
  getUpdatedEvent(state) {
    return (sensorId) => {
      if (state.updatedEventQueue.length === 0) {
        return null;
      }
      const first = state.updatedEventQueue[0];
      return first.camera_id === sensorId ? first : null;
    };
  },
};

export const state = () => ({
  updatedEventQueue: [],
});
