<template>
  <station-v2-event
    ref="stationEvent"
    :event="event"
    @pollEvent="$emit('pollEvent')"
    v-if="event.type === 'station_v2'"
  />
  <station-v1-event
    ref="stationEvent"
    :event="event"
    @pollEvent="$emit('pollEvent')"
    v-else
  />
</template>

<script>
import StationV2Event from './StationV2Event';
import StationV1Event from './StationV1Event';

export default {
  name: 'station-event',
  components: {
    StationV2Event,
    StationV1Event,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  methods: {
    // public
    resetView() {
      this.$refs.stationEvent.resetView();
    },
  },
};
</script>

<style scoped lang="sass">
</style>
