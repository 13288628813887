<template>
  <card-dialog
    :value="value"
    @input="val => $emit('input', val)"
    :title="$t('label.edit_place')"
    :width="500"
  >
    <v-form v-model="valid" ref="form">
      <form-field :label="$t('field.name')">
        <text-field
          v-model="form.name"
          :rules="[noDuplicates, required($t('field.name'))]"
          data-testid="name"
        />
      </form-field>
    </v-form>
    <template #actions>
      <dialog-action @click="close">{{ $t('button.cancel') }}</dialog-action>
      <dialog-action
       @click.stop="save"
       :disabled="!place || saving || !valid || unchanged"
      >
        {{ saving ? $t('button.saving') : $t('button.save') }}
      </dialog-action>
    </template>
  </card-dialog>
</template>

<i18n lang="yaml">
ja:
  label:
    edit_place: 設置場所の編集
    saving: 保存中
  button:
    cancel: キャンセル
    save: 保存
    saving: 保存中...
  field:
    name: 名称
  messages:
    place_with_name_exists: この名称は既に使用されています
en:
  label:
    edit_place: Edit Place
  button:
    cancel: Cancel
    save: Save
    saving: Saving...
  field:
    name: Name
  messages:
    place_with_name_exists: This name is already in use
</i18n>

<script>
import Validations from '@/components/Form/mixins/form-validations';

import CardDialog from '@/components/atoms/CardDialog';
import DialogAction from '@/components/atoms/DialogAction';
import FormField from '@/components/atoms/FormField';
import TextField from '@/components/atoms/TextField';

export default {
  name: 'station-place-editor',
  mixins: [
    Validations,
  ],
  props: {
    value: {
      type: Boolean,
    },
    place: {
      type: Object,
    },
  },
  components: {
    CardDialog,
    DialogAction,
    FormField,
    TextField,
  },
  computed: {
    unchanged() {
      return this.form.name === this.place.name;
    },
  },
  data() {
    return {
      form: {
        name: '',
      },
      valid: false,
      saving: false,
    };
  },
  inject: ['getStationPlaces', 'updateStationPlace'],
  methods: {
    save: async function () {
      try {
        this.saving = true;
        const attributes = { ...this.form };
        const updated = await this.updateStationPlace(this.place.id, attributes);
        this.$emit('update', updated);
        this.close();
      } finally {
        this.saving = false;
      }
    },
    close() {
      this.$emit('input', false);
    },
    noDuplicates() {
      if (this.form.name === this.place.name) {
        return true;
      }

      const placeWithSameName = this.getStationPlaces().find(
        place => place.name === this.form.name,
      );
      return !placeWithSameName || this.$t('messages.place_with_name_exists');
    },
  },
  watch: {
    place(value) {
      this.form.name = value ? value.name : '';
    },
  },
};
</script>

<style scoped lang="sass">
</style>
