var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "transition",
        [
          _vm.open
            ? _c(
                "v-card",
                {
                  staticClass: "insect-type-picker",
                  style: _vm.style,
                  attrs: { ripple: false },
                },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "pb-0" },
                    _vm._l(_vm.detections, function (item, index) {
                      return _c(
                        "div",
                        { key: item.index, staticClass: "d-flex align-center" },
                        [
                          _vm.withInsectTypes
                            ? _c("select-box", {
                                ref: "selects",
                                refInFor: true,
                                staticClass: "pa-0",
                                attrs: {
                                  placeholder: _vm.$t("placeholder"),
                                  items: _vm.options,
                                  disabled: _vm.insectSelectionDisabled(item),
                                  itemMenuClass:
                                    "insect-detections-type-select",
                                },
                                on: { input: _vm.handleChange },
                                model: {
                                  value: item.type,
                                  callback: function ($$v) {
                                    _vm.$set(item, "type", $$v)
                                  },
                                  expression: "item.type",
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          !item.new
                            ? [
                                !_vm.isRemoved(item)
                                  ? _c(
                                      "v-btn",
                                      {
                                        staticClass: "action-button red--text",
                                        attrs: { text: "", icon: "" },
                                        on: {
                                          click: function () {
                                            return _vm.toggleRemove(item, index)
                                          },
                                        },
                                      },
                                      [_c("v-icon", [_vm._v("delete")])],
                                      1
                                    )
                                  : _c(
                                      "v-btn",
                                      {
                                        staticClass:
                                          "action-button green--text",
                                        attrs: {
                                          text: "",
                                          icon: "",
                                          color: "success",
                                        },
                                        on: {
                                          click: function () {
                                            return _vm.toggleRemove(item, index)
                                          },
                                        },
                                      },
                                      [_c("v-icon", [_vm._v("undo")])],
                                      1
                                    ),
                              ]
                            : _vm._e(),
                        ],
                        2
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-actions",
                    { staticClass: "justify-end" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "green--text darken-1",
                          attrs: { text: "" },
                          on: { click: _vm.close },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("buttons.cancel")) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "green--text darken-1",
                          attrs: { text: "", disabled: _vm.hasBlank },
                          on: { click: _vm.confirm },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("buttons.confirm")) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.open
        ? _c("div", {
            staticClass: "insect-type-picker__overlay",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.close.apply(null, arguments)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }