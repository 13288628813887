<template>
  <v-dialog
    no-click-animation
    :value="value"
    @input="val => $emit('input', val)"
    v-bind="$attrs"
    v-on="$listeners"
    role="dialog">
    <template #activator="props">
      <slot name="activator" v-bind="props" />
    </template>
    <slot/>
  </v-dialog>
</template>

<script>
export default {
  name: 'base-dialog',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    allowDocumentScroll(value) {
      const _ = this._;
      const html = document.documentElement;
      const classNames = _.split(html.className, ' ');
      if (!value && !_.includes(classNames, 'no-document-scroll')) {
        html.className = _.join(_.concat(classNames, 'no-document-scroll'), ' ');
      } else if (value) {
        html.className = _.join(_.pull(classNames, 'no-document-scroll'), ' ');
      }
    },
  },
  destroyed: function () {
    this.allowDocumentScroll(true);
  },
  watch: {
    value(newValue) {
      this.allowDocumentScroll(!newValue);
    },
  },
};
</script>

<style lang="sass" scoped>
</style>

<style lang="sass">
.no-document-scroll
  overflow: hidden
</style>
