<template>
  <div class="d-flex justify-center" v-if="event !== null">
    <base-dialog
      content-class="overflow-x-hidden"
      transition="dialog-bottom-transition"
      fullscreen
      hide-overlay
      persistent
      v-model="visible"
    >
      <v-card>
        <v-toolbar>
          <v-toolbar-title v-if="event.stationPlace">
            {{event.stationPlace}}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="close">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-list>
          <div class="d-flex">
            <div class="handle">
              <v-btn
                text
                color="primary"
                class="align-left"
                @click="$emit('previous')"
                :disabled="!hasPrevEvent"
                data-testid="previous_event_button"
              >
                <v-icon left>fa-chevron-circle-left</v-icon>
              </v-btn>
            </div>
            <div class="main pa-4">
              <station-event
                ref="stationEvent"
                :event="event"
                @pollEvent="$emit('pollEvent')"
              />
            </div>
            <div class="handle">
              <v-btn
                text
                color="primary"
                class="align-right"
                @click="$emit('next')"
                :disabled="!hasNextEvent"
                data-testid="next_event_button"
              >
                <v-icon right>fa-chevron-circle-right</v-icon>
              </v-btn>
            </div>
          </div>
        </v-list>
      </v-card>
    </base-dialog>
  </div>
</template>

<i18n lang="yaml">
ja:
  button:
    cancel: 'キャンセル'
en:
  button:
    cancel: 'Cancel'
</i18n>

<script>
import BaseDialog from '@/components/atoms/BaseDialog';
import StationEvent from '@/components/StationEvent';

export default {
  name: 'station-event-detail-view',
  props: {
    event: {
      type: Object,
      required: true,
    },
    visible: Boolean,
    hasPrevEvent: Boolean,
    hasNextEvent: Boolean,
  },
  components: {
    BaseDialog,
    StationEvent,
  },
  methods: {
    close() {
      this.$emit('visibilityChanged', false);
      this.$refs.stationEvent.resetView();
    },
  },
  model: {
    prop: 'visible',
    event: 'visibilityChanged',
  },
};
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'

.v-card
  display: flex
  flex-direction: column
  min-height: 100%
  background-color: map-get($material-theme, 'background') !important

.v-toolbar
  flex-grow: 0

.v-list
  flex: 1

.handle
  min-width: 64px

.main
  width: calc(100% - 128px)
</style>
