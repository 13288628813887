var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form-group",
      class: { "form-group--hide-border": _vm.hideBorder },
    },
    [
      _c(
        "div",
        {
          staticClass: "form-group__item form-group__item--label",
          attrs: { "data-testid": "field_label" },
        },
        [
          _vm._t("label"),
          _vm._v(" "),
          _vm.required
            ? _c("span", { staticClass: "form-group__item--required-icon" }, [
                _vm._v("*"),
              ])
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group__item form-group__item--input" },
        [_vm._t("input")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }