var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sign-up" },
    [
      _c("img", {
        staticClass: "mb-4",
        attrs: { src: require("../assets/logo.png"), width: "140" },
      }),
      _vm._v(" "),
      _vm.success
        ? [
            _c("p", {
              domProps: { innerHTML: _vm._s(_vm.$t("label.signup_complete")) },
            }),
            _vm._v(" "),
            _c(
              "v-btn",
              {
                attrs: {
                  color: "primary",
                  large: "",
                  block: "",
                  to: "/sign_in",
                },
              },
              [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("button.close")) + "\n      "
                ),
                _c("v-icon", { attrs: { dark: "", right: "" } }, [
                  _vm._v("fa-times-circle"),
                ]),
              ],
              1
            ),
          ]
        : [
            _vm.submitted
              ? _c("h4", { staticClass: "text-h4 mb-4" }, [
                  _vm._v(_vm._s(_vm.$t("label.sign_up_information"))),
                ])
              : _c("h4", { staticClass: "text-h4 mb-4" }, [
                  _vm._v(_vm._s(_vm.$t("label.sign_up"))),
                ]),
            _vm._v(" "),
            _vm.error !== ""
              ? _c("v-alert", { attrs: { type: "error" } }, [
                  _vm._v(_vm._s(_vm.error)),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "v-form",
              {
                ref: "form",
                class: { submitted: _vm.submitted },
                model: {
                  value: _vm.valid,
                  callback: function ($$v) {
                    _vm.valid = $$v
                  },
                  expression: "valid",
                },
              },
              [
                _c("form-group", {
                  attrs: { "hide-border": "", required: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "label",
                      fn: function () {
                        return [
                          _c("span", [_vm._v(_vm._s(_vm.$t("field.email")))]),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "input",
                      fn: function () {
                        return [
                          _c("text-field", {
                            staticClass: "sign-up__email",
                            attrs: {
                              type: "email",
                              value: _vm.email,
                              disabled: "",
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("form-group", {
                  attrs: { "hide-border": "", required: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "label",
                      fn: function () {
                        return [
                          _c("span", [_vm._v(_vm._s(_vm.$t("field.name")))]),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "input",
                      fn: function () {
                        return [
                          _c("text-field", {
                            staticClass: "sign-up__name",
                            attrs: {
                              rules: [_vm.required(_vm.$t("field.name"))],
                              edit: !_vm.submitted,
                            },
                            model: {
                              value: _vm.model.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "name", $$v)
                              },
                              expression: "model.name",
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("form-group", {
                  attrs: { "hide-border": "", required: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "label",
                      fn: function () {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("field.password"))),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "input",
                      fn: function () {
                        return [
                          _c("text-field", {
                            staticClass: "sign-up__password",
                            attrs: {
                              type: "password",
                              rules: [
                                _vm.required(_vm.$t("field.password")),
                                _vm.password_length(),
                                _vm.password_characters(),
                              ],
                              edit: !_vm.submitted,
                            },
                            model: {
                              value: _vm.model.password,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "password", $$v)
                              },
                              expression: "model.password",
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("form-group", {
                  attrs: { "hide-border": "", required: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "label",
                      fn: function () {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("field.password_confirm"))),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "input",
                      fn: function () {
                        return [
                          _c("text-field", {
                            staticClass: "sign-up__password_confirm",
                            attrs: {
                              type: "password",
                              rules: [_vm.match_password()],
                              edit: !_vm.submitted,
                            },
                            model: {
                              value: _vm.model.password_confirm,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "password_confirm", $$v)
                              },
                              expression: "model.password_confirm",
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
                _vm._v(" "),
                _c("form-group", {
                  staticClass: "sign-up__locale",
                  attrs: { "hide-border": "", required: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "label",
                      fn: function () {
                        return [
                          _c("span", [_vm._v(_vm._s(_vm.$t("field.locale")))]),
                        ]
                      },
                      proxy: true,
                    },
                    {
                      key: "input",
                      fn: function () {
                        return [
                          _c("select-field", {
                            attrs: {
                              items: _vm.locale_options,
                              rules: [_vm.required(_vm.$t("field.locale"))],
                              edit: !_vm.submitted,
                            },
                            model: {
                              value: _vm.model.locale,
                              callback: function ($$v) {
                                _vm.$set(_vm.model, "locale", $$v)
                              },
                              expression: "model.locale",
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
                _vm._v(" "),
                _vm.submitted
                  ? [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mb-3",
                          attrs: { color: "primary", large: "", block: "" },
                          on: {
                            click: function ($event) {
                              _vm.submitted = false
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("button.back")) +
                              "\n          "
                          ),
                          _c("v-icon", { attrs: { dark: "", right: "" } }, [
                            _vm._v("fa-undo"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          staticClass: "sign-up__register",
                          attrs: { color: "primary", large: "", block: "" },
                          on: { click: _vm.onRegister },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("button.register")) +
                              "\n          "
                          ),
                          _c("v-icon", { attrs: { dark: "", right: "" } }, [
                            _vm._v("fa-check"),
                          ]),
                        ],
                        1
                      ),
                    ]
                  : [
                      _c(
                        "v-btn",
                        {
                          staticClass: "sign-up__submit mb-5",
                          attrs: { color: "primary", large: "", block: "" },
                          on: { click: _vm.onSubmit },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("button.send")) +
                              "\n          "
                          ),
                          _c("v-icon", { attrs: { dark: "", right: "" } }, [
                            _vm._v("fa-arrow-circle-right"),
                          ]),
                        ],
                        1
                      ),
                    ],
              ],
              2
            ),
          ],
      _vm._v(" "),
      _c("terms-modal", {
        attrs: { onAccept: _vm.allowRegister, onCancel: _vm.moveToSignin },
        model: {
          value: _vm.modal.terms,
          callback: function ($$v) {
            _vm.$set(_vm.modal, "terms", $$v)
          },
          expression: "modal.terms",
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }