import http from 'axios';
import store from './store';
import router from './router';

const logoutOn401 = () => {
  http.interceptors.response.use(_ => _, (error) => {
    if (error.response && error.response.status === 401) {
      // TODO: Show logout reason
      store.dispatch('signOut');
      router.push({ path: '/sign_in', query: { redirectTo: router.currentRoute.path } });
    }
    return Promise.reject(error);
  });
};

export default (Vue) => {
  const requestCancelSources = [];
  http.interceptors.request.use((config) => {
    if (config.url.startsWith('/api/') && store.getters.isSignedIn) {
      // Add Access token to API requests if logged in
      config.headers.Authorization = `Bearer ${store.getters.getAccessToken}`;
    }
    if (!config.cancelToken) {
      const source = http.CancelToken.source();
      config.cancelToken = source.token;
      requestCancelSources.push(source);
    }
    if (store.getters.assumedUser) {
      config.headers['assumed-user-id'] = store.getters.assumedUser.id;
    }
    return config;
  });

  router.beforeEach((to, from, next) => {
    requestCancelSources.forEach((source) => {
      source.cancel('Request canceled');
    });
    requestCancelSources.clear();
    next();
  });

  logoutOn401();

  Object.defineProperties(Vue.prototype, {
    $http: {
      get() {
        return http;
      },
    },
  });
  Vue.http = http;
};
