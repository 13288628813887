var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "card-dialog",
    {
      attrs: {
        value: _vm.value,
        width: 700,
        title: _vm.$t("label.edit"),
        persistent: _vm.confirmVisible,
      },
      on: {
        input: function (val) {
          return _vm.$emit("input", val)
        },
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c("dialog-action", { on: { click: _vm.close } }, [
                _vm._v(_vm._s(_vm.$t("button.cancel"))),
              ]),
              _vm._v(" "),
              _c(
                "dialog-action",
                {
                  attrs: { disabled: _vm.anyFieldEmpty || !_vm.valid },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      _vm.confirmVisible = true
                    },
                  },
                },
                [_vm._v("\n      " + _vm._s(_vm.$t("button.save")) + "\n    ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm.event !== null
        ? [
            _c(
              "v-row",
              { staticClass: "d-flex flex-wrap" },
              [
                _c(
                  "v-col",
                  {
                    staticClass: "d-flex align-center justify-center",
                    attrs: { xs12: "", sm5: "" },
                  },
                  [
                    _c("loading-image", {
                      attrs: { src: _vm.event.attachments.tab.original },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-col",
                  { attrs: { xs12: "", sm7: "" } },
                  [
                    _c(
                      "v-form",
                      {
                        ref: "form",
                        model: {
                          value: _vm.valid,
                          callback: function ($$v) {
                            _vm.valid = $$v
                          },
                          expression: "valid",
                        },
                      },
                      [
                        _c(
                          "form-field",
                          { attrs: { label: _vm.$t("field.place") } },
                          [
                            _c("autocomplete-text-field", {
                              attrs: {
                                "allow-new-values": "",
                                label: _vm.$t("field.place"),
                                items: _vm.placeItems,
                                "data-testid": "place",
                              },
                              model: {
                                value: _vm.form.placeName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "placeName", $$v)
                                },
                                expression: "form.placeName",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "form-field",
                          { attrs: { label: _vm.$t("field.trap_no") } },
                          [
                            _c("autocomplete-text-field", {
                              attrs: {
                                "allow-new-values": "",
                                label: _vm.$t("field.trap_no"),
                                items: _vm.trapNoItems,
                                "data-testid": "trap_no",
                              },
                              model: {
                                value: _vm.form.trap_no,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "trap_no", $$v)
                                },
                                expression: "form.trap_no",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "form-field",
                          { attrs: { label: _vm.$t("field.installed_at") } },
                          [
                            _c("date-field", {
                              attrs: {
                                "data-testid": "installed_at",
                                rules: [_vm.validateDates],
                              },
                              model: {
                                value: _vm.form.installed_at,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "installed_at", $$v)
                                },
                                expression: "form.installed_at",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "form-field",
                          { attrs: { label: _vm.$t("field.collected_at") } },
                          [
                            _c("date-field", {
                              attrs: {
                                "data-testid": "collected_at",
                                rules: [_vm.validateDates],
                              },
                              model: {
                                value: _vm.form.collected_at,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "collected_at", $$v)
                                },
                                expression: "form.collected_at",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "form-field",
                          { attrs: { label: _vm.$t("field.month") } },
                          [
                            _c("month-field", {
                              attrs: { "data-testid": "month" },
                              model: {
                                value: _vm.form.month,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "month", $$v)
                                },
                                expression: "form.month",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm._v(" "),
      _c(
        "card-dialog",
        {
          attrs: { width: "auto" },
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function () {
                return [
                  _c(
                    "dialog-action",
                    {
                      attrs: { disabled: _vm.saving },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          _vm.confirmVisible = false
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " + _vm._s(_vm.$t("button.no")) + "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "dialog-action",
                    {
                      attrs: { disabled: _vm.saving },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.save.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm.saving
                        ? [_vm._v(_vm._s(_vm.$t("button.saving")))]
                        : [_vm._v(_vm._s(_vm.$t("button.yes")))],
                    ],
                    2
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.confirmVisible,
            callback: function ($$v) {
              _vm.confirmVisible = $$v
            },
            expression: "confirmVisible",
          },
        },
        [
          _c("div", { staticClass: "pt-6" }, [
            _vm._v(_vm._s(_vm.$t("messages.confirm_save"))),
          ]),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }