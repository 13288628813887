<template>
  <base-dialog :value="value" @input="val => $emit('input', val)" v-bind="$attrs" v-on="$listeners">
    <template #activator="props">
      <slot name="activator" v-bind="props" />
    </template>
    <v-card>
      <v-card-title class="headline">{{ title }}</v-card-title>
      <v-card-text><slot/></v-card-text>
      <v-card-actions v-if="this.$slots.actions">
        <v-spacer></v-spacer>
        <slot name="actions"/>
      </v-card-actions>
    </v-card>
  </base-dialog>
</template>

<script>
import BaseDialog from './BaseDialog';

export default {
  name: 'card-dialog',
  components: {
    BaseDialog,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
