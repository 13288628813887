<template>
  <v-container>
    <v-row>
      <v-spacer/>
      <icon-button icon="fa-angle-left" @click="goToPreviousMonth"/>
      <span class="d-inline-flex align-center">
        <h1>{{ month }}</h1>
        <date-picker-dropdown :value="month" @input="value => $emit('update:month', value)" type="month">
          <template #activator="{ on }">
            <v-btn icon color="primary" v-on="on">
              <v-icon>fa-calendar</v-icon>
            </v-btn>
          </template>
        </date-picker-dropdown>
      </span>
      <icon-button icon="fa-angle-right" @click="goToNextMonth"/>
      <v-spacer/>
    </v-row>
    <v-row v-for="{ place, sensors } in places" :key="place" class="mt-12">
      <v-col cols="12" >
        <h1 class="text-center">{{ place }}</h1>
      </v-col>
      <v-col class="graph" cols="4" v-for="sensor in sensors" :key="sensor.id">
        <loading-chart
          :sensor="sensor"
          :month="month"
          :y-max="yMax"
          @max-count-updated="updateMaxCount"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';

import DatePickerDropdown from '@/components/atoms/DatePickerDropdown';
import IconButton from '@/components/atoms/IconButton';
import LoadingChart from './LoadingChart';

const DEFAULT_YMAX = 10;

export default {
  name: 'insect-count-line-chart-list',
  components: {
    DatePickerDropdown,
    IconButton,
    LoadingChart,
  },
  props: {
    sensors: {
      type: Array,
      required: true,
    },
    month: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      yMax: DEFAULT_YMAX,
    };
  },
  computed: {
    places() {
      const sensorsByPlace = _.groupBy(this.sensors, 'place');
      const places = Object.keys(sensorsByPlace).map(place => ({
        place,
        sensors: sensorsByPlace[place],
      }));
      return _.sortBy(places, 'place');
    },
  },
  methods: {
    goToPreviousMonth() {
      this.$emit('update:month', moment(this.month).subtract(1, 'month').format('YYYY-MM'));
    },
    goToNextMonth() {
      this.$emit('update:month', moment(this.month).add(1, 'month').format('YYYY-MM'));
    },
    roundUpToNextTen(value) {
      return Math.ceil(value / 10) * 10;
    },
    updateMaxCount(maxCount) {
      this.yMax = this.roundUpToNextTen(Math.max(this.yMax, maxCount));
    },
  },
  watch: {
    month() {
      this.yMax = DEFAULT_YMAX;
    },
  },
};
</script>

<style scoped lang="sass">
.graph
  height: 300px

  div
    height: 100%
</style>
