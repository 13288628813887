<template>
  <div>
    <transition>
      <v-card class="insect-type-picker" :style="style" @click.stop v-if="open">
        <v-card-text class="pb-0">
          <v-row align="center" no-gutters :key="item.index" v-for="(item, index) in detections">
            <select-box
              class="pa-0"
              :placeholder="$t('placeholder')"
              :items="options"
              :disabled="insectSelectionDisabled(item)"
              v-model="item.type"
              @input="handleChange"
              itemMenuClass="insect-detections-type-select"
              ref="selects"
              v-if="withInsectTypes"/>
            <template v-if="!item.new">
              <v-btn class="action-button red--text" text icon @click="() => toggleRemove(item, index)" v-if="!isRemoved(item)">
                <v-icon>delete</v-icon>
              </v-btn>
              <v-btn class="action-button green--text" text icon color="success" @click="() => toggleRemove(item, index)" v-else>
                <v-icon>undo</v-icon>
              </v-btn>
            </template>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn class="green--text darken-1" text @click="close">
            {{ $t('buttons.cancel') }}
          </v-btn>
          <v-btn class="green--text darken-1" text @click="confirm" :disabled="hasBlank">
            {{ $t('buttons.confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </transition>
    <div class="insect-type-picker__overlay" @click.stop="close" v-if="open" />
  </div>
</template>

<i18n lang="yaml">
ja:
  placeholder: 選択してください
  buttons:
    cancel: キャンセル
    confirm: 確定

en:
  placeholder: Select
  buttons:
    cancel: Cancel
    confirm: Confirm
</i18n>

<script>
import InsectTypes from '@/mixins/insectTypes';

import SelectBox from '@/components/atoms/SelectBox';

export default {
  name: 'insect-type-picker',
  mixins: [InsectTypes],
  components: {
    SelectBox,
  },
  props: {
    withInsectTypes: Boolean,
  },
  computed: {
    hasBlank() {
      return this.withInsectTypes && !!this.detections.find(item => !item.type);
    },
    options() {
      return this._.map(this.INSECT_TYPES, (item, key) => ({
        text: `${item.symbol}. ${item.label}`,
        value: key,
      }));
    },
    style() {
      return {
        top: `${this.position.top}px`,
        left: `${this.position.left}px`,
      };
    },
  },
  data() {
    return {
      detections: [],
      open: false,
      position: {
        top: 0,
        left: 0,
      },
    };
  },
  methods: {
    // NOTE: vuetify 0.15.xでは選択時にfocusが解除されない
    // TODO: vuetifyのバージョン更新後、問題がなければ除去
    blurSelects() {
      if (this.withInsectTypes) {
        this.$refs.selects.forEach((item) => {
          item.blur();
        });
      }
    },
    close() {
      this.position = {};
      this.detections = [];
      this.open = false;
      this.$emit('close');
    },
    confirm() {
      this.$emit('change', this.detections.map(detection => ({
        ...detection,
        ...this.INSECT_TYPES[detection.type],
      })));
      this.close();
    },
    handleChange() {
      this.blurSelects();
      this.updateDetections();
    },
    insectSelectionDisabled(item) {
      return !this.withInsectTypes || this.isRemoved(item);
    },
    isRemoved(item) {
      return !!item.remove;
    },
    show(items, position = { top: 0, left: 0 }) {
      if (!this.open) {
        this.position = position;
        this.detections = items;
        this.open = true;
      }
    },
    toggleRemove(item, index) {
      const newValue = {
        ...item,
        remove: !item.remove,
      };
      this.detections.splice(index, 1, newValue);
      this.blurSelects();
    },
    // TODO: computedかwatchでdetectionsの更新を検知可能にする
    updateDetections() {
      this.detections = [...this.detections];
    },
  },
};
</script>

<style lang="sass" scoped>
.insect-type-picker
  position: absolute
  background-color: white
  z-index: 6

  ::v-deep .ripple__container
    display: none

  &__overlay
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 5

  .input-group--disabled
    opacity: 0.5

    ::v-deep .input-group__selections
      text-decoration: line-through

  .action-button
    margin: auto

.v-enter-active,
.v-leave-active
  transition: all 0.3s ease

.v-enter,
.v-leave-to
  transform: translateY(5px)
  opacity: 0
</style>
