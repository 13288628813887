var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.place
        ? _c(
            "div",
            [
              _c("page-title-area", [
                _c("h1", [_vm._v(_vm._s(_vm.place.name))]),
              ]),
              _vm._v(" "),
              _c("event-period-selector", {
                attrs: {
                  loading: _vm.loading,
                  "initial-value": _vm.initialEventPeriod,
                },
                on: { submit: _vm.loadEvents, clear: _vm.clearEvents },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex align-baseline justify-end mt-4" },
                [
                  _c("picked-event-chart-button", {
                    attrs: { events: _vm.sortedSelectedEvents },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "ml-1" }),
                  _vm._v(" "),
                  _c("export-excel-button", {
                    attrs: { events: _vm.sortedSelectedEvents },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "ml-1" }),
                  _vm._v(" "),
                  _c("export-csv-button", {
                    attrs: {
                      events: _vm.sortedSelectedEvents,
                      place: _vm.place.name,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("station-place-event-table", {
                attrs: { events: _vm.events, loading: _vm.loading },
                on: {
                  sortedSelectedEventsUpdated: _vm.updateSortedSelectedEvents,
                  editEvent: _vm.editEvent,
                  viewEvents: _vm.viewEvents,
                  eventUpdated: _vm.onEventUpdate,
                },
              }),
              _vm._v(" "),
              _c("station-event-editor", {
                attrs: { places: _vm.places, event: _vm.currentEditingEvent },
                on: { update: _vm.onEventUpdate },
                model: {
                  value: _vm.editDialogVisible,
                  callback: function ($$v) {
                    _vm.editDialogVisible = $$v
                  },
                  expression: "editDialogVisible",
                },
              }),
              _vm._v(" "),
              _vm.currentDisplayedEvent
                ? _c("station-event-detail-view", {
                    attrs: {
                      event: _vm.currentDisplayedEvent,
                      hasPrevEvent:
                        _vm.previousEvent && !_vm.previousEvent.processing,
                      hasNextEvent: _vm.nextEvent && !_vm.nextEvent.processing,
                    },
                    on: {
                      previous: function ($event) {
                        return _vm.changeDisplayedIndexBy(-1)
                      },
                      next: function ($event) {
                        return _vm.changeDisplayedIndexBy(1)
                      },
                      pollEvent: function () {
                        return _vm.setEventUpdating(_vm.currentDisplayedEvent)
                      },
                    },
                    model: {
                      value: _vm.detailViewVisible,
                      callback: function ($$v) {
                        _vm.detailViewVisible = $$v
                      },
                      expression: "detailViewVisible",
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _c("page-load-spinner"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }