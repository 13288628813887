<template>
  <div class="form-group" :class="{ 'form-group--hide-border': hideBorder }">
    <div class="form-group__item form-group__item--label" data-testid="field_label">
      <slot name="label"></slot>
      <span v-if="required" class="form-group__item--required-icon">*</span>
    </div>
    <div class="form-group__item form-group__item--input"><slot name="input"></slot></div>
  </div>
</template>

<script>
export default {
  name: 'form-group',
  props: {
    hideBorder: Boolean,
    required: Boolean,
  },
};
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'

.form-group
  color: map-get($material-theme, 'text', 'primary')
  border-color: map-get($material-theme, 'text', 'primary')
  display: flex

  &__item
    padding-top: 4px
    padding-bottom: 4px

    &--label
      border-right: 1px solid
      padding-right: 16px
      width: 35%

    &--required-icon
      color: red

    &--input
      flex: 1
      padding-left: 16px

  & + & &__item
    padding-top: 16px

@media #{map-get($display-breakpoints, 'md-and-up')}
  .form-group
    border-bottom: 1px solid map-get($material-theme, 'dividers')

    &__item
      padding: 20px 16px

      &--label
        border-right: 0

    &--hide-border
      border: 0

  *:not(.form-group) + .form-group,
  .form-group:not(.form-group--hide-border):first-child
    border-top: 1px solid map-get($material-theme, 'dividers')
</style>
