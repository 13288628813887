<template>
  <icon :badge="badge" :size="size" :type="sensor.type"/>
</template>

<script>
import moment from 'moment';
import SensorHealthCheck from '@/mixins/sensorHealthCheck';
import Icon from './Icon';

export default {
  name: 'sensor-icon',
  mixins: [SensorHealthCheck],
  props: {
    size: {
      type: Number,
      default: 48,
    },
    sensor: {
      type: Object,
    },
  },
  components: {
    Icon,
  },
  computed: {
    badge() {
      if (this.sensor.health_check && (this.noResponse || this.hasError)) {
        return 'warning';
      }
      return '';
    },
    hasError() {
      const result = this.healthCheck(this.sensor);
      return this._.find(result, { status: 'error' });
    },
    noResponse() {
      const now = moment();
      const lastActive = moment.unix(this.sensor.last_active);
      return lastActive.isBefore(now.subtract(12, 'hours'));
    },
  },
};
</script>

<style scoped lang="sass">
</style>
