<template>
  <div class="tag-picker">
    <dropdown-menu v-model="visible" @activate="show" :width="400">
      <template #activator="props">
        <slot name="activator" v-bind="props" />
      </template>
      <div class="tag-picker__content">
        <template v-for="tag in model.tags">
          <v-btn block class="elevation-0 tag-picker__tag tag-picker__tag__selected" @click="() => removeTag(tag)">
            <div class="tag-picker__tag__name">{{tag}}</div>
            <v-icon right class="tag-picker__tag__icon">fa-remove</v-icon>
          </v-btn>
        </template>
        <v-divider class="my-2" v-if="model.tags.length > 0"/>
        <v-text-field
          class="tag-picker__search"
          hide-details
          :placeholder="placeholder"
          prepend-icon="search"
          v-model="search"
        />
        <v-divider class="my-2" v-if="enableAdd || selectableTags.length > 0"/>
        <template v-for="tag in selectableTags">
          <v-btn block class="elevation-0 tag-picker__tag tag-picker__tag__available" @click="() => addTag(tag)">
            <div class="tag-picker__tag__name">{{tag}}</div>
            <v-icon right class="tag-picker__tag__icon">fa-plus</v-icon>
          </v-btn>
        </template>
        <template v-if="addTagVisible">
          <v-btn block class="elevation-0 tag-picker__tag tag-picker__tag__add" @click="() => addTag(search)">
            <div class="tag-picker__tag__name">{{ $t('label.add_specific_tag', { t: search }) }}</div>
            <v-icon right class="tag-picker__tag__icon">fa-plus</v-icon>
          </v-btn>
        </template>
        <div class="tag-picker__actions">
          <div class="tag-picker__action">
            <round-button
              class="tag-picker__cancel"
              icon="fa-arrow-circle-left"
              fullWidth
              @click="onCancel"
            >
              {{ $t('button.cancel') }}
            </round-button>
          </div>
          <div class="tag-picker__action">
            <round-button
              class="tag-picker__submit"
              icon="fa-check-circle"
              fullWidth
              @click="onSubmit"
              :disabled="!existTags"
            >
              {{ $t('button.ok') }}
            </round-button>
          </div>
        </div>
      </div>
    </dropdown-menu>
  </div>
</template>

<i18n lang="yaml">
ja:
  label:
    search_tag: 'タグ名で検索'
    add_tag: 'タグを追加'
    add_specific_tag: '「{t}」を追加'

  button:
    cancel: 'キャンセル'
    ok: 'OK'

en:
  label:
    search_tag: 'Search Tag'
    add_tag: 'Add Tag'
    add_specific_tag: 'Add "{t}"'

  button:
    cancel: 'Cancel'
    ok: 'OK'
</i18n>

<script>
import RoundButton from '@/components/atoms/RoundButton';
import DropdownMenu from './DropdownMenu';

const DISPLAY_LIMIT = 5;
export default {
  name: 'tag-picker',
  components: {
    RoundButton,
    DropdownMenu,
  },
  props: {
    enableAdd: {
      type: Boolean,
      default: false,
    },
    tags: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    addTagVisible() {
      return this.enableAdd && this._.isEmpty(this.selectableTags)
        && !this._.isEmpty(this.search) && !this._.includes(this.model.tags, this.search);
    },
    existTags() {
      return (this.model.tags.length + this.selectableTags.length) > 0;
    },
    placeholder() {
      return this.enableAdd ? this.$t('label.add_tag') : this.$t('label.search_tag');
    },
    selectableTags() {
      const lowerSearchText = this.search.toLowerCase();
      const selectableTags = this.availableTags.filter(tag =>
        !this._.includes(this.model.tags, tag),
      );
      const filtered = selectableTags.filter((tag) => {
        const lowerTagText = tag.toLowerCase();
        return this._.includes(lowerTagText, lowerSearchText);
      });
      return this._.take(filtered, DISPLAY_LIMIT);
    },
  },
  data() {
    return {
      availableTags: [],
      model: { tags: [] },
      search: '',
      visible: false,
    };
  },
  methods: {
    addTag(tag) {
      this.model.tags.push(tag);
    },
    onCancel() {
      this.visible = false;
    },
    onSubmit() {
      this.$emit('submit', [...this.model.tags]);
      this.visible = false;
    },
    removeTag(tag) {
      this.model.tags = this.model.tags.filter(t => !this._.isEqual(t, tag));
    },
    requestTags(params) {
      return this.$store.dispatch('getUsedTags', params).then((tags) => {
        this.availableTags = this._.uniq([...this.availableTags, ...tags]);
      });
    },
    show() {
      this.model.tags = [...this.tags];
      this.search = '';
      this.requestTags({ limit: DISPLAY_LIMIT });
    },
  },
  model: {
    prop: 'tags',
    event: 'submit',
  },
  watch: {
    search() {
      this.requestTags({ search: this.search, limit: DISPLAY_LIMIT });
    },
  },
};
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'
$picker-width: 400px

.tag-picker
  height: 100%

  &__content
    background-color: #f2f2f2
    padding: 24px

  &__tag
    margin-top: 6px

  &__search
    margin-bottom: 16px
    padding: 0

    ::v-deep .input-group__prepend-icon
      padding-left: 5px

    ::v-deep input
      height: 40px

  &__tag
    background-color: #999!important
    border-radius: 4px
    color: #fff!important
    margin-left: 0
    margin-right: 0

    ::v-deep .v-btn__content
      text-transform: none

    &__name
      display: inline-block
      text-align: left
      width: 100%

    &__icon
      color: #fff!important

  &__actions
    display: table
    margin-top: 32px
    table-layout: fixed
    width: 100%

  &__action
    display: table-cell
    padding-right: 4px

  &__action + &__action
    padding-right: 0
    padding-left: 4px

@media #{map-get($display-breakpoints, 'md-and-up')}
  .tag-picker
    &__content
      padding: 48px
      width: 400px
</style>
