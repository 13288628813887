var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    _vm._g(
      _vm._b(
        {
          attrs: { "no-click-animation": "", value: _vm.value, role: "dialog" },
          on: {
            input: function (val) {
              return _vm.$emit("input", val)
            },
          },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function (props) {
                  return [_vm._t("activator", null, null, props)]
                },
              },
            ],
            null,
            true
          ),
        },
        "v-dialog",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [_vm._v(" "), _vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }