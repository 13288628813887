<template>
  <div class="date-picker">
    <v-text-field
      v-model="date"
      class="date-picker__preview mb-2"
      :placeholder="placeholder"
      hide-details
      clearable
      readonly
      @click:clear="clear"
    />
    <!-- 年単位のページング追加のため、新規ヘッダーを追加 -->
    <div class="date-picker__header mb-2">
      <div class="date-picker__header-item date-picker__header-item--icon" @click="paginateCalendar('year', -1)">
        <i class="fa fa-angle-double-left"></i>
      </div>
      <div class="date-picker__header-item date-picker__header-item--icon" @click="paginateCalendar('month', -1)">
        <i class="fa fa-angle-left"></i>
      </div>
      <div class="date-picker__header-item date-picker__header-item--text">
        <span>{{ headerLabel }}</span>
      </div>
      <div class="date-picker__header-item date-picker__header-item--icon" @click="paginateCalendar('month', 1)">
        <i class="fa fa-angle-right"></i>
      </div>
      <div class="date-picker__header-item date-picker__header-item--icon" @click="paginateCalendar('year', 1)">
        <i class="fa fa-angle-double-right"></i>
      </div>
    </div>
    <v-date-picker
      :value="date"
      class="date-picker__date-picker"
      @input="onPick"
      locale="en"
      ref="picker"
    />
  </div>
</template>

<script>
export default {
  name: 'date-picker',
  props: {
    date: String,
    placeholder: String,
    format: {
      type: String,
      default: 'YYYY-MM-DD',
    },
  },
  data() {
    return {
      headerLabel: this.formatForHeader(this.date || new Date()),
    };
  },
  methods: {
    clear() {
      this.$emit('change', null);
    },
    formatForHeader(date) {
      return this.$moment(date).format('YYYY.MM');
    },
    onPick(value) {
      this.$emit('change', this.$moment(value).format(this.format));
    },
    paginateCalendar(unit, change) {
      const current = this.$refs.picker.tableDate;
      this.$refs.picker.tableDate = this.$moment(current).add(change, unit).format('YYYY-MM');
      this.headerLabel = this.formatForHeader(this.$refs.picker.tableDate);
    },
  },
  model: {
    prop: 'date',
    event: 'change',
  },
};
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'
.date-picker
  &__header
    align-items: center
    background-color: #333
    border-radius: 12px
    color: #fff
    display: flex
    font-size: 13px
    height: 24px
    padding: 0 16px

    &-item
      text-align: center

      &--icon
        border-radius: 10px
        height: 21px
        width: 21px

        &:hover
          background-color: rgba(255, 255, 255, .25)

      &--text
        flex-grow: 1

  &__preview
    ::v-deep input
      padding-left: 16px!important
      padding-right: 16px!important

  &__date-picker,
  ::v-deep .v-picker__body
    background-color: transparent !important
    box-shadow: none
    width: 100%

  ::v-deep .v-date-picker-table
    height: auto

  ::v-deep .v-picker--date
    .v-date-picker-header,
    .v-picker__title
      display: none!important

    .v-picker__body
      width: 100%!important

      table
        border-collapse: collapse
        width: 100%

      tr
        border-bottom: 1px solid white

      td
        padding: 2px

      table .v-btn.btn--floating
        color: inherit!important
        height: 26px
        width: 26px

        &.v-btn--active:after,
        &:hover:after
          background-color: rgba(0, 0, 0, 0.2)!important
</style>
