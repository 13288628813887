import { groupBy } from 'lodash';
import moment from 'moment';

export const dailyInsectCounts = (dates, eventData) => dates.map(date => {
  const dataOnDate = eventDataOnDate(date, eventData);

  if (dataOnDate.length === 0) {
    return null;
  }

  const dataBySensorId = groupBy(dataOnDate, 'sensorId');

  let result = 0;

  Object.values(dataBySensorId).forEach(dataForSensor => {
    const counts = dataForSensor.map(({ count }) => count);
    result += Math.max(...counts);
  });

  return result;
});

export const alertPeriods = (dates, eventData) => {
  const result = [];

  const dataPerDate = dates.map(date => eventDataOnDate(date, eventData));

  const searches = [
    {
      data: dataPerDate.map(dataOnDate => dataOnDate.some(({ alert }) => alert)),
      type: 'cumulative',
    },
    {
      data: dataPerDate.map(dataOnDate => dataOnDate.some(({ increaseAlert }) => increaseAlert)),
      type: 'differential',
    },
  ];

  searches.forEach(({ data, type }) => {
    let periodStartIndex;
    let periodEndIndex;

    for (;;) {
      periodStartIndex = data.indexOf(true, periodEndIndex);
      if (periodStartIndex === -1) {
        break;
      }

      periodEndIndex = data.indexOf(false, periodStartIndex);
      if (periodEndIndex === -1) {
        periodEndIndex = data.length;
      }

      result.push({
        type,
        start: dates[periodStartIndex],
        end: dates[periodEndIndex - 1],
      });

      if (periodEndIndex === data.length) {
        break;
      }
    }
  });

  return result;
};

const eventDataOnDate = (date, eventData) => {
  const minTimestamp = moment(date).unix();
  const maxTimestamp = moment(date).endOf('day').unix();

  return eventData.filter(({ timestamp }) =>
    timestamp >= minTimestamp && timestamp <= maxTimestamp,
  );
};
