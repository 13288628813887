<template>
  <v-menu
    :close-on-content-click="false"
    offset-y
    transition="fade-transition"
    v-model="datePickerVisible"
  >
    <template #activator="props">
      <slot name="activator" v-bind="props" />
    </template>
    <v-date-picker
      :value="value"
      @input="onSelect"
      :type="type"
      no-title
      scrollable
      :locale="$i18n.locale"
      :day-format="dayFormat"
      v-bind="datePickerProps"
    />
  </v-menu>
</template>

<script>
import moment from 'moment';

export default {
  name: 'date-picker-dropdown',
  props: {
    value: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'date',
    },
    datePickerProps: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      datePickerVisible: false,
    };
  },
  methods: {
    dayFormat(date) {
      return moment(date).format('D');
    },
    onSelect(date) {
      this.datePickerVisible = false;
      this.$emit('input', date);
    },
  },
};
</script>
