import EXIF from 'exif-js';
import moment from 'moment';

const getEXIF = file => (
  new Promise((resolve) => {
    EXIF.getData(file, () => {
      resolve(file.exifdata);
    });
  })
);

export async function getFileTimestamp(file) {
  const exif = await getEXIF(file);
  if (exif.DateTime) {
    return moment(exif.DateTime, 'YYYY:MM:DD HH:mm:ss').unix();
  }
  return moment(file.lastModified).unix();
}

export const getBase64 = (file, opt) => {
  const options = opt || { asUrl: false };
  const { asUrl } = options;
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      const base64 = asUrl ?
        reader.result : reader.result.substring(reader.result.indexOf(',') + 1);
      resolve(base64);
    };
    reader.readAsDataURL(file);
  });
};
