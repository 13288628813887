var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sensor-user-list" },
    [
      _c("div", { staticClass: "sensor-user-list__title" }, [
        _vm._v(_vm._s(_vm.$t("label.users"))),
      ]),
      _vm._v(" "),
      _vm.loading
        ? [
            _c(
              "div",
              [
                _c("v-progress-circular", {
                  staticClass: "load-spinner primary--text",
                  attrs: { indeterminate: "", size: 40 },
                }),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.$t("label.loading")))]),
              ],
              1
            ),
          ]
        : [
            _c("user-list", {
              attrs: {
                users: _vm.users,
                no_users_message: _vm.$t("label.no_users"),
              },
              scopedSlots: _vm._u([
                {
                  key: "list-items",
                  fn: function (ref) {
                    var user = ref.user
                    return [
                      user.shared_by.name
                        ? [
                            _c(
                              "v-list-item",
                              {
                                staticClass: "text-caption",
                                attrs: { disabled: "" },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.$t("label.shared_by", {
                                        name: user.shared_by.name,
                                      })
                                    ) +
                                    "\n          "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("v-divider"),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "v-list-item",
                        {
                          staticClass: "red--text",
                          on: {
                            click: function ($event) {
                              return _vm.confirmRemoveUser(user)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("button.remove")) +
                              "\n        "
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
      _vm._v(" "),
      _c(
        "round-button",
        {
          staticClass: "sensor-user-list__share-sensor mt-2",
          attrs: { icon: "fa-share-alt", fullWidth: "" },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.openInvitationDialog.apply(null, arguments)
            },
          },
        },
        [_vm._v("\n    " + _vm._s(_vm.$t("button.share")) + "\n  ")]
      ),
      _vm._v(" "),
      _c("confirm-dialog", {
        attrs: { message: _vm.confirmDialog.message },
        on: { yes: _vm.confirmDialog.onConfirm },
        model: {
          value: _vm.confirmDialog.visible,
          callback: function ($$v) {
            _vm.$set(_vm.confirmDialog, "visible", $$v)
          },
          expression: "confirmDialog.visible",
        },
      }),
      _vm._v(" "),
      _c(
        "card-dialog",
        {
          attrs: { title: _vm.$t("label.share_sensor"), width: "290px" },
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function () {
                return [
                  _c(
                    "dialog-action",
                    {
                      nativeOn: {
                        click: function ($event) {
                          _vm.addUserDialog.visible = false
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("button.cancel")) +
                          "\n      "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "dialog-action",
                    {
                      attrs: {
                        disabled:
                          _vm.invitationEmail === "" ||
                          _vm.$refs.emailField.hasError,
                      },
                      on: { click: _vm.onUserAdd },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("button.invite")) +
                          "\n      "
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
          model: {
            value: _vm.addUserDialog.visible,
            callback: function ($$v) {
              _vm.$set(_vm.addUserDialog, "visible", $$v)
            },
            expression: "addUserDialog.visible",
          },
        },
        [
          _c("v-text-field", {
            ref: "emailField",
            attrs: {
              required: "",
              autofocus: "",
              label: _vm.$t("field.invitationEmail"),
              hint: _vm.$t("msg.enter_email"),
              rules: _vm.addUserDialog.emailField.rules,
            },
            model: {
              value: _vm.invitationEmail,
              callback: function ($$v) {
                _vm.invitationEmail = $$v
              },
              expression: "invitationEmail",
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }