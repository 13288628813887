var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { id: "app-signed-out" } },
    [
      _c(
        "v-container",
        { staticClass: "signin text-center", attrs: { fluid: "" } },
        [_c("div", { staticClass: "box" }, [_c("router-view")], 1)]
      ),
      _vm._v(" "),
      _c("copyright-footer", { staticClass: "text-center" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }