<template>
  <v-btn primary fab dark class="scroll-button hidden-md-and-up" v-if="position > 0" @click="scrollToTop()">
    <v-icon>keyboard_arrow_up</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'scroll-button',
  data() {
    return {
      position: 0,
    };
  },
  methods: {
    scrollToTop() {
      this.$smoothScroll({
        scrollTo: document.getElementById('app'),
      });
    },
  },
  mounted: function () {
    const self = this;
    document.onscroll = () => {
      self.position = window.pageYOffset;
    };
  },
};
</script>

<style scoped lang="sass">
.scroll-button
  height: 40px
  width: 40px
  position: fixed
  bottom: 75px
  right: 20px

  i
    font-size: 30px
</style>
