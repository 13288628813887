import Vue from 'vue';

import { startDownload } from '@/libs/downloads';

import * as types from '../constants/mutationTypes';


export const actions = {
  polling({ commit, dispatch, getters }, jobId) {
    commit(types.SET_JOB_ID, jobId);
    const polling = setInterval(async () => {
      if (!getters.getJobId) {
        clearInterval(polling);
      } else {
        const data = await dispatch('getReport');

        if (data.progress) {
          commit(types.UPDATE_PROGRESS, data.progress);
        }
        if (data.download_path) {
          commit(types.CLEAR_PENDING_DOWNLOAD);
          startDownload(data.download_path);
        }
        // Remove when deleting report_new_download_method feature toggle
        if (data.url) {
          commit(types.CLEAR_PENDING_DOWNLOAD);
          window.location.href = data.url;
        }
      }
    }, 5000);
  },
  getReport: async ({ getters }) => {
    const jobId = getters.getJobId;
    const response = await Vue.http.get(`/api/pending_jobs/${jobId}`);
    return response.data;
  },
  postGenerateReport: async (_, content) => {
    const response = await Vue.http.post('/api/v2/events/station/request_excel_generation', content);
    return response.data.job_id;
  },
  cancelGenerateReport({ commit }) {
    commit(types.CLEAR_PENDING_DOWNLOAD);
  },
};


export const mutations = {
  SET_JOB_ID(state, jobId) {
    state.jobId = jobId;
  },
  UPDATE_PROGRESS(state, progress) {
    state.progress = progress;
  },
  CLEAR_PENDING_DOWNLOAD(state) {
    state.jobId = '';
    state.progress = 0;
  },
};

export const getters = {
  getJobId(state) {
    return state.jobId;
  },
  getProgress(state) {
    return state.progress;
  },
};

export const state = () => ({
  jobId: '',
  progress: 0,
});
