var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    { attrs: { align: "center", "no-gutters": "" } },
    [
      _vm._t("icon", function () {
        return [_vm.icon ? _c("icon", { attrs: { type: _vm.icon } }) : _vm._e()]
      }),
      _vm._v(" "),
      _c("v-col", { staticClass: "d-md-flex align-md-center" }, [
        _c(
          "h1",
          {
            staticClass: "headline primary--text",
            class: { "ml-2": _vm.hasIcon },
          },
          [_vm._t("default")],
          2
        ),
        _vm._v(" "),
        _vm.details
          ? _c("span", { staticClass: "ml-2" }, [_vm._v(_vm._s(_vm.details))])
          : _vm._e(),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }