var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.event !== null
    ? _c(
        "div",
        { staticClass: "d-flex justify-center" },
        [
          _c(
            "base-dialog",
            {
              attrs: {
                "content-class": "overflow-x-hidden",
                transition: "dialog-bottom-transition",
                fullscreen: "",
                "hide-overlay": "",
                persistent: "",
              },
              model: {
                value: _vm.visible,
                callback: function ($$v) {
                  _vm.visible = $$v
                },
                expression: "visible",
              },
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-toolbar",
                    [
                      _vm.event.stationPlace
                        ? _c("v-toolbar-title", [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.event.stationPlace) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("v-spacer"),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        { attrs: { icon: "" }, on: { click: _vm.close } },
                        [_c("v-icon", [_vm._v("close")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-list", [
                    _c("div", { staticClass: "d-flex" }, [
                      _c(
                        "div",
                        { staticClass: "handle" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "align-left",
                              attrs: {
                                text: "",
                                color: "primary",
                                disabled: !_vm.hasPrevEvent,
                                "data-testid": "previous_event_button",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("previous")
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { left: "" } }, [
                                _vm._v("fa-chevron-circle-left"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "main pa-4" },
                        [
                          _c("station-event", {
                            ref: "stationEvent",
                            attrs: { event: _vm.event },
                            on: {
                              pollEvent: function ($event) {
                                return _vm.$emit("pollEvent")
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "handle" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "align-right",
                              attrs: {
                                text: "",
                                color: "primary",
                                disabled: !_vm.hasNextEvent,
                                "data-testid": "next_event_button",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("next")
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { right: "" } }, [
                                _vm._v("fa-chevron-circle-right"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }