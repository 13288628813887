var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    _vm._g(
      _vm._b(
        {
          staticClass: "bar-button",
          class: { "v-btn--active": _vm.highlight },
          attrs: { color: "primary", text: "" },
        },
        "v-btn",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c("v-icon", { class: { valueFalse: !_vm.value } }, [
        _vm._v(_vm._s(_vm.icon)),
      ]),
      _vm._v(" "),
      _c("small", [_vm._t("default")], 2),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }