var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "date-picker" },
    [
      _c("v-text-field", {
        staticClass: "date-picker__preview mb-2",
        attrs: {
          placeholder: _vm.placeholder,
          "hide-details": "",
          clearable: "",
          readonly: "",
        },
        on: { "click:clear": _vm.clear },
        model: {
          value: _vm.date,
          callback: function ($$v) {
            _vm.date = $$v
          },
          expression: "date",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "date-picker__header mb-2" }, [
        _c(
          "div",
          {
            staticClass:
              "date-picker__header-item date-picker__header-item--icon",
            on: {
              click: function ($event) {
                return _vm.paginateCalendar("year", -1)
              },
            },
          },
          [_c("i", { staticClass: "fa fa-angle-double-left" })]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "date-picker__header-item date-picker__header-item--icon",
            on: {
              click: function ($event) {
                return _vm.paginateCalendar("month", -1)
              },
            },
          },
          [_c("i", { staticClass: "fa fa-angle-left" })]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "date-picker__header-item date-picker__header-item--text",
          },
          [_c("span", [_vm._v(_vm._s(_vm.headerLabel))])]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "date-picker__header-item date-picker__header-item--icon",
            on: {
              click: function ($event) {
                return _vm.paginateCalendar("month", 1)
              },
            },
          },
          [_c("i", { staticClass: "fa fa-angle-right" })]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "date-picker__header-item date-picker__header-item--icon",
            on: {
              click: function ($event) {
                return _vm.paginateCalendar("year", 1)
              },
            },
          },
          [_c("i", { staticClass: "fa fa-angle-double-right" })]
        ),
      ]),
      _vm._v(" "),
      _c("v-date-picker", {
        ref: "picker",
        staticClass: "date-picker__date-picker",
        attrs: { value: _vm.date, locale: "en" },
        on: { input: _vm.onPick },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }