<template>
  <div>
    <page-title-area>
      <h1>S-TYPE</h1>
      <template #right-floating-content>
        <station-plan-status />
      </template>
    </page-title-area>
    <div class="d-flex align-baseline justify-end mt-4">
      <csv-import-button
        @placesCreated="loadPlaces"
      />
    </div>
    <station-place-table
      :places="places"
      :loading="loading"
      @edit="editPlace"
      @delete="confirmDelete"
      @toggleHidden="toggleHidden"
    />
    <confirm-dialog
      v-model="deleteConfirmDialogVisible"
      :message="$t('confirm_delete', { placeName: deleteTarget.name })"
      @yes="deletePlace(deleteTarget)"
    />
    <station-place-editor
      v-model="editorVisible"
      @update="updateTable"
      :place="editTarget"
    />
  </div>
</template>

<i18n lang="yaml">
ja:
  confirm_delete: '"{placeName}"を削除します。よろしいですか？'
  delete_success: '"{placeName}"を削除しました。'

en:
  confirm_delete: 'This will delete "{placeName}". Are you sure?'
  delete_success: '"{placeName}" was deleted.'
</i18n>

<script>
import Notifications from '@/mixins/notifications';


import ConfirmDialog from '@/components/atoms/ConfirmDialog';
import PageTitleArea from '@/components/atoms/PageTitleArea';

import CsvImportButton from './CsvImportButton';
import StationPlaceEditor from './StationPlaceEditor';
import StationPlaceTable from './StationPlaceTable';
import StationPlanStatus from './StationPlanStatus';

export default {
  name: 'station-places',
  mixins: [
    Notifications,
  ],
  components: {
    ConfirmDialog,
    CsvImportButton,
    PageTitleArea,
    StationPlaceEditor,
    StationPlaceTable,
    StationPlanStatus,
  },
  data() {
    return {
      places: [],
      loading: true,
      deleteConfirmDialogVisible: false,
      deleteTarget: { name: '' },
      editorVisible: false,
      editTarget: null,
    };
  },
  inject: ['fetchStationPlaces', 'updateStationPlace', 'deleteStationPlace'],
  mounted: async function () {
    await this.loadPlaces();
  },
  methods: {
    confirmDelete(place) {
      this.deleteTarget = place;
      this.deleteConfirmDialogVisible = true;
    },
    deletePlace: async function (place) {
      this.places = this.places.filter(p => p.id !== place.id);
      this.notifySuccess(this.$t('delete_success', { placeName: place.name }));
      await this.deleteStationPlace(place.id);
    },
    editPlace(place) {
      this.editTarget = place;
      this.editorVisible = true;
    },
    loadPlaces: async function () {
      this.loading = true;
      this.places = await this.fetchStationPlaces();
      this.loading = false;
    },
    toggleHidden(place) {
      const newValue = !place.hidden;
      this.updateTable({ ...place, hidden: newValue });
      this.updateStationPlace(place.id, { hidden: newValue });
    },
    updateTable(place) {
      this.places = this.places.map((p) => {
        if (p.id === place.id) {
          return place;
        }

        return p;
      });
    },
  },
  provide() {
    return {
      getStationPlaces: () => this.places,
    };
  },
  watch: {
    editorVisible(value) {
      if (!value) {
        this.editTarget = null;
      }
    },
  },
};
</script>

<style scoped lang="sass">
</style>
