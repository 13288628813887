var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-btn",
    _vm._b(
      {
        staticClass: "elevation-0",
        attrs: { rounded: "", block: _vm.fullWidth, color: _vm.color },
        on: {
          click: function (e) {
            return _vm.$emit("click", e)
          },
        },
      },
      "v-btn",
      _vm.$attrs,
      false
    ),
    [
      _vm.icon
        ? _c("v-icon", { attrs: { left: "" } }, [_vm._v(_vm._s(_vm.icon))])
        : _vm._e(),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }