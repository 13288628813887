<template>
  <div class="insect-type-snippets my-n2">
    <v-row dense>
      <v-col cols="4" class="snippet px-3 my-2" v-for="snippet, index in snippets" :key="snippet.name">
        <div class="d-flex align-center text-nowrap mb-1">
          <span class="insect-color" :style="{ backgroundColor: colors[index] }"></span>
          <span class="insect-name ml-1">{{snippet.name}}</span>
          <icon-button
            class="insect-info ml-auto"
            small
            icon="info"
            @click="showInsectInfo(snippet.type)"
            v-if="snippet.type"
          />
        </div>
        <div class="sample-image mb-1">
          <img :src="insectImages[snippet.type]" v-if="insectImages[snippet.type]" />
          <span v-else>No image</span>
        </div>
        <div class="d-flex align-center justify-space-between">
          <div class="d-flex align-center justify-start">
            <div class="route" :style="{ backgroundColor: routeColors[snippet.route] }">
              {{ $t(`route.${snippet.route}`) }}
            </div>
            <insect-cause-icon size="18" :cause="cause" :key="cause" v-for="cause in getCauses(snippet.type)" />
          </div>
          <div>{{snippet.count | numeric}}</div>
        </div>
      </v-col>
    </v-row>
    <card-dialog v-model="insectExplanationVisible" width="600px">
      <insect-description :insect-type="insectExplanationType" />
      <template #actions>
        <dialog-action @click="insectExplanationVisible = false">{{ $t('button.close') }}</dialog-action>
      </template>
    </card-dialog>
  </div>
</template>

<i18n lang="yaml">
ja:
  route:
    indoor: 内部
    outdoor: 外部

  button:
    close: 閉じる

en:
  route:
    indoor: Indoor
    outdoor: Outdoor

  button:
    close: Close
</i18n>

<script>
import insectPhotos from 'Data/insect_photos';

import InsectTypes from '@/mixins/insectTypes';

import CardDialog from '@/components/atoms/CardDialog';
import DialogAction from '@/components/atoms/DialogAction';
import IconButton from '@/components/atoms/IconButton';
import InsectCauseIcon from '@/components/atoms/InsectCauseIcon';
import InsectDescription from '@/components/organisms/InsectDescription';

import { indoorColor, outdoorColor } from '../colors';

export default {
  name: 'insect-type-snippets',
  props: {
    insects: {
      type: Array, // [{ name: string, type: string, route: string, count: number }]
      required: true,
    },
    colors: {
      type: Array,
      required: true,
    },
  },
  components: {
    CardDialog,
    DialogAction,
    IconButton,
    InsectCauseIcon,
    InsectDescription,
  },
  mixins: [InsectTypes],
  data() {
    return {
      insectExplanationType: null,
      insectExplanationVisible: false,
    };
  },
  computed: {
    insectImages() {
      return insectPhotos;
    },
    snippets() {
      return this.insects;
    },
    routeColors() {
      return {
        indoor: indoorColor,
        outdoor: outdoorColor,
      };
    },
  },
  methods: {
    getCauses(insectType) {
      if (!insectType) {
        return [];
      }
      return this.INSECT_TYPES[insectType].causes || [];
    },
    showInsectInfo(insectType) {
      this.insectExplanationType = insectType;
      this.insectExplanationVisible = true;
    },
  },
  filters: {
    numeric(value) {
      return value.toLocaleString();
    },
  },
};
</script>

<style scoped lang="sass">
.insect-type-snippets
  display: flex
  flex-wrap: wrap

.snippet
  width: 150px

.sample-image
  width: 100%
  aspect-ratio: 16 / 9
  background-color: #f0f0f0
  display: flex
  align-items: center
  justify-content: center

  img
    width: 100%
    height: 100%
    object-fit: contain

.insect-color
  display: inline-block
  min-width: 1rem
  min-height: 1rem

.insect-name
  overflow: hidden
  text-overflow: ellipsis

.insect-info
  height: 21px !important

.route
  color: #fff
  font-size: 0.8em
  font-weight: bold
  padding: 0.2em 0.5em 0
  border-radius: 0.2em
  margin-right: 2px

  &.indoor
    background-color: #1976d2

  &.outdoor
    background-color: #e98731
</style>
