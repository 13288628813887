<template>
  <div class="document-list pb-4">
    <div class="document-list-btn">
      <round-button
        class="document-list-round-btn ma-0"
        :icon="expanded ? 'fa-chevron-circle-up' : 'fa-chevron-circle-down'"
        @click="expanded = !expanded"
      >
        <span class="font-weight-bold">{{ SENSOR_TYPE_NAMES[sensorType] }}</span>
      </round-button>
    </div>
    <div v-if="expanded">
      <div class="document-list-item pl-5 pt-2" v-for="item in documents">
        <div v-if="item.fileName">
          <span class="font-weight-bold pr-3">・{{ $t(item.title) }}</span>
          <span v-if="isRecently(item.uploaded)" class="document-list-item-new">New</span>
          <a href="#" @click.prevent.stop="download(item.fileName)">{{ item.fileName }}</a>
          <span class="document-list-item-timestamp">{{ formatDate(item.uploaded) }}</span>
        </div>
      </div>
      <div class="pl-5 pt-3" v-if="sensorType == 'station'">
        <youtube :video-id="STATION_REPORT_FEATURE_VIDEO_ID"></youtube>
      </div>
    </div>
  </div>
</template>

<i18n lang="yaml">
ja:
  manual: 取扱説明書
  station_manual_until_4_7_16: 取扱説明書 (〜ver. 4.7.16)
  station_manual_from_4_7_21: 取扱説明書 (ver. 4.7.21〜)
  app: アプリケーション
  identifiable_insect: 同定可能虫種

en:
  manual: Manual
  station_manual_until_4_7_16: Manual (〜ver. 4.7.16)
  station_manual_from_4_7_21: Manual (ver. 4.7.21〜)
  app: Application
  identifiable_insect: Identifiable insect species
</i18n>

<script>
import moment from 'moment';
import VueYoutube from 'vue-youtube';

import { startDownload } from '@/libs/downloads';

import Features from '@/mixins/features';
import SensorTypes from '@/mixins/sensorTypes';

import RoundButton from '@/components/atoms/RoundButton';

export default {
  name: 'document-list',
  mixins: [
    Features,
    SensorTypes,
  ],
  components: {
    RoundButton,
    VueYoutube,
  },
  props: {
    sensorType: String,
    documents: Array,
  },
  data: function () {
    return {
      expanded: true,
    };
  },
  computed: {
    STATION_REPORT_FEATURE_VIDEO_ID() {
      return '_Z62XzCZ7k8';
    },
  },
  methods: {
    download: async function (fileName) {
      if (!this.featureIsActive('information_tab_new_download_method')) {
        const response = await this.$http.get(`/api/documents/${this.sensorType}/${fileName}/download_url`);
        window.open(response.data.url, '_blank');
      }

      const { data: { download_path: downloadPath } } = await this.$http.get(
        `/api/documents/${this.sensorType}/${fileName}/download_path`,
      );

      startDownload(downloadPath);
    },
    isRecently(timestamp) {
      const oneMonthAgo = moment().subtract(30, 'days');
      return moment.unix(timestamp).isAfter(oneMonthAgo);
    },
    formatDate(timestamp) {
      return moment.unix(timestamp).format('YYYY-MM-DD');
    },
  },
};
</script>

<style lang="sass">
.document
  &-list
    &-round-btn
      font-size: large !important
      .v-btn__content
        display: flex
        align-items: center

    &-item
      font-size: larger
      display: flex
      align-items: center
      &-new
        font-size: smaller
        color: red
      &-timestamp
        padding-left: 5px
        font-size: small
</style>
