<template>
  <div class="replacement-notification" v-if="sensor.trap_replaced_on">
    <div class="replacement-notification__box">
      {{ $t('status', { date: replaced, days: daysRemaining }) }}
    </div>
    <div class="replacement-notification__box replacement-notification__box--warning" v-if="daysExceeded">
      {{ $t('warning', { days: daysExceeded }) }}
    </div>
  </div>
</template>

<i18n lang="yaml">
ja:
  status: 前回捕虫紙交換日:{date}  交換まで{days}日
  warning:
    前回捕虫紙交換日から{days}日を過ぎています、交換してください。

en:
  status: Trap replaced on {date} ({days} days until next replacement)
  warning:
    Trap was supposed to be replaced {days} days ago. Please replace the trap.
</i18n>

<script>
export default {
  name: 'replacement-notification',
  props: {
    sensor: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    daysExceeded() {
      const result = this.interval - this.daysSinceReplacement;
      if (result < 0) {
        return Math.abs(result);
      }
      return null;
    },
    daysRemaining() {
      const result = this.interval - this.daysSinceReplacement;
      if (result > 0) {
        return result;
      }
      return 0;
    },
    replaced() {
      return this.$moment(this.sensor.trap_replaced_on).format('YYYY/MM/DD');
    },
    daysSinceReplacement() {
      const today = this.$moment();
      const replaced = this.$moment(this.sensor.trap_replaced_on);
      return today.diff(replaced, 'days');
    },
    interval() {
      if (this.sensor.trap_effective_days != null) {
        return this.sensor.trap_effective_days;
      }
      return 30; // as default
    },
  },
};
</script>

<style scoped lang="sass">
@import 'vuetify/src/styles/styles.sass'

.replacement-notification
  display: inline-flex
  flex-direction: column
  flex: auto 0 0

  &__box
    background-color: #fff
    color: #0068b6
    display: inline-block
    font-size: 16px
    font-weight: bold
    letter-spacing: 1pt
    padding: 0.5rem 1rem
    margin: 0.25rem

    &--warning
      color: #ef8200
</style>
