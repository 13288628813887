<template>
  <v-row class="content-header align-md-center flex-column flex-md-row" no-gutters>
    <component :is="headerWrapper" :to="to" class="content-header__title">
      <v-row class="align-center" no-gutters>
        <div class="mr-2" v-if="this.$slots.icon">
          <slot name="icon"/>
        </div>
        <div class="overflow-hidden">
          <div class="d-flex align-md-baseline flex-column flex-md-row">
            <div :class="['primary--text', 'overflow-ellipsis', titleClass]">
              <template v-if="!!title">{{ title }}</template>
              <template v-else-if="$slots.title"><slot name="title" /></template>
            </div>
            <template v-if="!!subtitle && typeof subtitle === 'string'">
              <div :class="['overflow-ellipsis', subtitleClass]">{{ subtitle }}</div>
            </template>
            <template v-else>
              <slot name="subtitle" />
            </template>
          </div>
          <div class="caption my-1 hidden-sm-and-down">{{ description }}</div>
        </div>
        <counter class="hidden-md-and-up ml-auto" :count="count" v-if="hasCount" />
      </v-row>
      <div class="caption my-1 hidden-md-and-up">{{ description }}</div>
    </component>
    <slot />
    <counter class="hidden-sm-and-down ml-auto" :count="count" v-if="hasCount" />
  </v-row>
</template>

<script>
import Counter from './Counter';
import ButtonWrapper from './ButtonWrapper';
import DefaultWrapper from './DefaultWrapper';

export default {
  name: 'content-header',
  components: {
    Counter,
    ButtonWrapper,
    DefaultWrapper,
  },
  computed: {
    hasCount() {
      return this.count != null;
    },
    headerWrapper() {
      return this.to ? ButtonWrapper : DefaultWrapper;
    },
  },
  props: {
    count: [Number, String],
    description: String,
    title: String,
    titleClass: String,
    subtitle: String,
    subtitleClass: String,
    to: String,
  },
};
</script>

<style scoped lang="sass">
.content-header
  &__title
    color: inherit !important
    height: auto!important
    margin: -0.5rem!important
    padding: 0.5rem!important

    ::v-deep .v-btn__content
      display: block
      padding: 0
</style>
