<template>
  <card-dialog persistent v-model="dialogVisible" content-class="insect-count-line-charts-dialog">
    <template #activator="{ on, attrs }">
      <round-button color="white" icon="show_chart" v-on="on" v-bind="attrs" :disabled="applicableSensors.length == 0">
        {{ $t('display_insect_graphs') }}
      </round-button>
    </template>
    <div>
      <chart-list :month.sync="month" :sensors="applicableSensors" />
    </div>
    <template #actions>
      <dialog-action @click="close">{{ $t('close') }}</dialog-action>
    </template>
  </card-dialog>
</template>

<i18n lang="yaml">
ja:
  display_insect_graphs: '捕虫グラフ表示'
  close: '閉じる'

en:
  display_insect_graphs: 'Display Insect Graphs'
  close: 'Close'
</i18n>

<script>
import moment from 'moment';

import CardDialog from '@/components/atoms/CardDialog';
import DialogAction from '@/components/atoms/DialogAction';
import RoundButton from '@/components/atoms/RoundButton';
import ChartList from './ChartList';

export default {
  name: 'insect-count-line-charts-button',
  components: {
    CardDialog,
    DialogAction,
    RoundButton,
    ChartList,
  },
  props: {
    sensors: {
      type: Array,
      required: true,
    },
    requestParams: {
      type: Object,
      required: true,
    },
  },
  computed: {
    applicableSensors() {
      return this.sensors.filter(sensor => sensor.type === 'fly' || sensor.type === 'fly_count');
    },
  },
  data() {
    return {
      dialogVisible: false,
      month: this.calcInitialMonth(),
    };
  },
  methods: {
    calcInitialMonth() {
      return moment().format('YYYY-MM');
    },
    close() {
      this.dialogVisible = false;
    },
  },
  watch: {
    dialogVisible(wasOpened) {
      if (wasOpened) {
        this.month = this.calcInitialMonth();
      }
    },
  },
};
</script>

<style lang="sass">
.v-dialog.insect-count-line-charts-dialog
  // Make sure Close action is always visible
  .v-card__text
    max-height: 80vh
    overflow-y: auto
</style>
