import { render, staticRenderFns } from "./RatView.vue?vue&type=template&id=24dd560d&scoped=true&"
import script from "./RatView.vue?vue&type=script&lang=js&"
export * from "./RatView.vue?vue&type=script&lang=js&"
import style0 from "./RatView.vue?vue&type=style&index=0&id=24dd560d&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24dd560d",
  null
  
)

/* custom blocks */
import block0 from "./RatView.vue?vue&type=custom&index=0&blockType=i18n&lang=yaml"
if (typeof block0 === 'function') block0(component)

/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/project/client/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('24dd560d')) {
      api.createRecord('24dd560d', component.options)
    } else {
      api.reload('24dd560d', component.options)
    }
    module.hot.accept("./RatView.vue?vue&type=template&id=24dd560d&scoped=true&", function () {
      api.rerender('24dd560d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/GalleryView/RatView/RatView.vue"
export default component.exports