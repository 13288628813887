var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "query-filter pa-4" },
    [
      _vm._t("default"),
      _vm._v(" "),
      _c("v-divider", { staticClass: "my-4 px-n6" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _c(
            "round-button",
            {
              attrs: {
                icon: "cached",
                loading: _vm.loading,
                disabled: _vm.loading || !_vm.applyable,
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("apply")
                },
              },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("button.apply_filter")) + "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "round-button",
            {
              attrs: {
                icon: "clear",
                loading: _vm.loading,
                disabled: _vm.loading || !_vm.clearable,
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("clear")
                },
              },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("button.clear_filter")) + "\n    "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }