var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "card-dialog",
    {
      attrs: {
        title: _vm.$t("confirmation"),
        value: _vm.value,
        role: "dialog",
        width: "290px",
      },
      on: {
        input: function (val) {
          return _vm.$emit("input", val)
        },
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c(
                "dialog-action",
                {
                  on: {
                    click: function ($event) {
                      return _vm.handleAction("no")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("no")))]
              ),
              _vm._v(" "),
              _c(
                "dialog-action",
                {
                  on: {
                    click: function ($event) {
                      return _vm.handleAction("yes")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("yes")))]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [_vm._v("\n  " + _vm._s(_vm.message) + "\n  ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }