var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "sensor-type d-flex align-center" },
    [
      _vm.type
        ? _c("icon", { attrs: { type: _vm.type, size: 36 } })
        : _c("sensor-icon", { attrs: { sensor: _vm.sensor, size: 36 } }),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s(_vm.label))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }