export const LOCALES = {
  en: 'English',
  ja: '日本語',
};

export default {
  computed: {
    locales() {
      return LOCALES;
    },
    locale_options() {
      return this._.map(LOCALES, (name, locale) => ({ text: name, value: locale }));
    },
  },
};
