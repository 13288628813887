var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pv-select-field" },
    [
      _vm.edit
        ? _c(
            "select-box",
            _vm._b(
              {
                ref: "select",
                attrs: {
                  items: _vm.items,
                  rules: _vm.rules,
                  value: _vm.value,
                  "data-testid": _vm.dataTestid,
                },
                on: {
                  input: function (v) {
                    return _vm.$emit("input", v)
                  },
                },
              },
              "select-box",
              _vm.$attrs,
              false
            )
          )
        : _c("span", [_vm._v(_vm._s(_vm.selected.text))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }