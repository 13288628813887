<template>
  <polling-channel
    :input="event"
    :active="pollingActive"
    :updateFunction="updateEvent"
    @valueUpdated="onEventUpdate"
  >
    <template v-slot="props">
      <slot :event="props.currentValue"/>
    </template>
  </polling-channel>
</template>

<script>
import PollingChannel from '@/components/atoms/PollingChannel';

export default {
  name: 'StationEventPolling',
  components: {
    PollingChannel,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      pollingActive: this.needsPolling(this.event),
    };
  },
  methods: {
    needsPolling(event) {
      if (!event) {
        return false;
      }
      return event.updating || event.processing;
    },
    onEventUpdate(updatedEvent) {
      this.$emit('eventUpdated', updatedEvent);
      if (!this.needsPolling(updatedEvent)) {
        this.pollingActive = false;
      }
    },
    async updateEvent() {
      try {
        const { data } = await this.$http.get(`/api/v2/events/${this.event.id}`);
        return data;
      } catch (_event) {
        return this.event;
      }
    },
  },
  watch: {
    event(value) {
      this.pollingActive = false;
      this.$nextTick().then(() => {
        this.pollingActive = this.needsPolling(value);
      });
    },
  },
};
</script>
